<div style="margin-top: 30px;"> 
    <div class="row"> 
        <div class="form-group col-lg-12">
            <span style="font-family: 'Montserrat'; color: blue; font-weight: 500">Kijk uw winkelmandje na; voer promocode in op tabblad 3.</span> 
        </div>
        <div class="form-group col-lg-12">
            <table class="andro_responsive-table">
    <colgroup>
        <col span="1" style="width: 5%;">
        <col span="1" style="width: 55%;">
        <col span="1" style="width: 10%;">
        <col span="1" style="width: 10%;">
        <col span="1" style="width: 10%;">
        <col span="1" style="width: 10%;">
    </colgroup>
    <thead>
        <tr>
            <th class="remove-item"></th>
            <th>Product</th>
            <th>Prijs</th>
            <th [ngStyle]="setMyStyles_korting()">Korting</th>
            <th>Hoeveelheid</th>
            <th>Totaal</th>
        </tr>
    </thead>
    <tbody *ngFor="let cartitem of list_cart">
        <tr>
            <td class="remove">
                <button type="button" class="close-btn close-danger remove-from-cart" (click)="remove(cartitem)" title="item verwijderen">
                    <span></span>
                    <span></span>
                </button>
            </td>
            <td data-title="Product">
                <div class="andro_cart-product-wrapper">
                    <a *ngIf="cartitem.product.photo != ''" href="javascript:void(0)" (click)="select_product(cartitem.product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true">
                        <img src="{{cartitem.product.photo}}" alt="prod1">
                    </a>
                    <div class="andro_cart-product-body" *ngIf="cartitem.product.principle == 'code'">
                        <h6> {{cartitem.product.name}} </h6>
                    </div>
                    <div class="andro_cart-product-body" *ngIf="cartitem.product.principle != 'code'">
                        <h6> <a href="javascript:void(0)" (click)="select_product(cartitem.product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true">{{cartitem.product.name}}</a> </h6>
                    </div>
                </div>
            </td>
            <td data-title="Prijs"> {{cartitem.product.price_ori}}€ </td>                    
            <td data-title="Korting" [ngStyle]="setMyStyles_korting()"> {{cartitem.product.discount_text}} </td>
            <td class="quantity" data-title="Hoeveelheid">
                <span style="padding-left: 15px;" *ngIf="cartitem.product.principle == 'code'">1</span>
                <input type="number" *ngIf="cartitem.product.principle != 'code'" class="qty form-control" id="{{cartitem.product.cnk}}" min="0" value="{{cartitem.quantity}}" (input)="adjust_quantity(cartitem)">
            </td>
            <td data-title="Total"> {{(cartitem.product.price-0)*(cartitem.quantity) | number: '1.0-2'}}€ </td>
        </tr>
        <tr [ngStyle]="setMyStyles_freeproduct(cartitem)">
            <td></td>
            <td data-title="Product">
                <div class="andro_cart-product-wrapper">
                    <a href="javascript:void(0)" (click)="select_product(cartitem.product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true">
                        <img src="{{cartitem.product.photo}}" alt="prod1">
                    </a>
                    <div class="andro_cart-product-body">
                        <h6> <a href="javascript:void(0)" (click)="select_product(cartitem.product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true">{{cartitem.product.name}} (gratis)</a> </h6>
                    </div>
                </div>
            </td>
            <td data-title="Prijs"></td>                    
            <td data-title="Korting" [ngStyle]="setMyStyles_korting()"></td>
            <td data-title="Hoeveelheid"> <span style="padding-left: 15px;">{{cartitem.quantity_free}}</span> </td>
            <td data-title="Total"></td>    
        </tr>
    </tbody>
</table>
<div class="row"  style="margin-top: 50px;">
<div class="col-lg-3"></div> 
<!-- <div class="col-lg-4">
    <div class="form-group mb-0">
        <div class="input-group mb-0">
            <input type="text" id="code_txt"  (keyup.enter)="apply_code()" class="form-control" placeholder="Kortingscode invoeren" aria-label="Kortingscode">
            <div class="input-group-append">
                <button class="andro_btn-custom shadow-none" type="button" (click)="apply_code()">Toepassen</button>
            </div>
        </div>
    </div>
</div> -->
<div class="col-lg-6">
    <table>
        <tbody>
            <tr>
                <th>subtotaal</th>
                <td>{{totaal | number: '1.0-2'}} €</td>
            </tr>
            <tr>
                <th>BTW</th>
                <td>{{totaal_btw | number: '1.0-2'}} €</td>
            </tr>
            <tr>
                <th> <b>Totaal <br>(excl. verzendingskost/promocode)</b></th>
                <td> <b>{{totaal + totaal_btw | number: '1.0-2' }} €</b> </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="col-lg-3"></div> 

</div>
<button data-toggle="modal" data-target="#enter_mail" style="background: transparent; border: none !important; font-size: 0; outline: none;"  #btn></button>
</div>

