<!-- Aside (Right Panel) -->
<button data-toggle="modal" data-target="#pwd_wrong" style="background: transparent; border: none !important; font-size: 0; outline: none;" #btn1></button>
<aside class="andro_aside andro_aside-right" title="Categorieën">
    <div class="sidebar">
        <!-- Popular Categories Start -->
        <div class="sidebar-widget widget-categories-icons">
            <h5 class="widget-title">Categorieën</h5>
            <div class="row">
                <div id="top"></div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a  routerLink='/shop-v1' (click)="change_page('Afslanken')">
                            <i class="flaticon-diet"></i>
                            <h5>Afslanken</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Baby')">
                            <i class="flaticon-baby"></i>
                            <h5>Baby & mama</h5>
                        </a>
                    </div>
                </div>
                <!-- <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Corona')">
                            <img src="assets/icons/kid.png" style="color: gray; height: 40px; margin-bottom: 14px;">
                            <h5>Corona</h5>
                        </a>
                    </div>
                </div> -->
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Wondzorg')">
                            <img src="assets/icons/firstaid.png" style="color: gray; height: 40px; margin-bottom: 14px;">
                            <h5>Eerste hulp</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Huid')">
                            <img src="assets/icons/treatment.png" style="color: gray; height: 40px; margin-bottom: 14px;">
                            <h5>Huid</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Vitamines')">
                            <i class="flaticon-supplements"></i>
                            <h5>Vitamines</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Seksualiteit')">
                            <img src="assets/icons/sexual.png" style="color: gray; height: 40px; margin-bottom: 14px;">
                            <h5>Seksualiteit</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Fytotherapie')">
                            <img src="assets/icons/leaf.png" style="color: gray; height: 40px; margin-bottom: 14px;">
                            <h5>Natuur- geneeskunde</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Oog')">
                            <img src="assets/icons/sun.png" style="color: gray; height: 40px; margin-bottom: 14px;">
                            <h5>Keel / neus / oren</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Geneesmiddelen')">
                            <img src="assets/icons/house.png" style="color: gray; height: 40px; margin-bottom: 14px;">
                            <h5>Geneesmiddelen</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/shop-v1' (click)="change_page('Dieren')">
                            <img src="assets/icons/pet-house.png" style="color: gray; height: 40px; margin-bottom: 14px;">
                            <h5>Dieren & Insecten</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Popular Categories End -->       
    </div>
</aside>
<div class="andro_aside-overlay aside-trigger-right"></div>
<!-- Aside (Mobile Navigation) -->
<aside class="andro_aside andro_aside-left" style="background-color: rgb(204, 236, 204) !important; ;">
    <!-- <a class="navbar-brand" routerLink='/'> <img style="height: 100px;" src="assets/img/logo_def.jpg" alt="logo"> </a> -->
    
    <ul>
        <li class="menu-item"> <a routerLink='' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
            <span style="color: #5d5d5d;" class="hoveritem">Home</span></a> </li>
        <li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
            <span style="color: #5d5d5d;" class="hoveritem">Over APO@HOME</span></a> </li>
        <li class="menu-item"> <a routerLink='/blog-masonary' (click)="change_cat('')" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
            <span style="color: #5d5d5d;" class="hoveritem">Nieuws & informatie</span></a> </li>
        <li class="menu-item"> <a routerLink='/contact' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
            <span style="color: #5d5d5d;" class="hoveritem">Hoe bestellen?</span></a> </li>
        <!-- <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Home Pages</a>
            <ul class="sub-menu">
                <li class="menu-item"> <a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v11</a> </li>
                <li class="menu-item"> <a routerLink='/home-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v2</a> </li>
                <li class="menu-item"> <a routerLink='/home-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v3</a> </li>
            </ul>
        </li>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Blog</a>
            <ul class="sub-menu">
                <li class="menu-item menu-item-has-children">
                    <a href="javascript:void(0)">Blog Archive</a>
                    <ul class="sub-menu">
                        <li class="menu-item"> <a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid View</a> </li>
                        <li class="menu-item"> <a routerLink='/blog-list' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">List View</a> </li>
                        <li class="menu-item"> <a routerLink='/blog-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Masonry View</a> </li>
                    </ul>
                </li>
                <li class="menu-item"> <a routerLink='/blog-single' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Single</a> </li>
            </ul>
        </li>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Pages</a>
            <ul class="sub-menu">
                <li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a> </li>
                <li class="menu-item"> <a routerLink='/login' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a> </li>
                <li class="menu-item"> <a routerLink='/register' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a> </li>
                <li class="menu-item"> <a routerLink='/checkout' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a> </li>
                <li class="menu-item"> <a routerLink='/cart' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a> </li>
                <li class="menu-item"> <a routerLink='/wishlist' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wish List</a> </li>
                <li class="menu-item"> <a routerLink='/legal' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Legal</a> </li>
                
            </ul>
        </li>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Shop Pages</a>
            <ul class="sub-menu">
                <li class="menu-item"> <a routerLink='/shop-v1' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v1 (Default) </a> </li>
                <li class="menu-item"> <a routerLink='/shop-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v2 (Full Width) </a> </li>
                <li class="menu-item"> <a routerLink='/shop-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v3 (No Sidebar) </a> </li>
                <li class="menu-item"> <a routerLink='/shop-v4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v4 (List view) </a> </li>
            </ul>
        </li>
        <li class="menu-item"> <a routerLink='/contact' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a> </li> -->
    </ul>
</aside>
<div class="andro_aside-overlay aside-trigger-left"></div>
<!-- Header Start -->
<header class="andro_header header-3 can-sticky">
    <!-- Topheader Start -->
    <div class="andro_header-top" style="background-color: #ebecf0;">
        <div class="container nomobile">
            <div class="andro_header-top-inner" style="height: 50px;">
                <ul class="andro_header-top-sm andro_sm">
                    <li><a routerLink='' title="Home"><img style="height: 185px; margin-top: 150px; z-index:100; position: relative;" src="assets/img/logo_def.jpg"></a></li>
                    <li style="color: #5d5d5d;"> <i class="fas fa-phone-square" style="margin-left: 17px;padding-right: 7px;"></i> +32 52 21 18 00 </li>
                    <li style="color: #5d5d5d;"> <i class="fas fa-fax" style="padding-right: 7px;"></i> +32 52 21 31 24 </li>
                    <li style="color: #5d5d5d;"> <i class="fas fa-envelope-square" style="padding-right: 7px;"></i> info@apoathome.be </li>
                    <li> <a  routerLink='/sale_point'> <span style="color: #5d5d5d;" class="hoveritem"> <i class="fas fa-store" style="padding-right: 7px;"></i>verkooppunten</span> </a> </li>
                </ul>
                <ul class="andro_header-top-links">
                    <!-- <li class="menu-item" [ngStyle]="setMyStyles_favoriet()"><a href="javascript: void(0)" (click)="logout()">Mijn Account</a></li> -->                   
                    <li class="menu-item" [ngStyle]="{'display': !favvisible ? '' : 'none'}" > 
                        <a href="javascript: void(0)" data-toggle="modal" data-target="#login_fb" data-backdrop="static" data-keyboard="false">
                            <span class="andro_search-adv-cats" style="background-color: transparent; color: #5d5d5d; font-size: 14px;">
                            Inloggen
                            </span>
                        </a>                       
                    </li>
                    <li class="menu-item" [ngStyle]="{'display': favvisible ? '' : 'none'}">
                        <div class="andro_search-adv-cats" style="background-color: transparent; color: #5d5d5d;">
                            Mijn account                             
                            <div class="sub-menu">
                                <a data-toggle="modal" data-target="#Accountmodal" (click)="change_modus()">Mijn gegevens</a> 
                                <a routerLink='/last_orders'> Vorige bestellingen </a> 
                                <a href="javascript: void(0)" (click)="logout()"> Uitloggen</a>                                 
                            </div>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- Topheader End -->
    <!-- Middle Header Start -->
    <div class="andro_header-middle" style="background-color: #ebecf0; border:1px solid white;">
        <div class="container">
            <nav class="navbar">
                <!-- Search Form -->
                <!-- <div class="andro_search-adv" style="margin-left: 30%;">
                    <form method="post">                        
                        <div class="andro_search-adv-input">
                            <input style="border-color: rgba(81,189,77,255) ; border-width: 1px; border-radius: 50px;" type="text" class="form-control" placeholder="Zoek in meer dan 50.000 producten" name="search" value="">
                            <button type="submit" name="button" style="background-color: white; border-width: 0px; border-color: rgba(81,189,77,255) !important"><i class="fa fa-search"></i></button>
                        </div>
                    </form>                    
                </div> -->
                <button data-toggle="modal" data-target="#construction" style="background: transparent; border: none !important; font-size: 0; outline: none;" #btn></button>
                <div class="andro_search-adv" style="margin-left: 15%;">
                    <form method="post">
                        <div class="andro_search-adv-input">
                            <input type="text" style="width: 90%; border-radius: 50px 0px 0px 50px; border-width: 2px 0px 2px 2px; border-color: rgba(81,189,77,255) ;" matInput [formControl]="myControl" [matAutocomplete]="auto"  class="form-control" placeholder="Zoek in 50.000 producten (op naam of CNK-code)" name="search" value="">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="change_page('_' + $event.option.value)">
                                <mat-option *ngFor="let option of filteredOptions" [value]="option.name">
                                  {{option.name}}
                                </mat-option>
                              </mat-autocomplete>
                            <button (click)="change_page('_' + searchitem)" type="submit" style="background-color: rgba(81,189,77,255);" name="button"><i class="fa fa-search" style="color: white;"></i></button>
                        </div>
                        <!-- <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Zoek in 50.000 producten</mat-label>
                            <input style="width: 90%; border-radius: 50px 0px 0px 50px; border-width: 2px 0px 2px 2px; border-color: rgba(81,189,77,255) ;"  class="form-control" type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions" [value]="option.name">
                                  {{option.name}}
                                </mat-option>
                              </mat-autocomplete>
                              <button (click)="change_page('_' + searchitem)" type="submit" style="background-color: rgba(81,189,77,255);" name="button"><i class="fa fa-search" style="color: white;"></i></button>
                          </mat-form-field> -->
                    </form>

                    
                </div>
                <div class="andro_header-controls">                   
                    <ul style="position: absolute; left: 5px; top: 7px">                        
                        <li class="menu-item">
                            <div class="andro_search-adv-cats" [ngStyle]="setMyStyles_user()" style="width: 58px; height: 58px; padding: 15px;">
                                <i [ngStyle]="{'color': favvisible ? 'white' : 'gray'}" class="fas fa-user fa-2x"></i>
                                <div class="sub-menu" style="width: 300% !important;" [ngStyle]="{'display': favvisible ? '' : 'none'}">
                                        <a data-toggle="modal" data-target="#Accountmodal" (click)="change_modus()">Mijn gegevens</a> 
                                    <a routerLink='/last_orders'> Vorige bestellingen </a> 
                                    <a href="javascript: void(0)" (click)="logout()"> Uitloggen</a>                                 
                                </div>
                                <div class="sub-menu" style="width: 300% !important;"  [ngStyle]="{'display': favvisible ? 'none' : ''}">                                    
                                    <a href="javascript: void(0)" data-toggle="modal" data-target="#login_fb" data-backdrop="static" data-keyboard="false">
                                        <span class="andro_search-adv-cats" style="background-color: transparent; color: #5d5d5d; font-size: 14px;">
                                        Inloggen
                                        </span>
                                    </a> 
                                </div>
                            </div>
    
                        </li>
                        
                    </ul>
                    <ul class="andro_header-controls-inner">                        
                        <li class="andro_header-favorites" [ngStyle]="{'visibility': favvisible ? '' : 'hidden'}"> 
                            <a [class.disabled]="favdisabled ? true : null" [ngStyle]="setMyStyles_favoriet_color()" (mouseover)="hover1=true" (mouseleave)="hover1=false" routerLink='/shop-v1' 
                            (click)="change_page('favorieten')" title="Favorieten">
                                <span matBadge="{{aantal_favorieten}}" matBadgeSize="small">
                                    <i class="flaticon-like" [ngStyle]="setMyStyles_favoriet_color1()"></i> 
                                </span>  
                            </a>
                        </li>
                        <li (mouseover)="hover=true" (mouseleave)="hover=false" class="andro_header-cart" [ngStyle]="setMyStyles_cart()">
                            <a routerLink='/cart' title="Uw winkelmandje" [class.disabled]="cartdisabled ? true : null" >
                                <i  [ngStyle]="setMyStyles_cart_color()" class="flaticon-shopping-basket"></i>
                                <div class="andro_header-cart-content">
                                    <span [ngStyle]="setMyStyles_cart_color()">{{aantal_mandje}} Items</span>
                                    <span [ngStyle]="setMyStyles_cart_color()">{{kostprijs_mandje}} €</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- Toggler -->
                    <div class="aside-toggler aside-trigger-left">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <!-- Middle Header End -->
    <!-- Bottom Header Start -->
    <div class="andro_header-bottom"  style="background-color: #ebecf0;">
        <div class="container">
            <div class="andro_header-bottom-inner">
                <div class="andro_search-adv-cats" style="visibility: hidden; background-color: rgba(81,189,77,255); border-radius: 0px; width: 265px; height: 65px;">
                    <h6 style="font-weight: normal; font-size: 14px; line-height: 1.7; color: white; margin-top: 20px; margin-right: 110px;"> <i class="fas fa-th-large" style="padding-right: 5px"></i> Categorieën </h6>
                    <div class="sub-menu">
                        <a routerLink='/shop-v1' (click)="change_page('Afslanken')"> Afslanken </a> 
                        <a routerLink='/shop-v1' (click)="change_page('Baby')"> Baby & mama</a> 
                        <a routerLink='/shop-v1' (click)="change_page('Wondzorg')"> Eerste hulp</a> 
                        <a routerLink='/shop-v1' (click)="change_page('Huid')"> Lichaam en gelaat</a> 
                        <a routerLink='/shop-v1' (click)="change_page('Vitamines')"> Vitamines/voedingssupplementen</a> 
                        <a routerLink='/shop-v1' (click)="change_page('Seksualiteit')"> Seksualiteit</a> 
                        <a routerLink='/shop-v1' (click)="change_page('Fytotherapie')"> Natuurgeneeskunde</a> 
                        <a routerLink='/shop-v1' (click)="change_page('Thuiszorg')"> Thuiszorg</a> 
                        <a routerLink='/shop-v1' (click)="change_page('Dieren')"> Dieren & Insecten</a> 
                    </div>
                </div>
                <!-- Menu -->
                <ul class="navbar-nav" style="margin-left: 2%;">
                    <!-- <li class="menu-item menu-item-has-children">
                        <a href="javascript:void(0)">Home Pages</a>
                        <ul class="sub-menu">
                            <li class="menu-item"> <a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v1</a> </li>
                            <li class="menu-item"> <a routerLink='/home-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v2</a> </li>
                            <li class="menu-item"> <a routerLink='/home-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v3</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children">
                        <a href="javascript:void(0)">Blog</a>
                        <ul class="sub-menu">
                            <li class="menu-item menu-item-has-children">
                                <a href="javascript:void(0)">Blog Archive</a>
                                <ul class="sub-menu">
                                    <li class="menu-item"> <a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid View</a> </li>
                                    <li class="menu-item"> <a routerLink='/blog-list' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">List View</a> </li>
                                    <li class="menu-item"> <a routerLink='/blog-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Masonry View</a> </li>
                                </ul>
                            </li>
                            <li class="menu-item"> <a routerLink='/blog-single'>Blog Single</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children">
                        <a href="javascript:void(0)">Pages</a>
                        <ul class="sub-menu">
                            <li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a> </li>
                            <li class="menu-item"> <a routerLink='/login' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a> </li>
                            <li class="menu-item"> <a routerLink='/register' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a> </li>
                            <li class="menu-item"> <a routerLink='/checkout' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a> </li>
                            <li class="menu-item"> <a routerLink='/cart' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a> </li>
                            <li class="menu-item"> <a routerLink='/wishlist' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wish List</a> </li>
                            <li class="menu-item"> <a routerLink='/legal' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Legal</a> </li>
                            
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children mega-menu-wrapper">
                        <a href="javascript:void(0)">Shop</a>
                        <ul class="sub-menu">
                            <li>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="mega-menu-item">
                                                <h6>Membership Delivery</h6>
                                                <p>
                                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC,
                                                    making it over 2000 years old.
                                                </p>
                                                <a routerLink='/login' class="andro_btn-custom shadow-none btn-sm">Become a Member</a>
                                            </div>
                                        </div>
                                        <div class="offset-lg-1 col-lg-3">
                                            <div class="mega-menu-item">
                                                <h6>Shop Pages</h6>
                                                <a routerLink='/shop-v1' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v1 (Default)</a>
                                                <a routerLink='/shop-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v2 (Full Width)</a>
                                                <a routerLink='/shop-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v3 (No Sidebar)</a>
                                                <a routerLink='/shop-v4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v4 (List view)</a>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="mega-menu-item">
                                                <h6>Product Details Pages</h6>
                                                <a routerLink='/product-single' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product Details v1</a>
                                                <a routerLink='/product-single-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product Details v2</a>
                                                <a href="#" class="coming-soon">Product Details v3 <span>Coming Soon</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li> -->
                    <li class="menu-item"> <a routerLink='' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                        <span style="color: #5d5d5d;" class="hoveritem">Home</span></a> </li>
                    <li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                        <span style="color: #5d5d5d;" class="hoveritem">Over APO@HOME</span></a> </li>
                    <li class="menu-item"> <a routerLink='/blog-masonary' (click)="change_cat('')" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                        <span style="color: #5d5d5d;" class="hoveritem">Nieuws & informatie</span></a> </li>
                    <li class="menu-item"> <a routerLink='/contact' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                        <span style="color: #5d5d5d;" class="hoveritem">Hoe bestellen?</span></a> </li>
                </ul>
                <!-- Side navigation toggle -->
                <div class="aside-toggler aside-trigger-right desktop-toggler" [ngStyle]="{'display': page != 'shop1' ? '' : 'none'}">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
    <!-- Bottom Header End -->
</header>
<!-- Header End -->