<app-header-two></app-header-two>
<!-- Subheader Start -->
<div class="andro_subheader pattern-bg primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1>Product Details v2</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Shop</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Product Details v2</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Product Content Start -->
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-5">
                <div class="andro_product-single-thumb">
                    <img src="assets/img/products/15.png" alt="product">
                </div>
                <!-- Pagination Start -->
                <div class="andro_single-pagination">
                    <div class="andro_single-pagination-item andro_single-pagination-prev">
                        <a routerLink='/product-single'>
                            <div class="andro_single-pagination-thumb">
                                <img src="assets/img/products/9.png" alt="product">
                            </div>
                            <div class="andro_single-pagination-content">
                                <h6>Tomatoes</h6>
                            </div>
                            <i class="fas fa-chevron-left"></i>
                        </a>
                    </div>
                    <div class="andro_single-pagination-item andro_single-pagination-next">
                        <a routerLink='/product-single-v2'>
                            <div class="andro_single-pagination-thumb">
                                <img src="assets/img/products/15.png" alt="product">
                            </div>
                            <div class="andro_single-pagination-content">
                                <h6>Apricots</h6>
                            </div>
                            <i class="fas fa-chevron-right"></i>
                        </a>
                    </div>
                </div>
                <!-- Pagination End -->
            </div>
            <div class="col-lg-4 col-md-7">
                <div class="andro_product-single-content andro_product-single-2">
                    <div class="andro_product-single-controls andro_post-share">
                        <a href="#" data-toggle="tooltip" title="Add to Wishlist" class="andro_add-to-favorite"> <i class="flaticon-like"></i> </a>
                        <ul class="andro_sm">
                            <li> <a href="#" data-toggle="tooltip" title="Share on Facebook"> <i class="fab fa-facebook-f"></i> </a> </li>
                            <li> <a href="#" data-toggle="tooltip" title="Share on Twitter"> <i class="fab fa-twitter"></i> </a> </li>
                            <li> <a href="#" data-toggle="tooltip" title="Share on Linkedin"> <i class="fab fa-linkedin-in"></i> </a> </li>
                        </ul>
                    </div>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                    <h3> Apricots </h3>
                    <div class="andro_product-price">
                        <span>8$</span>
                        <span>14$</span>
                    </div>
                    <p class="andro_product-excerpt">123sdfCras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <form class="andro_product-atc-form">
                        <div class="andro_product-variation-wrapper">
                            <div class="form-group">
                                <select class="form-control" name="amount">
                                    <option value="">Select Amount</option>
                                    <option value="1">1 Kilo</option>
                                    <option value="2">2 Kilos</option>
                                    <option value="3">3 Kilos</option>
                                    <option value="4">4 Kilos</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <select class="form-control" name="breed">
                                    <option value="">Select a Breed</option>
                                    <option value="italian">Italian</option>
                                    <option value="egyptian">Egyptian</option>
                                </select>
                            </div>
                        </div>
                        <div class="qty-outter">
                            <a routerLink='/product-single' class="andro_btn-custom">Buy Now</a>
                            <div class="qty">
                                <span class="qty-subtract"><i class="fa fa-minus"></i></span>
                                <input type="text" name="qty" value="1">
                                <span class="qty-add"><i class="fa fa-plus"></i></span>
                            </div>
                        </div>
                    </form>
                    <ul class="andro_product-meta">
                        <li>
                            <span>Categories: </span>
                            <div class="andro_product-meta-item">
                                <a href="#">Food</a>
                            </div>
                        </li>
                        <li>
                            <span>Tags: </span>
                            <div class="andro_product-meta-item">
                                <a href="#">Healthy</a>,
                                <a href="#">Vegetarian</a>,
                                <a href="#">Farm</a>
                            </div>
                        </li>
                        <li>
                            <span>SKU: </span>
                            <div class="andro_product-meta-item">
                                <span>N/A</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="sidebar">
                    <!-- Daily Deals Start -->
                    <div class="sidebar-widget mb-0">
                        <h5 class="widget-title"> Daily Deals </h5>
                        <div class="deals-slider">
                            <!-- Deal Start -->
                            <div class="andro_product">
                                <div class="andro_product-badge andro_badge-sale">
                                    20% Off
                                </div>
                                <div class="andro_product-thumb">
                                    <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                                    <div class="andro_countdown-timer" data-countdown="2021/01/01"></div>
                                </div>
                                <div class="andro_product-body">
                                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Watermelon </a> </h5>
                                    <div class="andro_rating-wrapper">
                                        <div class="andro_rating">
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                        <span>4 Stars</span>
                                    </div>
                                </div>
                                <div class="andro_product-footer">
                                    <div class="andro_product-price">
                                        <span>8$</span>
                                        <span>14$</span>
                                    </div>
                                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                </div>
                            </div>
                            <!-- Deal End -->
                            <!-- Deal Start -->
                            <div class="andro_product">
                                <div class="andro_product-badge andro_badge-sale">
                                    10% Off
                                </div>
                                <div class="andro_product-thumb">
                                    <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                                    <div class="andro_countdown-timer" data-countdown="2021/05/06"></div>
                                </div>
                                <div class="andro_product-body">
                                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Cucumbers </a> </h5>
                                    <div class="andro_rating-wrapper">
                                        <div class="andro_rating">
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                        <span>4 Stars</span>
                                    </div>
                                </div>
                                <div class="andro_product-footer">
                                    <div class="andro_product-price">
                                        <span>8$</span>
                                        <span>14$</span>
                                    </div>
                                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                </div>
                            </div>
                            <!-- Deal End -->
                            <!-- Deal Start -->
                            <div class="andro_product">
                                <div class="andro_product-badge andro_badge-sale">
                                    20% Off
                                </div>
                                <div class="andro_product-thumb">
                                    <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                                    <div class="andro_countdown-timer" data-countdown="2021/09/09"></div>
                                </div>
                                <div class="andro_product-body">
                                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h5>
                                    <div class="andro_rating-wrapper">
                                        <div class="andro_rating">
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                        <span>4 Stars</span>
                                    </div>
                                </div>
                                <div class="andro_product-footer">
                                    <div class="andro_product-price">
                                        <span>8$</span>
                                        <span>14$</span>
                                    </div>
                                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                </div>
                            </div>
                            <!-- Deal End -->
                        </div>
                    </div>
                    <!-- Daily Deals End -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Product Content End -->
<!-- Additional Information Start -->
<div class="section pt-0">
    <div class="container">
        <div class="andro_product-additional-info">
            <div class="row">
                <div class="col-lg-4">
                    <ul class="nav andro_sticky-section" id="bordered-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="tab-product-desc-tab" data-toggle="pill" href="#tab-product-desc" role="tab" aria-controls="tab-product-desc" aria-selected="true">Description</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="tab-product-info-tab" data-toggle="pill" href="#tab-product-info" role="tab" aria-controls="tab-product-info" aria-selected="false">Additional Information</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="tab-product-reviews-tab" data-toggle="pill" href="#tab-product-reviews" role="tab" aria-controls="tab-product-reviews" aria-selected="false">Reviews (25)</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-8">
                    <div class="tab-content" id="bordered-tabContent">
                        <div class="tab-pane fade show active" id="tab-product-desc" role="tabpanel" aria-labelledby="tab-product-desc-tab">
                            <h4>Description</h4>
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute,
                            non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a
                            bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica
                        </div>
                        <div class="tab-pane fade" id="tab-product-info" role="tabpanel" aria-labelledby="tab-product-info-tab">
                            <h4>Additional Information</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col">Attributes</th>
                                        <th scope="col">Values</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> <strong>Color</strong> </td>
                                        <td>blue, red, yellow, green</td>
                                    </tr>
                                    <tr>
                                        <td> <strong>Material</strong> </td>
                                        <td>wood, plastic, stainless steel</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="tab-pane fade" id="tab-product-reviews" role="tabpanel" aria-labelledby="tab-product-reviews-tab">
                            <h4>Leave a Review</h4>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                </div>
                                <span>Your Review</span>
                            </div>
                            <!-- Review Form start -->
                            <div class="comment-form">
                                <form method="post">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <input type="text" class="form-control" placeholder="Full Name" name="fname" value="">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <input type="email" class="form-control" placeholder="Email Address" name="email" value="">
                                        </div>
                                        <div class="col-md-12 form-group">
                                            <textarea class="form-control" placeholder="Type your comment..." name="comment" rows="7"></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" class="andro_btn-custom primary" name="button">Post Review</button>
                                </form>
                            </div>
                            <!-- Review Form End -->
                            <!-- Reviews Start -->
                            <div class="comments-list">
                                <ul>
                                    <li class="comment-item">
                                        <img src="assets/img/people/1.jpg" alt="comment author">
                                        <div class="comment-body">
                                            <h5>John Doe</h5>
                                            <div class="andro_rating">
                                                <i class="fa fa-star active"></i>
                                                <i class="fa fa-star active"></i>
                                                <i class="fa fa-star active"></i>
                                                <i class="fa fa-star active"></i>
                                                <i class="fa fa-star"></i>
                                            </div>
                                            <span>Posted on: January 13 2020</span>
                                            <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
                                            <a href="#" class="reply-link"> Reply </a>
                                        </div>
                                    </li>
                                    <li class="comment-item">
                                        <img src="assets/img/people/2.jpg" alt="comment author">
                                        <div class="comment-body">
                                            <h5>John Doe</h5>
                                            <div class="andro_rating">
                                                <i class="fa fa-star active"></i>
                                                <i class="fa fa-star active"></i>
                                                <i class="fa fa-star active"></i>
                                                <i class="fa fa-star active"></i>
                                                <i class="fa fa-star active"></i>
                                            </div>
                                            <span>Posted on: January 13 2020</span>
                                            <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches</p>
                                            <a href="#" class="reply-link"> Reply </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!-- Reviews End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Additional Information End -->
<!-- Related Products Start -->
<div class="section pt-0 andro_related-posts">
    <div class="container">
        <div class="section-title flex-title">
            <h4 class="title">Related Products</h4>
            <div class="andro_arrows">
                <i class="fa fa-arrow-left slick-arrow slider-prev"></i>
                <i class="fa fa-arrow-right slick-arrow slider-next"></i>
            </div>
        </div>
        <div class="andro_related-posts-slider">
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-badge andro_badge-featured">
                    <i class="fa fa-star"></i>
                    <span>Featured</span>
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/2.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Kiwi & Strawberry </a> </h5>
                    <div class="andro_product-price">
                        <span>19$</span>
                        <span>29$</span>
                    </div>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-badge andro_badge-featured">
                    <i class="fa fa-star"></i>
                    <span>Featured</span>
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Fresh Oranges </a> </h5>
                    <div class="andro_product-price">
                        <span>19$</span>
                        <span>29$</span>
                    </div>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                        </div>
                        <span>5 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-badge andro_badge-sale">
                    20% Off
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Cucumbers </a> </h5>
                    <div class="andro_product-price">
                        <span>8$</span>
                        <span>14$</span>
                    </div>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-badge andro_badge-featured">
                    <i class="fa fa-star"></i>
                    <span>Featured</span>
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/2.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Kiwi & Strawberry </a> </h5>
                    <div class="andro_product-price">
                        <span>19$</span>
                        <span>29$</span>
                    </div>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-badge andro_badge-featured">
                    <i class="fa fa-star"></i>
                    <span>Featured</span>
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Fresh Oranges </a> </h5>
                    <div class="andro_product-price">
                        <span>11$</span>
                    </div>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                        </div>
                        <span>5 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-badge andro_badge-sale">
                    20% Off
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Cucumbers </a> </h5>
                    <div class="andro_product-price">
                        <span>8$</span>
                        <span>14$</span>
                    </div>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
        </div>
    </div>
</div>
<!-- Related Products End -->
<app-insta-two></app-insta-two>
<app-footer></app-footer>