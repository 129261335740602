import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { product } from '../variable';
import { HttpClient } from '@angular/common/http';
import { ApoService } from 'src/app/apo.service';
import { Attention, cartitem, Discount, Fresh, info, mailing, Product } from 'src/app/model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.css']
})
export class HomeTwoComponent implements OnInit, OnDestroy {

  list_product_kijker : product[] = [];
  list_product_nieuw : product[] = [];  
  pagina = "home";
  list_deals: Discount[] = [];
  list_freshs: Fresh[] = [];
  list_attentions: Attention[] = [];
  list_products: Product[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "arrows": false,
    "dots": true,
    "autoplay": true
   };
   slideConfig1 = {
     "slidesToShow": 3,
     "slidesToScroll": 1,
     "arrows": false,
     "dots": false,
     "autoplay": true,
     "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          autoplay: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          autoplay: true
        }
      }
    ]
    };

     @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;
     @ViewChild('slickModal2', { static: true }) slickModal2: SlickCarouselComponent;
     _api = "";
     mailing_ok = "";
     _mailing = "";
     mail_valid = false;
     spinner1_active = false; 
     spinner2_active = false; 
     spinner3_active = false;
     spinner4_active = false;
     ok_disable_fb: Boolean = true;
     list_info = [];  
     spinner_active = false;    

    form_login = this.formBuilder.group({ 
      pwd: '',    
      login: ''
    });  

  select_info(id){
    this._aposervice.change_info(this.list_info[this.list_info.findIndex(x => x.id == id)]);
  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();    
  }

  change_page(cat: string){
    this._aposervice.select_cat(cat);
  }

  constructor(public http: HttpClient, public _aposervice: ApoService, private toastr: ToastrService, public formBuilder: FormBuilder, public authService: AuthService) {

    // this.http.get<product[]>(this._api + "product?categorie=in_de_kijker").pipe(takeUntil(this.destroy$)).subscribe(data => {
    // for (let x of data) {
    //   if (x.naam.length > 32){
    //     x.naam_kort = x.naam.substr(0,30) + "...";
    //   }
    //   this.list_product_kijker.push(x);
    // }
    // });

    // this.http.get<product[]>(this._api + "product?categorie=nieuw").pipe(takeUntil(this.destroy$)).subscribe(data => {
    // for (let x of data) {
    //   if (x.naam.length > 32){
    //     x.naam_kort = x.naam.substr(0,30) + "...";
    //   }
    //   this.list_product_nieuw.push(x);
    // }
    // }); 
  
    // this.http.get<Product[]>(this._api + "products/0").pipe(takeUntil(this.destroy$)).subscribe(data => {
    //   this.list_products = data;
    // });

    this.form_login.valueChanges.subscribe(() => {
      if (this.validateEmail(this.form_login.controls['login'].value) && this.form_login.controls['pwd'].value != ''){
      this.ok_disable_fb = false;
      }
      else {
        this.ok_disable_fb = true;
      }
    })

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this._api = data;
    })

   }

  ngOnInit(): void { 
    this.to_top();
    this.spinner1_active = true;
    this.spinner4_active = true;
    this.http.get<any[]>(this._api + "discounts/10").pipe(takeUntil(this.destroy$)).subscribe(data => {
      for (let x of data){
        this.list_deals.push(
          {
            info_short: x.info_short,
            name: x.name,
            title_short: x.title_short,
            info_long: x.info_long,
            percentage: x.percentage,
            number_to_free: x.number_to_free,
            _product: {
              name: x.name,
              price: x.price,
              price_disc: x.price_disc,
              info_short_product: x.info_short_product,
              photo: x.photo,
              cnk: x.cnk,
              brand: x.brand,    
              attention_product: x.attention_product,
              popular: x.popular,
              id: x.id,
              subcategory: x.subcategory,
              documentation: x.documentation,
              prescription: x.prescription,
              principle: x.principle, 
              _use: x._use, 
              composition: x.composition,
              favoriet: x.favoriet,
              active: x.active,
              special: x.special,
              creationtime: x.creationtime,
              percentage: x.percentage,
              number_to_free: x.number_to_free,
              discount_special: x.discount_special,
              price_ori: x.price_ori,
              btw: x.btw,
              discount_text: x.discount_text,
              disc_start: x.disc_start,
              disc_stop: x.disc_stop,
              att_start: x.att_start,
              att_stop: x.att_stop,
              check: false, 
              check_select: false, 
              principle2: x.principle2,
              info: x.info,
              product_collection: x.product_collection
            }
          }
        ) 

      }

      console.log(this.list_deals);
      for (let x of this.list_deals){
        if (x.title_short != ""){x.name = x.title_short;}
        if (x._product.photo != "") { x._product.photo = "https://apoathomeapi.be/data/foto/" + x._product.photo;}
        else {x._product.photo = "https://apoathomeapi.be/data/no_image.jpg"}
        x._product.active = 1; 
        x._product.percentage = x.percentage;
        x._product.number_to_free = x.number_to_free;
        x._product = this._aposervice.calculate_discount(x._product); 
      }

      this.spinner1_active = false;      
    });   
    
    this.http.get<any>(this._api + "infos/2").pipe(takeUntil(this.destroy$)).subscribe(data => {
      for (let x of data){
            
        var products = [];
        for (let y of x[1]){
          for (let z of y){
            z.photo = "https://apoathomeapi.be/data/foto/" +z.photo;
            products.push(z);
          }
        }       

        var _info: info = {
          title: x[0].title, text: x[0].text, products: x[0].products, id: x[0].id, category: x[0].category, photo: "https://apoathomeapi.be/data/info/" + x[0].photo,
          text_intro: x[0].text_intro, list_products: products}
        

        this.list_info.push(_info);
      }

      this.spinner4_active = false;
      
    });
    
    this.spinner2_active = true;
    this.http.get<any[]>(this._api + "freshs/10").pipe(takeUntil(this.destroy$)).subscribe(data => {
      
      for (let x of data){
        this.list_freshs.push(
          {
            info_short: x.info_short,
            name: x.name,
            title_short: x.title_short,
            _product: {
              name: x.name,
              price: x.price,
              price_disc: x.price_disc,
              info_short_product: x.info_short_product,
              photo: x.photo,
              cnk: x.cnk,
              brand: x.brand,    
              attention_product: x.attention_product,
              popular: x.popular,
              id: x.id,
              subcategory: x.subcategory,
              documentation: x.documentation,
              prescription: x.prescription,
              principle: x.principle, 
              _use: x._use, 
              composition: x.composition,
              favoriet: x.favoriet,
              active: x.active,
              special: x.special,
              creationtime: x.creationtime,
              percentage: x.percentage,
              number_to_free: x.number_to_free,
              discount_special: x.discount_special,
              price_ori: x.price_ori,
              btw: x.btw,
              discount_text: x.discount_text,
              disc_start: x.disc_start,
              disc_stop: x.disc_stop,
              att_start: x.att_start,
              att_stop: x.att_stop,
              check: false, 
              check_select: false, 
              principle2: x.principle2,
              info: x.info,
              product_collection: x.product_collection
            },
            disc_start: x.disc_start,
            disc_stop: x.disc_stop,
            percentage: x.percentage,
            number_to_free: x.number_to_free
          }
        ) 
      }

      for (let x of this.list_freshs){
        if (x.title_short != ""){x.name = x.title_short;}
        if (x._product.photo != "") { x._product.photo = "https://apoathomeapi.be/data/foto/" + x._product.photo;}
        else {x._product.photo = "https://apoathomeapi.be/data/no_image.jpg"}
        x._product.active = 1; 
        x._product.percentage = x._product.percentage;
        x._product.number_to_free = x._product.number_to_free;
        x._product = this._aposervice.calculate_discount(x._product); 
      }      
      this.spinner2_active = false;
    });     
    
    this.spinner3_active = true;
    this.http.get<any[]>(this._api + "attentions/10").pipe(takeUntil(this.destroy$)).subscribe(data => {
      for (let x of data){
        this.list_attentions.push(
          {
            info_short: x.info_short,
            name: x.name,
            title_short: x.title_short,
            _product: {
              name: x.name,
              price: x.price,
              price_disc: x.price_disc,
              info_short_product: x.info_short,
              photo: x.photo,
              cnk: x.cnk,
              brand: x.brand,    
              attention_product: x.attention_product,
              popular: x.popular,
              id: x.id,
              subcategory: x.subcategory,
              documentation: x.documentation,
              prescription: x.prescription,
              principle: x.principle, 
              _use: x._use, 
              composition: x.composition,
              favoriet: x.favoriet,
              active: x.active,
              special: x.special,
              creationtime: x.creationtime,
              percentage: x.percentage,
              number_to_free: x.number_to_free,
              discount_special: x.discount_special,
              price_ori: x.price_ori,
              btw: x.btw,
              discount_text: x.discount_text,
              disc_start: x.disc_start,
              disc_stop: x.disc_stop,
              att_start: x.att_start,
              att_stop: x.att_stop,
              check: false, 
              check_select: false, 
              principle2: x.principle2,
              info: x.info,
              product_collection: x.product_collection
            },
            disc_start: x.disc_start,
            disc_stop: x.disc_stop,
            percentage: x.percentage,
            number_to_free: x.number_to_free
          }
        ) 
      }

      for (let x of this.list_attentions){
        if (x.title_short != ""){x.name = x.title_short;}
        if (x._product.photo != "" && x._product.photo != null) { x._product.photo = "https://apoathomeapi.be/data/foto/" + x._product.photo;}
        else {x._product.photo = "https://apoathomeapi.be/data/no_image.jpg"}
        x._product.active = 1; 
        x._product = this._aposervice.calculate_discount(x._product); 
      }
     
      this.spinner3_active = false;
    });     
  }

  login(){
    this.authService.SignIn(this.form_login.controls['login'].value, this.form_login.controls['pwd'].value); 
    this.form_login.controls['login'].patchValue('');
    this.form_login.controls['pwd'].patchValue('');
  }
      
  next() {
    this.slickModal.slickNext();
  }   

  select_product(_product){
    this._aposervice.change_product_selected(_product);
  }  

  add_to_cart(product: Product){
    var cartitem: cartitem = {product: product, quantity: 1, quantity_free: 0, promocode: ""};
    this._aposervice.addProduct(cartitem);
    this.toastr.success('item(s) toegevoegd aan mandje', 'Gelukt', {timeOut: 3000, positionClass: 'toast-bottom-center'})
  }

  to_top(){
    setTimeout (() => {
      this.scrolltotop();
   }, 200);
  }

  scrolltotop(){
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView({behavior: "smooth"});
      top = null;
    }  
  }

  check_empty(event){
    this.mail_valid = this.validateEmail(this._mailing);
    if (event.key != "Enter"){this.mailing_ok = "";}
    else {
      this.spinner_active = true;
      if (!this.mail_valid) {this.mailing_ok = "novalid";this.spinner_active = false;}
      else {
        let mailing: mailing = {
          "mail": this._mailing,
          "discount": this.generator(5)
        }
        this.http.post<string>(this._api + "mailings",mailing).pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.mailing_ok = data;
          this.spinner_active = false;
        });  
      }       
    }    
  }
  
  prev() {
    this.slickModal.slickPrev();
  }
      
  next2() {
    this.slickModal2.slickNext();
  }
  
  prev2() {
    this.slickModal2.slickPrev();
  }

  generator(len) {
    var allCapsAlpha = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"]; 
    var allLowerAlpha = [..."abcdefghijklmnopqrstuvwxyz"]; 
    var allNumbers = [..."0123456789"];
    var base = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha];

    return [...Array(len)]
    .map(i => base[Math.random()*base.length|0])
    .join('');
  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }

}
