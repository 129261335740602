import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { info, pag_request, Product, stat_cat, mailing } from 'src/app/model';
import { product } from '../../pages/variable';

@Component({
  selector: 'app-blog-sidebar',
  templateUrl: './blog-sidebar.component.html',
  styleUrls: ['./blog-sidebar.component.css']
})
export class BlogSidebarComponent implements OnInit, OnDestroy {

  _info: info = {list_products: [], products: '', title: '', photo: '', text: '', id: 0, category: '', text_intro: ''};
  private destroy$: Subject<void> = new Subject<void>();
  list_cat: stat_cat[] = [];
  _api = "";  
  mailing_ok = "";
  _mailing = "";
  mail_valid = false;
  spinner_active = false;

  constructor(public _aposervice: ApoService, public http: HttpClient) {  

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this._api = data;
    })

    _aposervice.current_info.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this._info = info;      
    })
  }

  ngOnInit(): void {

    this.http.get<any>(this._api + "stat_cat").pipe(takeUntil(this.destroy$)).subscribe(data => {
      for (let x of data){
        this.list_cat.push({
          cat: x[0], aantal: x[1]
        })
      }
    });


  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();    
  }

  select_product(_product){
    this._aposervice.change_product_selected(_product);    
  } 

  change_cat(_cat){
    this._aposervice.change_infocat(_cat);
  }
  
  check_empty(event){
    this.mail_valid = this.validateEmail(this._mailing);
    if (event.key != "Enter"){this.mailing_ok = "";}
    else {
      this.spinner_active = true;
      if (!this.mail_valid) {this.mailing_ok = "novalid";    this.spinner_active = false;}
      else {
        let mailing: mailing = {
          "mail": this._mailing,
          "discount": this.generator(5)
        }
        this.http.post<string>(this._api + "mailings/",mailing).pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.mailing_ok = data;
          this.spinner_active = false;
        });  
      }       
    }    
  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }

  generator(len) {
    var allCapsAlpha = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"]; 
    var allLowerAlpha = [..."abcdefghijklmnopqrstuvwxyz"]; 
    var allNumbers = [..."0123456789"];
    var base = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha];

    return [...Array(len)]
    .map(i => base[Math.random()*base.length|0])
    .join('');
  }

}
