<!-- Instagram Start -->
<div class="row no-gutters">
    <div class="col-lg-4" style="background-image: url('assets/mood/facebook.jpg'); background-size: cover;">
        <div class="andro_instagram">
            <h3 class="text-white">Volg ons op Facebook</h3>
            <a href="https://www.facebook.com/apoathome" target="_blank" class="andro_btn-custom light">Volg ons</a>
        </div>
    </div>
    <div class="col-lg-8">
        <div class="row no-gutters">
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <div class="andro_ig-item">
                    <img src="assets/mood/covid19.jpg" alt="ig">
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <div class="andro_ig-item">
                    <img src="assets/mood/mortier.jpg" alt="ig">
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <div class="andro_ig-item">
                    <img src="assets/mood/gamma.jpg" alt="ig">
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <div class="andro_ig-item">
                    <img src="assets/mood/kruis.jpg" alt="ig">
                </div>
                <!-- <a href="#" class="andro_ig-item"> -->
                <!-- </a> -->
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <div class="andro_ig-item">
                    <img src="assets/mood/apotheek.jpg" alt="ig">
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <a href="#" class="andro_ig-item">
                    <img src="assets/mood/bear.JPG" alt="ig">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- Instagram End -->