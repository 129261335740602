<app-header-two></app-header-two>
<!-- Subheader Start -->
<div class="andro_subheader pattern-bg primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1>Shop v2 (Full Width)</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Shop</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Shop v2 (Full Width)</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Products Start -->
<div class="section">
    <div class="andro_section-fw">
        <div class="row">
            <div class="col-lg-9">
                <!-- Product Count & Orderby Start -->
                <div class="andro_shop-global">
                    <p>Showing <b>20</b> of <b>320</b> products </p>
                    <form method="post">
                        <select class="form-control" name="orderby">
                            <option value="default">Default sorting</option>
                            <option value="latest">Latest release</option>
                            <option value="price-down">Price: High - Low</option>
                            <option value="price-up">Price: Low - High</option>
                            <option value="popularity">Popularity Sorting</option>
                        </select>
                    </form>
                </div>
                <!-- Product Count & Orderby End -->
                <div class="row masonry">
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-badge andro_badge-featured">
                                <i class="fa fa-star"></i>
                                <span>Featured</span>
                            </div>
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/7.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Ginger </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>19$</span>
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/8.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Lettuce </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>8$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/9.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Tomatoes </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>6$</span>
                                    <span></span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/10.png" alt="product"></a>
                            </div>
                            <div class="andro_product-badge andro_badge-sale">
                                20% Off
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Corn </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>19$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-badge andro_badge-featured">
                                <i class="fa fa-star"></i>
                                <span>Featured</span>
                            </div>
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/11.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Onions </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>13$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/12.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Brocolis </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>19$</span>
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/2.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Kiwi & Strawberry </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>19$</span>
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Cucumber </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Watermelons </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/14.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Grapes </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/15.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Apricots </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>13$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Watermelons </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/14.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Grapes </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-md-3 col-sm-4 masonry-item">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/15.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Apricots </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>13$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                </div>
                <!-- Pagination Start -->
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active">
                        <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                </ul>
                <!-- Pagination End -->
            </div>
            <!-- Sidebar Start -->
            <div class="col-lg-3">
                <app-shop-sidebar></app-shop-sidebar>
            </div>
            <!-- Sidebar End -->
        </div>
    </div>
</div>
<!-- Products End -->
<app-insta-two></app-insta-two>
<app-footer></app-footer>