import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { brand, category, cat_subcat, filter, mailing, product_select, searchitem, send_check, subcategory, subcategory_item } from 'src/app/model';
import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-shop-sidebar',
  templateUrl: './shop-sidebar.component.html',
  styleUrls: ['./shop-sidebar.component.css']
})
export class ShopSidebarComponent implements OnInit, OnDestroy {

  current_tag: '';
  current_cat = [];
  ids_selected = [];
  current_subcat : subcategory_item[] = [];
  subcats_selected = [];
  att_checked = false;
  sale_checked = false;
  presc_checked = false;
  min_price = 0;
  max_price = 10000;
  min_price_selected = 0;
  _filter : filter = { modus: "", categories: [], subcategories: [], attention: false, sale: false, no_presc: false, 
                      brands: [], price_min: 0, price_max: 10000, searchterm: '', sort: '', gen_search: ''};
  private destroy$: Subject<void> = new Subject<void>();
  searchitem = "";
  cat_length = 0;
  @Output() filteractive = new EventEmitter<boolean>();
  @Output() loadproducts = new EventEmitter<filter>();
  @Output() reloadproducts = new EventEmitter<Boolean>();
  @Output() clearselection = new EventEmitter<Boolean>();
  cat_header = "";
  userid = 0;
  _modus = "x";
  all_selected = true;
  @ViewChild('slider') slider: NgxSliderModule;
  max_price_selected = 10000;
  mailing_ok = "";
  _mailing = "";
  api = "";
  mail_valid = false;
  search = "";

  options: Options = {
    floor: this.min_price,
    ceil: this.max_price,
    step: 5,
    showTicks: true,
    translate: (value: number): string => {
      return value + '€';
    },
    selectionBarGradient: {
      from: 'white',
      to: 'rgba(81,189,77,255)'
    }
  };

  // informatie die gewijzigd moet worden
  info_select : product_select[] = [];
  info_select_view: product_select[] = [];
  cat_subcat_current: cat_subcat[] = [];
  select_aantal = 0;
  selection_one = false;
  selection_min_one = false;
  info = "";
  cat_subcat_display = false;
  rslt_check : send_check[] = [];
  delete_disable = true;
  check_visible = false;

   // hard gecodeerde informatie 

   list_cat: category[] = [
    // {name: 'Promo Nuxe Prod. Boost', checked: false, code: 'PROMONUXE'},
    {name: 'Promo Difrax fopspenen', checked: false, code: 'PROMODIFRAX'},
    {name: 'Promo Saforelle', checked: false, code: 'PROMOSAFO'},
    {name: 'Promo Lashilé', checked: false, code: 'PROMOLASH'},
    {name: 'Aambeien', checked: false, code: 'Aambeien'},
    {name: 'Allergie', checked: false, code: 'Allergie'},
    {name: 'Baby', checked: false, code: 'Baby'},
    {name: 'Cosmetica', checked: false, code: 'Cosmetica'},
    {name: 'Dieren', checked: false, code: 'Dieren'},
    {name: 'Fytotherapie', checked: false, code: 'Fytotherapie'},
    {name: 'Geneesmiddelen', checked: false, code: 'Geneesmiddelen'},
    {name: 'Gewicht', checked: false, code: 'Gewicht'},
    {name: 'Haar', checked: false, code: 'Haar'},
    {name: 'Huid', checked: false, code: 'Huid'},
    {name: 'Maag- & Darmklachten', checked: false, code: 'Maag'},
    {name: 'Mond', checked: false, code: 'Mond'},
    {name: 'Nagel', checked: false, code: 'Nagel'},
    {name: 'Oog & Oor', checked: false, code: 'Oog'},
    {name: 'Pijn & Ontsteking', checked: false, code: 'Pijn'},
    {name: 'Rookstop', checked: false, code: 'Rookstop'},
    {name: 'Seksualiteit', checked: false, code: 'Seksualiteit'},
    {name: 'Stress & Slaap', checked: false, code: 'Stress'},
    {name: 'Testmateriaal', checked: false, code: 'Testmateriaal'},
    {name: 'Verkoudheid', checked: false, code: 'Verkoudheid'},
    {name: 'Vitamines', checked: false, code: 'Vitamines'},
    {name: 'Wondzorg', checked: false, code: 'Wondzorg'}
   ];
 
   list_subcategory: subcategory[] = 
   [ 
    {category: 'Geneesmiddelen',list_subcat:[
        {subcat:'Bloedruk/hartklachten', value: [1000], checked:false},
        {subcat:'Suiker', value: [1001], checked:false},
        {subcat:'Ademhaling', value: [1002], checked:false},
        {subcat:'Cholesterol', value: [1003], checked:false},
        {subcat:'Cytostatica', value: [1308], checked:false},
        {subcat:'Epilepsie', value: [1309], checked:false},
        {subcat:'Vaccin', value: [1310], checked:false},
        {subcat:'Allergie', value: [1004], checked:false},
        {subcat:'Bloedstolling', value: [1005], checked:false},
        {subcat:'Jicht', value: [1006], checked:false},
        {subcat:'Botontkalking', value: [1302], checked:false},
        {subcat:'Pijnstilling', value: [1007], checked:false},
        {subcat:'Kalmerend/slaapmiddelen', value: [1008], checked:false},
        {subcat:'Darmklachten', value: [1009], checked:false},
        {subcat:'Maagklachten', value: [1010], checked:false},
        {subcat:'Cortisones', value: [1011], checked:false},
        {subcat:'Hormonale preparaten', value: [1012], checked:false},
        {subcat:'Schildklierhormonen', value: [1013], checked:false},
        {subcat:'Antibiotica', value: [1014], checked:false},
        {subcat:'Antischimmel', value: [1015], checked:false},
        {subcat:'Antivirale middelen', value: [1210], checked:false},
        {subcat:'Antipsychotica', value: [1016], checked:false},
        {subcat:'Verkoudheid', value: [1203], checked:false},
        {subcat:'Blaas', value: [1319], checked:false},
        {subcat:'Toebehoren', value: [1303], checked:false},
        {subcat:'Seksualiteit', value: [1316], checked:false},
        {subcat:'Andere', value: [1017], checked:false}]},
    {category: 'Huid',list_subcat:[
        {subcat:'Reiniging ', value: [1018], checked:false},
        {subcat:'Normaal', value: [1019], checked:false},
        {subcat:'Acné', value: [1020], checked:false},
        {subcat:'Veroudering', value: [1021], checked:false},
        {subcat:'Hand', value: [1311], checked:false},
        {subcat:'Zweten', value: [1312], checked:false},
        {subcat:'Couperose', value: [1022], checked:false},
        {subcat:'Pigmentvlekken', value: [1023], checked:false},
        {subcat:'Baby', value: [1024], checked:false},
        {subcat:'Droog/atopie', value: [1025], checked:false},
        {subcat:'Litteken', value: [1026], checked:false},
        {subcat:'Lippen', value: [1211], checked:false},
        {subcat:'Zonnebescherming', value: [1027], checked:false},
        {subcat:'Voet', value: [1212], checked:false},
        {subcat:'Wonde', value: [1213], checked:false},
        {subcat:'Mannen', value: [1330], checked:false},
        {subcat:'Wratten', value: [1304], checked:false},
        {subcat:'Insectenbescherming', value: [1028], checked:false},
        {subcat:'Toebehoren', value: [1306], checked:false}]},
    {category: 'Rookstop',list_subcat:[ 
      {subcat:'Huid', value: [1328], checked:false},
      {subcat:'Oraal', value: [1329], checked:false}
    ]},
    {category: 'Cosmetica',list_subcat:[
        {subcat:'Ogen', value: [1029], checked:false},
        {subcat:'Lippen', value: [1030], checked:false},
        {subcat:'Nagellak', value: [1031], checked:false},
        {subcat:'Gelaat', value: [1032], checked:false}]},
    {category: 'Wondzorg',list_subcat:[
        {subcat:'Spoelen', value: [1033], checked:false},
        {subcat:'Ontsmetting', value: [1034], checked:false},
        {subcat:'Ontsmetting oppervlaktes', value: [1315], checked:false},
        {subcat:'Verbandmateriaal', value: [1035], checked:false},
        {subcat:'Insectenbeten', value: [1036], checked:false},
        {subcat:'Zonnebrand', value: [1037], checked:false},
        {subcat:'Brandwonde', value: [1322], checked:false},
        {subcat:'Nabehandeling', value: [1038], checked:false},
        {subcat:'Sondes', value: [1313], checked:false},
        {subcat:'Steunmateriaal', value: [1314], checked:false},
        {subcat:'Injectiemateriaal', value: [1205], checked:false}]},
    {category: 'Oog',list_subcat:[
      {subcat:'Oogdruppels', value: [1039], checked:false},
      {subcat:'Droge ogen', value: [1320], checked:false},
      {subcat:'Ooggel/oogzalf', value: [1040], checked:false},
        {subcat:'Lenzenproducten', value: [1301], checked:false},
        {subcat:'Oordruppels', value: [1200], checked:false},
        {subcat:'Oordoppen', value: [1201], checked:false},
        {subcat:'Vitamines', value: [1202], checked:false}]},
    {category: 'Mond',list_subcat:[
        {subcat:'Spoeling', value: [1041], checked:false},
        {subcat:'Prothesemateriaal', value: [1042], checked:false},
        {subcat:'Tandpasta', value: [1043], checked:false},
        {subcat:'Hygiënemateriaal', value: [1044], checked:false}]},
    {category: 'Nagel',list_subcat:[
        {subcat:'Schimmelnagel', value: [1045], checked:false},
        {subcat:'Versteviging', value: [1046], checked:false},
        {subcat:'Kalknagel', value: [1047], checked:false},
        {subcat:'Toebehoren', value: [1305], checked:false}]},
    {category: 'Haar',list_subcat:[
        {subcat:'Uitval', value: [1048], checked:false},
        {subcat:'Vet', value: [1049], checked:false},
        {subcat:'Roos', value: [1050], checked:false},
        {subcat:'Normaal', value: [1051], checked:false},
        {subcat:'Luizen', value: [1052], checked:false}]},
    {category: 'Vitamines',list_subcat:[
        {subcat:'Haar', value: [1053], checked:false},
        {subcat:'Immuniteit', value: [1054], checked:false},
        {subcat:'Examen', value: [1055], checked:false},
        {subcat:'Energie', value: [1056], checked:false},
        {subcat:'Algemeen', value: [1057], checked:false},
        {subcat:'50+', value: [1058], checked:false},
        {subcat:'Kinderen', value: [1059], checked:false},
        {subcat:'Oog', value: [1060], checked:false},
        {subcat:'Vitamine D', value: [1061], checked:false},
        {subcat:'Ijzer', value: [1062], checked:false},
        {subcat:'Foliumzuur', value: [1063], checked:false},
        {subcat:'Magnesium', value: [1307], checked:false},
        {subcat:'Vitamine B', value: [1064], checked:false},
        {subcat:'Zink', value: [1065], checked:false},
        {subcat:'Zwangerschap', value: [1066], checked:false},
        {subcat:'Calcium', value: [1321], checked:false},
        {subcat:'Zware benen', value: [1216], checked:false},
        {subcat:'Overige', value: [1067], checked:false}]},
    {category: 'Gewicht',list_subcat:[
        {subcat:'Vermagering', value: [1068], checked:false},
        {subcat:'Proteïnerijk', value: [1069], checked:false},
        {subcat:'Eetlustopwerker', value: [1070], checked:false},
        {subcat:'Sportvoeding', value: [1071], checked:false}]},
    {category: 'Pijn',list_subcat:[
        {subcat:'Gewrichten', value: [1072], checked:false},
        {subcat:'Spieren', value: [1073], checked:false},
        {subcat:'Hoofdpijn', value: [1074], checked:false},
        {subcat:'Koorts', value: [1075], checked:false},
        {subcat:'Menstruatie', value: [1076], checked:false}]},
    {category: 'Stress',list_subcat:[
        {subcat:'Gemoedstoestand', value: [1077], checked:false},
        {subcat:'Kalmerend', value: [1078], checked:false},
        {subcat:'Slaap', value: [1079], checked:false},
        {subcat:'Andere', value: [1204], checked:false}]},
    {category: 'Verkoudheid',list_subcat:[
        {subcat:'Keelpijn', value: [1080], checked:false},
        {subcat:'Koorts', value: [1081], checked:false},
        {subcat:'Slijmhoest', value: [1082], checked:false},
        {subcat:'Droge hoest', value: [1083], checked:false},
        {subcat:'Neusspoeling', value: [1084], checked:false},
        {subcat:'Neusloop/verstopte neus', value: [1085], checked:false},
        {subcat:'Kinderen', value: [1086], checked:false}]},
    {category: 'Allergie',list_subcat:[
        {subcat:'Neusloop/verstopte neus', value: [1087], checked:false},
        {subcat:'Ogen', value: [1088], checked:false},
        {subcat:'Algemeen', value: [1089], checked:false},
        {subcat:'Huid', value: [1090], checked:false}]},
    {category: 'Fytotherapie',list_subcat:[
        {subcat:'Thee', value: [1091], checked:false},
        {subcat:'Homeopathie', value: [1092], checked:false},
        {subcat:'Bachbloesems', value: [1093], checked:false},
        {subcat:'Zware benen', value: [1300], checked:false},
        {subcat:'Overige', value: [1094], checked:false}]},
    {category: 'Dieren',list_subcat:[
        {subcat:'Wormen', value: [1095], checked:false},
        {subcat:'Vlooien/teken', value: [1096], checked:false},
        {subcat:'Verzorging', value: [1097], checked:false}]},
    {category: 'Maag',list_subcat:[
        {subcat:'Misselijkheid', value: [1098], checked:false},
        {subcat:'Maagzuur', value: [1099], checked:false},
        {subcat:'Verteringsproblemen', value: [1100], checked:false},
        {subcat:'Krampen/winderigheid', value: [1101], checked:false},
        {subcat:'Diarree', value: [1102], checked:false},
        {subcat:'Constipatie', value: [1103], checked:false}]},
    {category: 'Baby',list_subcat:[
        {subcat:'Zwangerschapsvitamines', value: [1104], checked:false},
        {subcat:'Melkvoeding', value: [1105], checked:false},
        {subcat:'Verdikkingsmiddelen', value: [1106], checked:false},
        {subcat:'Flessen', value: [1107], checked:false},
        {subcat:'Krampen', value: [1108], checked:false},
        {subcat:'Fopspenen', value: [1109], checked:false},
        {subcat:'Huid', value: [1110], checked:false},
        {subcat:'Eerste tandjes', value: [1111], checked:false},
        {subcat:'Overige', value: [1112], checked:false}]},
    {category: 'Seksualiteit',list_subcat:[
        {subcat:'Condomen', value: [1113], checked:false},
        {subcat:'Glijmiddel', value: [1114], checked:false},
        {subcat:'Intieme hygiëne', value: [1115], checked:false},
        {subcat:'Zwangerschapstesten', value: [1116], checked:false},
        {subcat:'Vruchtbaarheid', value: [1117], checked:false},
        {subcat:'Urineweginfectie', value: [1318], checked:false},
        {subcat:'Menopauze', value: [1317], checked:false},
        {subcat:'Schimmelinfectie', value: [1118], checked:false}]} ,
    {category: 'Testmateriaal',list_subcat:[
        {subcat:'Bloeddruk/hartslag', value: [1206], checked:false},
        {subcat:'Suiker', value: [1207], checked:false},
        {subcat:'Corona', value: [1208], checked:false},
        {subcat:'Zelftesten', value: [1209], checked:false}]},
    {category: 'Aambeien',list_subcat:[
        {subcat:'Zalf', value: [1213], checked:false},
        {subcat:'Suppo', value: [1214], checked:false},
        {subcat:'Tablet', value: [1215], checked:false}]}    
   ];			

   list_specials: category[] = 
   [
    {name: "in de kijker", checked: false, code: "in de kijker"},
    {name: "koopje", checked: false, code: "koopje"},
    {name: "zonder voorschrift", checked: false, code: "zonder voorschrift"}
   ]
   
   list_brand: category[] = [
    {name:"2Cme", checked: false, code: "2Cme"},
    {name:"2Pharma B2B", checked: false, code: "2Pharma B2B"},
    {name:"2Pharma Otc", checked: false, code: "2Pharma Otc"},
    {name:"3 Ddd Pharma", checked: false, code: "3 Ddd Pharma"},
    {name:"3M Belgium", checked: false, code: "3M Belgium"},
    {name:"A.C.R.A.F", checked: false, code: "A.C.R.A.F"},
    {name:"A.V.S.", checked: false, code: "A.V.S."},
    {name:"A.Vogel", checked: false, code: "A.Vogel"},
    {name:"A2Ds", checked: false, code: "A2Ds"},
    {name:"Abacus Medicine", checked: false, code: "Abacus Medicine"},
    {name:"Abbott", checked: false, code: "Abbott"},
    {name:"Abbott Diabetes Care.", checked: false, code: "Abbott Diabetes Care."},
    {name:"Abbvie", checked: false, code: "Abbvie"},
    {name:"Abesef", checked: false, code: "Abesef"},
    {name:"Aboca Belgium", checked: false, code: "Aboca Belgium"},
    {name:"Acar'Up", checked: false, code: "Acar'Up"},
    {name:"Accord Healthcare Bv", checked: false, code: "Accord Healthcare Bv"},
    {name:"Acos Visoderm", checked: false, code: "Acos Visoderm"},
    {name:"Actavis Group Ptc Ehf", checked: false, code: "Actavis Group Ptc Ehf"},
    {name:"Addmedica", checked: false, code: "Addmedica"},
    {name:"Adephar", checked: false, code: "Adephar"},
    {name:"Aderma - Pierre Fabre Benelux", checked: false, code: "Aderma - Pierre Fabre Benelux"},
    {name:"Adg Consultancy", checked: false, code: "Adg Consultancy"},
    {name:"Advys", checked: false, code: "Advys"},
    {name:"Ae Nature", checked: false, code: "Ae Nature"},
    {name:"Aforti", checked: false, code: "Aforti"},
    {name:"Agepha Pharma", checked: false, code: "Agepha Pharma"},
    {name:"Aguettant", checked: false, code: "Aguettant"},
    {name:"Air Liquide Medical", checked: false, code: "Air Liquide Medical"},
    {name:"Airpec Medical Devices", checked: false, code: "Airpec Medical Devices"},
    {name:"Akileine Belge", checked: false, code: "Akileine Belge"},
    {name:"Alanine", checked: false, code: "Alanine"},
    {name:"Alcina Cosmetic Belux", checked: false, code: "Alcina Cosmetic Belux"},
    {name:"Alcoline", checked: false, code: "Alcoline"},
    {name:"Alcon", checked: false, code: "Alcon"},
    {name:"Alcyon Belux", checked: false, code: "Alcyon Belux"},
    {name:"Alfasigma Belgium", checked: false, code: "Alfasigma Belgium"},
    {name:"Alfasigma Healthscience Bv", checked: false, code: "Alfasigma Healthscience Bv"},
    {name:"Alfasigma Spa", checked: false, code: "Alfasigma Spa"},
    {name:"Alk Abello", checked: false, code: "Alk Abello"},
    {name:"Alkobel", checked: false, code: "Alkobel"},
    {name:"Allergan", checked: false, code: "Allergan"},
    {name:"Allopharm", checked: false, code: "Allopharm"},
    {name:"Almirall", checked: false, code: "Almirall"},
    {name:"Alphamega", checked: false, code: "Alphamega"},
    {name:"Alveolus Biomedical B.V.", checked: false, code: "Alveolus Biomedical B.V."},
    {name:"Amdipharm Limited", checked: false, code: "Amdipharm Limited"},
    {name:"Amgen", checked: false, code: "Amgen"},
    {name:"Amgen Europe Bv", checked: false, code: "Amgen Europe Bv"},
    {name:"Amo Belgium", checked: false, code: "Amo Belgium"},
    {name:"Amophar", checked: false, code: "Amophar"},
    {name:"Anicur", checked: false, code: "Anicur"},
    {name:"Appeg", checked: false, code: "Appeg"},
    {name:"Aptalis Pharma", checked: false, code: "Aptalis Pharma"},
    {name:"Aquarius Age Company Belgium", checked: false, code: "Aquarius Age Company Belgium"},
    {name:"Archibo Biologica", checked: false, code: "Archibo Biologica"},
    {name:"Arega Medical", checked: false, code: "Arega Medical"},
    {name:"Arkopharma Belux", checked: false, code: "Arkopharma Belux"},
    {name:"Arseus Dental", checked: false, code: "Arseus Dental"},
    {name:"Arseus Medical", checked: false, code: "Arseus Medical"},
    {name:"Arte-Vita", checked: false, code: "Arte-Vita"},
    {name:"Arvato Distribution Gmbh", checked: false, code: "Arvato Distribution Gmbh"},
    {name:"Ascensia Diabetes Care", checked: false, code: "Ascensia Diabetes Care"},
    {name:"Asepta", checked: false, code: "Asepta"},
    {name:"Asha International", checked: false, code: "Asha International"},
    {name:"Aspen Pharma Trading Ltd", checked: false, code: "Aspen Pharma Trading Ltd"},
    {name:"Astel Medica", checked: false, code: "Astel Medica"},
    {name:"Astellas Pharma", checked: false, code: "Astellas Pharma"},
    {name:"Astrazeneca", checked: false, code: "Astrazeneca"},
    {name:"At Medicals", checked: false, code: "At Medicals"},
    {name:"Atnahs Pharma Uk Ltd", checked: false, code: "Atnahs Pharma Uk Ltd"},
    {name:"Atos Medical", checked: false, code: "Atos Medical"},
    {name:"Atrium Innovations Belgium", checked: false, code: "Atrium Innovations Belgium"},
    {name:"Attends", checked: false, code: "Attends"},
    {name:"Audistim Pharma", checked: false, code: "Audistim Pharma"},
    {name:"Auriga International - Isdin", checked: false, code: "Auriga International - Isdin"},
    {name:"Aurobindo Nv", checked: false, code: "Aurobindo Nv"},
    {name:"Aurobindo Pharma Bv", checked: false, code: "Aurobindo Pharma Bv"},
    {name:"Avene - Pierre Fabre Benelux", checked: false, code: "Avene - Pierre Fabre Benelux"},
    {name:"Awt Belgium", checked: false, code: "Awt Belgium"},
    {name:"Axamed Nv - Sa", checked: false, code: "Axamed Nv - Sa"},
    {name:"Axone Pharma T1", checked: false, code: "Axone Pharma T1"},
    {name:"Axone Pharma T2", checked: false, code: "Axone Pharma T2"},
    {name:"Axone Pharma T4", checked: false, code: "Axone Pharma T4"},
    {name:"B.Braun Medical", checked: false, code: "B.Braun Medical"},
    {name:"Baert S", checked: false, code: "Baert S"},
    {name:"Bap Medical", checked: false, code: "Bap Medical"},
    {name:"Baradi", checked: false, code: "Baradi"},
    {name:"Bard Benelux", checked: false, code: "Bard Benelux"},
    {name:"Basf As", checked: false, code: "Basf As"},
    {name:"Bauerfeind Benelux Bv", checked: false, code: "Bauerfeind Benelux Bv"},
    {name:"Bausch & Lomb Pharma", checked: false, code: "Bausch & Lomb Pharma"},
    {name:"Bavarian Nordic Gmbh", checked: false, code: "Bavarian Nordic Gmbh"},
    {name:"Baxter Belgium", checked: false, code: "Baxter Belgium"},
    {name:"Baxter Oncology", checked: false, code: "Baxter Oncology"},
    {name:"Bayer Animal Health", checked: false, code: "Bayer Animal Health"},
    {name:"Bayer Consumer Health", checked: false, code: "Bayer Consumer Health"},
    {name:"Bayer Cropscience", checked: false, code: "Bayer Cropscience"},
    {name:"Bayer Pharmaceuticals", checked: false, code: "Bayer Pharmaceuticals"},
    {name:"Bdmo", checked: false, code: "Bdmo"},
    {name:"Beaba", checked: false, code: "Beaba"},
    {name:"Beaphar", checked: false, code: "Beaphar"},
    {name:"Becton Dickinson Benelux", checked: false, code: "Becton Dickinson Benelux"},
    {name:"Bee Nature Cosmetic", checked: false, code: "Bee Nature Cosmetic"},
    {name:"Beiersdorf", checked: false, code: "Beiersdorf"},
    {name:"Belgavet", checked: false, code: "Belgavet"},
    {name:"Belgium Medical", checked: false, code: "Belgium Medical"},
    {name:"Belle Et Bien", checked: false, code: "Belle Et Bien"},
    {name:"Bello Mondo", checked: false, code: "Bello Mondo"},
    {name:"Benckmar", checked: false, code: "Benckmar"},
    {name:"Bepharbel Manufacturing", checked: false, code: "Bepharbel Manufacturing"},
    {name:"Besins Healthcare", checked: false, code: "Besins Healthcare"},
    {name:"Beter Wonen", checked: false, code: "Beter Wonen"},
    {name:"Billen", checked: false, code: "Billen"},
    {name:"Bio Life", checked: false, code: "Bio Life"},
    {name:"Bio Minerals", checked: false, code: "Bio Minerals"},
    {name:"Bio Service", checked: false, code: "Bio Service"},
    {name:"Bioamoles", checked: false, code: "Bioamoles"},
    {name:"Biocodex Benelux", checked: false, code: "Biocodex Benelux"},
    {name:"Biocyte Benelux", checked: false, code: "Biocyte Benelux"},
    {name:"Biodev Group - Biophytarom", checked: false, code: "Biodev Group - Biophytarom"},
    {name:"Biofarm", checked: false, code: "Biofarm"},
    {name:"Biogen Belgium", checked: false, code: "Biogen Belgium"},
    {name:"Bioholistic Diffusion", checked: false, code: "Bioholistic Diffusion"},
    {name:"Biolytical Laboratories", checked: false, code: "Biolytical Laboratories"},
    {name:"Biomarin International Ltd", checked: false, code: "Biomarin International Ltd"},
    {name:"Bionoto", checked: false, code: "Bionoto"},
    {name:"Bioproject Pharma", checked: false, code: "Bioproject Pharma"},
    {name:"Bioprojet Europe Ltd", checked: false, code: "Bioprojet Europe Ltd"},
    {name:"Bioservices International", checked: false, code: "Bioservices International"},
    {name:"Biosix", checked: false, code: "Biosix"},
    {name:"Biosynex", checked: false, code: "Biosynex"},
    {name:"Bioticas", checked: false, code: "Bioticas"},
    {name:"Bioventus Coop", checked: false, code: "Bioventus Coop"},
    {name:"Biover", checked: false, code: "Biover"},
    {name:"Biozocal", checked: false, code: "Biozocal"},
    {name:"Blackpoint", checked: false, code: "Blackpoint"},
    {name:"Bmedcare", checked: false, code: "Bmedcare"},
    {name:"Bms Wijndepot", checked: false, code: "Bms Wijndepot"},
    {name:"Boehringer Ingelheim Animal Heal", checked: false, code: "Boehringer Ingelheim Animal Heal"},
    {name:"Bofar", checked: false, code: "Bofar"},
    {name:"Boiron Hnc", checked: false, code: "Boiron Hnc"},
    {name:"Boiron Spe", checked: false, code: "Boiron Spe"},
    {name:"Bolton Belgium", checked: false, code: "Bolton Belgium"},
    {name:"Bomedys", checked: false, code: "Bomedys"},
    {name:"Bonduelle", checked: false, code: "Bonduelle"},
    {name:"Bota", checked: false, code: "Bota"},
    {name:"Bracco Imaging Europe", checked: false, code: "Bracco Imaging Europe"},
    {name:"Brinis", checked: false, code: "Brinis"},
    {name:"Bristol Myers Squibb Belg.  0053", checked: false, code: "Bristol Myers Squibb Belg.  0053"},
    {name:"Brita Benelux", checked: false, code: "Brita Benelux"},
    {name:"Brouwland", checked: false, code: "Brouwland"},
    {name:"Brucare", checked: false, code: "Brucare"},
    {name:"Bs Nutrition", checked: false, code: "Bs Nutrition"},
    {name:"Bsn Medical", checked: false, code: "Bsn Medical"},
    {name:"Buccalco", checked: false, code: "Buccalco"},
    {name:"C&S International", checked: false, code: "C&S International"},
    {name:"C.A.G Pharma", checked: false, code: "C.A.G Pharma"},
    {name:"Caf - Dcf", checked: false, code: "Caf - Dcf"},
    {name:"Camurus Ab", checked: false, code: "Camurus Ab"},
    {name:"Cardinal Health Belgium 505", checked: false, code: "Cardinal Health Belgium 505"},
    {name:"Care Cosmetics", checked: false, code: "Care Cosmetics"},
    {name:"Care Innov", checked: false, code: "Care Innov"},
    {name:"Caresse Cosmetics Bv", checked: false, code: "Caresse Cosmetics Bv"},
    {name:"Carlo Gerard Et Cie", checked: false, code: "Carlo Gerard Et Cie"},
    {name:"Casen Recordati", checked: false, code: "Casen Recordati"},
    {name:"Cbf Medical", checked: false, code: "Cbf Medical"},
    {name:"Cbx Medical", checked: false, code: "Cbx Medical"},
    {name:"Celltrion Healthcare Hungary", checked: false, code: "Celltrion Healthcare Hungary"},
    {name:"Cephalon Bv", checked: false, code: "Cephalon Bv"},
    {name:"Cerave - L'Oreal Belgilux", checked: false, code: "Cerave - L'Oreal Belgilux"},
    {name:"Ceres Pharma", checked: false, code: "Ceres Pharma"},
    {name:"Ceres Pharma Otc 1", checked: false, code: "Ceres Pharma Otc 1"},
    {name:"Ceva Sante Animale", checked: false, code: "Ceva Sante Animale"},
    {name:"Cf Pharma", checked: false, code: "Cf Pharma"},
    {name:"Charco", checked: false, code: "Charco"},
    {name:"Charles", checked: false, code: "Charles"},
    {name:"Chemi", checked: false, code: "Chemi"},
    {name:"Cheplapharm Arzneimittel Gmbh", checked: false, code: "Cheplapharm Arzneimittel Gmbh"},
    {name:"Chiesi", checked: false, code: "Chiesi"},
    {name:"Chiesi Pharmaceutici", checked: false, code: "Chiesi Pharmaceutici"},
    {name:"Chocolat Essentiel", checked: false, code: "Chocolat Essentiel"},
    {name:"Chrisal", checked: false, code: "Chrisal"},
    {name:"Cipla Europe", checked: false, code: "Cipla Europe"},
    {name:"Cld Distribution Nv", checked: false, code: "Cld Distribution Nv"},
    {name:"Co-Ordination", checked: false, code: "Co-Ordination"},
    {name:"Col-Group", checked: false, code: "Col-Group"},
    {name:"Colgate-Palmolive Belgium", checked: false, code: "Colgate-Palmolive Belgium"},
    {name:"Colin Labo", checked: false, code: "Colin Labo"},
    {name:"Colman Labo", checked: false, code: "Colman Labo"},
    {name:"Coloplast Belgium", checked: false, code: "Coloplast Belgium"},
    {name:"Comed", checked: false, code: "Comed"},
    {name:"Complemed Group", checked: false, code: "Complemed Group"},
    {name:"Conforma", checked: false, code: "Conforma"},
    {name:"Consilient Health Ltd", checked: false, code: "Consilient Health Ltd"},
    {name:"Consulta Belgium", checked: false, code: "Consulta Belgium"},
    {name:"Consumedix", checked: false, code: "Consumedix"},
    {name:"Contactlenses Continental", checked: false, code: "Contactlenses Continental"},
    {name:"Continental Pharma Inc", checked: false, code: "Continental Pharma Inc"},
    {name:"Convatec Belgium", checked: false, code: "Convatec Belgium"},
    {name:"Cophana", checked: false, code: "Cophana"},
    {name:"Corman", checked: false, code: "Corman"},
    {name:"Corpus Pharma", checked: false, code: "Corpus Pharma"},
    {name:"Cosmofarma", checked: false, code: "Cosmofarma"},
    {name:"Cosmosiris Sprl", checked: false, code: "Cosmosiris Sprl"},
    {name:"Cosmxpert", checked: false, code: "Cosmxpert"},
    {name:"Covarmed", checked: false, code: "Covarmed"},
    {name:"Covetrus", checked: false, code: "Covetrus"},
    {name:"Covidien Belgium", checked: false, code: "Covidien Belgium"},
    {name:"Creations Cosmetiques", checked: false, code: "Creations Cosmetiques"},
    {name:"Creavest", checked: false, code: "Creavest"},
    {name:"Credophar", checked: false, code: "Credophar"},
    {name:"Cressana", checked: false, code: "Cressana"},
    {name:"Croma Belgie", checked: false, code: "Croma Belgie"},
    {name:"Croons", checked: false, code: "Croons"},
    {name:"Crucell", checked: false, code: "Crucell"},
    {name:"Crystal", checked: false, code: "Crystal"},
    {name:"Csl Behring", checked: false, code: "Csl Behring"},
    {name:"Curaden Swiss - Benelux Division", checked: false, code: "Curaden Swiss - Benelux Division"},
    {name:"Curador", checked: false, code: "Curador"},
    {name:"Cytus", checked: false, code: "Cytus"},
    {name:"D-Line", checked: false, code: "D-Line"},
    {name:"D.E.N. Medical", checked: false, code: "D.E.N. Medical"},
    {name:"Daiichi Sankyo Belgium", checked: false, code: "Daiichi Sankyo Belgium"},
    {name:"Darmaxis", checked: false, code: "Darmaxis"},
    {name:"De Coninck", checked: false, code: "De Coninck"},
    {name:"De Reiger - Vanneste Guillaume", checked: false, code: "De Reiger - Vanneste Guillaume"},
    {name:"Deb-Stoko Europe", checked: false, code: "Deb-Stoko Europe"},
    {name:"Deba Pharma", checked: false, code: "Deba Pharma"},
    {name:"Dechra Veterinary Products", checked: false, code: "Dechra Veterinary Products"},
    {name:"Deco-Vision", checked: false, code: "Deco-Vision"},
    {name:"Decola", checked: false, code: "Decola"},
    {name:"Delattre-Hemapurine Laboratoire", checked: false, code: "Delattre-Hemapurine Laboratoire"},
    {name:"Delta Pet Care", checked: false, code: "Delta Pet Care"},
    {name:"Deltamed", checked: false, code: "Deltamed"},
    {name:"Dema International", checked: false, code: "Dema International"},
    {name:"Densmore Laboratoire", checked: false, code: "Densmore Laboratoire"},
    {name:"Dentaflow", checked: false, code: "Dentaflow"},
    {name:"Dentaid Benelux", checked: false, code: "Dentaid Benelux"},
    {name:"Dental Care Pierre Fabre", checked: false, code: "Dental Care Pierre Fabre"},
    {name:"Dental Care Products", checked: false, code: "Dental Care Products"},
    {name:"Depharm", checked: false, code: "Depharm"},
    {name:"Deprophar", checked: false, code: "Deprophar"},
    {name:"Dermat Medical Supplies", checked: false, code: "Dermat Medical Supplies"},
    {name:"Dhl Pharma Logistics T.A.V. Fsc", checked: false, code: "Dhl Pharma Logistics T.A.V. Fsc"},
    {name:"Diacosmo Belgium", checked: false, code: "Diacosmo Belgium"},
    {name:"Dialex Biomedica", checked: false, code: "Dialex Biomedica"},
    {name:"Diepharmex Laboratoires", checked: false, code: "Diepharmex Laboratoires"},
    {name:"Diet World", checked: false, code: "Diet World"},
    {name:"Dieximport", checked: false, code: "Dieximport"},
    {name:"Difitan", checked: false, code: "Difitan"},
    {name:"Difrax", checked: false, code: "Difrax"},
    {name:"Dilco", checked: false, code: "Dilco"},
    {name:"Dirk Timberch", checked: false, code: "Dirk Timberch"},
    {name:"Distrac", checked: false, code: "Distrac"},
    {name:"Distribio", checked: false, code: "Distribio"},
    {name:"Ditra", checked: false, code: "Ditra"},
    {name:"Dodoens Phyto", checked: false, code: "Dodoens Phyto"},
    {name:"Dopharma Veterinaire Farmaca", checked: false, code: "Dopharma Veterinaire Farmaca"},
    {name:"Dos Medical", checked: false, code: "Dos Medical"},
    {name:"Douwe Egberts", checked: false, code: "Douwe Egberts"},
    {name:"Dr. Falk Pharma Benelux B.V.", checked: false, code: "Dr. Falk Pharma Benelux B.V."},
    {name:"Dr. Kade", checked: false, code: "Dr. Kade"},
    {name:"Draeck Optics", checked: false, code: "Draeck Optics"},
    {name:"Drenco", checked: false, code: "Drenco"},
    {name:"Dsg Vlesia", checked: false, code: "Dsg Vlesia"},
    {name:"Ducray", checked: false, code: "Ducray"},
    {name:"Ducray - Pierre Fabre Benelux", checked: false, code: "Ducray - Pierre Fabre Benelux"},
    {name:"Duivensport Belgie", checked: false, code: "Duivensport Belgie"},
    {name:"Dyna ", checked: false, code: "Dyna "},
    {name:"Dynarop Products", checked: false, code: "Dynarop Products"},
    {name:"E-Bugs Sprl", checked: false, code: "E-Bugs Sprl"},
    {name:"Eco Animal Health Europe Limited", checked: false, code: "Eco Animal Health Europe Limited"},
    {name:"Eco Pharma Supply", checked: false, code: "Eco Pharma Supply"},
    {name:"Ecomar", checked: false, code: "Ecomar"},
    {name:"Ecopharma", checked: false, code: "Ecopharma"},
    {name:"Ecuphar Nv/Sa", checked: false, code: "Ecuphar Nv/Sa"},
    {name:"Edialux - Formulex", checked: false, code: "Edialux - Formulex"},
    {name:"Effik Benelux", checked: false, code: "Effik Benelux"},
    {name:"Eg Nv/Sa", checked: false, code: "Eg Nv/Sa"},
    {name:"Ehaco", checked: false, code: "Ehaco"},
    {name:"Eisai", checked: false, code: "Eisai"},
    {name:"Elanco Animal Health", checked: false, code: "Elanco Animal Health"},
    {name:"Eli Lilly Benelux", checked: false, code: "Eli Lilly Benelux"},
    {name:"Elin", checked: false, code: "Elin"},
    {name:"Emdoka", checked: false, code: "Emdoka"},
    {name:"Energetica Natura Benelux", checked: false, code: "Energetica Natura Benelux"},
    {name:"Enteco", checked: false, code: "Enteco"},
    {name:"Entusia", checked: false, code: "Entusia"},
    {name:"Envederm", checked: false, code: "Envederm"},
    {name:"Eodis", checked: false, code: "Eodis"},
    {name:"Epilation Services", checked: false, code: "Epilation Services"},
    {name:"Equilac Llc", checked: false, code: "Equilac Llc"},
    {name:"Ergomedics", checked: false, code: "Ergomedics"},
    {name:"Escos", checked: false, code: "Escos"},
    {name:"Esri", checked: false, code: "Esri"},
    {name:"Essential Pharma", checked: false, code: "Essential Pharma"},
    {name:"Essity Belgium", checked: false, code: "Essity Belgium"},
    {name:"Estetra", checked: false, code: "Estetra"},
    {name:"Eumedica", checked: false, code: "Eumedica"},
    {name:"Eureka Pharma", checked: false, code: "Eureka Pharma"},
    {name:"Euro Promo Consult", checked: false, code: "Euro Promo Consult"},
    {name:"Eurocept International Bv", checked: false, code: "Eurocept International Bv"},
    {name:"Eurocosmetic International", checked: false, code: "Eurocosmetic International"},
    {name:"Eurocosmetics & Accessoires", checked: false, code: "Eurocosmetics & Accessoires"},
    {name:"Eurolabor", checked: false, code: "Eurolabor"},
    {name:"Euromi", checked: false, code: "Euromi"},
    {name:"European Eye Care Supply", checked: false, code: "European Eye Care Supply"},
    {name:"European Sleep Research Institut", checked: false, code: "European Sleep Research Institut"},
    {name:"Eusa Pharma", checked: false, code: "Eusa Pharma"},
    {name:"Eutherapie Benelux", checked: false, code: "Eutherapie Benelux"},
    {name:"Evergreenland Europe", checked: false, code: "Evergreenland Europe"},
    {name:"Evolab Belux", checked: false, code: "Evolab Belux"},
    {name:"Exeltis Germany", checked: false, code: "Exeltis Germany"},
    {name:"Expanscience Laboratoires", checked: false, code: "Expanscience Laboratoires"},
    {name:"Exquises", checked: false, code: "Exquises"},
    {name:"F.D.H. Distribution", checked: false, code: "F.D.H. Distribution"},
    {name:"Fabel", checked: false, code: "Fabel"},
    {name:"Fabrilens Evolutions", checked: false, code: "Fabrilens Evolutions"},
    {name:"Faes Farma", checked: false, code: "Faes Farma"},
    {name:"Fagron", checked: false, code: "Fagron"},
    {name:"Farmafyt", checked: false, code: "Farmafyt"},
    {name:"Fcl Science B.V.", checked: false, code: "Fcl Science B.V."},
    {name:"Febelco Brugge", checked: false, code: "Febelco Brugge"},
    {name:"Febelco Group", checked: false, code: "Febelco Group"},
    {name:"Fendigo", checked: false, code: "Fendigo"},
    {name:"Ferrer Internacional", checked: false, code: "Ferrer Internacional"},
    {name:"Ferring", checked: false, code: "Ferring"},
    {name:"Fertipro", checked: false, code: "Fertipro"},
    {name:"Filter Service", checked: false, code: "Filter Service"},
    {name:"Fisher", checked: false, code: "Fisher"},
    {name:"Flen Health Pharma", checked: false, code: "Flen Health Pharma"},
    {name:"Foresee Line", checked: false, code: "Foresee Line"},
    {name:"Form'Axe", checked: false, code: "Form'Axe"},
    {name:"Forte Pharma", checked: false, code: "Forte Pharma"},
    {name:"Fraver", checked: false, code: "Fraver"},
    {name:"Fresenius Kabi", checked: false, code: "Fresenius Kabi"},
    {name:"Frio Deutschland Gmbh", checked: false, code: "Frio Deutschland Gmbh"},
    {name:"Fromont", checked: false, code: "Fromont"},
    {name:"Fsa Chemicals", checked: false, code: "Fsa Chemicals"},
    {name:"Fytofarma", checked: false, code: "Fytofarma"},
    {name:"G.L. Pharma", checked: false, code: "G.L. Pharma"},
    {name:"Galapagos", checked: false, code: "Galapagos"},
    {name:"Galderma Benelux Bv", checked: false, code: "Galderma Benelux Bv"},
    {name:"Galinov Sprl", checked: false, code: "Galinov Sprl"},
    {name:"Gd Medical", checked: false, code: "Gd Medical"},
    {name:"Ge Healthcare", checked: false, code: "Ge Healthcare"},
    {name:"Gedeon Richter Benelux", checked: false, code: "Gedeon Richter Benelux"},
    {name:"Gedeon Richter Plc.", checked: false, code: "Gedeon Richter Plc."},
    {name:"Gelbopharma", checked: false, code: "Gelbopharma"},
    {name:"Gen.Orph", checked: false, code: "Gen.Orph"},
    {name:"Geode Chemicals & Laboratories", checked: false, code: "Geode Chemicals & Laboratories"},
    {name:"Gibaud Benelux", checked: false, code: "Gibaud Benelux"},
    {name:"Gilead Sciences Belgium", checked: false, code: "Gilead Sciences Belgium"},
    {name:"Gillet H. Medical", checked: false, code: "Gillet H. Medical"},
    {name:"Gillette Group Belgium", checked: false, code: "Gillette Group Belgium"},
    {name:"Glaxo Smithkline Biologicals", checked: false, code: "Glaxo Smithkline Biologicals"},
    {name:"Glaxosmithkline Cons. Healthcare", checked: false, code: "Glaxosmithkline Cons. Healthcare"},
    {name:"Glaxosmithkline Pharmaceuticals", checked: false, code: "Glaxosmithkline Pharmaceuticals"},
    {name:"Glenwood Gmbh", checked: false, code: "Glenwood Gmbh"},
    {name:"Glnp", checked: false, code: "Glnp"},
    {name:"Global Medics", checked: false, code: "Global Medics"},
    {name:"Global Smile", checked: false, code: "Global Smile"},
    {name:"Globifer Intl.", checked: false, code: "Globifer Intl."},
    {name:"Golvita", checked: false, code: "Golvita"},
    {name:"Goodlife Pharma", checked: false, code: "Goodlife Pharma"},
    {name:"Gouttiere Peter Euro Enterprise", checked: false, code: "Gouttiere Peter Euro Enterprise"},
    {name:"Greendock", checked: false, code: "Greendock"},
    {name:"Grunenthal Belgium", checked: false, code: "Grunenthal Belgium"},
    {name:"Gsa Healthcare Team 1", checked: false, code: "Gsa Healthcare Team 1"},
    {name:"Gsa Nutrimed", checked: false, code: "Gsa Nutrimed"},
    {name:"Guerbet", checked: false, code: "Guerbet"},
    {name:"Hac Pharma", checked: false, code: "Hac Pharma"},
    {name:"Hagor", checked: false, code: "Hagor"},
    {name:"Hal Allergy Benelux", checked: false, code: "Hal Allergy Benelux"},
    {name:"Hanff Global Health Solutions S.", checked: false, code: "Hanff Global Health Solutions S."},
    {name:"Haromed", checked: false, code: "Haromed"},
    {name:"Hauliga", checked: false, code: "Hauliga"},
    {name:"Hdp Medical Int.", checked: false, code: "Hdp Medical Int."},
    {name:"Health And Happiness", checked: false, code: "Health And Happiness"},
    {name:"Health Benefits O8", checked: false, code: "Health Benefits O8"},
    {name:"Heel-Belgium", checked: false, code: "Heel-Belgium"},
    {name:"Heijne Import", checked: false, code: "Heijne Import"},
    {name:"Helios Laboratoires", checked: false, code: "Helios Laboratoires"},
    {name:"Helvetica Pharma Distribution", checked: false, code: "Helvetica Pharma Distribution"},
    {name:"Henatechs Sarl", checked: false, code: "Henatechs Sarl"},
    {name:"Henrotech", checked: false, code: "Henrotech"},
    {name:"Henry Schein", checked: false, code: "Henry Schein"},
    {name:"Herba Plus", checked: false, code: "Herba Plus"},
    {name:"Herbalgem", checked: false, code: "Herbalgem"},
    {name:"Hew Pharma", checked: false, code: "Hew Pharma"},
    {name:"Hill'S Pet Nutrition", checked: false, code: "Hill'S Pet Nutrition"},
    {name:"Hollister Belgium", checked: false, code: "Hollister Belgium"},
    {name:"Hollister Dansac", checked: false, code: "Hollister Dansac"},
    {name:"Honey Patch", checked: false, code: "Honey Patch"},
    {name:"Horizane", checked: false, code: "Horizane"},
    {name:"Horse Power", checked: false, code: "Horse Power"},
    {name:"Horus Pharma", checked: false, code: "Horus Pharma"},
    {name:"Hospidex", checked: false, code: "Hospidex"},
    {name:"Hospira Benelux", checked: false, code: "Hospira Benelux"},
    {name:"Hospira Healthcare", checked: false, code: "Hospira Healthcare"},
    {name:"Hospithera", checked: false, code: "Hospithera"},
    {name:"Hra Pharma Benelux", checked: false, code: "Hra Pharma Benelux"},
    {name:"Huckert'S International", checked: false, code: "Huckert'S International"},
    {name:"Hungr", checked: false, code: "Hungr"},
    {name:"Hwb International", checked: false, code: "Hwb International"},
    {name:"I.D. Phar", checked: false, code: "I.D. Phar"},
    {name:"Ibsa Farmaceutici Italia", checked: false, code: "Ibsa Farmaceutici Italia"},
    {name:"Ic Pharma Distribution - Bio Win", checked: false, code: "Ic Pharma Distribution - Bio Win"},
    {name:"Impexeco", checked: false, code: "Impexeco"},
    {name:"Inatura", checked: false, code: "Inatura"},
    {name:"Incara Lab", checked: false, code: "Incara Lab"},
    {name:"Indivior Uk Limited", checked: false, code: "Indivior Uk Limited"},
    {name:"Inex", checked: false, code: "Inex"},
    {name:"Infinity Pharma", checked: false, code: "Infinity Pharma"},
    {name:"Innoceutics", checked: false, code: "Innoceutics"},
    {name:"Innomedis", checked: false, code: "Innomedis"},
    {name:"Innoventis One", checked: false, code: "Innoventis One"},
    {name:"Instrumentation Difra", checked: false, code: "Instrumentation Difra"},
    {name:"Interbel", checked: false, code: "Interbel"},
    {name:"Interhealth", checked: false, code: "Interhealth"},
    {name:"International Medical", checked: false, code: "International Medical"},
    {name:"Interphar", checked: false, code: "Interphar"},
    {name:"Intertrade Cosmetics", checked: false, code: "Intertrade Cosmetics"},
    {name:"Ipsen", checked: false, code: "Ipsen"},
    {name:"Ipsen Consumer Healthcare", checked: false, code: "Ipsen Consumer Healthcare"},
    {name:"Ixx Pharma", checked: false, code: "Ixx Pharma"},
    {name:"J. Uriach Y Compana", checked: false, code: "J. Uriach Y Compana"},
    {name:"Jacquiez Ets", checked: false, code: "Jacquiez Ets"},
    {name:"Janssen Cilag", checked: false, code: "Janssen Cilag"},
    {name:"Janssen Pharmaceutica", checked: false, code: "Janssen Pharmaceutica"},
    {name:"Jcf Pharma", checked: false, code: "Jcf Pharma"},
    {name:"Jerlo", checked: false, code: "Jerlo"},
    {name:"Johnson & Johnson Consumer", checked: false, code: "Johnson & Johnson Consumer"},
    {name:"Johnson & Johnson Consumer Otc", checked: false, code: "Johnson & Johnson Consumer Otc"},
    {name:"Johnson & Johnson Medical", checked: false, code: "Johnson & Johnson Medical"},
    {name:"Juvise Pharmaceuticals", checked: false, code: "Juvise Pharmaceuticals"},
    {name:"Kami Basic", checked: false, code: "Kami Basic"},
    {name:"Karo Pharma", checked: false, code: "Karo Pharma"},
    {name:"Katiouchka", checked: false, code: "Katiouchka"},
    {name:"Kaz Europe", checked: false, code: "Kaz Europe"},
    {name:"Kela", checked: false, code: "Kela"},
    {name:"Kela Pharma", checked: false, code: "Kela Pharma"},
    {name:"Kela Veterinaria", checked: false, code: "Kela Veterinaria"},
    {name:"Keypharm", checked: false, code: "Keypharm"},
    {name:"Kimberly-Clarck Europe Ltd", checked: false, code: "Kimberly-Clarck Europe Ltd"},
    {name:"Klorane - Pierre Fabre Benelux", checked: false, code: "Klorane - Pierre Fabre Benelux"},
    {name:"Kneipp Belgie", checked: false, code: "Kneipp Belgie"},
    {name:"Kopra Trading", checked: false, code: "Kopra Trading"},
    {name:"Krka", checked: false, code: "Krka"},
    {name:"Kuraci", checked: false, code: "Kuraci"},
    {name:"Kuracilo", checked: false, code: "Kuracilo"},
    {name:"Kyowa Kirin Pharma Bv.", checked: false, code: "Kyowa Kirin Pharma Bv."},
    {name:"L'Oreal Belgilux - Division Cosm", checked: false, code: "L'Oreal Belgilux - Division Cosm"},
    {name:"La Roche-Posay - L'Oreal Belgilu", checked: false, code: "La Roche-Posay - L'Oreal Belgilu"},
    {name:"Lab Totum/Aromalys", checked: false, code: "Lab Totum/Aromalys"},
    {name:"Labo Life Belgium", checked: false, code: "Labo Life Belgium"},
    {name:"Labophar B  Pharma", checked: false, code: "Labophar B  Pharma"},
    {name:"Labophar Bioradix", checked: false, code: "Labophar Bioradix"},
    {name:"Labophar Energetic Food & Supple", checked: false, code: "Labophar Energetic Food & Supple"},
    {name:"Labophar Labart", checked: false, code: "Labophar Labart"},
    {name:"Labophar Nv", checked: false, code: "Labophar Nv"},
    {name:"Laboratoire Axone", checked: false, code: "Laboratoire Axone"},
    {name:"Laboratoire Biokeep", checked: false, code: "Laboratoire Biokeep"},
    {name:"Laboratoire Dermatologique Acm", checked: false, code: "Laboratoire Dermatologique Acm"},
    {name:"Laboratoire Jaldes", checked: false, code: "Laboratoire Jaldes"},
    {name:"Laboratoire Lisandra", checked: false, code: "Laboratoire Lisandra"},
    {name:"Laboratoire Marque Verte", checked: false, code: "Laboratoire Marque Verte"},
    {name:"Laboratoire Mc Natura", checked: false, code: "Laboratoire Mc Natura"},
    {name:"Laboratoire Native", checked: false, code: "Laboratoire Native"},
    {name:"Laboratoire Nutergia", checked: false, code: "Laboratoire Nutergia"},
    {name:"Laboratoire Oxyform", checked: false, code: "Laboratoire Oxyform"},
    {name:"Laboratoire Terpan", checked: false, code: "Laboratoire Terpan"},
    {name:"Laboratoire Tvm", checked: false, code: "Laboratoire Tvm"},
    {name:"Laboratoires Bailleul", checked: false, code: "Laboratoires Bailleul"},
    {name:"Laboratoires Biopha", checked: false, code: "Laboratoires Biopha"},
    {name:"Laboratoires De Biarritz", checked: false, code: "Laboratoires De Biarritz"},
    {name:"Laboratoires Delbert", checked: false, code: "Laboratoires Delbert"},
    {name:"Laboratoires Dietetique Et Sante", checked: false, code: "Laboratoires Dietetique Et Sante"},
    {name:"Laboratoires Doliage Developpeme", checked: false, code: "Laboratoires Doliage Developpeme"},
    {name:"Laboratoires Equilibre Attitude", checked: false, code: "Laboratoires Equilibre Attitude"},
    {name:"Laboratoires Fenioux Benelux", checked: false, code: "Laboratoires Fenioux Benelux"},
    {name:"Laboratoires Galeniques Vernin", checked: false, code: "Laboratoires Galeniques Vernin"},
    {name:"Laboratoires Gilbert Benelux", checked: false, code: "Laboratoires Gilbert Benelux"},
    {name:"Laboratoires Ineldea", checked: false, code: "Laboratoires Ineldea"},
    {name:"Laboratoires Iprad Sante", checked: false, code: "Laboratoires Iprad Sante"},
    {name:"Laboratoires Leadersante", checked: false, code: "Laboratoires Leadersante"},
    {name:"Laboratoires Lehning", checked: false, code: "Laboratoires Lehning"},
    {name:"Laboratoires Majorelle", checked: false, code: "Laboratoires Majorelle"},
    {name:"Laboratoires Pi Care", checked: false, code: "Laboratoires Pi Care"},
    {name:"Laboratoires Prodene Klint", checked: false, code: "Laboratoires Prodene Klint"},
    {name:"Laboratoires Repouss", checked: false, code: "Laboratoires Repouss"},
    {name:"Laboratoires Surveal", checked: false, code: "Laboratoires Surveal"},
    {name:"Laboratoires Svr Belgium", checked: false, code: "Laboratoires Svr Belgium"},
    {name:"Laboratoires Tradiphar", checked: false, code: "Laboratoires Tradiphar"},
    {name:"Laboratoria Farmaceutico Sit", checked: false, code: "Laboratoria Farmaceutico Sit"},
    {name:"Laboratorios Babe", checked: false, code: "Laboratorios Babe"},
    {name:"Lactalis Nutrition Sante", checked: false, code: "Lactalis Nutrition Sante"},
    {name:"Lagae & Zonen", checked: false, code: "Lagae & Zonen"},
    {name:"Lambo", checked: false, code: "Lambo"},
    {name:"Lamepro Bv", checked: false, code: "Lamepro Bv"},
    {name:"Lanaform", checked: false, code: "Lanaform"},
    {name:"Lansinoh Laboratories Benelux", checked: false, code: "Lansinoh Laboratories Benelux"},
    {name:"Lapperre", checked: false, code: "Lapperre"},
    {name:"Lasters", checked: false, code: "Lasters"},
    {name:"Ldu Belux", checked: false, code: "Ldu Belux"},
    {name:"Lensfactory", checked: false, code: "Lensfactory"},
    {name:"Leo Pharma", checked: false, code: "Leo Pharma"},
    {name:"Lepi Vits Belgium", checked: false, code: "Lepi Vits Belgium"},
    {name:"Lesvi Avda", checked: false, code: "Lesvi Avda"},
    {name:"Lick", checked: false, code: "Lick"},
    {name:"Lievens - Lanckman", checked: false, code: "Lievens - Lanckman"},
    {name:"Lifescan Belgium", checked: false, code: "Lifescan Belgium"},
    {name:"Lifestream Pharma", checked: false, code: "Lifestream Pharma"},
    {name:"Lille Healthcare", checked: false, code: "Lille Healthcare"},
    {name:"Limacom", checked: false, code: "Limacom"},
    {name:"Line-Gold", checked: false, code: "Line-Gold"},
    {name:"Locamed", checked: false, code: "Locamed"},
    {name:"Loen Laboratories Europe", checked: false, code: "Loen Laboratories Europe"},
    {name:"Lohmann & Rauscher", checked: false, code: "Lohmann & Rauscher"},
    {name:"Lt Laboratoires", checked: false, code: "Lt Laboratoires"},
    {name:"Lundbeck", checked: false, code: "Lundbeck"},
    {name:"Lusine Bruxelles", checked: false, code: "Lusine Bruxelles"},
    {name:"M&M Pro Life", checked: false, code: "M&M Pro Life"},
    {name:"M.Ex.T.Belgium", checked: false, code: "M.Ex.T.Belgium"},
    {name:"M2M Europe", checked: false, code: "M2M Europe"},
    {name:"Magis Pharma", checked: false, code: "Magis Pharma"},
    {name:"Malaise & Co", checked: false, code: "Malaise & Co"},
    {name:"Malkia", checked: false, code: "Malkia"},
    {name:"Mam Babyartikel Gmbh", checked: false, code: "Mam Babyartikel Gmbh"},
    {name:"Marma Natuurproducten", checked: false, code: "Marma Natuurproducten"},
    {name:"Maximize Europe", checked: false, code: "Maximize Europe"},
    {name:"Maxzenco", checked: false, code: "Maxzenco"},
    {name:"Mayoly Benelux", checked: false, code: "Mayoly Benelux"},
    {name:"Mcdavid Europe", checked: false, code: "Mcdavid Europe"},
    {name:"Mead Johnson Nutrition Belgium 0", checked: false, code: "Mead Johnson Nutrition Belgium 0"},
    {name:"Meda Pharma", checked: false, code: "Meda Pharma"},
    {name:"Medela Benelux", checked: false, code: "Medela Benelux"},
    {name:"Medi Belgium", checked: false, code: "Medi Belgium"},
    {name:"Medibling", checked: false, code: "Medibling"},
    {name:"Medical Equipment Trade", checked: false, code: "Medical Equipment Trade"},
    {name:"Medical Essentials", checked: false, code: "Medical Essentials"},
    {name:"Medice Arzneimittel Putter", checked: false, code: "Medice Arzneimittel Putter"},
    {name:"Medidat", checked: false, code: "Medidat"},
    {name:"Medident", checked: false, code: "Medident"},
    {name:"Mediq Medeco", checked: false, code: "Mediq Medeco"},
    {name:"Medix Laboratoires N.V", checked: false, code: "Medix Laboratoires N.V"},
    {name:"Medline International Belgium", checked: false, code: "Medline International Belgium"},
    {name:"Medoxys", checked: false, code: "Medoxys"},
    {name:"Medskin Nutrition", checked: false, code: "Medskin Nutrition"},
    {name:"Medtradex", checked: false, code: "Medtradex"},
    {name:"Mega Company", checked: false, code: "Mega Company"},
    {name:"Meli", checked: false, code: "Meli"},
    {name:"Melisana", checked: false, code: "Melisana"},
    {name:"Melphar", checked: false, code: "Melphar"},
    {name:"Memidis Pharma", checked: false, code: "Memidis Pharma"},
    {name:"Menarini Benelux", checked: false, code: "Menarini Benelux"},
    {name:"Mendes", checked: false, code: "Mendes"},
    {name:"Merck", checked: false, code: "Merck"},
    {name:"Merck Chemicals", checked: false, code: "Merck Chemicals"},
    {name:"Merck Sharp & Dohme", checked: false, code: "Merck Sharp & Dohme"},
    {name:"Merck Sharp & Dohme B.V.", checked: false, code: "Merck Sharp & Dohme B.V."},
    {name:"Mercury Pharma Group", checked: false, code: "Mercury Pharma Group"},
    {name:"Metagenics Belgium", checked: false, code: "Metagenics Belgium"},
    {name:"Metis Supplements", checked: false, code: "Metis Supplements"},
    {name:"Metra", checked: false, code: "Metra"},
    {name:"Mibetec", checked: false, code: "Mibetec"},
    {name:"Millet Innovation", checked: false, code: "Millet Innovation"},
    {name:"Miloa", checked: false, code: "Miloa"},
    {name:"Mithra Pharmaceuticals", checked: false, code: "Mithra Pharmaceuticals"},
    {name:"Modus Vivendi", checked: false, code: "Modus Vivendi"},
    {name:"Mojeko", checked: false, code: "Mojeko"},
    {name:"Molnlycke Healthcare", checked: false, code: "Molnlycke Healthcare"},
    {name:"Mooncup Ltd", checked: false, code: "Mooncup Ltd"},
    {name:"Mordan", checked: false, code: "Mordan"},
    {name:"Mosquitno", checked: false, code: "Mosquitno"},
    {name:"Moulinroyal Cosmetics", checked: false, code: "Moulinroyal Cosmetics"},
    {name:"Mpi", checked: false, code: "Mpi"},
    {name:"Mps Europe", checked: false, code: "Mps Europe"},
    {name:"Msd Animal Health", checked: false, code: "Msd Animal Health"},
    {name:"Msd Belgium", checked: false, code: "Msd Belgium"},
    {name:"Msd Vaccines Belgium", checked: false, code: "Msd Vaccines Belgium"},
    {name:"Msh", checked: false, code: "Msh"},
    {name:"Muller & Schmidt Pfeilringwerk", checked: false, code: "Muller & Schmidt Pfeilringwerk"},
    {name:"Multi G", checked: false, code: "Multi G"},
    {name:"Mundipharma", checked: false, code: "Mundipharma"},
    {name:"Musst", checked: false, code: "Musst"},
    {name:"My Health", checked: false, code: "My Health"},
    {name:"Mylan", checked: false, code: "Mylan"},
    {name:"Mylan Epd", checked: false, code: "Mylan Epd"},
    {name:"Naos", checked: false, code: "Naos"},
    {name:"Naqi", checked: false, code: "Naqi"},
    {name:"Natur'Inov", checked: false, code: "Natur'Inov"},
    {name:"Natura Medicatrix", checked: false, code: "Natura Medicatrix"},
    {name:"Natural Granen", checked: false, code: "Natural Granen"},
    {name:"Natural Health Products", checked: false, code: "Natural Health Products"},
    {name:"Nature Element", checked: false, code: "Nature Element"},
    {name:"Negma", checked: false, code: "Negma"},
    {name:"Neocare", checked: false, code: "Neocare"},
    {name:"Nestle Belgilux", checked: false, code: "Nestle Belgilux"},
    {name:"Neuapharma", checked: false, code: "Neuapharma"},
    {name:"Neuraxpharm France", checked: false, code: "Neuraxpharm France"},
    {name:"New Davigo", checked: false, code: "New Davigo"},
    {name:"New Valmar", checked: false, code: "New Valmar"},
    {name:"Next Generation Product Europe", checked: false, code: "Next Generation Product Europe"},
    {name:"Nhm", checked: false, code: "Nhm"},
    {name:"Nl Pharma Veterinair", checked: false, code: "Nl Pharma Veterinair"},
    {name:"Noble-House", checked: false, code: "Noble-House"},
    {name:"Nodens", checked: false, code: "Nodens"},
    {name:"Nootens", checked: false, code: "Nootens"},
    {name:"Nordic Pharma", checked: false, code: "Nordic Pharma"},
    {name:"Norgine", checked: false, code: "Norgine"},
    {name:"Normaphar", checked: false, code: "Normaphar"},
    {name:"Novartis Animal Health Sector", checked: false, code: "Novartis Animal Health Sector"},
    {name:"Novartis Pharma", checked: false, code: "Novartis Pharma"},
    {name:"Novitan", checked: false, code: "Novitan"},
    {name:"Novo Nordisk Pharma", checked: false, code: "Novo Nordisk Pharma"},
    {name:"Novomedis Laboratoire", checked: false, code: "Novomedis Laboratoire"},
    {name:"Novum Pharma", checked: false, code: "Novum Pharma"},
    {name:"Nrgi", checked: false, code: "Nrgi"},
    {name:"Nu Skin Belgium", checked: false, code: "Nu Skin Belgium"},
    {name:"Nut-Hom-Phyt", checked: false, code: "Nut-Hom-Phyt"},
    {name:"Nutamed", checked: false, code: "Nutamed"},
    {name:"Nutraya Belgium", checked: false, code: "Nutraya Belgium"},
    {name:"Nutreov Physcience", checked: false, code: "Nutreov Physcience"},
    {name:"Nutri-Ethic", checked: false, code: "Nutri-Ethic"},
    {name:"Nutriceuticoils", checked: false, code: "Nutriceuticoils"},
    {name:"Nutricia Amn", checked: false, code: "Nutricia Amn"},
    {name:"Nutricia Eln", checked: false, code: "Nutricia Eln"},
    {name:"Nutrifarma", checked: false, code: "Nutrifarma"},
    {name:"Nutrifor", checked: false, code: "Nutrifor"},
    {name:"Nutriliq", checked: false, code: "Nutriliq"},
    {name:"Nutrimed", checked: false, code: "Nutrimed"},
    {name:"Nutripharm", checked: false, code: "Nutripharm"},
    {name:"Nutriphyt", checked: false, code: "Nutriphyt"},
    {name:"Nutrisan", checked: false, code: "Nutrisan"},
    {name:"Nutrisante", checked: false, code: "Nutrisante"},
    {name:"Nutrisens Medical", checked: false, code: "Nutrisens Medical"},
    {name:"Nutrissentiel", checked: false, code: "Nutrissentiel"},
    {name:"Nutrition & Sante Benelux", checked: false, code: "Nutrition & Sante Benelux"},
    {name:"Nuxe", checked: false, code: "Nuxe"},
    {name:"O'Life Natural", checked: false, code: "O'Life Natural"},
    {name:"Oak Pharma", checked: false, code: "Oak Pharma"},
    {name:"Oceanterre Biotechnologie", checked: false, code: "Oceanterre Biotechnologie"},
    {name:"Ocebio", checked: false, code: "Ocebio"},
    {name:"Octapharma", checked: false, code: "Octapharma"},
    {name:"Ohmeda", checked: false, code: "Ohmeda"},
    {name:"Ojibwa-De Roeck", checked: false, code: "Ojibwa-De Roeck"},
    {name:"Okt Benelux", checked: false, code: "Okt Benelux"},
    {name:"Olmavita", checked: false, code: "Olmavita"},
    {name:"Olys Pharma Srl", checked: false, code: "Olys Pharma Srl"},
    {name:"Om Pharma", checked: false, code: "Om Pharma"},
    {name:"Omega Patch", checked: false, code: "Omega Patch"},
    {name:"Omega Pharma Belgium", checked: false, code: "Omega Pharma Belgium"},
    {name:"Ontex", checked: false, code: "Ontex"},
    {name:"Op De Locht", checked: false, code: "Op De Locht"},
    {name:"Ophtalmo Service", checked: false, code: "Ophtalmo Service"},
    {name:"Optiek Cassiers", checked: false, code: "Optiek Cassiers"},
    {name:"Oracoat Benelux", checked: false, code: "Oracoat Benelux"},
    {name:"Oral B Laboratories Belgium", checked: false, code: "Oral B Laboratories Belgium"},
    {name:"Orexigen Therapeutics Ireland", checked: false, code: "Orexigen Therapeutics Ireland"},
    {name:"Organon", checked: false, code: "Organon"},
    {name:"Organon Belge", checked: false, code: "Organon Belge"},
    {name:"Organon Belgium", checked: false, code: "Organon Belgium"},
    {name:"Orifarm  A/S", checked: false, code: "Orifarm  A/S"},
    {name:"Orifarm Healthcare", checked: false, code: "Orifarm Healthcare"},
    {name:"Orion Corporation", checked: false, code: "Orion Corporation"},
    {name:"Orion Pharma", checked: false, code: "Orion Pharma"},
    {name:"Orion Pharma Benelux", checked: false, code: "Orion Pharma Benelux"},
    {name:"Oropharma", checked: false, code: "Oropharma"},
    {name:"Ortho Clinical Diagnostics", checked: false, code: "Ortho Clinical Diagnostics"},
    {name:"Ortho Medico", checked: false, code: "Ortho Medico"},
    {name:"Ortis", checked: false, code: "Ortis"},
    {name:"Oscar", checked: false, code: "Oscar"},
    {name:"Otc Medical", checked: false, code: "Otc Medical"},
    {name:"Otc Solutions", checked: false, code: "Otc Solutions"},
    {name:"Otl Pharma", checked: false, code: "Otl Pharma"},
    {name:"Otsuka Pharmaceutical Netherland", checked: false, code: "Otsuka Pharmaceutical Netherland"},
    {name:"Ottimedi", checked: false, code: "Ottimedi"},
    {name:"Oxylent", checked: false, code: "Oxylent"},
    {name:"Oxysphair", checked: false, code: "Oxysphair"},
    {name:"Oystershell", checked: false, code: "Oystershell"},
    {name:"P & G Health Belgium", checked: false, code: "P & G Health Belgium"},
    {name:"Pab Benelux", checked: false, code: "Pab Benelux"},
    {name:"Panacea Dm", checked: false, code: "Panacea Dm"},
    {name:"Panasonic", checked: false, code: "Panasonic"},
    {name:"Pannoc", checked: false, code: "Pannoc"},
    {name:"Parabolic Biologicals", checked: false, code: "Parabolic Biologicals"},
    {name:"Patch Pharma", checked: false, code: "Patch Pharma"},
    {name:"Paul Hartmann", checked: false, code: "Paul Hartmann"},
    {name:"Paxvax Berna Gmbh", checked: false, code: "Paxvax Berna Gmbh"},
    {name:"Perrigo", checked: false, code: "Perrigo"},
    {name:"Perrigo Cloud", checked: false, code: "Perrigo Cloud"},
    {name:"Perrigo Healthcare", checked: false, code: "Perrigo Healthcare"},
    {name:"Perrigo Phytosun", checked: false, code: "Perrigo Phytosun"},
    {name:"Perrigo Portal", checked: false, code: "Perrigo Portal"},
    {name:"Perrigo Well-Being", checked: false, code: "Perrigo Well-Being"},
    {name:"Petnet", checked: false, code: "Petnet"},
    {name:"Pfizer", checked: false, code: "Pfizer"},
    {name:"Pfizer Europe Ma Eeig", checked: false, code: "Pfizer Europe Ma Eeig"},
    {name:"Pfizer Sante Familiale", checked: false, code: "Pfizer Sante Familiale"},
    {name:"Phar - Gms Research", checked: false, code: "Phar - Gms Research"},
    {name:"Pharm'Up Development", checked: false, code: "Pharm'Up Development"},
    {name:"Pharma More", checked: false, code: "Pharma More"},
    {name:"Pharma Nord", checked: false, code: "Pharma Nord"},
    {name:"Pharma Pack", checked: false, code: "Pharma Pack"},
    {name:"Pharma Sgp", checked: false, code: "Pharma Sgp"},
    {name:"Pharma.Pet", checked: false, code: "Pharma.Pet"},
    {name:"Pharmaand", checked: false, code: "Pharmaand"},
    {name:"Pharmacar", checked: false, code: "Pharmacar"},
    {name:"Pharmacia - Upjohn Vet.", checked: false, code: "Pharmacia - Upjohn Vet."},
    {name:"Pharmacobel", checked: false, code: "Pharmacobel"},
    {name:"Pharmaconsult", checked: false, code: "Pharmaconsult"},
    {name:"Pharmacosmedics", checked: false, code: "Pharmacosmedics"},
    {name:"Pharmacoz", checked: false, code: "Pharmacoz"},
    {name:"Pharmanutrics", checked: false, code: "Pharmanutrics"},
    {name:"Pharmaplant", checked: false, code: "Pharmaplant"},
    {name:"Pharmaswiss", checked: false, code: "Pharmaswiss"},
    {name:"Pharmatec", checked: false, code: "Pharmatec"},
    {name:"Philips Dap Oral Healthcare", checked: false, code: "Philips Dap Oral Healthcare"},
    {name:"Phytal-Crea", checked: false, code: "Phytal-Crea"},
    {name:"Phyto", checked: false, code: "Phyto"},
    {name:"Phytoresc", checked: false, code: "Phytoresc"},
    {name:"Phytovet", checked: false, code: "Phytovet"},
    {name:"Pi Pharma", checked: false, code: "Pi Pharma"},
    {name:"Pierre Fabre Benelux", checked: false, code: "Pierre Fabre Benelux"},
    {name:"Pierre Fabre Dermatologie", checked: false, code: "Pierre Fabre Dermatologie"},
    {name:"Pierre Fabre Medicament Benelux", checked: false, code: "Pierre Fabre Medicament Benelux"},
    {name:"Pierre Fabre Sante Benelux", checked: false, code: "Pierre Fabre Sante Benelux"},
    {name:"Pietercil Delby'S", checked: false, code: "Pietercil Delby'S"},
    {name:"Pileje Benelux", checked: false, code: "Pileje Benelux"},
    {name:"Piramal Critical Care", checked: false, code: "Piramal Critical Care"},
    {name:"Play-Off", checked: false, code: "Play-Off"},
    {name:"Plus Pro Pharma", checked: false, code: "Plus Pro Pharma"},
    {name:"Polive Laboratoires", checked: false, code: "Polive Laboratoires"},
    {name:"Postula", checked: false, code: "Postula"},
    {name:"Pranarom International", checked: false, code: "Pranarom International"},
    {name:"Primrose Farma Belgica", checked: false, code: "Primrose Farma Belgica"},
    {name:"Pro-Vera", checked: false, code: "Pro-Vera"},
    {name:"Probiotic Group Luxembourg", checked: false, code: "Probiotic Group Luxembourg"},
    {name:"Procter & Gamble Dce", checked: false, code: "Procter & Gamble Dce"},
    {name:"Prodivet Pharmaceuticals", checked: false, code: "Prodivet Pharmaceuticals"},
    {name:"Promed Retail", checked: false, code: "Promed Retail"},
    {name:"Protec Som Laboratoire", checked: false, code: "Protec Som Laboratoire"},
    {name:"Protech Europe", checked: false, code: "Protech Europe"},
    {name:"Proteiform", checked: false, code: "Proteiform"},
    {name:"Proxident International", checked: false, code: "Proxident International"},
    {name:"Purasana", checked: false, code: "Purasana"},
    {name:"Puressentiel Benelux", checked: false, code: "Puressentiel Benelux"},
    {name:"Pvb - Ponroy Vitarmonyl Benelux", checked: false, code: "Pvb - Ponroy Vitarmonyl Benelux"},
    {name:"Pyke", checked: false, code: "Pyke"},
    {name:"Pz Business", checked: false, code: "Pz Business"},
    {name:"Q-Viva", checked: false, code: "Q-Viva"},
    {name:"Qnt", checked: false, code: "Qnt"},
    {name:"Qualiphar", checked: false, code: "Qualiphar"},
    {name:"Quies", checked: false, code: "Quies"},
    {name:"Ratiopharm Belgium", checked: false, code: "Ratiopharm Belgium"},
    {name:"Ravan Sa", checked: false, code: "Ravan Sa"},
    {name:"Realco", checked: false, code: "Realco"},
    {name:"Rebotec Medical", checked: false, code: "Rebotec Medical"},
    {name:"Recherche Et Sante", checked: false, code: "Recherche Et Sante"},
    {name:"Reckitt Benckiser Footwear", checked: false, code: "Reckitt Benckiser Footwear"},
    {name:"Reckitt Benckiser Healthcare", checked: false, code: "Reckitt Benckiser Healthcare"},
    {name:"Recordati Ireland Ltd", checked: false, code: "Recordati Ireland Ltd"},
    {name:"Recordati Rare Deseases", checked: false, code: "Recordati Rare Deseases"},
    {name:"Recordati S.P.A.", checked: false, code: "Recordati S.P.A."},
    {name:"Remed Pharma", checked: false, code: "Remed Pharma"},
    {name:"Remitan Gmbh", checked: false, code: "Remitan Gmbh"},
    {name:"Reskin Medical", checked: false, code: "Reskin Medical"},
    {name:"Respect Nutrition", checked: false, code: "Respect Nutrition"},
    {name:"Revogan", checked: false, code: "Revogan"},
    {name:"Rhino Horn Benelux", checked: false, code: "Rhino Horn Benelux"},
    {name:"Rhone Poulenc Rorer Div. Sopar", checked: false, code: "Rhone Poulenc Rorer Div. Sopar"},
    {name:"Rivex", checked: false, code: "Rivex"},
    {name:"Rmed", checked: false, code: "Rmed"},
    {name:"Roc", checked: false, code: "Roc"},
    {name:"Roche", checked: false, code: "Roche"},
    {name:"Roche Diagnostics", checked: false, code: "Roche Diagnostics"},
    {name:"Roedolf Institute", checked: false, code: "Roedolf Institute"},
    {name:"Romed", checked: false, code: "Romed"},
    {name:"Rota Cycling", checked: false, code: "Rota Cycling"},
    {name:"Royal Canin", checked: false, code: "Royal Canin"},
    {name:"San'Up", checked: false, code: "San'Up"},
    {name:"Sandoz", checked: false, code: "Sandoz"},
    {name:"Sanicalor Medical", checked: false, code: "Sanicalor Medical"},
    {name:"Sanifort Pharma", checked: false, code: "Sanifort Pharma"},
    {name:"Sanodis", checked: false, code: "Sanodis"},
    {name:"Sanofi Belgium", checked: false, code: "Sanofi Belgium"},
    {name:"Sanofi Chc", checked: false, code: "Sanofi Chc"},
    {name:"Santen Oy", checked: false, code: "Santen Oy"},
    {name:"Santinov", checked: false, code: "Santinov"},
    {name:"Santurel", checked: false, code: "Santurel"},
    {name:"Sasmar Pharmaceuticals", checked: false, code: "Sasmar Pharmaceuticals"},
    {name:"Sbm Life Science", checked: false, code: "Sbm Life Science"},
    {name:"Schreurs", checked: false, code: "Schreurs"},
    {name:"Schwabe Pharma Belgium", checked: false, code: "Schwabe Pharma Belgium"},
    {name:"Schwarzkopf-Henkel Cosmetics", checked: false, code: "Schwarzkopf-Henkel Cosmetics"},
    {name:"Scs Boehringer Ingelheim Comm.V", checked: false, code: "Scs Boehringer Ingelheim Comm.V"},
    {name:"Seaderm International", checked: false, code: "Seaderm International"},
    {name:"Seconde Nature", checked: false, code: "Seconde Nature"},
    {name:"Serb", checked: false, code: "Serb"},
    {name:"Serb Laboratoires", checked: false, code: "Serb Laboratoires"},
    {name:"Servier Benelux", checked: false, code: "Servier Benelux"},
    {name:"Sharez", checked: false, code: "Sharez"},
    {name:"Shire Belgium", checked: false, code: "Shire Belgium"},
    {name:"Shire Pharmaceuticals Ireland Li", checked: false, code: "Shire Pharmaceuticals Ireland Li"},
    {name:"Sideri Laboratory", checked: false, code: "Sideri Laboratory"},
    {name:"Siemens Medical Solutions Diagn.", checked: false, code: "Siemens Medical Solutions Diagn."},
    {name:"Simovision", checked: false, code: "Simovision"},
    {name:"Simple Pharma Company Ltd", checked: false, code: "Simple Pharma Company Ltd"},
    {name:"Sissel Benelux", checked: false, code: "Sissel Benelux"},
    {name:"Sjankara", checked: false, code: "Sjankara"},
    {name:"Smb Laboratoire", checked: false, code: "Smb Laboratoire"},
    {name:"Smith Nephew", checked: false, code: "Smith Nephew"},
    {name:"Smiths Medical Belgium", checked: false, code: "Smiths Medical Belgium"},
    {name:"Snad", checked: false, code: "Snad"},
    {name:"Soba Sport", checked: false, code: "Soba Sport"},
    {name:"Sobelhysan", checked: false, code: "Sobelhysan"},
    {name:"Soleil Vie", checked: false, code: "Soleil Vie"},
    {name:"Solidages", checked: false, code: "Solidages"},
    {name:"Solidpharma", checked: false, code: "Solidpharma"},
    {name:"Sonar Brands Bv", checked: false, code: "Sonar Brands Bv"},
    {name:"Sonora", checked: false, code: "Sonora"},
    {name:"Sophysa", checked: false, code: "Sophysa"},
    {name:"Soria Bel", checked: false, code: "Soria Bel"},
    {name:"Sotrexco International", checked: false, code: "Sotrexco International"},
    {name:"Souranis Europe", checked: false, code: "Souranis Europe"},
    {name:"Soyin", checked: false, code: "Soyin"},
    {name:"Sportimaxx", checked: false, code: "Sportimaxx"},
    {name:"Springfield Nutraceuticals", checked: false, code: "Springfield Nutraceuticals"},
    {name:"Springmedical Benelux", checked: false, code: "Springmedical Benelux"},
    {name:"Ssl Healthcare Belgium", checked: false, code: "Ssl Healthcare Belgium"},
    {name:"Stallergenes Belgium", checked: false, code: "Stallergenes Belgium"},
    {name:"Stallergenes France", checked: false, code: "Stallergenes France"},
    {name:"Stasisport Pharma", checked: false, code: "Stasisport Pharma"},
    {name:"Stelma International", checked: false, code: "Stelma International"},
    {name:"Sterima", checked: false, code: "Sterima"},
    {name:"Sterop", checked: false, code: "Sterop"},
    {name:"Stevigny", checked: false, code: "Stevigny"},
    {name:"Stragen Pharma", checked: false, code: "Stragen Pharma"},
    {name:"Stylepharma", checked: false, code: "Stylepharma"},
    {name:"Substipharm", checked: false, code: "Substipharm"},
    {name:"Sunstar Benelux", checked: false, code: "Sunstar Benelux"},
    {name:"Superdiet Laboratoires", checked: false, code: "Superdiet Laboratoires"},
    {name:"Superphar", checked: false, code: "Superphar"},
    {name:"Swedish Orphan Biovitrum", checked: false, code: "Swedish Orphan Biovitrum"},
    {name:"Swedish Orphan Biovitrum Interna", checked: false, code: "Swedish Orphan Biovitrum Interna"},
    {name:"Sxwell Belgium", checked: false, code: "Sxwell Belgium"},
    {name:"Synapharm", checked: false, code: "Synapharm"},
    {name:"Synergia", checked: false, code: "Synergia"},
    {name:"Synformulas Gmbh", checked: false, code: "Synformulas Gmbh"},
    {name:"Takeda Belgium", checked: false, code: "Takeda Belgium"},
    {name:"Tanghe Pharma", checked: false, code: "Tanghe Pharma"},
    {name:"Taradon Laboratory", checked: false, code: "Taradon Laboratory"},
    {name:"Teleflex Medical", checked: false, code: "Teleflex Medical"},
    {name:"Teofarma", checked: false, code: "Teofarma"},
    {name:"Teonia", checked: false, code: "Teonia"},
    {name:"Tepe Benelux", checked: false, code: "Tepe Benelux"},
    {name:"Terumo Europe", checked: false, code: "Terumo Europe"},
    {name:"Teva B.V.", checked: false, code: "Teva B.V."},
    {name:"Teva Pharma Belgium", checked: false, code: "Teva Pharma Belgium"},
    {name:"Thd S.P.A.", checked: false, code: "Thd S.P.A."},
    {name:"Thea Pharma", checked: false, code: "Thea Pharma"},
    {name:"Therabel Pharma", checked: false, code: "Therabel Pharma"},
    {name:"Theramex Belgium", checked: false, code: "Theramex Belgium"},
    {name:"Theramex Ireland Limited", checked: false, code: "Theramex Ireland Limited"},
    {name:"Therascience Belgium", checked: false, code: "Therascience Belgium"},
    {name:"Thiry", checked: false, code: "Thiry"},
    {name:"Thuasne Benelux", checked: false, code: "Thuasne Benelux"},
    {name:"Tillotts Pharma", checked: false, code: "Tillotts Pharma"},
    {name:"Tilman", checked: false, code: "Tilman"},
    {name:"Titus Health Care", checked: false, code: "Titus Health Care"},
    {name:"Toby'S", checked: false, code: "Toby'S"},
    {name:"Toco-Tholin", checked: false, code: "Toco-Tholin"},
    {name:"Tonicaril", checked: false, code: "Tonicaril"},
    {name:"Trb Chemedica Ag", checked: false, code: "Trb Chemedica Ag"},
    {name:"Trenker", checked: false, code: "Trenker"},
    {name:"Trimb Healthcare", checked: false, code: "Trimb Healthcare"},
    {name:"Trisport Pharma", checked: false, code: "Trisport Pharma"},
    {name:"Tristel Nv", checked: false, code: "Tristel Nv"},
    {name:"Tropenzorg Bv", checked: false, code: "Tropenzorg Bv"},
    {name:"Trusetal Verbandstoffwerk", checked: false, code: "Trusetal Verbandstoffwerk"},
    {name:"Truvion Healthcare", checked: false, code: "Truvion Healthcare"},
    {name:"Ts Health Products", checked: false, code: "Ts Health Products"},
    {name:"Ucb Pharma", checked: false, code: "Ucb Pharma"},
    {name:"Ultimed Products Belgium", checked: false, code: "Ultimed Products Belgium"},
    {name:"Unicell", checked: false, code: "Unicell"},
    {name:"Unilever Belgium", checked: false, code: "Unilever Belgium"},
    {name:"Unilever Bestfoods Belgium", checked: false, code: "Unilever Bestfoods Belgium"},
    {name:"Upjohn Srl/Bv", checked: false, code: "Upjohn Srl/Bv"},
    {name:"Upsa Sas", checked: false, code: "Upsa Sas"},
    {name:"Urgo Healthcare", checked: false, code: "Urgo Healthcare"},
    {name:"Uriage", checked: false, code: "Uriage"},
    {name:"Ursapharm Benelux Bv", checked: false, code: "Ursapharm Benelux Bv"},
    {name:"V.F. Medicals", checked: false, code: "V.F. Medicals"},
    {name:"V.M.D.", checked: false, code: "V.M.D."},
    {name:"Vaccifar", checked: false, code: "Vaccifar"},
    {name:"Valneva Austria Gmbh", checked: false, code: "Valneva Austria Gmbh"},
    {name:"Valterra", checked: false, code: "Valterra"},
    {name:"Van Dommelen Beheer", checked: false, code: "Van Dommelen Beheer"},
    {name:"Van Heek Medical", checked: false, code: "Van Heek Medical"},
    {name:"Van Nielandt", checked: false, code: "Van Nielandt"},
    {name:"Vandeputte Medical", checked: false, code: "Vandeputte Medical"},
    {name:"Varta", checked: false, code: "Varta"},
    {name:"Vedax International Bv", checked: false, code: "Vedax International Bv"},
    {name:"Vemedia Consumer Health Belgium", checked: false, code: "Vemedia Consumer Health Belgium"},
    {name:"Vemedia Manufacturing", checked: false, code: "Vemedia Manufacturing"},
    {name:"Verdepharma", checked: false, code: "Verdepharma"},
    {name:"Vereecke", checked: false, code: "Vereecke"},
    {name:"Vermeiren", checked: false, code: "Vermeiren"},
    {name:"Vernopharma", checked: false, code: "Vernopharma"},
    {name:"Versele-Laga", checked: false, code: "Versele-Laga"},
    {name:"Vesale Pharma", checked: false, code: "Vesale Pharma"},
    {name:"Vet I Pharma", checked: false, code: "Vet I Pharma"},
    {name:"Vetoquinol", checked: false, code: "Vetoquinol"},
    {name:"Vetplus", checked: false, code: "Vetplus"},
    {name:"Vexo", checked: false, code: "Vexo"},
    {name:"Veyx Pharma Gmbh", checked: false, code: "Veyx Pharma Gmbh"},
    {name:"Vichy - L'Oreal Belgilux", checked: false, code: "Vichy - L'Oreal Belgilux"},
    {name:"Vici Products", checked: false, code: "Vici Products"},
    {name:"Vifor Fresenius Medical Care", checked: false, code: "Vifor Fresenius Medical Care"},
    {name:"Vifor Pharma Belgie", checked: false, code: "Vifor Pharma Belgie"},
    {name:"Viiv Healthcare", checked: false, code: "Viiv Healthcare"},
    {name:"Viiv Healthcare Uk Limited", checked: false, code: "Viiv Healthcare Uk Limited"},
    {name:"Vijghen", checked: false, code: "Vijghen"},
    {name:"Virbac", checked: false, code: "Virbac"},
    {name:"Vista-Life Pharma Belgium", checked: false, code: "Vista-Life Pharma Belgium"},
    {name:"Vita Mina", checked: false, code: "Vita Mina"},
    {name:"Vitaflo International Ltd", checked: false, code: "Vitaflo International Ltd"},
    {name:"Vitamed", checked: false, code: "Vitamed"},
    {name:"Vitanutrics", checked: false, code: "Vitanutrics"},
    {name:"Vitaswitch Eood", checked: false, code: "Vitaswitch Eood"},
    {name:"Vitry", checked: false, code: "Vitry"},
    {name:"Vivadis", checked: false, code: "Vivadis"},
    {name:"Vivet Cosmetics", checked: false, code: "Vivet Cosmetics"},
    {name:"Vivre Au Sud", checked: false, code: "Vivre Au Sud"},
    {name:"Vmax", checked: false, code: "Vmax"},
    {name:"Vrancken", checked: false, code: "Vrancken"},
    {name:"Vroome Pharma Managment", checked: false, code: "Vroome Pharma Managment"},
    {name:"Vuylsteke & Co", checked: false, code: "Vuylsteke & Co"},
    {name:"Vwr International", checked: false, code: "Vwr International"},
    {name:"Vygon", checked: false, code: "Vygon"},
    {name:"Wapeka Tools", checked: false, code: "Wapeka Tools"},
    {name:"Weleda", checked: false, code: "Weleda"},
    {name:"Wellspect Healthcare", checked: false, code: "Wellspect Healthcare"},
    {name:"Whc Labs", checked: false, code: "Whc Labs"},
    {name:"Widmer Louis", checked: false, code: "Widmer Louis"},
    {name:"Wido Solutions", checked: false, code: "Wido Solutions"},
    {name:"Wilcothera", checked: false, code: "Wilcothera"},
    {name:"Will Pharma", checked: false, code: "Will Pharma"},
    {name:"Willems", checked: false, code: "Willems"},
    {name:"Wm Supplies", checked: false, code: "Wm Supplies"},
    {name:"Wolf - Safco", checked: false, code: "Wolf - Safco"},
    {name:"Ysonut", checked: false, code: "Ysonut"},
    {name:"Yun", checked: false, code: "Yun"},
    {name:"Yvb", checked: false, code: "Yvb"},
    {name:"Zambon", checked: false, code: "Zambon"},
    {name:"Zeitschild", checked: false, code: "Zeitschild"},
    {name:"Zeno Phar", checked: false, code: "Zeno Phar"},
    {name:"Zhen", checked: false, code: "Zhen"},
    {name:"Ziphius Therapeutics Nv", checked: false, code: "Ziphius Therapeutics Nv"},
    {name:"Zoetis Belgium", checked: false, code: "Zoetis Belgium"}
   ] 

  constructor(public _aposervice: ApoService, public formBuilder: FormBuilder, public route: ActivatedRoute, public http: HttpClient, public toastr: ToastrService) { 

    _aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {            
      this.userid = data.id;

      if (data.uid == "YIzrj1r6g6NyBIMmfMHHEJ4HgcG2" || data.uid == "HKAVXBnPAFX9jsFgB14CPNUyEzF3" || data.uid == "uaJvFJe8CaUpAWesIVfKGJ0FqlL2" || data.uid == "ogh1LYBksHUGmQxORNFwjvNiT012"){this.check_visible = true;}
      else {this.check_visible = false;}
    });

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => {            
      this.api = data;
    });

    _aposervice.current_pricemax.pipe(takeUntil(this.destroy$)).subscribe(data => {    
      if (data != 0){     
        this.max_price = data;
        this.max_price_selected = data;
        this.setNewCeil(data);
      }       
    });

    this._aposervice.current_catselected.pipe(takeUntil(this.destroy$)).subscribe(data => {   
      // if (data != ""){
        setTimeout (() => {
          this.click_cat_ext(data);
       }, 500); 
      // }                  
    });  

    _aposervice.current_products_selected.pipe(takeUntil(this.destroy$)).subscribe(data => {
      
     this.info_select = [];
     this.info_select_view = [];
     this.info = "";
     this.rslt_check = [];
     this.selection_one = false;
     this.delete_disable = true;     
     
      for (let x of data){       
        
        this.rslt_check.push({
          cnk: x.cnk, cat: x.category, subcat: x.subcategory, info: x.info
        })

        if (x.subcategory != null) {
          if (x.subcategory.indexOf(',') != -1){
            var lst_subcat = x.subcategory.split(',');
            for (let y of lst_subcat) {
              this.info_select.push({
                cnk: x.cnk, categorie_select: this.get_info_subcat(y).cat, subcategorie_select: this.get_info_subcat(y).subcat_name, number: 0, check: false
              })
            }
          }
          else {
            this.info_select.push({
              cnk: x.cnk, categorie_select: this.get_info_subcat(x.subcategory).cat, subcategorie_select: this.get_info_subcat(x.subcategory).subcat_name, number: 0, check: false
            })
          }
        }
        else if (x.category != null){
          if (x.category.indexOf(',') != -1){
            var lst_cat = x.category.split(',');
            for (let y of lst_cat) {
              this.info_select.push({
                cnk: x.cnk, categorie_select: y, subcategorie_select: '', number: 0, check: false
              })
            }
          }
          else {
            this.info_select.push({
              cnk: x.cnk, categorie_select: x.category, subcategorie_select: '', number: 0, check: false
            })
          }         
        }
        else {
          this.info_select.push({
            cnk: x.cnk, categorie_select: '', subcategorie_select: '', number: 0, check: false
          })
        }               
        
        if (x.principle2 == null) {x.principle2 = "";}
        if (x.principle == null) {x.principle = "";}
        if (x._use == null){x._use = "";}
        if (x.composition == null){x.composition = "";}  

        if (data.length == 1){
          if (x.info == null || x.info == ''){
            this.info = x.principle2 + " " + x.principle + " " + x._use + " " + x.composition
          }
          else {
            this.info = x.info;
          }
        }
      }

      for (let y of this.info_select){
        if (y.categorie_select != ''){
          if (this.info_select_view.findIndex(z => z.cnk === y.categorie_select + y.subcategorie_select) == -1){
            this.info_select_view.push({
              cnk: y.categorie_select + y.subcategorie_select, categorie_select: y.categorie_select, subcategorie_select: y.subcategorie_select, number: 1, check: false
            })
          }
          else {
            this.info_select_view[this.info_select_view.findIndex(z => z.cnk === y.categorie_select + y.subcategorie_select)].number++;
          }
        }       
      }      

      this.select_aantal = this.rslt_check.length;   
      if (this.select_aantal == 1){ this.selection_one = true;}
    });

  }

  get_info_subcat(subcat_nr: number){
    for (let x of this.cat_subcat_current){
      if (x.subcat_nr == subcat_nr){
        return x;
      }
    }
  }

  get_info_subcat2(cat_name: string, subcat_name: string){
    for (let x of this.cat_subcat_current){
      if ((x.subcat_name == subcat_name)&&(x.cat == cat_name)){
        return x;
      }
    }
  }

  clear_selection(){
    this.clearselection.emit(true);
  }

  save_checkdata(){
   this.rslt_check[0].info = this.info;    
   this.http.post<any>(this.api + "check_cnks",this.rslt_check).pipe(takeUntil(this.destroy$)).subscribe(data => {
    this.toastr.success('aanpassingen gestuurd', 'Gelukt', {timeOut: 3000, positionClass: 'toast-bottom-center'})
    this.clear_selection();
    this.reloadproducts.emit(true); },
  error => {this.toastr.error('er is iets misgegaan', error, {timeOut: 3000, positionClass: 'toast-bottom-center'})})
  }

  ngOnInit(): void {

    for (let x of this.list_subcategory){
      for (let y of x.list_subcat){
        this.cat_subcat_current.push(
          {cat: x.category, subcat_name: y.subcat, subcat_nr: +y.value, check: false}
        )
      }
    }
    this.cat_subcat_current.sort(function (a, b) {
      return a.cat.localeCompare(b.cat) || a.subcat_name.localeCompare(b.subcat_name);
  });

  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();    
  }

  check_empty(event){
    this.mail_valid = this.validateEmail(this._mailing);
    if (event.key != "Enter"){this.mailing_ok = "";}
    else {
      if (!this.mail_valid) {this.mailing_ok = "novalid";}
      else {
        let mailing: mailing = {
          "mail": this._mailing,
          "discount": this.generator(5)
        }
        this.http.post<string>(this.api + "mailings",mailing).pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.mailing_ok = data;
        });  
      }       
    }    
  }

  check_cat(item, event){
    for (let x of this.cat_subcat_current){
      if (x.cat == item.cat && x.subcat_name == item.subcat_name){
        x.check = event.checked;
      }
    }
  }

  check_cat2(item, event){
    for (let x of this.info_select_view){
      if (x.categorie_select == item.categorie_select && x.subcategorie_select == item.subcategorie_select){
        x.check = event.checked;
      }
    }

    if (this.info_select_view.filter(x => x.check == true).length > 0){
      this.delete_disable = false;
    }
    else {
      this.delete_disable = true;
    }
  }

  current_brands = this.list_brand;  

  
  construct_filter(){   
    this.current_subcat = [];
    this.current_brands = [];
    for (let x of this.list_cat.filter(x => x.checked == true)){   
      //subcategorieën toevoegen    
      if (this.list_subcategory.findIndex(y => y.category == x.code) != -1){
        let _listsubcat = this.list_subcategory[this.list_subcategory.findIndex(y => y.category == x.code)].list_subcat;
        for (let y of _listsubcat){y.checked = false;}
        this.current_subcat =  this.current_subcat.concat(_listsubcat); }
    }  
    this.current_subcat.sort((a,b) => a.subcat.localeCompare(b.subcat))
    this.current_brands = this.list_brand;
    for (let y of this.current_brands) {y.checked = false;}
    for (let x of this.list_specials){x.checked = false;} 
    this.max_price_selected = this.max_price;
    this.min_price_selected = this.min_price;
    this.searchitem = "";
  }

  setNewCeil(newCeil: number): void {
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.ceil = newCeil;
    this.options = newOptions;
  }

  add_searchitem(){
    this.is_filter_empty("");
  }

  remove_cat(){
    this.all_selected = true;
    for (let x of this.list_cat){x.checked = false;}
    this.cat_length = 0;
    this.construct_filter();
    this._modus = "x";
    this.is_filter_empty("");
  }

  click_cat_ext(cat: string){  
    if (cat == ""){this.all_selected = true;}
    else {this.all_selected = false;}           
    if (cat.startsWith("*")){
      this.is_filter_empty("");
    } 
    else if (cat.startsWith("_")){
      this.search = cat.substring(1); 
      this.all_selected = true;
      for (let x of this.list_cat){x.checked = false;}
      this.cat_length = 0;
      this._modus = "x";
      this.construct_filter();
      this.is_filter_empty("");
    } 
    else if (cat == "favorieten"){
      this.search = "";
      this.all_selected = false;
      for (let x of this.list_cat){x.checked = false;}
      this.cat_length = 0;
      this._modus = "f";
      this.construct_filter();
      this.is_filter_empty("");
    }
    else {
      this.search = "";
      this._modus = "x";
      let _index = this.list_cat.findIndex(x => x.code == cat);   
      if (_index != -1){this.list_cat[_index].checked = true;}              
      this.cat_length = this.list_cat.filter(x => x.checked == true).length;
      this.construct_filter();
      this.is_filter_empty("");      
    }    
  }

  click_cat(list_cat, cat, event){
    this.search = "";
    this.all_selected = false;
    list_cat[cat].checked = event.checked;
    this.cat_length = list_cat.filter(x => x.checked == true).length;
    if (this.cat_length == 0){this.all_selected = true;}
    this.construct_filter();
    this._modus = "x";
    this.is_filter_empty("");
  }

  click_subcat(current_subcat, index, event){   
    current_subcat[index].checked = event.checked;
    this.is_filter_empty("");   
  }

  click_special(list_specials, index, event){
    list_specials[index].checked = event.checked;
    this.is_filter_empty("");
  }

  click_brand(current_brands, index, event){
    current_brands[index].checked = event.checked;
    this.is_filter_empty("");
  }  

  delete_cat_subcat(){
    var selected_items = this.info_select_view.filter(x => x.check == true);

    for (let x of selected_items){
      for (let y of this.rslt_check){
        if (y.subcat != null){          
          if (y.subcat.indexOf(this.get_info_subcat2(x.categorie_select,x.subcategorie_select).subcat_nr.toString()) != -1){
            y.subcat = y.subcat.replace(this.get_info_subcat2(x.categorie_select,x.subcategorie_select).subcat_nr.toString(),"").replace(",,",",");
            if (y.subcat == ","){y.subcat = "";}
            if (y.subcat.startsWith(",")){y.subcat = y.subcat.substring(1)}
            if (y.subcat.endsWith(",")){y.subcat = y.subcat.substring(0,y.subcat.length-1)}
          }
        }       
       
        if (y.cat != null){
          if (y.cat.indexOf(x.categorie_select.toUpperCase()) != -1){
            var delete_cat = true;

            if (y.subcat != ""){
              var subcats = y.subcat.split(',');
              for (let z of subcats){   
                if (z != ""){
                  if (this.get_info_subcat(+z).cat.toLowerCase() == x.categorie_select.toLowerCase()){
                    delete_cat = false;
                  }
                }   
              }
            }
            
            if (delete_cat){
              y.cat = y.cat.replace(x.categorie_select.toUpperCase(),"").replace("__","_");
              if (y.cat == "_"){y.cat = "";}
              if (y.cat.startsWith("_")){y.cat = y.cat.substring(1)}
              if (y.cat.endsWith("_")){y.cat = y.cat.substring(0,y.cat.length-1)}
            }           
          }           
        }
      }

      this.info_select_view.splice(this.info_select_view.findIndex(z => z.cnk == x.cnk),1);      
    }
  }

  add_cat_subcat(){
    var selected_items = this.cat_subcat_current.filter(x => x.check == true);    

    if (this.cat_subcat_display){
      for (let x of selected_items){
        for (let y of this.rslt_check){
          if (y.cat != null){
            if (y.cat.indexOf(x.cat.toUpperCase()) == -1){
              y.cat = y.cat + "_" + x.cat.toUpperCase();
            }           
          }
          else {
            y.cat = x.cat.toUpperCase();
          }
         
          if (y.subcat != null){
            if (y.subcat.indexOf(x.subcat_nr.toString()) == -1){
              y.subcat = y.subcat + "," + x.subcat_nr.toString();
            }
          }
          else {
            y.subcat = x.subcat_nr.toString();
          }

          if (y.cat == "_"){y.cat = "";}
          if (y.cat.startsWith("_")){y.cat = y.cat.substring(1)}
          if (y.subcat == ","){y.subcat = "";}
          if (y.subcat.startsWith(",")){y.subcat = y.subcat.substring(1)}
        }

        if (this.info_select_view.findIndex(i => (i.categorie_select == x.cat && i.subcategorie_select == x.subcat_name)) == -1){
          this.info_select_view.push({
            categorie_select : x.cat, subcategorie_select : x.subcat_name, cnk: '', number: this.select_aantal, check: false
          })
        }
        else {
          this.info_select_view[this.info_select_view.findIndex(i => (i.categorie_select == x.cat && i.subcategorie_select == x.subcat_name))].number++;
        }

      }

      for (let z of this.cat_subcat_current){
        z.check = false;
      }

    }
    
    if (this.info_select.length != 0){
      this.cat_subcat_display = !this.cat_subcat_display;
    }
    else {this.cat_subcat_display = false;}

  }

  onSliderChange_max(e){      
    this.max_price_selected = e.highValue;   
    this.min_price_selected = e.value; 
    this.is_filter_empty("");
  }

  setMyStyles_tag(searchitem){
    let styles = {
      'background': this.current_tag == searchitem ? 'rgba(81,189,77,255)': 'transparent',
      'color': this.current_tag == searchitem ? 'white': 'black'
    };
    return styles;
  }  

  setMyStyles_nosubcat(){
    let styles = {
      'display': this.current_subcat.length == 0 ? '': 'none'
    };
    return styles;
  }

  setMyStyles_nobrand(){
    let styles = {
      'display': this.current_brands.length == 0 ? '': 'none'
    };
    return styles;
  } 

  is_filter_empty(_sort: string){     

    if (  this.current_subcat.filter(x => x.checked == true).length == 0 &&
          this.list_specials.filter(x => x.checked == true).length == 0 &&
          this.current_brands.filter(x => x.checked == true).length == 0 &&
          this.searchitem == "" &&
          this.min_price_selected == this.min_price &&
          this.max_price_selected == this.max_price ) 
    {
      this.filteractive.emit(false);
      if (this._modus == "f"){
        this.loadproducts.emit(
          { modus: this._modus, categories: [{name: this.userid.toString(), checked: true}], subcategories: [], attention: false, sale: false, no_presc: false, 
            brands: [], price_min: this.min_price_selected, price_max: this.max_price_selected, searchterm: "", sort: _sort, gen_search: ''}
        )
      }
      else {
        this.loadproducts.emit(
          { modus: this._modus, categories: this.list_cat.filter(x => x.checked == true), subcategories: [], attention: false, sale: false, no_presc: false, 
            brands: [], price_min: this.min_price_selected, price_max: this.max_price_selected, searchterm: this.search, sort: _sort, gen_search: this.search}
        )
      }
    }
    else {
      this.filteractive.emit(true);
      if (this._modus == "f"){
        this.loadproducts.emit(
          {
            modus: "f",
            categories: [{name: this.userid.toString(), checked: true}],
            subcategories: [],
            brands: [],
            price_max: this.max_price_selected,
            price_min: this.min_price_selected,
            searchterm: this.searchitem,
            attention: this.list_specials[this.list_specials.findIndex(x => x.name == "in de kijker")].checked,
            sale: this.list_specials[this.list_specials.findIndex(x => x.name == "koopje")].checked,
            no_presc: this.list_specials[this.list_specials.findIndex(x => x.name == "zonder voorschrift")].checked,
            sort: _sort, 
            gen_search: ""
          }
        )
      }
      else if (this.search != "") {
        this.loadproducts.emit(
          {
            modus: this._modus,
            categories: this.list_cat.filter(x => x.checked == true),
            subcategories: [],
            brands: this.current_brands.filter(x => x.checked == true),
            price_max: this.max_price_selected,
            price_min: this.min_price_selected,
            searchterm: this.search,
            attention: this.list_specials[this.list_specials.findIndex(x => x.name == "in de kijker")].checked,
            sale: this.list_specials[this.list_specials.findIndex(x => x.name == "koopje")].checked,
            no_presc: this.list_specials[this.list_specials.findIndex(x => x.name == "zonder voorschrift")].checked,
            sort: _sort, 
            gen_search: this.search
          }
        )
      }
      else {
        this.loadproducts.emit(
          {
            modus: this._modus,
            categories: this.list_cat.filter(x => x.checked == true),
            subcategories: this.current_subcat.filter(x => x.checked == true),
            brands: this.current_brands.filter(x => x.checked == true),
            price_max: this.max_price_selected,
            price_min: this.min_price_selected,
            searchterm: this.searchitem,
            attention: this.list_specials[this.list_specials.findIndex(x => x.name == "in de kijker")].checked,
            sale: this.list_specials[this.list_specials.findIndex(x => x.name == "koopje")].checked,
            no_presc: this.list_specials[this.list_specials.findIndex(x => x.name == "zonder voorschrift")].checked,
            sort: _sort, 
            gen_search: ''
          }
        )
      }
    }
  }

generator(len) {
  var allCapsAlpha = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"]; 
  var allLowerAlpha = [..."abcdefghijklmnopqrstuvwxyz"]; 
  var allNumbers = [..."0123456789"];
  var base = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha];

  return [...Array(len)]
  .map(i => base[Math.random()*base.length|0])
  .join('');
}

validateEmail(email) {
  const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regularExpression.test(String(email).toLowerCase());
 }


}
