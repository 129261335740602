<div id="top"></div>
<app-header></app-header>       

<!-- Product Content Start -->
<div class="section andro_post-single">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="entry-content">
                    <div class="andro_post-single-thumb">
                        <img src="{{_info.photo}}" alt="post">
                    </div>
                    <h2>{{_info.title}}</h2>
                    <div [ngStyle]="{'display': (_info.id != 2 && _info.id != 1 && _info.id != 3) ? '' : 'none'}" [innerHTML]="_info.text"></div>      
                    <div [ngStyle]="{'display': _info.id == 3 ? '' : 'none'}" style="text-align: justify;">
                        <p>Wratten is een huidafwijking veroorzaakt door het <span style="color: #50BD4D;">humaan papillomavirus (HPV)</span>.</p>
                        <p><u>VERSCHILLENDE TYPES</u></p>
                        <ul style="margin-left: 1.25rem; list-style: circle;">
                            <li><span style="color: #50BD4D;">Banale</span> wratten (ruw, vaak teruggevonden op de handen en knie&euml;n)</li>
                            <li><span style="color: #50BD4D;">voet</span>wratten onderaan de voeten</li>
                            <li><span style="color: #50BD4D;">parel</span>wratten (kleine, gladde structuren)</li>
                            <li><span style="color: #50BD4D;">Genitale</span> wratten (in de genitale regio), die ook een rol spelen in de ontwikkeling van kanker</li>
                        </ul>
                        <p>De HPV-virussen die niet-genitale wratten veroorzaken zijn niet dezelfde als deze die genitale wratten veroorzaken; voor het laatste type is een vaccin beschikbaar.</p>
                        <p><u>HOE KRIJG JE WRATTEN?</u></p>
                        <p>De niet-genitale huidwratten komen het meest frequent voor bij kinderen en jong-volwassenen. Mensen met een specifieke job (zoals werken in de vlees, vis en kipindustrie), een andere huidafwijking (zoals eczeem) en/of&nbsp; een verlaagde immuniteit (door diabetes, orgaantransplantatie, ..) hebben meer risico op uitgebreide wratten die moeilijk te controleren zijn.</p>
                        <p>Een besmetting met HPV gebeurt door <span style="color: #50BD4D;">direct contact met de wrat van een andere persoon</span>. De infectie is meer waarschijnlijk als de huid beschadigd is of door waterindringing verzacht is (denk maar aan zwemmen). Hoewel, perfecte huid kan ook besmet worden. Na direct contact kan het tot wel 6 maanden of langer duren alvorens de wrat zichtbaar wordt.</p>
                        <p><u>HOE HERKEN IK EEN WRAT?</u></p>
                        <p>Een persoon kan &eacute;&eacute;n enkele of meerdere wratten hebben.</p>
                        <ul style="margin-left: 1.25rem; list-style: circle;">
                            <li>Banale wrat: een <span style="color: #50BD4D;">ruw bultje</span> op de huid, vaak rond of ovaal en kleiner dan 1cm. De wratten kunnen samensmelten of groeien tot grotere structuren.&nbsp; Ze komen het meest voor op handen en knie&euml;n.</li>
                            <li>Voetwratten: worden teruggevonden <span style="color: #50BD4D;">onderaan de voeten</span>; ze beginnen als kleine, ronde structuren die kunnen toenemen in grootte</li>
                            <li>Parelwratten: <span style="color: #50BD4D;">kleine gladde verhogingen, huidkleurig, roos of bruin van kleur</span>; vaak teruggevonden op het gezicht, de handen, bovenkant voeten, armen of benen</li>
                        </ul>
                        <p><u>HOE BEHANDEL JE EEN WRAT?</u></p>
                        <p>De behandeling van wratten hangt af van de locatie en de mate waarin de pati&euml;nt er hinder van ondervindt. Ze is niet steeds nodig; zo zal 2/3 van de wratten bij kinderen in een periode van 2j spontaan verdwijnen. Echter tijdens deze periode kan de wrat wel sterk groeien en zich verder verspreiden. Bijkomend is het veel eenvoudiger een aantal kleine wratten te behandelen in vergelijking met meerdere grote wratten. Een behandeling is dan ook aangeraden wanneer de wratten <span style="color: #50BD4D;">pijnlijk, persistent en hinderlijk</span> worden.</p>
                        <p>Er zijn verschillende manieren waarmee je een wrat kan behandelen; sommige therapie&euml;n moeten weken tot maanden aangehouden worden en succes is niet altijd verzekerd (zeker voetwratten en wratten rond de nagels zijn zeer moeilijk te behandelen). Mensen met diabetes moeten voorzichtig zijn, aangezien ze meer risico hebben op complicaties.</p>
                        <p><u>THUISBEHANDELINGEN</u></p>
                        <ul style="margin-left: 1.25rem; list-style: circle;">
                            <li>De minst pijnlijke behandeling, die ook naar voren geschoven wordt bij kleine kinderen, gebruikt als basis een <span style="color: #50BD4D;">hoge concentratie salicylzuur</span>. Zulke producten moeten wel gedurende ongeveer 12 weken 1 &agrave; 2 keer per dag aangestipt worden op de wrat vooraleer succes kan gegarandeerd worden. Vermijd contact van het product met de omliggende huid en natuurlijk ook de ogen. Een beter resultaat wordt bekomen door het vorige vliesje dat het product op de wrat achterlaat weg te halen met wat ether of alcohol, vooraleer de nieuwe laag wordt aangebracht.</li>
                            <li>Een snellere manier om hand- of voetwratten te verwijderen, is via bevriezing. In de apotheek zijn er verschillende preparaten beschikbaar die via <span style="color: #50BD4D;">extreem koude temperaturen</span> de wrat kunnen verwijderen. Deze producten mogen enkel gebruikt worden vanaf 12 jaar. Deze behandeling is meestal in 1 keer doeltreffend. Bij hardnekkige hand- of voetwratten kan het zijn dat de behandeling na 1 week 1-malig moet herhaald worden.&nbsp;</li>
                        </ul>
                        <p><u>CONTACTEER UW HUISARTS OF APOTHEKER:</u></p>
                        <ul style="margin-left: 1.25rem; list-style: circle;">
                            <li>u niet zeker bent of het een wrat is</li>
                            <li>de wrat niet weggaat met de behandeling</li>
                            <li>voor advies over de voor U geschikte behandeling</li>
                            <li>een snelgroeiende of bloedende wrat</li>
                            <li>u na de behandeling roodheid, pijn, etter of andere tekens van een huidinfectie vertoont.</li>
                        </ul>
                    </div>  
                    <div [ngStyle]="{'display': _info.id == 1 ? '' : 'none'}" style="text-align: justify;">
                        <p><span style="text-decoration: underline;">WAAROM IK?</span></p>
                        <p>ook wel bekend als atopische dermatitis, is een huidziekte die een droge, jeukende, schilferende en rode huid kan veroorzaken. Het is een frequent voorkomende aandoening op alle leeftijden. Familiale factoren zoals allergie of astma, kunnen er wel voor zorgen dat je meer aanleg hebt om eczeem te ontwikkelen. Bij eczeem wordt een <span style="color: #50BD4D;">genetische afwijking vastgesteld in de opperste laag van de huid, namelijk het epidermis</span>. Een intacte epidermis vormt de eerste bescherming tegen allerlei invloeden van buitenaf: allergenen, microben, irriterende stoffen en de epidermis zorgt ervoor dat de huid niet teveel water verliest. Een minder goed werkende epidermis kan dus tal van problemen veroorzaken. Mensen die een aanleg hebben voor eczeem, ontwikkelen de eerste symptomen reeds in hun kinderjaren, voor de leeftijd van 5 jaar.</p>
                        <p><span style="text-decoration: underline;">HOE HERKEN IK HET?</span></p>
                        <p>Wees alert op terugkerende en langdurende jeuk (erger &rsquo;s avonds), rode huid, kleine bultjes en huidschilfering, want deze kunnen de eerste tekenen zijn van atopische dermatitis. Krabben kan een verergering van de ziekte teweeg brengen doordat ze kunnen de letsels infecteren. Antibiotica therapie is dan mogelijks aangewezen. De zone waar eczeem het meest optreedt, kan veranderen met de jaren. Bij baby&rsquo;s komt rode, geschilferde huid meestal voor op de armen, benen, wangen en hoofd. De luierregio blijft meestal gevrijwaard. Bij kinderen en volwassenen komen eczeem plekken meestal voor achteraan in de nek, elleboogplooien en de knieplooien. Door veelvuldig krabben kan de huid dikker en donkerder worden en kunnen zelfs littekens ontstaan.</p>
                        <p><span style="text-decoration: underline;">WAT KAN IK DOEN?</span></p>
                        <p>Eczeem is een chronische aandoening met periodische opstoten. Hoewel eczeem niet kan genezen, zijn er wel meerdere maatregelen en geneesmiddelen die je kan nemen om deze opstoten zolang mogelijk uit te stellen. Enerzijds zijn er<span style="color: #50BD4D;"> triggers</span> die een opstoot van eczeem kan bevorderen, zoals stress, zweten, koud of droge omgeving of blootstelling aan bepaalde chemicali&euml;n (parfums, detergenten, cosmetica,&hellip;). Zulke triggers zijn persoonsgebonden, maar eenmaal ge&iuml;dentificeerd, kan je zodoende wel proberen een opstoot te vermijden. Anderzijds geldt bij eczeem het motto: &ldquo;<span style="color: #50BD4D;">hoe vettiger, hoe prettiger</span>&rdquo;! Door het gebruik van allerlei lotions en zalven, kan men voorkomen dat de huid uitdroogt. <span style="color: #ff0000;">Eucerin</span> heeft een heel gamma aan deze aandoening gewijd, namelijk het Atopicontrol gamma. &nbsp;Ook <span style="color: #ff0000;">La Roche Posay </span>heeft met het Lipikar gamma een hele resem producten die veel verlichting kunnen bieden. De zalven worden best 2 keer per dag aangebracht. Let ook op welke zeep je gebruikt! Ook hier is het best om te wassen met een olie (Eucerin was- en badolie, Lipikar wasolie) in plaats van een wasgel. Warme en lange baden (langer dan 15 minuten) worden best vermeden omdat ze de huid nog sterker kunnen uitdrogen. Merken zoals Eucerin hebben nu ook sprays op de markt om te gebruiken bij een eczeemopstoot om de jeuk te verminderen.</p>
                        <p><span style="text-decoration: underline;">ALS HET TE ERG WORDT, WAT DAN?</span></p>
                        <p>Indien de eczeem opstoten te erg worden, is het natuurlijk noodzakelijk om de <span style="color: #50BD4D;">huisarts </span>of huidarts te raadplegen. Deze kan opteren om <span style="color: #50BD4D;">zalven op basis van cortisones</span> voor te schrijven. Zulke zalven of cr&egrave;mes zijn beschikbaar in verschillende sterktes, waarbij de meest lichte cortisone preparaten zonder voorschrift verkrijgbaar zijn (cremicort, delphi), terwijl de sterkere een voorschrift vereisen. Al deze preparaten moeten 2 maal per dag aangebracht worden totdat een verbetering merkbaar is (dat duurt meestal een paar dagen). Daarna kan men weer overschakelen op de vette zalven om een nieuwe opstoot te vermijden. Sterke cortisones preparaten mogen niet langdurig gebruikt worden, zo voorkom je de neveneffecten van topische cortisone therapie, namelijk de huid die dunner wordt. Orale cortisone therapie wordt slechts zelden gebruikt bij een extreem ernstige opstoot van eczeem. In zulke situaties moet er toch eerder geopteerd worden voor een oraal anti-histaminicum om de jeuk opstoot te verminderen. Ook zulke producten zijn gewoon verkrijgbaar, zoals cetirizine.</p>
                    </div>           
                    <div [ngStyle]="{'display': _info.id == 2 ? '' : 'none'}" style="text-align: justify;">
                        <p><span style="text-decoration: underline;">WELKE SEKSUELE PROBLEMEN KUNNEN MANNEN HEBBEN?</span></p>
                        <ul style="margin-left: 1.25rem; list-style: circle;">
                        <li><span style="color: #50BD4D;">Impotentie</span>: wanneer je meestal geen erectie kunt krijgen tijdens seksuele activiteiten.</li>
                        <li><span style="color: #50BD4D;">Laag libido</span>: weinig of geen interesse in seks</li>
                        <li><span style="color: #50BD4D;">Premature ejaculatie</span>: dan treedt er reeds ejaculatie op, snel nadat de seks begint en vooraleer je klaar bent om te ejaculeren.</li>
                        <li><span style="color: #50BD4D;">Niet kunnen ejaculeren</span></li>
                        </ul>
                        <p><span style="text-decoration: underline;">WAT KAN DE OORZAAK HIERVAN ZIJN?</span>
                            <p>Indien mannen geen erectie kunnen krijgen, moet vooreerst nagegaan worden of zij geen onderliggende aandoening hebben die ervoor zorgt dat er <span style="color: #50BD4D;">niet voldoende bloed</span> naar de penis kan vloeien! Denk maar aan hart-en vaataandoeningen, ouderdom, diabetes, hoge bloeddruk, roken, alcohol en/of drugsgebruik. Maar ook <span style="color: #50BD4D;">depressie, stress, problemen</span> in de relatie of bepaald geneesmiddelengebruik kunnen zulke problemen veroorzaken.</p>
                            <p>Het is daarom zeker aangewezen om met zulke problemen <span style="color: #50BD4D;">een arts te raadplegen</span> om de nodige onderzoeken uit te voeren om belangrijke aandoeningen te ontdekken en te behandelen.</p>
                        <p><span style="text-decoration: underline;">BEHANDELING VAN ERECTIESTOORNISSEN</span></p>
                        <p>Er zijn reeds meerdere geneesmiddelen op de markt om een erectie te stimuleren en te houden. Meest bekende zijn zeker en vast de blauwe <span style="color: #50BD4D;">Viagra</span> pilletjes, maar ondertussen zijn er reeds meerdere &ndash; <span style="color: #50BD4D;">vaak goedkopere &ndash; generieken</span> op de markt (sildenafil, tadalafil, avanafil,&hellip;). Het is vaak noodzakelijk om meerdere soorten uit te proberen om het geneesmiddel te vinden dat het beste persoonlijk resultaat geeft. Let wel op voor eventuele bijwerkingen, zoals bloeddrukval en voor de combinatie met bepaalde medicatie (bv op basis van nitrieten). Daarnaast bestaan ook andere minder gebruikte therapie&euml;n, zoals een apparaat dat in de penis wordt ingeplant om een erectie te verkrijgen, of hormonentherapie als een man niet voldoende mannelijke hormonen aanmaakt. Soms helpen anti-depressiva ook om het gemoed terug op te krikken en daardoor kan ook het erectie probleem opgelost worden.</p>
                        <p><span style="text-decoration: underline;"><span style="text-decoration: underline;">KAN IK ZELF IETS DOEN?</span></span></p>
                        <p>Misschien is het ook best om zelf ook eens te kijken of een onderliggende oorzaak kan aangepakt worden.</p>
                        <ul style="margin-left: 1.25rem; list-style: circle;">
                        <li>Als het probleem <span style="color: #50BD4D;">relatie gebonden</span> is, dan is het noodzakelijk om hierover met de partner te praten (hoe kan de seks beter gemaakt worden, plan afspraakjes in, lees boeken over seks, of denk aan relatie therapie)</li>
                        <li><span style="color: #50BD4D;">Verander je levensstijl</span>: stop met roken en drinken, probeer af te vallen, zorg voor regelmatige beweging, &hellip;</li>
                        <li>Neem eventueel een <span style="color: #50BD4D;">vitamine supplement</span> voor wat meer energie te hebben. Sommige van deze preparaten combineren soms vitamines met een aantal plantenextracten die het libido op plantaardige basis kunnen verhogen.</li>
                        </ul>
                </div>
                    </div>
                    
                <!-- Post Meta Start -->
                <div class="andro_post-single-meta">                    
                    <div class="andro_post-single-meta-item">
                        <div class="tagcloud">
                            <a routerLink='/shop-v1' (click)="change_page(_info.category)">{{_info.category}}</a>
                        </div>
                    </div>
                </div>
                <!-- Post Meta End -->              
            </div>
            <!-- Sidebar Start -->
            <div class="col-lg-4">
                <app-blog-sidebar></app-blog-sidebar>
            </div>
            <!-- Sidebar End -->
        </div>  
    </div>
</div>
<!-- Product Content End -->

<!-- Related Posts Start -->
<div class="section pt-0 andro_related-posts">
    <div class="container">
        <div class="section-title flex-title">
            <h4 class="title">Andere berichten</h4>
            <div [ngStyle]="{'display': list_info.length > 3 ? '' : 'none'}" class="andro_arrows">
                <i class="fa fa-arrow-left slick-arrow slider-prev" (click)="prev()"></i>
                <i class="fa fa-arrow-right slick-arrow slider-next" (click)="next()"></i>
            </div>
        </div>
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig1">
            <!-- Article Start -->
            <div ngxSlickItem *ngFor="let info of list_info" class="slide" >
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single' (click)="select_info(info.id)">
                            <img src="{{info.photo}}" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a routerLink='/shop-v1' (click)="change_page(info.category)">{{info.category}}</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single' (click)="select_info(info.id)">{{info.title}}</a> </h5>
                            <div [innerHTML]="info.text_intro"></div>    
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single' (click)="select_info(info.id)"> Lees meer </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
        </ngx-slick-carousel>
    </div>
</div>

<app-insta-two></app-insta-two>
<app-footer (to_top)="to_top()"></app-footer>