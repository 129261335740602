import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { mailmessage } from 'src/app/model';
import { validateLocaleAndSetLanguage } from 'typescript';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, OnDestroy {

  _api = "";
  private destroy$: Subject<void> = new Subject<void>();
  formvalid = false;
  form_mail = this.formBuilder.group({
    lname: '',
    fname: '',
    mail: '',
    subject: '',
    message: ''});

  constructor(public _aposervice: ApoService, public formBuilder: FormBuilder, private toastr: ToastrService, public http: HttpClient) { 

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this._api = data;      
    })

    _aposervice.currentcontactpage.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data){ 
        this.to_contact();
        setTimeout(()=>{ 
          document.getElementById('input').focus();
        },0);  
      }  
      else {this.to_top();}    
    })

    this.form_mail.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.check_formvalid();
    })
  }

  ngOnInit(): void {
  }

  send_mail(){

    var message: mailmessage = {
      subject : this.form_mail.controls['subject'].value,
      firstname : this.form_mail.controls['fname'].value,
      lastname : this.form_mail.controls['lname'].value,
      mail : this.form_mail.controls['mail'].value,
      message : this.form_mail.controls['message'].value     
    }

    this.http.post<mailmessage>(this._api + "info",message).pipe(takeUntil(this.destroy$)).subscribe();
    this.form_mail.reset();
    this.toastr.success('We contacteren U spoedig!', 'Bericht verstuurd', {timeOut: 5000, positionClass: 'toast-bottom-center'})

  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();    
  } 

  check_formvalid(){
    if (this.validateEmail(this.form_mail.controls['mail'].value) && this.form_mail.controls['message'].value != ""){
      this.formvalid = true;
    }
    else {this.formvalid = false;}
  } 

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  scrolltotop(){
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView({behavior: "smooth"});
      top = null;
    }  
  }

  to_top(){
    setTimeout (() => {
      this.scrolltotop();
   }, 200);
  }

  scroll_to_contact(){
    let top = document.getElementById('contact');
    if (top !== null) {
      top.scrollIntoView({behavior: "smooth"});
      top = null;
    }  
  }

  to_contact(){
    setTimeout (() => {
      this.scroll_to_contact();
   }, 200);
  }
}
