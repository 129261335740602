import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './organista/layouts/header/header.component';
import { HeaderTwoComponent } from './organista/layouts/header-two/header-two.component';
import { HeaderThreeComponent } from './organista/layouts/header-three/header-three.component';
import { PreloaderComponent } from './organista/layouts/preloader/preloader.component';
import { BlogSidebarComponent } from './organista/layouts/blog-sidebar/blog-sidebar.component';
import { InstaOneComponent } from './organista/layouts/insta-one/insta-one.component';
import { InstaTwoComponent } from './organista/layouts/insta-two/insta-two.component';
import { FooterComponent } from './organista/layouts/footer/footer.component';
import { FooterTwoComponent } from './organista/layouts/footer-two/footer-two.component';
import { ShopSidebarComponent } from './organista/layouts/shop-sidebar/shop-sidebar.component';
import { HomeComponent } from './organista/pages/home/home.component';
import { HomeTwoComponent } from './organista/pages/home-two/home-two.component';
import { HomeThreeComponent } from './organista/pages/home-three/home-three.component';
import { AboutComponent } from './organista/pages/about/about.component';
import { BlogGridComponent } from './organista/pages/blog-grid/blog-grid.component';
import { BlogListComponent } from './organista/pages/blog-list/blog-list.component';
import { BlogMasonaryComponent } from './organista/pages/blog-masonary/blog-masonary.component';
import { BlogSingleComponent } from './organista/pages/blog-single/blog-single.component';
import { CartComponent } from './organista/pages/cart/cart.component';
import { CheckoutComponent } from './organista/pages/checkout/checkout.component';
import { ContactComponent } from './organista/pages/contact/contact.component';
import { ErrorComponent } from './organista/pages/error/error.component';
import { LegalComponent } from './organista/pages/legal/legal.component';
import { LoginComponent } from './organista/pages/login/login.component';
import { ProductSingleComponent } from './organista/pages/product-single/product-single.component';
import { ProductSingleTwoComponent } from './organista/pages/product-single-two/product-single-two.component';
import { RegisterComponent } from './organista/pages/register/register.component';
import { WishlistComponent } from './organista/pages/wishlist/wishlist.component';
import { ShopOneComponent } from './organista/pages/shop-one/shop-one.component';
import { ShopTwoComponent } from './organista/pages/shop-two/shop-two.component';
import { ShopThreeComponent } from './organista/pages/shop-three/shop-three.component';
import { ShopFourComponent } from './organista/pages/shop-four/shop-four.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTooltipModule} from '@angular/material/tooltip';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { APOATHOME_httpinterceptor } from 'src/apoathome-http-interceptor';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {MatBadgeModule} from '@angular/material/badge';
import {CdkStepperModule} from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import {MatInputModule} from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnersAngularModule } from 'spinners-angular';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AccountComponent } from './organista/account/account.component';
import { CartitemsComponent } from './organista/pages/cartitems/cartitems.component';
import { AuthInterceptor } from './auth.interceptor';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AuthService } from './auth.service'; 
import { LastOrdersComponent } from './organista/pages/last-orders/last-orders.component';
import { NgbAccordionConfig, NgbAccordionModule, NgbDatepickerModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryComponent } from './organista/pages/delivery/delivery.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { OrderconfirmationComponent } from './organista/pages/orderconfirmation/orderconfirmation.component';
import { BetalingOrderComponent } from './organista/pages/betaling-order/betaling-order.component';
import { SalePointsComponent } from './organista/pages/sale-points/sale-points.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderTwoComponent,
    HeaderThreeComponent,
    PreloaderComponent,
    BlogSidebarComponent,
    InstaOneComponent,
    InstaTwoComponent,
    FooterComponent,
    FooterTwoComponent,
    ShopSidebarComponent,
    HomeComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    AboutComponent,
    BlogGridComponent,
    BlogListComponent,
    BlogMasonaryComponent,
    BlogSingleComponent,
    CartComponent,
    CheckoutComponent,
    ContactComponent,
    ErrorComponent,
    LegalComponent,
    LoginComponent,
    ProductSingleComponent,
    ProductSingleTwoComponent,
    RegisterComponent,
    WishlistComponent,
    ShopOneComponent,
    ShopTwoComponent,
    ShopThreeComponent,
    ShopFourComponent,
    AccountComponent,
    CartitemsComponent,
    LastOrdersComponent,
    DeliveryComponent,
    OrderconfirmationComponent,
    BetalingOrderComponent,
    SalePointsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    SlickCarouselModule,
    PerfectScrollbarModule,
    MatProgressSpinnerModule,
    NgxSliderModule,    
    MatBadgeModule,
    CdkStepperModule,
    NgStepperModule,
    CommonModule,
    ToastrModule.forRoot(),
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnersAngularModule,
    MatSlideToggleModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgbTooltipModule,
    NgbAccordionModule,
    MatCheckboxModule,
    NgbDatepickerModule,
    NgbModule,
    MatAutocompleteModule
  ],
  providers: [
    AuthService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor , multi: true},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
