<div id="top"></div>
<app-header></app-header>
<!-- Subheader Start -->
<!-- <div class="andro_subheader pattern-bg primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1>Contact Us</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                </ol>
            </nav>
        </div>
    </div>
</div> -->
<!-- Subheader End -->
<!-- Locations Start -->
<div class="section section-padding" style="margin-bottom: 30px;">
    <div class="container" style="padding-bottom: 50px; border:2px solid #50BD4D;">
        <div  style="height: 500px">            
        <div>
            <h1> Hoe bestellen?</h1>
        </div>
        <perfect-scrollbar style="height: 600px; padding-right: 35px; padding-bottom: 15px;">
        <div style="text-align: justify; padding-right: 10px"> 
            <p>Op apo@home kan iedere meerderjarige, kwalitatieve en voorschriftvrije gezondheidsproducten bestellen. 
                Bestellingen plaatsen kan heel eenvoudig: online winkelen, registreren en adres ingeven, overzicht en betalen. </p>
            <h2>Opzoeken product</h2>
            Er zijn meerdere manieren om een product op te zoeken: 
            <ul style="margin-left: 1.25rem; list-style: circle;">
                <li>Zoeken: hierin kan u de naam van het gewenste product (volledig of gedeeltelijk) invullen om het in onze database terug te vinden.</li>
                <li>Categorieën: en subcategoriën kunnen gebruikt worden als u niet echt op zoek ban naar een baapld product, 
                    maar eerder naar een oplossing voor een probleem. Onze filters kunnen u dan helpen om uw zoektocht te verkleinen.</li>
                <li>Merken: in het merken overzicht, kunt u alles van een bepaald merk terugvinden. Ook hier kunnen de filters u helpen om meer nauwgericht te zoeken.</li>
            </ul>
            De zoekresultaten kunnen gefilterd worden op nieuwheid, prijs en populariteit.
            <p>
                Wanneer uw winkelmandje gevuld is, moet u nog maar een paar stappen doorlopen om uw bestelling te finaliseren.
            </p>

            <h2> Winkelmandje</h2>
            <p> Op deze stap is het belangrijk dat u controleert wat er allemaal in u winkelmandje zit.
                Zo ja, dan kunnen we nog een paar administratieve gegevens in orde maken en kunnen wij voor u aan het werk!
            </p>

            <h2> Account </h2>
            <p>
                Uw bestelling is voor ons heel belangrijk en zal met de uiterste zorg behandeld worden. 
                We zorgen voor een zo snel mogelijke en verzorgde levering. 
                Natuurlijk hebben we hiervoor een aantal gegevens nodig.
                U kan deze gegevens opslaan in een account, zodat U ze bij de volgende bestellingen niet hoeft opnieuw in te geven, maar dit is niet verplicht.
                Deze minimale gegevens zijn enkel nodig om u te kunnen identificeren en eventueel contact op te nemen bij problemen en/of vragen. 
                Sowieso worden geen gegevens kenbaar gemaakt aan derden en worden ze beveiligd gestockeerd.
            </p>

            <h2> Levering </h2>
            <ul style="list-style: circle; margin-left: 1.25rem;">
                Hier kunt u kiezen welke levering of afhaling u opteert:
                <li>Binnen de postcodes 9200, 9280 en 9255 zal de levering gratis zijn en wordt de levering verzorgd door een gediplomeerde zorgverlener.</li>  
                <li>Het is ook mogelijk om zelf je bestelling af te halen in onze 2 centraal gelegen verkooppunten:<br>
                    Apotheek Van Causbroeck (Kerkstraat 88, 9200 Dendermonde) en Apotheek Ceulemans (Kerkstraat 97, 9255 Buggenhout)</li>  
                <li>Voor andere adressen of leveringsmethodes wordt de hulp van POSTNL ingeschakeld. 
                    Hierbij kan het adres van uw keuze ingegeven worden (werk, thuis, vrienden, buren, …). 
                    Let wel op, wij leveren enkel in België. Voor leveringen met POSTNL worden de meeste bestellingen die voor de middag worden geplaatst, 
                    de volgende dag (werkdag) nog uitgestuurd. Indien het product een beperkte voorraad heeft, kan de levering wat langer op zich laten wachten 
                    (voeg 3 tot 5 werkdagen toe).</li>  
                </ul>

            <h2> Bevestiging en betaling</h2>
            <p>           
                Nog even een finale controle of alles wel correct is ingegeven! Is er iets niet in orde, dan kunt u steeds terug naar het 
                vorige tabblad om de verbetering uit te voeren. Voor de betaling wordt u doorverwezen naar een beveiligde website (Mollie) 
                waar u met bancontact kan betalen. Zodra wij uw betaling hebben ontvangen, wordt uw bestelling zo snel mogelijk verwerkt en verstuurd. 
            </p>

            <h2>Terugzendingen</h2>
            <p>
                Een pakje zal automatisch naar ons worden teruggestuurd in de volgende gevallen: afwezigheid van de klant bij thuislevering, 
                fout in de adresgegevens die de levering onmogelijk maakt, niet ophalen van het pakje door de klant binnen 14 kalenderdagen. 
                In al deze gevallen zal het bedrag van de bestelling aan de klant worden terugbetaald na aftrek van de leveringskosten en van de retourkosten.
            </p>

            <p>
                Voor aanvullende informatie over onze website kunt u de <a href="javascript: void(0)" data-toggle="modal" data-target="#AVV">algemene voorwaarden</a>  
                doornemen.
            </p>
        </div>
    </perfect-scrollbar>   
    </div>
</div>

</div>
<!-- Locations Start -->

<!-- Icons End -->
<!-- FAQ & Contact Form Start -->
<div class="section pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 mb-lg-30">
                <div class="section-title">
                    <h4 class="title">FAQ</h4>
                </div>
                <div class="accordion with-gap" id="generalFAQExample">
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalOne" aria-expanded="true" aria-controls="generalOne">
                            Log-in en/of wachtwoord vergeten!?
                        </div>
                        <div id="generalOne" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body" style="text-align: justify;">
                                Om naar uw account te gaan, wordt uw e-mailadres gebruikt als ID. 
                                De toegang wordt beveiligd door een geheim wachtwoord, dat u zelf heeft gekozen. 
                                <ul>
                                    <li>
                                        <span style="color: #50BD4D">Log-in</span> vergeten: 
                                        Contacteer ons dan. Samen met u overlopen we welk e-mailadres u gebruikt heeft.
                                    </li>
                                    <li>
                                        <span style="color: #50BD4D">Wachtwoord</span> vergeten: 
                                        Klik op <a href="javascript:void(0)" data-toggle="modal" data-target="#pwd_forgotten" >Paswoord vergeten?</a> in het loginscherm? Vermeld het e-mailadres waarmee u uw account hebt aangemaakt. 
                                        Er wordt u dan automatisch per e-mail een link toegestuurd om een nieuw wachtwoord aan te maken.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalTwo" aria-expanded="false" aria-controls="generalTwo">
                            Hou wijzig ik mijn persoonsgegevens?
                        </div>
                        <div id="generalTwo" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body" style="text-align: justify;">
                                U vindt na het inloggen rechts bovenaan de rubriek <a href="javascript:void(0)" data-toggle="modal" data-target="#Accountmodal">Mijn gegevens</a>; 
                                hier kan U de desbetreffende wijzigingen aanbrengen en opslaan. <br>
                                Deze minimale gegevens zijn enkel nodig om u te kunnen identificeren en eventueel contact op te nemen bij problemen en/of vragen. 
                                Sowieso worden geen gegevens kenbaar gemaakt aan derden en worden ze beveiligd gestockeerd.  
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalThree" aria-expanded="false" aria-controls="generalThree">
                            Waarom vind ik het gezochte product niet?
                        </div>
                        <div id="generalThree" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body" style="text-align: justify;">
                                Een product kan om verschillende redenen niet beschikbaar zijn:
                                <ul style="list-style: circle; margin-left: 1.25rem;">
                                    <li>Het product is nog niet opgenomen op de site</li>
                                    <li>Het product is geen parafarmaceutisch product</li>
                                    <li>Het product is geen origineel product en mag dus niet in België verkocht worden</li>
                                    <li>Het product is niet langer verkrijgbaar in België.</li>                                    
                                </ul>      
                                Controleer ook goed op spelfouten of gebruik andere zoekcriteria.
                                Bij twijfel contacteer ons.                          
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalFour" aria-expanded="false" aria-controls="generalFour">
                            Hoe kan ik veilig betalen?
                        </div>
                        <div id="generalFour" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body" style="text-align: justify;">
                                Om voor iedereen een veilige betaling te garanderen, gebruiken wij de betaalmiddelen die door 
                                <a href="https://www.mollie.com/be/consumers">Mollie payments</a>
                                worden aangeboden: bancontact, KBC/CBC betaalknop en Belfius Pay Button.
                                De uitwisseling van delicate informatie (zoals het nummer van de kredietkaart) gebeurt uitsluitend tussen de klant 
                                en de e-commercesite van Mollie. De e-commerce oplossing van Mollie beschikt over een certificaat afgeleverd door Verisign. 
                                Alle mededelingen over de betalingen verlopen gecodeerd om de volledige vertrouwelijkheid te garanderen van de 
                                gevoelige informatie die wordt uitgewisseld tussen de klant en Mollie e-commerce.

                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalFive" aria-expanded="false" aria-controls="generalFive">
                            Hoe snel heb ik mijn pakje?
                        </div>
                        <div id="generalFive" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body">
                                Apo@home stelt alles in het werk om uw pakketje zo snel mogelijk tot bij uw thuis te brengen.
                                Helaas moeten er soms externe factoren in acht genomen worden, die de levering kunnen vertragen:
                                <ul style="margin-left: 1.25rem; list-style: circle;">
                                    <li>Het product is slecht in beperkte oplage in het depot van apo@home aanwezig</li>
                                    <li>Het product is nog niet in het depot van apo@home aanwezig</li>
                                    <li>De toegewezen pakjesdienst heeft door bvb. drukte vertraging in zijn leveringen</li>
                                    <li>U woont zelf iets verder waardoor uw pakketje een langere afstand moet afleggen. 
                                        De aangegeven termijnen worden louter ter indicatie gegeven en kunnen enkel beschouwd worden als streefdata.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalSix" aria-expanded="false" aria-controls="generalSix">
                            Oei, ik was net niet thuis toen jullie langskwamen! Wat nu?
                        </div>
                        <div id="generalSix" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body" style="text-align: justify;">
                                Indien uw pakket in de brievenbus past, zal u het in de meeste gevallen daar terugvinden. 
                                Zo niet, dan wordt uw pakket geleverd in het dichtstbijzijnde parcelshop of postkantoor, afhankelijk van 
                                welke pakjesdienst aan uw deur is geweest. Wees gerust, u kunt de info over waar uw pakketje zich bevindt, 
                                zeker terugvinden in uw brievenbus, wanneer ze zijn langsgeweest.
                                Check zeker ook U mailbox, waarin U berichten zal ontvangen over de status van de levering.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalSeven" aria-expanded="false" aria-controls="generalSeven">
                            Wat doe ik als mijn pakket of product beschadigd is?
                        </div>
                        <div id="generalSeven" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body" style="text-align: justify;">
                                Aanvaard nooit een beschadigd pakket (zwaar beschadigde of geopende verpakking) zonder de inhoud ervan te controleren. 
                                Controleer in het bijzijn van de chauffeur de staat en het aantal van de producten in het pakket. <br>
                                Indien er toch zoiets voorvalt, neem onmiddellijk met ons contact op en we spreken samen af wat u terugstuurt en 
                                wat we opnieuw moeten verzenden en welke formulieren er dienen ingevuld te worden.
                                <span style="color: red">Pas wel op, een door u geopende verpakking, wordt beschouwd als een aanvaarding van de bestelling. </span>
                                Zulke pakketjes worden niet meer als beschadigd beschouwd en kunnen niet teruggestuurd worden. <br>
                                Indien u twijfels heeft over de inhoud van uw pakje en of het het juiste product wel is, <span style="color: red;">contacteer ons</span> eerst! 
                                Zo kunnen we eerst samen nagaan of het het juiste is en of het eventueel dient vervangen te worden!<br>
                                Bij apo@home maken we er een prioriteit van om producten af te leveren met goede vervaldata en goede kwaliteit. 
                                Maar soms kan hier al eens een foutje insluipen. Bent u toevallig hiervan de dupe en blijkt de vervaldata bereikt te zijn, contacteer ons en we lossen dit samen op!

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7" style="background-color: rgb(204, 236, 204) !important;">
                <div class="section-title">
                    <h4 class="title" style="padding-left: 10px; padding-top: 10px;">Contacteer ons</h4>
                </div>
                <form [formGroup]="form_mail" >
                    <div class="row">
                        <div class="form-group col-lg-6">
                            <input type="text" formControlName="fname" placeholder="Voornaam" class="form-control" name="fname" value="" id = "input">
                        </div>
                        <div class="form-group col-lg-6">
                            <input type="text" formControlName="lname" placeholder="Achternaam" class="form-control" name="lname" value="">
                        </div>
                        <div class="form-group col-lg-12">
                            <input matTooltip="mail en bericht verplichte velden" matTooltipClass="sort" type="email" formControlName="mail" placeholder="E-mailadres" class="form-control" name="email" value="">
                        </div>
                        <div class="form-group col-lg-12">
                            <input type="text" formControlName="subject" placeholder="Onderwerp" class="form-control" name="subject" value="">
                        </div>
                        <div class="form-group col-lg-12">
                            <textarea matTooltip="mail en bericht verplichte velden" matTooltipClass="sort"  name="message" formControlName="message" class="form-control" placeholder="Wat wil je melden?" rows="8"></textarea>
                        </div>
                    </div>
                    <button class="andro_btn-custom primary" (click)="send_mail()" name="button" style="margin-bottom: 10px;" [disabled]="!formvalid" >Bericht versturen</button>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- FAQ & Contact Form End -->
<app-insta-two></app-insta-two>
<app-footer></app-footer>