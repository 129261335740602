<!-- Footer Start -->
<footer class="andro_footer andro_footer-dark">
    <!-- Top Footer -->
    <div class="container">
        <div class="andro_footer-top">
            <div class="andro_footer-logo">
                <img src="assets/img/logo-light.png" alt="logo">
            </div>
            <div class="andro_footer-buttons">
                <a href="#"> <img src="assets/img/android.png" alt="download it on the app store"></a>
                <a href="#"> <img src="assets/img/ios.png" alt="download it on the app store"></a>
            </div>
        </div>
    </div>
    <!-- Middle Footer -->
    <div class="andro_footer-middle">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
                    <h5 class="widget-title">Information</h5>
                    <ul>
                        <li> <a routerLink='/'>Home</a> </li>
                        <li> <a routerLink='/blog-grid'>Blog</a> </li>
                        <li> <a routerLink='/about'>About Us</a> </li>
                        <li> <a routerLink='/shop-v1'>Shop</a> </li>
                        <li> <a routerLink='/contact'>Contact Us</a> </li>
                    </ul>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
                    <h5 class="widget-title">Top Categories</h5>
                    <ul>
                        <li> <a href="#">Food</a> </li>
                        <li> <a href="#">Baskets</a> </li>
                        <li> <a href="#">Supplements</a> </li>
                        <li> <a href="#">Keto</a> </li>
                        <li> <a href="#">Home Care</a> </li>
                    </ul>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
                    <h5 class="widget-title">Others</h5>
                    <ul>
                        <li> <a routerLink='/checkout'>Checkout</a> </li>
                        <li> <a routerLink='/cart'>Cart</a> </li>
                        <li> <a routerLink='/product-single'>Product</a> </li>
                        <li> <a routerLink='/shop-v1'>Shop</a> </li>
                        <li> <a routerLink='/legal'>Legal</a> </li>
                    </ul>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer-widget">
                    <h5 class="widget-title">Social Media</h5>
                    <ul class="social-media">
                        <li> <a href="#" class="facebook"> <i class="fab fa-facebook-f"></i> </a> </li>
                        <li> <a href="#" class="pinterest"> <i class="fab fa-pinterest-p"></i> </a> </li>
                        <li> <a href="#" class="google"> <i class="fab fa-google"></i> </a> </li>
                        <li> <a href="#" class="twitter"> <i class="fab fa-twitter"></i> </a> </li>
                    </ul>
                    <div class="andro_footer-offer">
                        <p>Signup and get exclusive offers and coupon codes</p>
                        <a routerLink='/register' class="andro_btn-custom btn-sm shadow-none">Sign Up</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="andro_footer-bottom">
        <div class="container">
            <ul>
                <li> <a href="#">Privacy Policy</a> </li>
                <li> <a href="#">Refund Policy</a> </li>
                <li> <a href="#">Cookie Policy</a> </li>
                <li> <a href="#">Terms & Conditions</a> </li>
            </ul>
            <div class="andro_footer-copyright">
                <p> Copyright © 2020 <a href="#">AndroThemes</a> All Rights Reserved. </p>
                <a href="#" class="andro_back-to-top">Back to top <i class="fas fa-chevron-up"></i> </a>
            </div>
        </div>
    </div>
</footer>
<!-- Footer End -->
<!-- Newsletter Modal Start -->
<div class="modal fade" id="androNewsletterPopup" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog andro_newsletter-popup-modal modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <img src="assets/img/ig/3.jpg" alt="newsletter">
                <div class="close-btn close newsletter-popup-trigger" data-dismiss="modal">
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div class="modal-body">
                <div class="andro_newsletter-popup-text-wrapper">
                    <h3>Join Our Newsletter</h3>
                    <p>Sed porttitor lectus nibh. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur aliquet quam id dui posuere.</p>
                </div>
                <form method="post" class="text-center">
                    <div class="form-group">
                        <input type="email" placeholder="Email Address" class="form-control" name="newsletter-email" value="">
                    </div>
                    <button type="submit" class="andro_btn-custom" name="button">Subscribe</button>
                    <span class="newsletter-popup-trigger" data-dismiss="modal">No Thanks</span>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Newsletter Modal End -->
<!-- Quick View Modal Start -->
<div class="modal fade andro_quick-view-modal" id="quickViewModal" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="close-btn close-dark close" data-dismiss="modal">
                    <span></span>
                    <span></span>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-5">
                            <img src="assets/img/products/9.png" alt="product">
                        </div>
                        <div class="col-md-7">
                            <div class="andro_product-single-content">
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                    <span>4 Stars</span>
                                </div>
                                <h3> Tomatoes </h3>
                                <div class="andro_product-price">
                                    <span>8$</span>
                                    <span>14$</span>
                                </div>
                                <p class="andro_product-excerpt">125s ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                <form class="andro_product-atc-form">
                                    <div class="andro_product-variation-wrapper">
                                        <div class="form-group">
                                            <select class="form-control" name="amount">
                                                <option value="">Select Amount</option>
                                                <option value="1">1 Kilo</option>
                                                <option value="2">2 Kilos</option>
                                                <option value="3">3 Kilos</option>
                                                <option value="4">4 Kilos</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <select class="form-control" name="breed">
                                                <option value="">Select a Breed</option>
                                                <option value="italian">Italian</option>
                                                <option value="egyptian">Egyptian</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="qty-outter">
                                        <a routerLink='/product-single' class="andro_btn-custom">Buy Now</a>
                                        <div class="qty">
                                            <span class="qty-subtract"><i class="fa fa-minus"></i></span>
                                            <input type="text" name="qty" value="1">
                                            <span class="qty-add"><i class="fa fa-plus"></i></span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Quick View Modal End -->