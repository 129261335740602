<!-- Instagram Start -->
<div class="row no-gutters">
    <div class="col-lg-4 primary-bg pattern-bg">
        <div class="andro_instagram">
            <h3 class="text-white">Follow Us On Instagram</h3>
            <a href="#" class="andro_btn-custom light">Follow Us</a>
        </div>
    </div>
    <div class="col-lg-8">
        <div class="row no-gutters">
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <a href="#" class="andro_ig-item">
                    <img src="assets/img/ig/1.jpg" alt="ig">
                </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <a href="#" class="andro_ig-item">
                    <img src="assets/img/ig/2.jpg" alt="ig">
                </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <a href="#" class="andro_ig-item">
                    <img src="assets/img/ig/3.jpg" alt="ig">
                </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <a href="#" class="andro_ig-item">
                    <img src="assets/img/ig/4.jpg" alt="ig">
                </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <a href="#" class="andro_ig-item">
                    <img src="assets/img/ig/5.jpg" alt="ig">
                </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 p-0">
                <a href="#" class="andro_ig-item">
                    <img src="assets/img/ig/6.jpg" alt="ig">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- Instagram End -->