import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, timeout } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { AuthService } from 'src/app/auth.service';
import { account, cartitem, Product } from 'src/app/model';
import { AccountComponent } from '../../account/account.component';
declare var jQuery:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit, OnDestroy {

  constructor(public _aposervice: ApoService, public formBuilder: FormBuilder, public authService: AuthService, public http: HttpClient) {


    this.authService.current_logged_in.pipe(takeUntil(this.destroy$)).subscribe(data => { console.log(data); 
      if (data) { this.btn_close.nativeElement.click(); }});

    this._aposervice.current_product_select.pipe(takeUntil(this.destroy$)).subscribe(data => {
      console.log(data);
      
      this.aantal = 1;
       this._product_selected = data;
       
       if (data.prescription == '0'){this.buybtn_txt = 'in winkelmandje';} else {this.buybtn_txt = 'naar site apotheek van Causbroeck';}
           
       //checken of geselecteerd product al in winkelmandje zit
       if (this.list_cart.findIndex(x => x.product.cnk == this._product_selected.cnk) != -1){
            this.selection_in_list = true;
            this.aantal = this.list_cart[this.list_cart.findIndex(x => x.product.cnk == this._product_selected.cnk)].quantity;
            this.buybtn_txt = 'aantal aanpassen'
          }
          else {
            this.selection_in_list = false;
            this.aantal = 1;
          }

        // checken of geselecteerd product al in de favorieten zit
        if (this.list_fav.findIndex(x => x == data.id) != -1) {this._product_selected.favoriet = true; this.title_fav = 'verwijderen uit favorieten'}
        else {this._product_selected.favoriet = false; this.title_fav = 'toevoegen aan favorieten'}

       });

       _aposervice.current_mandje.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.list_cart = data;
      }); 

      _aposervice.current_logout.pipe(takeUntil(this.destroy$)).subscribe(data => {
        if (data){

          this.form_login = this.formBuilder.group({ 
            pwd: '',    
            login: ''
          });  
        
          this.form_pwd = this.formBuilder.group({ 
            pwd: '',    
            pwdrep: '',
            pwd_login: ''
          });  
        };      
      })

      _aposervice.current_formvalid.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.ok_disable1 = !data;
        if (data){
          
        }
      });     

      _aposervice.current_listfav.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.list_fav = data;
      });

      _aposervice.current_modusaccount.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.modusaccount = data;
      });
    
    this.form_pwd.valueChanges.subscribe((val) => {
      if (!this.account_db){
        if ((this.form_pwd.controls['pwd'].value == this.form_pwd.controls['pwdrep'].value) && this.form_pwd.controls['pwd'].value.length > 5)
        {this.form_pwd_valid = true;} else {this.form_pwd_valid = false;}
      }
      else if (this.form_pwd.controls['pwd_login'].value != ""){this.form_pwd_valid = true;} else {this.form_pwd_valid = false;}
    });    

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this._api = data;
    })  

    _aposervice.current_agree.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.agree = data;
    })

    _aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {            
      this.email_account = "";
      if (!this.ok_disable1 && data.mail != ""){        
        this.form_pwd_valid = false;
        this.email_account = data.mail;
        this.http.get<string>(this._api + "users/exist/" + data.mail).pipe(takeUntil(this.destroy$)).subscribe(x => {   
          console.log(x);
          if (x == "yes"){this.account_db = true; if (this.form_pwd_forgotten.controls['login'].value == ""){this.form_pwd_forgotten.controls['login'].patchValue(data.mail);}
        } else {this.account_db = false;}
        });
      }      
      this.userid = data.id;
    });

    authService.current_error.pipe(takeUntil(this.destroy$)).subscribe(data =>{
      if (data == "The password is invalid or the user does not have a password.") 
      { this.errormessage = "Het paswoord is niet correct of <br> dit account is niet geregistreerd." }   
      else if (data == "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.") 
      { this.errormessage = "Dit account is tijdelijk geblokkeerd. <br> Reset het paswoord of probeer het later nog eens." }
    })
    
  }

  change_agree(event){    
    this._aposervice.change_agree(event.checked); 
    this.btn1.nativeElement.click();  
  }

  agree: Boolean = false;
  _product_selected: Product = {name: '', price: 0, price_disc: 0, info_short_product: '', photo: '', cnk: '', brand: '', percentage: 0, attention_product: 0, popular: 0, id: 0, 
   subcategory: '', documentation: '', prescription: '', principle: '', _use: '', composition: '', favoriet: false, active: 0, special: '', creationtime: new Date(),
   price_ori:0, number_to_free: 0, discount_special: 0, btw: 6, discount_text: '', disc_start: new Date(), disc_stop: new Date(), att_start: new Date(), att_stop: new Date(),
   check: false, check_select: false, principle2: '', info: '', product_collection: ''};
   private destroy$: Subject<void> = new Subject<void>();
   aantal = 1;
   buybtn_txt = 'in winkelmandje';
   list_cart: cartitem[] = [];
   selection_in_list = false;
   list_fav = [];
   title_fav = ''; 
   items_focussed = [];  
   items_focussed_fb = [];  
   focus_item = '';
   title_login = '';
   account_exists: Boolean = true;
   ok_disable: Boolean = true;
   ok_disable_fb: Boolean = true;
   ok_disable1: Boolean = true;
   account_empty: Boolean = true;
   attempt_nmb = 3; 
   logged_in: Boolean = false;
   failed_login = 0;
   @ViewChild('btn') btn: ElementRef;
   @ViewChild('btn1') btn1: ElementRef;
   @ViewChild('btn_close') btn_close: ElementRef;
   @ViewChild('btn_close_login') btn_close_login: ElementRef;
   @ViewChild(AccountComponent) account_cmpt: AccountComponent;
   form_pwd_valid = false;
   account_db = false;
   _api = "";
   email_account = "";
   forgotten_valid = false;
   mail_verstuurd = false;
   modusaccount = "";
   errormessage = "Het paswoord is niet correct of <br> dit account is niet geregistreerd.";
   pwd_txt = "Paswoord kiezen";
   userid = 0;
   @Output() to_top = new EventEmitter<string>();


   form_account = this.formBuilder.group({ 
    pwd: '',
    pwdrep: '',
    pwd1: '',
    login: ''
  });

  form_login = this.formBuilder.group({ 
    pwd: '',    
    login: ''
  });  

  form_pwd = this.formBuilder.group({ 
    pwd: '',    
    pwdrep: '',
    pwd_login: ''
  });  

  form_pwd_forgotten = this.formBuilder.group({ 
   login: ''
  });  

  set_focus(item){
    this.focus_item = item;
    if (this.items_focussed.findIndex(x => x == item) == -1){this.items_focussed.push(item);}    
  }

  set_blur(item){
    if (this.focus_item == item){
      this.focus_item = '';
    }    
  }

  get_to_top(){
    this.to_top.emit('go');
  }

  downloadMyFile(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'https://apoathomeapi.be/data/Algemene Verkoopvoorwaarden.pdf');
    //link.setAttribute('download', `Algemene Verkoopvoorwaarden.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

  
  change_page(cat: string){    
    this._aposervice.select_cat(cat);
    (document.elementFromPoint(10, 5) as HTMLElement).click();
    this.get_to_top();
  }

  set_focus_fb(item){
    this.focus_item = item;
    if (this.items_focussed_fb.findIndex(x => x == item) == -1){this.items_focussed_fb.push(item);}    
  }

  set_blur_fb(item){
    if (this.focus_item == item){
      this.focus_item = '';
    }    
  }

  setMyStyles_pwd(account_item){
    let styles = {
      'border-color': this.focus_item == account_item ? '' : this.form_account.controls[account_item].value != ''  ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  }

  // save_data(){
  //   this.account_cmpt.save_account_to_service();
  // }

  setMyStyles_pwd1(account_item){
    let styles = {
      'border-color': this.focus_item == account_item ? '' : (this.form_account.controls[account_item].value == this.form_account.controls['pwd'].value && this.form_account.controls['pwd'].value != '') ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  }

  setMyStyles_pwd3(account_item){
    let styles = {
      'border-color': this.focus_item == account_item ? '' : (this.form_pwd.controls[account_item].value.length > 5) ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  }

  setMyStyles_pwd4(account_item){
    let styles = {
      'border-color': this.focus_item == account_item ? '' : (this.form_pwd.controls[account_item].value == this.form_pwd.controls['pwd'].value && this.form_pwd.controls['pwd'].value != '' && this.form_pwd.controls[account_item].value.length > 5) ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  }

  setMyStyles_checkpwd3(account_item){
    let styles = {
      'display': this.focus_item == account_item ? 'none' : (this.form_pwd.controls[account_item].value.length > 5) ? '' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'none' : 'none',
    };
    return styles;
  }

  setMyStyles_checkpwd4(account_item){
    let styles = {
      'display': this.focus_item == account_item ? 'none' : (this.form_pwd.controls[account_item].value == this.form_pwd.controls['pwd'].value && this.form_pwd.controls['pwd'].value != '' && this.form_pwd.controls[account_item].value.length > 5) ? '' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'none' : 'none',
    };
    return styles;
  }

  setMyStyles_pwd_fb(account_item){
    let styles = {
      'border-color': this.focus_item == account_item ? '' : (this.form_login.controls['pwd'].value != '') ? 'rgba(81,189,77,255)' : this.items_focussed_fb.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  }

  setMyStyles_login_fb(account_item){
    let styles = {
      'border-color': this.focus_item == account_item ? '' : (this.form_login.controls[account_item].value.includes('@') && this.form_login.controls[account_item].value.includes('.')) ? 'rgba(81,189,77,255)' : this.items_focussed_fb.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  }

  setMyStyles_pwd2(account_item){
    let styles = {      
      'border-color': this.focus_item == account_item ? '' : (this.form_account.controls[account_item].value.includes('@') && this.form_account.controls[account_item].value.includes('.')) ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  }

  setMyStyles_checkpwd(account_item){
    let styles = {
      'display': this.focus_item == account_item ? 'none' : this.form_account.controls[account_item].value != ''  ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_new(){
    let styles = {
      'display': this.account_empty  ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_new1(){
    let styles = {
      'display': !this.account_empty && !this.account_exists ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_loginpwd(){
    let styles = {
      'display': this.account_empty || this.account_exists ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_checkpwd1(account_item){
    let styles = {
      'display': this.focus_item == account_item ? 'none' : (this.form_account.controls[account_item].value == this.form_account.controls['pwd'].value && this.form_account.controls['pwd'].value != '') ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_check_pwd_fb(account_item){
    let styles = {
      'display': this.focus_item == account_item ? 'none' : this.form_login.controls['pwd'].value != '' ? '' : 'none',
    };
    return styles;
  }  

  setMyStyles_check_login_fb(account_item){
    let styles = {
      'display': this.focus_item == account_item ? 'none' : (this.form_login.controls[account_item].value.includes('@') && this.form_login.controls[account_item].value.includes('.')) ? '' : 'none',
    };
    return styles;
  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();  
    this._aposervice.change_modusaccount('');
  }

  data_empty(){
    this.items_focussed = [];    
    this.form_account.controls['pwd'].patchValue('');
    this.form_account.controls['pwdrep'].patchValue('');
    this.form_account.controls['pwd1'].patchValue('');    
    this.form_account.controls['login'].patchValue('');    
  }

  data_empty_fb(){
    this.form_login.controls['pwd'].patchValue('');
    this.form_login.controls['login'].patchValue('');    
    this.items_focussed_fb = [];
  }

  data_empty_pwd(){
    this.form_pwd.controls['pwd'].patchValue('');
    this.form_pwd.controls['pwdrep'].patchValue('');   
    this.data_empty1(); 
  }

  data_empty1(){
      var account: account = {lname: '', fname: '', mail: '', telefoon: '', RR: '', land: '', straat: '', nummer: '', bus: '', postcode: '', gemeente: '', 
      info: '', nieuw: true, logged_in: false, failed_login: 0, uid: "0", bedrijfsnaam: '',vertegenwoordiger: '', mail_fac: '', land_fac: '',straat_fac: '', 
      nummer_fac: '', bus_fac: '', postcode_fac: '', gemeente_fac: '', id: 0 };
      this._aposervice.change_account(account);
      this._aposervice.logout(true);
      this._aposervice.change_modusaccount('');
      // this.items_focussed = [];    
      this.form_pwd_valid = false;
      this._aposervice.set_mailblock(false);
    }

  ngOnInit(): void {

    this.form_login.valueChanges.subscribe(() => {
      if ((this.form_login.controls['login'].value.includes('@') && this.form_login.controls['login'].value.includes('.')) && 
      this.form_login.controls['pwd'].value != ''){
      this.ok_disable_fb = false;
      if (this.form_pwd_forgotten.controls['login'].value == ""){this.form_pwd_forgotten.controls['login'].patchValue(this.form_login.controls['login'].value);}
      }
      else {
        this.ok_disable_fb = true;
      }
    })


    this.form_account.valueChanges.subscribe(() => {
      if (this.account_empty){
        if ((this.form_account.controls['login'].value.includes('@') && this.form_account.controls['login'].value.includes('.')) && 
            this.form_account.controls['pwd1'].value != ''){
          this.ok_disable = false;
        }
        else {
          this.ok_disable = true;
        }
      }
      else if (this.account_exists) {
        if (this.form_account.controls['pwd1'].value != ''){
          this.ok_disable = false;
        }
        else {
          this.ok_disable = true;
        }
      }
      else {
        if (this.form_account.controls['pwd'].value != '' && this.form_account.controls['pwd'].value === this.form_account.controls['pwdrep'].value){
          this.ok_disable = false;}
        else {this.ok_disable = true;}
      }
    });

    this.form_pwd_forgotten.valueChanges.subscribe(() => {
      if (this.form_pwd_forgotten.controls['login'].value != "" && this.form_pwd_forgotten.controls['login'].value.includes('@') && this.form_pwd_forgotten.controls['login'].value.includes('.')){this.forgotten_valid = true;} else {this.forgotten_valid = false;}
    })
  }

  setMyStyles_discount(){
    let styles = {
      'display': this._product_selected.special == "" ? 'none' : this._product_selected.percentage == 0 ? 'none' : '',
    };
    return styles;
  }

  setMyStyles_discountalert(){
    let styles = {
      'background-color': (this._product_selected.number_to_free != 0) && (this.aantal < this._product_selected.number_to_free) ? 'red' : 'orange',
      'display': this._product_selected.special != "" ? '' : 'none'
    };
    return styles;
  }

  setMyStyles_discountalert2(){
    let styles = {
      'display': (this._product_selected.number_to_free != 0) && (this.aantal < this._product_selected.number_to_free) ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_bijsluiter(){
    let styles = {
      'display': this._product_selected.documentation == null ? 'none' : '',
    };
    return styles;
  }

  close_modal(){
    if (this.buybtn_txt == 'in winkelmandje' || this.buybtn_txt == 'aantal aanpassen'){
      var cartitem: cartitem = {product: this._product_selected, quantity: this.aantal, quantity_free: 0, promocode: ""};
      this._aposervice.addProduct(cartitem);
    }
    else {
      window.open( 
        "http://www.apotheekvancausbroeck.be/", "_blank");
    }
  }

  setMyStyles_favorieten(){
    let styles = {
      'background-color': this._product_selected.favoriet == true ? 'red' : '',
    };
    return styles;
  }

  setMyStyles_buybtn(){
    let styles = {
      'background-color': this._product_selected.active == 0 ? 'lightgray' : '',
      'font-size': this._product_selected.active == 0 ? '12px' : '14px',
    };
    return styles;
  }

  setMyStyles_buybtn1(){
    let styles = {
      'display': this._product_selected.prescription == '1' ? 'none' : '',
    };
    return styles;
  }


  contact(){
    this._aposervice.change_contact_page(true);
  }

  setMyStyles_favoriet_icon(){
    let styles = {
      'color': this._product_selected.favoriet == true ? 'white' : '',
    };
    return styles;
  }

  setMyStyles_modal(){
    let styles = {
      'border': this.failed_login != 0 ? 'red 6px solid' : '',
    };
    return styles;
  }

  setMyStyles_modal2(){
    let styles = {
      'display': this.failed_login != 0 ? '' : 'none',
    };
    return styles;
  }

  set_favoriet(){
    this._product_selected.favoriet = !this._product_selected.favoriet;
    if (this._product_selected.favoriet){
      
      this._aposervice.addlist_fav(this._product_selected.cnk, this.userid);
      this.title_fav = 'verwijderen uit favorieten';
    }
    else {
      this._aposervice.removelist_fav(this._product_selected.cnk, this.userid);
      this.title_fav = 'toevoegen aan favorieten';
    }
  } 

  adjust(modus){
    if (modus == 'min' && this.aantal != 1){
      this.aantal = this.aantal - 1;
    }
    else if (modus == 'plus'){
      this.aantal = this.aantal + 1;
    }
  }

  login(){
    var _pwd = this.form_account.controls['pwd1'].value;
    var _login = this.form_account.controls['login'].value;
    //this._aposervice.login(_login,_pwd);
    if (this.logged_in){
      this.btn.nativeElement.click();  
    }
    else if (this.failed_login != 0) {
      this.attempt_nmb = 5 - this.failed_login;
    }
  }

  open_account(){
    this._aposervice.change_modusaccount('neo');
  }

  create_account(){

    if (!this.account_db){
      this.authService.SignUp(
        this.email_account, this.form_pwd.controls['pwd'].value
      ); 
    }
    else {
      this.authService.SignIn(this.email_account, this.form_pwd.controls['pwd_login'].value); 
    }
  }

  data_empty_login(){
    this.form_login.controls['pwd'].patchValue('');
  }

  login_fb2(){    
    this.authService.SignIn(this.form_login.controls['login'].value, this.form_login.controls['pwd'].value); 
  }

  data_empty_pwd_forgotten(){
    this.form_pwd_forgotten.controls['login'].patchValue('');
    this.forgotten_valid = false;
    this.mail_verstuurd = false;
  }

  mail_sturen(){
    this.authService.ForgotPassword(this.form_pwd_forgotten.controls['login'].value);
    this.mail_verstuurd = true;
  }




}
