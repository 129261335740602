import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  

  constructor(public _aposervice: ApoService) { }

  ngOnInit(): void {
    this._aposervice.current_catselected.pipe(takeUntil(this.destroy$)).subscribe(data => {
      console.log(data);
    })
  }

  private destroy$: Subject<void> = new Subject<void>();
  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }

}
