<app-header-two></app-header-two>
<!-- Subheader Start -->
<div class="andro_subheader pattern-bg primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1>Wishlist</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Shop</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Wishlist</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Wishlist Start -->
<div class="section">
    <div class="container">
        <!-- Wishlist Table Start -->
        <table class="andro_responsive-table">
            <thead>
                <tr>
                    <th class="remove-item"></th>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Availability</th>
                    <th>Total</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="remove">
                        <button type="button" class="close-btn close-danger remove-from-cart">
                            <span></span>
                            <span></span>
                        </button>
                    </td>
                    <td data-title="Product">
                        <div class="andro_cart-product-wrapper">
                            <img src="assets/img/products/1.png" alt="prod1">
                            <div class="andro_cart-product-body">
                                <h6> <a href="#">Kiwi</a> </h6>
                                <p>2 Kilos</p>
                            </div>
                        </div>
                    </td>
                    <td data-title="Price"> <strong>12.99$</strong> </td>
                    <td data-title="Availability">
                        <span class="andro_product-stock instock">In Stock</span>
                    </td>
                    <td data-title="Total"> <strong>12.99$</strong> </td>
                    <td data-title="Actions"> <a href="#" class="andro_btn-custom btn-sm shadow-none">Add to Cart</a> </td>
                </tr>
                <tr>
                    <td class="remove">
                        <button type="button" class="close-btn close-danger remove-from-cart">
                            <span></span>
                            <span></span>
                        </button>
                    </td>
                    <td data-title="Product">
                        <div class="andro_cart-product-wrapper">
                            <img src="assets/img/products/5.png" alt="prod1">
                            <div class="andro_cart-product-body">
                                <h6> <a href="#">Watermelons</a> </h6>
                                <p>1 Piece</p>
                            </div>
                        </div>
                    </td>
                    <td data-title="Price"> <strong>9.99$</strong> </td>
                    <td data-title="Availability">
                        <span class="andro_product-stock instock">In Stock</span>
                    </td>
                    <td data-title="Total"> <strong>9.99$</strong> </td>
                    <td data-title="Actions"> <a href="#" class="andro_btn-custom btn-sm shadow-none">Add to Cart</a> </td>
                </tr>
                <tr>
                    <td class="remove">
                        <button type="button" class="close-btn close-danger remove-from-cart">
                            <span></span>
                            <span></span>
                        </button>
                    </td>
                    <td data-title="Product">
                        <div class="andro_cart-product-wrapper">
                            <img src="assets/img/products/4.png" alt="prod1">
                            <div class="andro_cart-product-body">
                                <h6> <a href="#">Cucumbers</a> </h6>
                                <p>3 Kilos</p>
                            </div>
                        </div>
                    </td>
                    <td data-title="Price"> <strong>13.99$</strong> </td>
                    <td data-title="Availability">
                        <span class="andro_product-stock outofstock">Out of Stock</span>
                    </td>
                    <td data-title="Total"> <strong>13.99$</strong> </td>
                    <td data-title="Actions"> <a href="#" class="andro_btn-custom btn-sm shadow-none">Add to Cart</a> </td>
                </tr>
            </tbody>
        </table>
        <!-- Wishlist Table End -->
        <!-- Share Wishlist Start -->
        <div class="text-center">
            <h5>Share Your Wishlist</h5>
            <div class="andro_post-share">
                <ul class="andro_sm justify-content-center">
                    <li> <a href="#" data-toggle="tooltip" title="" data-original-title="Share on Facebook"> <i class="fab fa-facebook-f"></i> </a> </li>
                    <li> <a href="#" data-toggle="tooltip" title="" data-original-title="Share on Twitter"> <i class="fab fa-twitter"></i> </a> </li>
                    <li> <a href="#" data-toggle="tooltip" title="" data-original-title="Share on Linkedin"> <i class="fab fa-linkedin-in"></i> </a> </li>
                </ul>
            </div>
        </div>
        <!-- Share Wishlist End -->
    </div>
</div>
<!--  Wishlist End -->
<app-insta-two></app-insta-two>
<app-footer></app-footer>