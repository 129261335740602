<div class="sidebar">

     <!-- Filter: Categories Start -->
     <div class="sidebar-widget" style="border:5px solid rgb(236, 146, 146); padding: 10px;" [ngStyle]="{'display': check_visible ? '' : 'none'}">
        <h5 style="color: rgb(179, 41, 41);font-weight: 600; text-decoration: underline;">CHECK ITEMS</h5>
        <div style="margin-top: -10px;">{{select_aantal}} product(en) geselecteerd</div>
        <div style="font-style: italic;">Categorieën / subcategorieën</div>
        <perfect-scrollbar style="height: 100px; padding-top: 10px; padding-bottom: 5px; border:2px solid rgb(236, 146, 146);">
            <div class="sidebar-widget-list" style="z-index: 10;">
                <div *ngFor="let item of info_select_view; let i = index" [ngStyle]="{'margin-top': i == 0 ? '0px' : '-10px'}" style="padding-left: 10px;">
                    <mat-checkbox (change)="check_cat2(item,$event)" [checked]="item.check">
                        <span style="font-family: 'Montserrat'; font-weight: 500; padding-right: 5px;">{{item.categorie_select}} => {{item.subcategorie_select}} ({{item.number}}x)</span>
                    </mat-checkbox>
                </div>
            </div>
          </perfect-scrollbar>
          <div style="padding-top: 5px;" [ngStyle]="{'display': cat_subcat_display ? '' : 'none'}" >
            <perfect-scrollbar style="height: 500px; padding-top: 10px; padding-bottom: 5px; border:2px solid rgb(111, 206, 111);">
                <div class="sidebar-widget-list" style="z-index: 10;">
                    <div *ngFor="let item of cat_subcat_current; let i = index" [ngStyle]="{'margin-top': i == 0 ? '0px' : '-10px'}" style="padding-left: 10px;">
                        <mat-checkbox (change)="check_cat(item,$event)" (keyup.enter)="add_cat_subcat()" [checked]="item.check">
                            <span style="font-family: 'Montserrat'; font-weight: 500; padding-right: 5px;">{{item.cat}} => {{item.subcat_name}}</span>
                        </mat-checkbox>
                    </div>
                </div>
              </perfect-scrollbar>
          </div>          
          <div class="row" style="padding-top: 10px;">
            <div class="col-lg-6">
                <a href="javascript:void(0)" (click)="add_cat_subcat()" [ngClass]="info_select.length === 0 ? 'andro_btn-custom light disabled' : 'andro_btn-custom light'" 
                 style="background-color: rgb(111, 206, 111);  border-radius: 5px; width: 100%; color: white">ADD</a>
              </div>
            <div class="col-lg-6">
                <a (click)="delete_cat_subcat()" [ngClass]="(delete_disable || info_select.length === 0) ? 'andro_btn-custom light disabled' : 'andro_btn-custom light'" 
                style="background-color: rgb(179, 41, 41); border-radius: 5px; width: 100%; color: white;">DELETE</a>
              </div>
              <div class="col-lg-12" style="padding-top: 10px;">
                <div style="font-style: italic;">Informatie</div>
                <textarea style="border:2px solid rgb(236, 146, 146); width: 100%; height: 400px;" [disabled]="!selection_one" [(ngModel)]="info"></textarea>
              </div>
              <div class="col-lg-6">
                <a [ngClass]="info_select.length === 0 ? 'andro_btn-custom light disabled' : 'andro_btn-custom light'"  (click)="save_checkdata()" 
                style="background-color: rgb(111, 206, 111);  border-radius: 5px; width: 100%; color: white">SAVE</a>
              </div>
            <div class="col-lg-6">
                <a href="javascript:void(0)" (click)="clear_selection()" 
                [ngClass]="info_select.length === 0 ? 'andro_btn-custom light disabled' : 'andro_btn-custom light'" 
                style="background-color: rgb(179, 41, 41); border-radius: 5px; width: 100%; color: white;">CANCEL</a>
              </div>
              
            
          </div>
          
    </div>
    <!-- Filter: Categories End -->


    <!-- Search Start -->
    <div class="sidebar-widget" >
            <div class="andro_search-adv-input">
                <input type="text" class="form-control" placeholder="Zoek specifiek item in selectie" id="search" name="search" [(ngModel)]="searchitem" (keyup.enter)="add_searchitem()">
                <button name="button" (click)="add_searchitem()"><i class="fa fa-search"></i></button>
            </div>
    </div>
    <!-- Search End -->
    <!-- Filter: Categories Start -->
    <div class="sidebar-widget"  style="border:5px solid rgb(204, 236, 204); padding: 10px;">
        <h5 class="widget-title">Categorie</h5>
        <perfect-scrollbar style="height: 170px;">
            <div class="sidebar-widget-list">
                <mat-checkbox (change)="remove_cat()" [checked]="all_selected" [disabled]="all_selected" >
                    <span style="font-family: 'Montserrat'; font-weight: 500;">Alle categorieën</span>
                </mat-checkbox>
                <div *ngFor="let item of list_cat; let i = index">
                    <!-- <mat-checkbox (change)="click_cat(item, $event)" [checked]="ids_selected.indexOf(i) !== -1"> -->
                     <mat-checkbox (change)="click_cat(list_cat, i, $event)" [checked]="item.checked" >
                            <span style="font-family: 'Montserrat'; font-weight: 500;">{{item.name}}</span>
                     </mat-checkbox>
                </div>
            </div>
          </perfect-scrollbar>
    </div>
    <!-- Filter: Categories End -->
    <!-- Filter: Categories Start -->
    <div class="sidebar-widget" style="padding: 10px;" [ngStyle]="{'border': current_subcat.length == 0 && cat_length == 0  ? '5px solid rgb(248, 246, 246)' : '5px solid rgb(204, 236, 204)' }">
        <h5 class="widget-title">Subcategorie</h5>
        <perfect-scrollbar style="height: 170px;">
            <div class="sidebar-widget-list" style="z-index: 10;">
                       <span style="font-family: 'Montserrat'; font-weight: 500;" [ngStyle]="{'display': current_subcat.length == 0 && cat_length != 0 ? '' : 'none'}">Geen teruggevonden</span>
                       <span style="font-family: 'Montserrat'; font-weight: 500;" [ngStyle]="{'display': current_subcat.length == 0 && cat_length == 0  ? '' : 'none'}">Selecteer aub een categorie</span>
                       <div *ngFor="let item of current_subcat; let i = index">
                            <mat-checkbox (change)="click_subcat(current_subcat, i, $event)" [checked]="item.checked">
                                <span style="font-family: 'Montserrat'; font-weight: 500;">{{item.subcat}}</span>
                            </mat-checkbox>
                       </div>
            </div>
          </perfect-scrollbar>
    </div>
    <!-- Filter: Categories End -->
    <!-- Filter: Condition Start -->
    <div class="sidebar-widget" style="border:5px solid rgb(204, 236, 204); padding: 10px;">
        <h5 class="widget-title">Speciaal voor U</h5>
        <div class="sidebar-widget-list">
            <div *ngFor="let item of list_specials; let i = index">
                <mat-checkbox (change)="click_special(list_specials, i, $event)" [checked]="item.checked">
                    <span style="font-family: 'Montserrat'; font-weight: 500;">{{item.code}}</span>
                </mat-checkbox>
            </div>
        </div>
    </div>
    <!-- Filter: Condition End -->
    <!-- Filter: State Start -->
    <div class="sidebar-widget" style="border:5px solid rgb(204, 236, 204); padding: 10px;" >
        <h5 class="widget-title">Merken</h5>
        <perfect-scrollbar style="height: 205px;">
                <div class="sidebar-widget-list">                    
                    <span style="font-family: 'Montserrat'; font-weight: 500;" [ngStyle]="{'display': current_brands.length == 0 && cat_length != 0 ? '' : 'none'}">Geen teruggevonden</span>
                    <span style="font-family: 'Montserrat'; font-weight: 500;" [ngStyle]="{'display': current_brands.length == 0 && cat_length == 0  ? '' : 'none'}">Selecteer aub een categorie</span>                   
                    <div *ngFor="let item of current_brands; let i = index">
                        <mat-checkbox (change)="click_brand(current_brands, i, $event)" [checked]="item.checked">
                            <span style="font-family: 'Montserrat'; font-weight: 500;">{{item.name}}</span>
                        </mat-checkbox>
                    </div>
                </div>
        </perfect-scrollbar>
    </div>
    <!-- Filter: State End -->
    <!-- Filter: Freshness Start -->
    <!-- <div class="sidebar-widget">
        <h5 class="widget-title"> Prijsrange </h5>
        <input type="text" class="js-range-slider" name="freshness_range" value="" data-type="double" data-min="1" data-max="100" data-from="1" data-to="100" data-grid="true" data-postfix=" €" />
    </div> -->
    <!-- Filter: Freshness End -->
    <!-- Filter: Price Start -->
    <div class="sidebar-widget" style="border:5px solid rgb(204, 236, 204); padding: 10px;">
        <h5 class="widget-title"> Prijsrange </h5>
        <!-- <input type="text" class="js-range-slider" name="freshness_range" value="" data-type="double" data-min="0" data-max="500" data-from="10" data-to="100" data-grid="true" data-postfix=" $" /> -->
        <!-- <npn-slider [minSelected]="min_price_selected" [maxSelected]="max_price_selected" [min]="min_price" [max]="max_price" (onChange)="onSliderChange($event)" [step]="20" [showStepIndicator]="true"></npn-slider> -->
        <div class="custom-slider">
            <ngx-slider [(value)]="min_price_selected" [(highValue)]="max_price_selected" #slider (userChangeEnd)="onSliderChange_max($event)" [options]="options"></ngx-slider>
        </div>
    </div>
    <!-- Filter: Price End -->
    <!-- Tags Start -->
    <!-- <div class="sidebar-widget">
        <h5 class="widget-title"> Popular Zoektermen </h5>
        <span [ngStyle]="setMyStyles_notag()">Geen teruggevonden</span>
        <div class="tagcloud" style="height: 72px;">
            <a href="javascript:void(0)" (click)="click_tag(searchitem)" [ngStyle]="setMyStyles_tag(searchitem)" *ngFor="let searchitem of current_searchs.list_search">{{searchitem}}</a>
        </div>
    </div> -->
    <!-- Tags End -->
    <!-- Newsletter Start -->
    <div class="sidebar-widget">
        <div class="andro_newsletter-form" [ngStyle]="{'border': mailing_ok == 'yippie' ? '3px solid rgba(81,189,77,255)' : (mailing_ok == 'noway' || mailing_ok == 'novalid') ? '3px solid red' : '3px solid transparent'} "  style="height: 200px; margin-top: 30px;">
            <h5>Nieuwsbrief</h5>
            <span [ngStyle]="{'display': (mailing_ok != 'yippie' && mailing_ok != 'noway' && mailing_ok != 'novalid') ? '' : 'none'} "><p> Snel op de hoogte van koopjes, nieuws, etc..<br>Schrijf je in en ontvang 5€ korting</p></span>
            <span [ngStyle]="{'display': mailing_ok == 'yippie' ? '' : 'none'} "><p> Bedankt voor de registratie<br>Check je mail voor jouw persoonlijk couponcode</p></span>
            <span [ngStyle]="{'display': mailing_ok == 'noway' ? '' : 'none'} "><p> Er is iets misgegaan<br>Dit e-mailadres is reeds geregistreerd</p></span>
            <span [ngStyle]="{'display': mailing_ok == 'novalid' ? '' : 'none'} "><p> Er is iets misgegaan<br>Dit is geen geldig e-mailadres</p></span>
            <form method="post">
                <div class="form-group">
                    <input type="email" [(ngModel)]="_mailing" class="form-control" name="newsletter_email" placeholder="e-mailadres" value="" (keyup)="check_empty($event)">
                </div>
                <!-- <button type="submit" class="andro_btn-custom secondary btn-block shadow-none" name="button">Join Newsletter</button> -->
            </form>
            <font size="1" [ngStyle]="{'display': mailing_ok == 'yippie' ? '' : 'none'} "><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" ></i></font>
            <font size="1" [ngStyle]="{'display': (mailing_ok == 'noway' || mailing_ok == 'novalid') ? '' : 'none'} "><i class="fas fa-times fa-2x" style="color: red; position: absolute; right: 30px; top: 15px;" ></i></font>
        </div>
    </div>
    <!-- Newsletter End -->
</div>