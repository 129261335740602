<app-header></app-header>

<!--Cart Start -->
<div class="container">
    <div class="section">
        <div style="margin-top: 20px;"> 
            <div class="row"> 
                <div class="form-group col-lg-12">
                    <span style="font-family: 'Montserrat'; color: blue; font-weight: 500">VORIGE BESTELLINGEN</span> 
                </div>
                <div class="form-group col-lg-12">
                    <mat-spinner [color]="'primary'" style="position: absolute; top: 20%; right: 50%; z-index: 999;" [ngStyle]="{'display': spinner_active ? '' : 'none'}"></mat-spinner>
       
                <table class="andro_responsive-table form-group col-lg-12" [ngStyle]="{'display': !geen_items ? '' : 'none'}">
                    <perfect-scrollbar >
                        <div style="max-height: 500px;">
                            <colgroup>
                                <col span="1" style="width: 8%;">
                                <col span="1" style="width: 10%;">
                                <col span="1" style="width: 58%;">
                                <col span="1" style="width: 8%;">
                                <col span="1" style="width: 8%;">
                                <col span="1" style="width: 8%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>Bestelnummer</th>
                                    <th>Datum</th>
                                    <th>Product</th>
                                    <th>Aantal</th>
                                    <th>Afgeleverd</th>
                                    <th>Opnieuw</th>
                                </tr>
                            </thead>
                        <tbody *ngFor="let lastorder of list_lastorders">
                        
                        <tr>
                            <td data-title="Bestelnummer" style="text-align: center;"> {{lastorder.bestelnummer}} </td>     
                            <td data-title="Datum"> {{lastorder.datum}} </td>   
                            <td data-title="Product">
                                <div class="andro_cart-product-wrapper">
                                    <a *ngIf="lastorder.product.photo != ''" href="javascript:void(0)" (click)="select_product(lastorder.product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true">
                                        <img src="{{lastorder.product.photo}}" alt="prod1">
                                    </a>
                                    <div class="andro_cart-product-body" *ngIf="lastorder.product.principle == 'code'">
                                        <h6> {{lastorder.product.name}} </h6>
                                    </div>
                                    <div class="andro_cart-product-body" *ngIf="lastorder.product.principle != 'code'">
                                        <h6> <a href="javascript:void(0)" (click)="select_product(lastorder.product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true">{{lastorder.product.name}}</a> </h6>
                                    </div>
                                </div>
                            </td> 
                            <td data-title="Hoeveelheid" style="text-align: center;"> {{lastorder.hoeveelheid}} </td>       
                            <td data-title="Afgeleverd" style="text-align: center;"> {{lastorder.afgeleverd}} </td>  
                            <td class="add" style="text-align: center;">
                                <a (click)="order_again(lastorder)" href="javascript:void(0)"><img src="../../../../assets/img/again.png" 
                                    matTooltip="Opnieuw bestellen" matTooltipClass="sort"></a>
                                                        
                            </td>
                        </tr>
                    
                    </tbody>
                </div>
            </perfect-scrollbar>
                
                </table>   
                
                <div class="form-group col-lg-12" [ngStyle]="{'display': geen_items ? '' : 'none'}" >
                    <span style="font-family: 'Montserrat'; color: blue;">Geen vorige bestellingen teruggevonden</span> 
                </div>
    
        </div>
        </div>
    
    
    </div>    
</div>    
</div>

<!-- Cart form End -->
<app-insta-two></app-insta-two>
<app-footer></app-footer>
