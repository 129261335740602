<div id="top"></div>
<app-header></app-header>

<!--Cart Start -->
<div class="section">
    <div class="container">

        <div class="container" [ngStyle]="{'display': betaald == 'al getoond' ? 'none' : ''}">
            <div class="row align-items-center">
                <div class="col-lg-12" [ngStyle]="{'display': betaald == 'betaald' ? '' : 'none'}">
                    <div class="section-title-wrap mr-lg-30" >
                        <h3 class="title" style="margin-left: 20px;">Ordernummer <span class="custom-primary">{{id}}</span></h3>
                        <p class="subtitle" style="margin-left: 20px;">
                            De betaling voor ordernummer {{id}} is <span class="custom-primary">GELUKT</span>.<br>
                            Bedankt voor Uw bestelling bij APO@HOME.<br>
                            Controleer Uw mailadres voor verdere inlichtingen <br> en de voortgang van Uw bestelling.<br>
                            We zorgen voor een spoedige levering! <br><br>
                            Tot gauw.<br>
                            Team APO@HOME            
                        </p>
                    </div>
                </div>
                <div class="col-lg-12" [ngStyle]="{'display': betaald == 'niet betaald' ? '' : 'none'}" style="background-image: url('assets/img/fail.png'); background-size: 220px;
                background-position: 50% 50%; background-repeat: no-repeat;">
                    <div class="section-title-wrap mr-lg-30" >
                        <h3 class="title" style="margin-left: 20px;">Ordernummer <span class="custom-primary">{{id}}</span></h3>
                        <p class="subtitle" style="margin-left: 20px;">
                            De betaling voor ordernummer {{id}} is <span class="custom-primary" style="color: red !important; font-weight: bold">MISLUKT</span>.<br>
                            Gelieve het bedrag van {{som}}€ op het <br> 
                            rekeningnummer BE82.0689.3296.9568 te storten <br> 
                            met als vermelding 'ordernummer {{id}}'.<br><br>
                            Met vriendelijke groeten.<br>
                            Team APO@HOME            
                        </p>
                    </div>
                </div>
            </div>
            <div id="pot" [ngStyle]="{'display': betaald == 'betaald' ? '' : 'none'}"><img style="height: 300px;" src="assets/img/levering.png" alt=""></div>
        </div>

        <div class="container" [ngStyle]="{'display': betaald == 'al getoond' ? '' : 'none'}" >
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="section-title-wrap mr-lg-30" >
                        <h3 class="title" style="margin-left: 20px;">ERROR</h3>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Cart form End -->
<app-insta-two></app-insta-two>
<app-footer></app-footer>