<div id="top"></div>
<app-header [page]="'home'" ></app-header>
<!-- Category Megamenu & Banner start -->
<div class="section">
    <div class="container">
        <div class="row">
            <!-- Menu Start -->
            <div class="col-lg-3">
                <div class="andro_category-mm">
                    <div class="andro_category-mm-header">
                        <h6 style="font-weight: normal;"> <i class="fas fa-th-large"></i> Categorieën </h6>
                    </div>
                    <div class="andro_category-mm-body">
                        <ul>
                           
                            <li class="andro_category-mm-item"> <a routerLink='/shop-v1' (click)="change_page('Allergie')"> Allergie </a> </li>
                            <li class="andro_category-mm-item"> <a routerLink='/shop-v1' (click)="change_page('Baby')"> Baby</a> </li>
                            <li class="andro_category-mm-item"> <a routerLink='/shop-v1' (click)="change_page('Cosmetica')"> Cosmetica</a> </li>
                            <li class="andro_category-mm-item"> <a routerLink='/shop-v1' (click)="change_page('Fytotherapie')"> Fytotherapie</a> </li>
                            <li class="andro_category-mm-item"> <a routerLink='/shop-v1' (click)="change_page('Geneesmiddelen')"> Geneesmiddelen</a> </li>
                            <li class="andro_category-mm-item"> <a routerLink='/shop-v1' (click)="change_page('Vitamines')"> Vitamines</a> </li>
                            <li class="andro_category-mm-item"> <a routerLink='/shop-v1' (click)="change_page('Seksualiteit')"> Seksualiteit</a> </li>
                            
                            <!-- Level 1 -->
                            <!-- <li class="andro_category-mm-item andro_category-mm-item-has-children">
                                <a href="#"> Food </a>
                                <ul class="andro_category-mm-2-cols">
                                     Level 2 
                                    <li>
                                        <div class="andro_category-mm-banner">
                                            <img src="assets/img/megamenu/1.jpg" alt="megamenu img">
                                            <div class="andro_category-mm-banner-desc">
                                                <h6>Food</h6>
                                                <p>Experience organic food like never before</p>
                                            </div>
                                        </div>
                                        <ul>
                                            <li> <a href="#">Vegetables & Fruits</a> </li>
                                            <li> <a href="#">Dairy</a> </li>
                                            <li> <a href="#">Vegan Dairy</a> </li>
                                            <li> <a href="#">Meats & Fish</a> </li>
                                            <li> <a href="#">Breads & Cereal</a> </li>
                                            <li> <a href="#">Honey</a> </li>
                                            <li> <a href="#">Jam & Spreads</a> </li>
                                            <li> <a href="#">Legumes</a> </li>
                                            <li> <a href="#">Oils</a> </li>
                                        </ul>
                                        <ul>
                                            <li> <a href="#">Beans</a> </li>
                                            <li> <a href="#">Vegan Food</a> </li>
                                            <li> <a href="#">Distillates</a> </li>
                                            <li> <a href="#">Eggs</a> </li>
                                            <li> <a href="#">Snacks</a> </li>
                                            <li> <a href="#">Syrups</a> </li>
                                            <li> <a href="#">Pickles</a> </li>
                                            <li> <a href="#">Wines & Spirit</a> </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li> -->
                            
                            <li class="andro_category-mm-item andro_category-mm-item-has-children andro_category-mm-item-expand">
                                <a href="javascript:void(0);"> <i class="fas fa-plus"></i> Meer categorieën</a>
                                <ul class="andro_category-mm-2-cols" style="height: 550px">
                                    <!-- Level 2 -->
                                    <li>                                        
                                        <ul>       
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Gewicht')">Gewicht</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Haar')">Haar</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Huid')">Huid</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Maag')">Maag- & Darmklachten</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Mond')">Mond</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Nagel')">Nagel</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Oog')">Oog</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Pijn')">Pijn & Ontsteking</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Stres')">Stress & Slaap</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Rookstop')">Rookstop</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Verkoudheid')">Verkoudheid</a> </li>
                                            <li> <a routerLink='/shop-v1' (click)="change_page('Wondzorg')">Wondzorg</a> </li>
                                        </ul>                                      
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Menu End -->
            <!-- Banner Start -->
            <div class="col-lg-9" style="position: relative;">
                <!-- <div class="andro_flex-menu d-none d-md-block" style="margin-top: 10px;">
                    <ul>
                        <li class="menu-item" style="z-index: 20"> <a routerLink='/home-v1'><span style="font-weight: 600 !important; font-size: 15px; color: rgba(81,189,77,255);" class="hoveritem" (click)="change_page('koopjes')">KOOPJES</span></a> </li>
                        <li class="menu-item" style="z-index: 20"> <a routerLink='/home-v1'><span style="font-weight: 600 !important; font-size: 15px; color: rgba(81,189,77,255); " class="hoveritem" (click)="change_page('kijker')">IN DE KIJKER</span></a> </li>
                        <li class="menu-item" style="z-index: 20"> <a routerLink='/home-v1'><span style="font-weight: 600 !important; font-size: 15px; color: rgba(81,189,77,255); " class="hoveritem" (click)="change_page('nieuw')">NIEUW</span></a> </li>
                    </ul>
                </div> -->
                <img [ngStyle]="{'display': spinner1_active ? '' : 'none'}" style="margin-top: -50px; left: 100px; z-index:10; position: absolute; opacity: 0.5; height: 450px;" src="assets/img/logo.gif">
                <ngx-slick-carousel class="carousel" #slickModal1="slick-carousel" [config]="slideConfig" style=" border:2px solid #50BD4D; padding: 20px; border-radius: 0.5em; height: 435px">
                    <div ngxSlickItem *ngFor="let deal of list_deals" class="slide">
                        <div class="container-fluid" style="height: 370px;">
                            <div class="row align-items-center" style="height: 350px;">
                                <div class="col-lg-6">
                                    <span [ngStyle]="{'display': deal.percentage == 0 ? 'none' : ''}"><h2>{{deal.percentage}}% korting <span class="fw-400">op {{deal.name}}</span> </h2></span>
                                    <span [ngStyle]="{'display': deal.percentage != 0 ? 'none' : ''}"><h2>{{deal.number_to_free}} + 1 gratis <span class="fw-400"> {{deal.name}}</span> </h2></span>
                                    <p style="color: darkgray">{{deal.info_short}}</p>
                                    <p style="color: black">{{deal.info_long}}</p>
                                    <a *ngIf="deal._product.product_collection == null" href="javascript:void(0)" (click)="select_product(deal._product)" class="andro_btn-custom" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true">koop nu</a>
                                    <a *ngIf="deal._product.product_collection != null" routerLink='/shop-v1' (click)="change_page(deal._product.product_collection)" class="andro_btn-custom">koop nu</a>
                                </div>
                                <div class="col-lg-6 d-none d-lg-block">
                                    <img src="{{deal._product.photo}}" alt="banner img">
                                </div>
                            </div>
                        </div>                        
                    </div>
                </ngx-slick-carousel>
            </div>
            <!-- Banner End -->
        </div>
    </div>
</div>
<!-- Category Megamenu & Banner end -->
<!-- Icons Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="andro_icon-block" style="height: 210px;">
                    <i class="flaticon-call"></i>
                    <h5>Een luisterend oor</h5>
                    <p>Gediplomeerde zorgverstrekkers zijn steeds bereikbaar om U persoonlijk bij te staan met raad en daad (9u-18u op weekdagen).</p>
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <rect height="500" width="500" class="andro_svg-stroke-shape-anim"></rect>
                    </svg>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="andro_icon-block" style="height: 210px;">
                    <i class="flaticon-location"></i>
                    <h5>Vlotte levering</h5>
                    <p>Apoathome heeft een sterke focus op een vlotte thuislevering, gratis in Buggenhout, Dendermonde en Lebbeke.</p>
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <rect height="500" width="500" class="andro_svg-stroke-shape-anim"></rect>
                    </svg>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="andro_icon-block" style="height: 210px;">
                    <i class="flaticon-tag"></i>
                    <h5>Lage prijs</h5>
                    <p>De combinatie van gratis of lage leveringskost en grote kortingen op koopjes maken het verschil.  </p>
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <rect height="500" width="500" class="andro_svg-stroke-shape-anim"></rect>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Icons End -->
<!-- Products Start -->
<div class="section pt-0 andro_fresh-arrivals" style="position: relative; height: 700px;" [ngStyle]="{'display': list_freshs.length == 0 ? 'none' : ''}">
    <img [ngStyle]="{'display': spinner2_active ? '' : 'none'}" style="margin-top: 30px; left: 500px; z-index:10; position: absolute; opacity: 0.5; height: 450px;" src="assets/img/logo.gif">
    <div class="container">
        <div class="section-title flex-title">
            <h4 class="title">Nieuw</h4>
            <div class="andro_arrows">
                <i class="fa fa-arrow-left slick-arrow slider-prev" (click)="prev()"></i>
                <i class="fa fa-arrow-right slick-arrow slider-next" (click)="next()"></i>
            </div>
        </div>
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig1">
            <div ngxSlickItem *ngFor="let fresh of list_freshs" class="slide" >
                <div class="andro_product andro_product-has-controls andro_product-has-buttons" style="height: 550px;" >
                    <div class="andro_product-badge andro_badge-featured" [ngStyle]="{'display': fresh._product.special != '' ? '' : 'none'}">
                        <span>{{fresh._product.special}}</span>
                    </div>
                    <div class="andro_product-thumb">
                        <a href="javascript:void(0)" (click)="select_product(fresh._product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true"><img src="{{fresh._product.photo}}" alt="product"></a>
                    </div>
                    <div class="andro_product-body" style="height: 180px ;">
                        <h5 class="andro_product-title"> <a href="javascript:void(0)" (click)="select_product(fresh._product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true"> {{fresh.name}} </a> </h5>
                        <div class="andro_product-price">
                            <span>{{fresh._product.price}}€</span>
                            <span [ngStyle]="{'display': fresh._product.percentage != 0 ? '' : 'none'}">{{fresh._product.price_ori}}€</span>
                        </div>
                        <p>{{fresh.info_short}}</p>                    
                    </div>
                    <div class="andro_product-footer">
                        <div class="andro_product-buttons">
                            <a href="javascript:void(0)" (click)="select_product(fresh._product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true" class="andro_btn-custom primary">Detail</a>
                            <a href="#" (click)="add_to_cart(fresh._product)" class="andro_btn-custom light">Koop nu</a>
                        </div>
                    </div>
                </div>                        
            </div>
        </ngx-slick-carousel>        
    </div>
</div>
<!-- Products End -->
<div class="container">
    <div class="section pt-0 andro_fresh-arrivals" style="position: relative; height: 700px;" [ngStyle]="{'display': list_attentions.length == 0 ? 'none' : ''}">
        <img [ngStyle]="{'display': spinner3_active ? '' : 'none'}" style="margin-top: 60px; left: 330px; z-index:10; position: absolute; opacity: 0.5; height: 450px;" src="assets/img/logo.gif">
            <div class="section-title flex-title">
                <h4 class="title">In de kijker</h4> 
                <div class="andro_arrows">
                    <i class="fa fa-arrow-left slick-arrow slider-prev" (click)="prev2()"></i>
                    <i class="fa fa-arrow-right slick-arrow slider-next" (click)="next2()"></i>
                </div>               
            </div>
            <ngx-slick-carousel class="carousel" #slickModal2="slick-carousel" [config]="slideConfig1">
                <div ngxSlickItem *ngFor="let attention of list_attentions" class="slide" >
                    <div class="andro_product andro_product-has-controls andro_product-has-buttons" style="height: 550px;" >
                        <div class="andro_product-badge andro_badge-featured" [ngStyle]="{'display': attention._product.special != '' ? '' : 'none'}">
                            <span>{{attention._product.special}}</span>
                        </div>
                        <div class="andro_product-thumb">
                            <a href="javascript:void(0)" (click)="select_product(attention._product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true"><img src="{{attention._product.photo}}" alt="product"></a>
                        </div>
                        <div class="andro_product-body" style="height: 180px ;">
                            <h5 class="andro_product-title"> <a href="javascript:void(0)" (click)="select_product(attention._product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true"> {{attention.name}} </a> </h5>
                            <div class="andro_product-price">
                                <span>{{attention._product.price}}€</span>
                                <span [ngStyle]="{'display': attention._product.percentage != 0 ? '' : 'none'}">{{attention._product.price_ori}}€</span>
                            </div>
                            <p>{{attention.info_short}}</p>                    
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="javascript:void(0)" (click)="select_product(attention._product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true" class="andro_btn-custom primary">Detail</a>
                                <a href="#" (click)="add_to_cart(attention._product)" class="andro_btn-custom light">Koop nu</a>
                            </div>
                        </div>
                    </div>                        
                </div>
            </ngx-slick-carousel>          
    </div>
</div>
<!-- Call to action Start -->
<div class="section pt-0">
    <div class="container">
        <div class="andro_cta-notice secondary-bg pattern-bg">
            <div class="andro_cta-notice-inner">
                <h3 class="text-white">5€ beginnerskorting</h3>
                <p class="text-white"style="font-size: normal">Gebruik de promocode <span style="font-style: italic;">NEO22</span> op Uw eerste bestelling *</p>
                <p class="text-white" style="font-size: small;">* enkel voor nieuwe klanten vanaf een aankoopbedrag van 50€</p>
                <a routerLink='/shop-v1' (click)="change_page('')" class="andro_btn-custom light">Ondek onze producten</a>
            </div>
        </div>
    </div>
</div>
<!-- Call to action End -->
<!-- Blog Posts Start -->
<div class="section pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="section-title">
                    <h4 class="title">Informatie</h4>
                </div>
                <img [ngStyle]="{'display': spinner4_active ? '' : 'none'}" style="margin-top: 60px; left: 70px; z-index:10; position: absolute; opacity: 0.5; height: 450px;" src="assets/img/logo.gif">
                <div class="row">
                    <!-- Article Start -->
                    <div class="col-md-6" *ngFor="let item of list_info">
                            <article class="andro_post" >
                                <div class="andro_post-thumb">
                                    <a routerLink='/blog-single' (click)="select_info(item.id)">
                                        <img src="{{item.photo}}" alt="post">
                                    </a>
                                </div>
                                <div class="andro_post-body">   
                                    <div class="andro_post-categories">
                                        <a routerLink='/shop-v1' (click)="change_page(item.category)">{{item.category}}</a>
                                    </div>
                                    <div class="andro_post-desc" style="height: 225px;">
                                        <h5> <a routerLink='/blog-single' (click)="select_info(item.id)">{{item.title}}</a> </h5>
                                        <div [innerHTML]="item.text_intro"></div>
                                    </div>
                                    <a class="andro_btn-custom btn-block" routerLink='/blog-single' (click)="select_info(item.id)"> Lees meer </a>
                                </div>
                            </article>                        
                    </div>
                    <!-- Article End -->                    
                </div>
            </div>
            <!-- Sidebar Start -->
            <div class="col-lg-4">
                <div class="sidebar">
                    <!-- Testimonials Start -->
                    <!-- <div class="sidebar-widget">
                        <div class="section-title">
                            <h4 class="title">Testomonials</h4>
                        </div>
                        <div class="andro_testimonials">
                            <div class="andro_testimonial-item">
                                <div class="andro_testimonials-inner">
                                    <img src="assets/img/people/4.jpg" alt="author">
                                    <h5>Jina Flock</h5>
                                    <span>Farmer</span>
                                    <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec sollicitudin molestie malesuada. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>
                                </div>
                                <ul class="andro_sm">
                                    <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                                    <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                                    <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
                                    <li> <a href="#"> <i class="fab fa-youtube"></i> </a> </li>
                                </ul>
                            </div>
                            <div class="andro_testimonial-item">
                                <div class="andro_testimonials-inner">
                                    <img src="assets/img/people/3.jpg" alt="author">
                                    <h5>Miranda Blue</h5>
                                    <span>Chemist</span>
                                    <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec sollicitudin molestie malesuada. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>
                                </div>
                                <ul class="andro_sm">
                                    <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                                    <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                                    <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
                                    <li> <a href="#"> <i class="fab fa-youtube"></i> </a> </li>
                                </ul>
                            </div>
                            <div class="andro_testimonial-item">
                                <div class="andro_testimonials-inner">
                                    <img src="assets/img/people/2.jpg" alt="author">
                                    <h5>Feleciti Rolling</h5>
                                    <span>Farmer</span>
                                    <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec sollicitudin molestie malesuada. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>
                                </div>
                                <ul class="andro_sm">
                                    <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                                    <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                                    <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
                                    <li> <a href="#"> <i class="fab fa-youtube"></i> </a> </li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                    <!-- Testimonials End -->
                    <!-- Image Start -->
                    <div class="sidebar-widget d-none d-lg-block">
                        <div class="andro_auth-mini" style="margin-top: 94px; height: 370px;">
                            <h5>Inloggen</h5>
                            <form method="post" [formGroup]="form_login">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="e-mailadres" name="username" value="" formControlName="login" >
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" placeholder="Paswoord" name="password" value="" formControlName="pwd">
                                </div>
                                <div class="form-group text-center">
                                    <a href="javascript:void(0)" data-toggle="modal" data-target="#pwd_forgotten" >Paswoord vergeten?</a>
                                </div>
                                <button [disabled]="ok_disable_fb" type="submit" class="andro_btn-custom primary btn-block" style="margin-top: -10px;" (click)="login()">Login</button>
                                <!-- <div class="andro_auth-seperator">
                                    <span>OR</span>
                                </div>
                                <div class="andro_social-login">
                                    <button type="button" class="andro_social-login-btn facebook"><i class="fab fa-facebook-f"></i> Continue with Facebook </button>
                                    <button type="button" class="andro_social-login-btn google"><i class="fab fa-google"></i> Continue with Google</button>
                                </div> -->
                                <p></p>
                                <p class="mb-0 text-center">Heb je geen account? <a href="javascript:void(0)" data-toggle="modal" data-target="#Accountmodal">Registreer je</a> </p>
                            </form>
                        </div>
                        <div class="andro_newsletter-form" [ngStyle]="{'border': mailing_ok == 'yippie' ? '3px solid rgba(81,189,77,255)' : (mailing_ok == 'noway' || mailing_ok == 'novalid') ? '3px solid red' : '3px solid transparent'} "  style="height: 200px; margin-top: 30px; position: relative;">
                            <h5>Nieuwsbrief</h5>
                            <span [ngStyle]="{'display': (mailing_ok != 'yippie' && mailing_ok != 'noway' && mailing_ok != 'novalid') ? '' : 'none'} "><p> Snel op de hoogte van koopjes, nieuws, etc..<br>Schrijf je in en ontvang 5€ korting</p></span>
                            <span [ngStyle]="{'display': mailing_ok == 'yippie' ? '' : 'none'} "><p> Bedankt voor de registratie<br>Check je mail voor jouw persoonlijk couponcode</p></span>
                            <span [ngStyle]="{'display': mailing_ok == 'noway' ? '' : 'none'} "><p> Er is iets misgegaan<br>Dit e-mailadres is reeds geregistreerd</p></span>
                            <span [ngStyle]="{'display': mailing_ok == 'novalid' ? '' : 'none'} "><p> Er is iets misgegaan<br>Dit is geen geldig e-mailadres</p></span>
                            <mat-spinner style="position: absolute; top: 50%; left: 50%; color: green" [ngStyle]="{'display': spinner_active? '' : 'none'}" [diameter]="30"></mat-spinner>
                            <form method="post">
                                <div class="form-group">
                                    <input type="email" [(ngModel)]="_mailing" class="form-control" name="newsletter_email" placeholder="e-mailadres" value="" (keyup)="check_empty($event)">
                                </div>
                                <!-- <button type="submit" class="andro_btn-custom secondary btn-block shadow-none" name="button">Join Newsletter</button> -->
                            </form>
                            <font size="1" [ngStyle]="{'display': mailing_ok == 'yippie' ? '' : 'none'} "><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" ></i></font>
                            <font size="1" [ngStyle]="{'display': (mailing_ok == 'noway' || mailing_ok == 'novalid') ? '' : 'none'} "><i class="fas fa-times fa-2x" style="color: red; position: absolute; right: 30px; top: 15px;" ></i></font>
                        </div>
                    </div>
                    <!-- Image End -->
                </div>
            </div>
            <!-- Sidebar End -->
        </div>
    </div>
</div>
<!-- Blog Posts End -->
<app-insta-two></app-insta-two>
<app-footer (to_top)="to_top()"></app-footer>
