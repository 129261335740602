<div id="top"></div>
<app-preloader></app-preloader>
<app-header [page]="'shop1'"></app-header>
<!-- Subheader Start -->
<!-- <div class="andro_subheader pattern-bg primary-bg" style="z-index: 100;">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1>{{categorie}}</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{categorie}}</li>
                </ol>
            </nav>
        </div>
    </div>
</div> -->
<!-- Subheader End -->
<!-- Products Start -->
<div class="section" [ngClass]="{'achtergrond_dieren': categorie_single === 'Dieren & Insecten', 'achtergrond_firstaid': categorie_single === 'Eerste hulp'}">
    <div class="container" >
        <div class="row">
            <div class="col-lg-8">
                <!-- Product Count & Orderby Start -->
                <div class="andro_shop-global" style="background-color: rgb(204, 236, 204) !important;" >
                    <!-- <p><b>{{aantal}}</b> van <b>{{totaal_aantal}}</b> producten tonen</p> -->
                    <ul class="pagination" style="margin-top: -2px;padding-top: 30px" [ngStyle]="setMyStyles_nopagination()">
                        <!-- <span style="color: #50BD4D; font-size: 30px; margin-top: -10px;" [ngStyle]="setMyStyles_prev()" >...</span> -->
                        <li [ngStyle]="setMyStyles_prev2()" [ngClass]="(link.active)?'page-item active':'page-item'" style="padding-right: 5px;" *ngFor="let link of list_page_def; let i = index">
                            <a class="page-link" (click)="nxt_page(i, list_page_def)">
                                <span [ngStyle]="{'display': (link.label === 'volgende' || link.label === 'vorige') ? 'none' : ''}">{{link.label}}</span>
                                <font size="1" [ngStyle]="{'display': link.label === 'volgende' ? '' : 'none'}"><i style="margin-left: -2px;" class="fas fa-angle-right fa-2x"></i></font>
                                <font size="1" [ngStyle]="{'display': link.label === 'vorige' ? '' : 'none'}"><i style="margin-left: -2px;" class="fas fa-angle-left fa-2x"></i></font>
                            </a>
                        </li>
                        <!-- <span style="color: #50BD4D; font-size: 30px; margin-top: -10px; padding-left: 10px;" [ngStyle]="setMyStyles_nxt()" >...</span> -->
                    </ul>  
                    <mat-checkbox [ngStyle]="{'display': check_visible ? '' : 'none'}" (change)="check_all($event)" [checked]="checked"> SELECT ALL </mat-checkbox>
                    <mat-checkbox [ngStyle]="{'display': check_visible ? '' : 'none'}" (change)="get_to_check($event)" [checked]="to_checked"> TO CHECK </mat-checkbox>
                    <button style="background: transparent; border: none !important; font-size: 0; outline: none;" #btn></button>
                    <ul class="pagination" style="float: right; margin-top: -2px; padding-top: 30px;">
                        <li style="padding-right: 5px;" class="page-item" matTooltip="filter verwijderen" matTooltipClass="filter" [ngStyle]="setMyStyles_filter()" >
                            <a (click)='filter_verwijderen()'>
                                <div style="height: 52px; width: 52px; border-radius: 10px; background-color: #e4281b; border: 15px solid #e4281b;">
                                    <font size="1"> <i style="color: white" class="fas fa-sort-amount-down-alt fa-2x"></i></font>
                                </div>
                            </a>
                        </li>
                        <li style="padding-right: 5px; height: 52px; width: 52px; z-index: 100;" [ngClass]="noproduct ? 'page-item inactive' : sort_active == 'm' ? 'page-item active' : 'page-item'" matTooltip="Goedkoopste items" matTooltipClass="sort">
                            <a class="page-link" (click)="sort('m')"> <font size="1"> <i style="margin-left: -2px;" class="fas fa-euro-sign fa-2x"></i></font></a>
                        </li>
                        <li style="padding-right: 5px;  height: 52px; width: 52px" [ngClass]="noproduct ? 'page-item inactive' : sort_active == 'n' ? 'page-item active' : 'page-item'" matTooltip="Nieuwste items" matTooltipClass="sort">
                            <a class="page-link" (click)="sort('n')"> <font size="1"><i style="margin-left: -6px;" class="fas fa-star fa-2x"></i></font></a>
                        </li>
                        <li style="height: 52px; width: 52px" [ngClass]="noproduct ? 'page-item inactive' : sort_active == 'p' ? 'page-item active' : 'page-item'" matTooltip="Populairste items" matTooltipClass="sort">
                            <a class="page-link" (click)="sort('p')"><font size="1"><i style="margin-left: -2px;" class="far fa-thumbs-up fa-2x"></i></font></a>
                        </li>
                    </ul> 
                    <span style="font-size: 20px; font-style: italic; font-weight: bold; color: #5d5d5d ; position: absolute; left: 50px; top: 4px; font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
                        {{categorie_txt}}  <span style="padding-left: 10px;" [ngStyle]="{'display': categorie_txt.substring(0,4) == 'Zoek'? '': 'none'}"> <a href="javascript: void(0)" (click)="clear_search()">(verwijderen)</a></span>
                    </span>
                        

                         
                    <!-- <form method="post">
                        <select class="form-control" name="orderby">
                            <option value="">Default sorteren</option>
                            <option value="n">Nieuwste items</option>
                            <option value="m">Goedkoopste items</option>
                            <option value="p">Populairste items</option>
                        </select>
                    </form> -->
                </div>                
                <!-- Product Count & Orderby End -->
                <div class="row masonry">    
                    <span [ngStyle]="setMyStyles_noproduct()"><i class="far fa-frown fa-2x" style="padding-right: 5px; margin-top: 10px"></i>
                        Geen producten teruggevonden <i class="far fa-frown fa-2x" style="padding-left: 5px; margin-top: 10px"></i> </span>
                    <!-- <mat-spinner [ngStyle]="setMyStyles_spinner()"></mat-spinner> -->
                    <!-- <sa-spinner-diamond [ngStyle]="setMyStyles_spinner()" [size]="100" [thickness]="180" [speed]="154" color="rgba(81,189,77,255)" secondaryColor="rgba(57, 172, 58, 0.1)" ></sa-spinner-diamond> -->
                    <img [ngStyle]="setMyStyles_spinner()" style="margin-left:100px; margin-top: -30px; width: 70%; z-index:99; position: absolute; opacity: 0.5;" src="assets/img/logo.gif">
                    <!-- <sa-spinner-dotted [ngStyle]="setMyStyles_spinner()" [size]="150" [thickness]="180" [speed]="350" color="rgba(57, 172, 118, 1)" ></sa-spinner-dotted>  -->                  
                        <div class="col-sm-6 masonry-item" *ngFor="let product of list_products">                        
                        <div class="andro_product" [ngStyle]="{'background-color': product.check_select ? 'rgb(204, 236, 204)' : 'transparent'}">
                            <div class="andro_product-badge andro_badge-featured" [ngStyle]="setMyStyles_special(product)">
                                <span>{{product.special}}</span>
                            </div>
                            <div class="andro_product-thumb">
                                <a href="javascript:void(0)" (click)="select_product(product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true"><img src="{{product.photo}}" alt="product"></a>
                            </div>
                            <div class="andro_product-body" style="height: 65px;">
                                <h5 class="andro_product-title" style="font-size: 20px;"> <a href="javascript:void(0)" (click)="select_product(product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true"
                                    [ngStyle]="{'color': !check_visible ? 'black' : product.category == null ? 'red' : (product.principle === null || product.principle === '') && (product._use === null || product._use === '')
                                    && (product.composition === null || product.composition === '') && (product.principle2 == null || product.principle2 === '') ? 'blue' : 'black'}">
                                     {{product.name}} <span [ngStyle]="{'display': check_visible ? '' : 'none'}">({{product.cnk}})</span> </a> </h5>
                                <!-- <p>{{product.info_short_product}}</p> -->
                                <!-- <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                    <span>4 Stars</span>
                                </div> -->
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>{{product.price}}€</span>
                                    <span [ngStyle]="setMyStyles_discount(product)">{{product.price_ori}}€</span>
                                </div>
                                <mat-checkbox [ngStyle]="{'display': check_visible ? '' : 'none'}" (change)="check_products(product,$event)" [checked]="product.check_select"> select </mat-checkbox>
                                <div class="btn-group">
                                    <a href="javascript:void(0)" (click)="select_product(product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true" class="andro_btn-custom btn-sm" style="border-radius: 5px;">Koop nu</a>
                                    <!-- <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="sr-only">Toggle Dropdown</span>
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                        <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                        <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <!-- Product End -->
            </div>
                <div [ngStyle]="{'display': (spinner_active || noproduct) ? 'none' : ''}"  style="background-color: rgb(204, 236, 204) !important; height: 70px; padding: 0; padding-top: 10px;">
                   <!-- <perfect-scrollbar style="width: 150px;" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: true}"> -->
                    <div>
                        <ul class="pagination" [ngStyle]="setMyStyles_nopagination2()" style="margin-top: 0px;">
                            <li  [ngClass]="(link.active)?'page-item active':'page-item'" style="margin-left: 3px !important;" *ngFor="let link of list_page_down; let i = index">
                                <a class="page-link" href="javascript:void(0)" (click)="nxt_page(i,list_page_down)">{{link.label}}</a>
                            </li>
                        </ul> 
                    </div>                    
                  <!-- </perfect-scrollbar> -->
                </div>  

                
            </div>
            
            <!-- Sidebar Start -->
            <div class="col-lg-4">
                <app-shop-sidebar (filteractive)="change_filteractive($event)" (reloadproducts)="reload_products()" (loadproducts)="load_products($event)" (clearselection)="clear_selection($event)"></app-shop-sidebar>
            </div>
            <!-- Sidebar End -->
        </div>
    </div>
</div>
<!-- Products End -->
<app-insta-two></app-insta-two>
<app-footer (to_top)="to_top()"></app-footer>
