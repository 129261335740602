import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import * as firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, iif, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ApoService } from './apo.service';
import { account } from './model';
import { User } from './shared/services/user';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  private destroy$: Subject<void> = new Subject<void>();
  api = "";
  account1: account = {lname: '', fname: '', mail: '', telefoon: '', RR: '', land: '', straat: '', nummer: '', bus: '', postcode: '', gemeente: '', info: '', 
  nieuw: true, logged_in: false, failed_login:0, uid: "0", bedrijfsnaam: '',vertegenwoordiger: '', mail_fac: '', land_fac: '',straat_fac: '', 
  nummer_fac: '', bus_fac: '', postcode_fac: '', gemeente_fac: '', id: 0 };  

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public http: HttpClient,
    public _aposervice: ApoService,
    private toastr: ToastrService
  ) {    
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {             
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));   
        var _uid = JSON.parse(JSON.stringify(user)).uid;
        var _lname = JSON.parse(JSON.stringify(user)).displayName;
        var _mail = JSON.parse(JSON.stringify(user)).email; 
        this._aposervice.login_auth(_uid, _mail, _lname);
           
      } 
      else {
        localStorage.setItem('user', null);      
      }
    })

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data=>{
      this.api = data;
    })

    _aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.account1 = data;
    });
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
        });
        this.SetUserData(result.user);
        this.toastr.success('Succesvol ingelogd', 'Gelukt', {timeOut: 3000, positionClass: 'toast-bottom-center'})
      }).catch((error) => {
        this.error_subject.next(error.message);
        var account: account = {lname: '', fname: '', mail: '', telefoon: '', RR: '', land: '', straat: '', nummer: '', bus: '', postcode: '', gemeente: '', 
        info: '', nieuw: true, logged_in: false, failed_login: 0, uid: "0", bedrijfsnaam: '',vertegenwoordiger: '', mail_fac: '', land_fac: '',straat_fac: '', 
        nummer_fac: '', bus_fac: '', postcode_fac: '', gemeente_fac: '', id: 0 };
        this._aposervice.change_account(account);
        this._aposervice.change_modusaccount('');
        this._aposervice.set_mailblock(false);
      })
  }

  _error: string = '';  
  private error_subject = new BehaviorSubject<string>(this._error);
  current_error = this.error_subject.asObservable();

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        //this.SendVerificationMail();
        this.SetUserData(result.user);
        this.account1.uid = JSON.parse(JSON.stringify(result.user)).uid;
        this._aposervice.create_account(this.account1);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  async SendVerificationMail() {
    return (await this.afAuth.currentUser).sendEmailVerification()
    .then(() => {
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {console.log('mail verstuurd');
    }).catch((error) => {
      window.alert(error)
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new firebase.default.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
       this.ngZone.run(() => {
          this.router.navigate(['']);
        })
      this.SetUserData(result.user);

      var _uid = JSON.parse(JSON.stringify(result.user)).uid;
      var _lname = JSON.parse(JSON.stringify(result.user)).displayName;
      var _mail = JSON.parse(JSON.stringify(result.user)).email; 
      this._aposervice.login_auth(_uid, _mail, _lname);

    }).catch((error) => {
      window.alert(error)
    })
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out 
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
    })
  }

  //categorie tweede pagina
  logged_in = false;
  private logged_in_source = new BehaviorSubject<Boolean>(this.logged_in);
  current_logged_in = this.logged_in_source.asObservable();

  change_loggedin_status(_logged_in: Boolean){
    this.logged_in_source.next(_logged_in);
    console.log(_logged_in);

  }
  

}