import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { account, cartitem, kortingscode, Product } from 'src/app/model';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-cartitems',
  templateUrl: './cartitems.component.html',
  styleUrls: ['./cartitems.component.css']
})
export class CartitemsComponent implements OnInit {

  private destroy$: Subject<void> = new Subject<void>();
  list_cart: cartitem[] = [];
  totaal = 0;
  totaal_btw = 0;
  korting = false;
  kortingscodes = [];
  code_txt = '';
  code_used = false;
  _api = "";
  current_emailadres = "";
  @ViewChild('btn') btn: ElementRef;


  constructor(public _aposervice: ApoService, public router: Router, private toastr: ToastrService, public formBuilder: FormBuilder,
    public http: HttpClient,  ) { 

    _aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.current_emailadres = data.mail;
    })      

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this._api = data;
    })

    _aposervice.current_mandje.pipe(takeUntil(this.destroy$)).subscribe(data => {
      
      this.totaal = 0;
      this.totaal_btw = 0;
      this.list_cart = data;      

      for (let x of data){        
        x.product.name = x.product.name.substring(x.product.name.indexOf('_'));
        this.totaal = this.totaal + x.product.price*x.quantity;
        this.totaal_btw = this.totaal_btw + (x.quantity*x.product.price*x.product.btw/100);
        if ((x.product.number_to_free != 0 && x.product.number_to_free != null) || (x.product.percentage != 0 && x.product.percentage != null)) {this.korting = true;}
        if (x.product.name == "Beginnerskorting" || x.product.name == "Korting mailing nieuwsbrief"){
          this.code_used = true;
        }
        else {
          this.code_used = false;
        }
      }
      this.totaal = this.totaal-this.totaal_btw;
    }) 

        //kortingscodes ophalen

  }

  

  select_product(_product){
    this._aposervice.change_product_selected(_product);
  }

  adjust_quantity(cartitem){
    cartitem.quantity = +(document.getElementById(cartitem.product.cnk) as HTMLInputElement).value;
    this.code_used = false;
    this._aposervice.addProduct(cartitem);
  }

  setMyStyles_freeproduct(cartitem: cartitem){
    let styles = {
      'display': cartitem.quantity_free != 0 ? '' : 'none'
    };
    return styles;
  }

  setMyStyles_korting(){
    let styles = {
      'display': this.korting ? '' : 'none'
    };
    return styles;
  }

  remove(cartitem){
    this._aposervice.removeProduct(cartitem);
    if (this.list_cart.length == 0){this.router.navigate(['']);}
    }

  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(){
    //if (this.list_cart.length == 0){this.router.navigate(['']);}
  }

}
