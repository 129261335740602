<div id="top"></div>
<app-header></app-header>
<!-- Subheader Start --> 

<!-- <div class="andro_subheader pattern-bg primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1>Cart</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Shop</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Cart</li>
                </ol>
            </nav>
        </div>
    </div>
</div> -->
<!-- Subheader End -->
<!--Cart Start -->
<div class="section">
    <div class="container">
        <ng-stepper #cdkStepper [linear]="true" (selectionChange)="selectionChange($event)">
            
            <cdk-step [optional]="true">
                <ng-template cdkStepLabel>
                    <div class="step-bullet">1</div>
                    <div class="step-title">Winkelmandje</div>
                  </ng-template>
                <app-cartitems></app-cartitems>
            <button style="margin-top: 50px;" class="andro_btn-custom primary btn-block" cdkStepperNext>{{cart_account_txt}}</button>
            </cdk-step>
            
            <cdk-step [completed]="!levering_disabled" >
                <ng-template cdkStepLabel>
                    <div class="step-bullet">2</div>
                    <div class="step-title">Account</div>
                </ng-template>
                <app-account></app-account>
                <button style="margin-top: 50px;" [disabled]="levering_disabled" class="andro_btn-custom primary btn-block" cdkStepperNext>Levering plannen</button>
            </cdk-step>
            
            <cdk-step [completed]="!order_disabled" >
            <ng-template cdkStepLabel>
                <div class="step-bullet">3</div>
                <div class="step-title">Levering</div>
            </ng-template>
            <app-delivery></app-delivery>
            <button style="margin-top: 50px;" [disabled]="order_disabled" class="andro_btn-custom primary btn-block" cdkStepperNext>Nog een laatste controle</button>
            </cdk-step>
            
            <cdk-step >
            <ng-template cdkStepLabel>
                <div class="step-bullet">4</div>
                <div class="step-title">Bevestiging</div>
            </ng-template>
            <mat-spinner [color]="'primary'" style="position: absolute; top: 20%; right: 50%" [ngStyle]="{'display': spinner_active ? '' : 'none'}"></mat-spinner>
            <app-orderconfirmation></app-orderconfirmation>
            <button style="margin-top: 50px;" (click)="place_order()" [disabled]="!agree" class="andro_btn-custom primary btn-block" cdkStepperNext>alles is correct...ga naar betaling</button>
            </cdk-step>
            
        </ng-stepper>

        <!-- <modal #rrmodal>
            <ng-template #modalHeader style="display: none;">
            </ng-template>
            <ng-template #modalBody>
                <img style="transform: scale(1.15);" src="assets/foto/rr.jpg" alt="">
            </ng-template>
        </modal> -->
 

    </div>
</div>

<!-- Cart form End -->
<app-insta-two></app-insta-two>
<app-footer></app-footer>