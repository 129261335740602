<div style="margin-top: 30px;"> 
        <div class="row"> 
            <div class="form-group col-lg-12">     
                <span style="font-family: 'Montserrat'; color: blue; font-weight: 500;">Controleer hieronder nauwgezet alle gegevens van jouw bestelling</span>                      
            </div> 

            <div class="form-group col-lg-6">

                <div class="row">
                    <div class="form-group col-lg-3">     
                        <span style="font-family: 'Montserrat'; color: rgba(81,189,77,255); font-weight: 800;">ACCOUNT:</span>                      
                    </div> 
                    
                    <div class="form-group col-lg-9" style="font-size: 15px; font-family: 'Montserrat'; font-weight: 500; color: rgba(81,189,77,255)">   
                        
                        <div class="row">         
                            <div class="form-group col-lg-12">  
                                {{_order._account.fname}} {{_order._account.lname}}
                            </div> 
        
                            <div class="form-group col-lg-12" style="margin-top: -20px;">     
                                    {{_order._account.straat}} {{_order._account.nummer}}<span *ngIf="_order._account.bus != null"> ({{_order._account.bus}})</span>,
                                    {{_order._account.postcode}} {{_order._account.gemeente}}
                            </div> 
        
                            <div class="form-group col-lg-12" style="margin-top: -20px;">     
                                    {{_order._account.mail}}
                            </div> 
        
                            <div class="form-group col-lg-12" style="margin-top: -20px;">     
                                    Telefoonnummer: {{_order._account.telefoon}}
                            </div> 
        
                            <div class="form-group col-lg-6" style="margin-top: -20px;">     
                                    RRN: {{_order._account.RR}}
                            </div>         
                        </div>           
                    </div>
                </div>

            </div> 

            <div class="form-group col-lg-6" [ngStyle]="{'display': facvalid ? '' : 'none'}">

                <div class="row">
                    <div class="form-group col-lg-3">     
                        <span style="font-family: 'Montserrat'; color: rgba(81,189,77,255); font-weight: 800;">FACTUUR:</span>                      
                    </div> 
                    
                    <div class="form-group col-lg-9" style="font-size: 15px; font-family: 'Montserrat'; font-weight: 500; color: rgba(81,189,77,255)">   
                        
                        <div class="row">         
                            <div class="form-group col-lg-12">  
                                {{_order._account.bedrijfsnaam}}
                            </div>   

                            <div class="form-group col-lg-12" style="margin-top: -20px;">  
                               t.a.v {{_order._account.vertegenwoordiger}}
                            </div> 
        
                            <div class="form-group col-lg-12" style="margin-top: -20px;">     
                                    {{_order._account.straat_fac}} {{_order._account.nummer_fac}}<span *ngIf="_order._account.bus_fac != null"> ({{_order._account.bus_fac}})</span>,
                                    {{_order._account.postcode_fac}} {{_order._account.gemeente_fac}}
                            </div> 
        
                            <div class="form-group col-lg-12" style="margin-top: -20px;">     
                                    {{_order._account.mail_fac}}
                            </div> 
        
                        </div>
                    </div>
                </div>

            </div> 

            <div class="form-group col-lg-6" [ngStyle]="{'margin-top': facvalid ? '-20px' : ''}">

                <div class="row">
                    <div class="form-group col-lg-3">     
                        <span style="font-family: 'Montserrat'; color: rgba(81,189,77,255); font-weight: 800;">LEVERING:</span>                      
                    </div> 
                    
                    <div class="form-group col-lg-9" style="font-size: 15px; font-family: 'Montserrat'; font-weight: 500; color: rgba(81,189,77,255)">   
                        
                        <div class="row">         
                            <div class="form-group col-lg-12" [ngStyle]="{'display': zelf_site != 0 ? 'none' : ''}">  
                                {{_order.delivery.lname}} {{_order.delivery.fname}}
                            </div> 
        
                            <div class="form-group col-lg-12" style="margin-top: -20px;" [ngStyle]="{'display': zelf_site != 0 ? 'none' : ''}">     
                                    {{_order.delivery.straat}} {{_order.delivery.nummer}}<span *ngIf="_order.delivery.bus != null"> ({{_order.delivery.bus}})</span>,
                                    {{_order.delivery.postcode}} {{_order.delivery.gemeente}}
                            </div>   

                            <div class="form-group col-lg-12" style="margin-top: -20px;" [ngStyle]="{'display': zelf_site != 0 ? 'none' : ''}">  
                                Tijdstip: {{_order.deliverytime_txt}}
                            </div>    

                            <div class="form-group col-lg-12" [ngStyle]="{'display': zelf_site == 1 ? '' : 'none'}">  
                                Zelf afhalen in apotheek Ceulemans, <br>Kerkstraat 97, 9255 Buggenhout <br> (klik <a href="#"> hier </a> voor openingsuren)
                            </div>   

                            <div class="form-group col-lg-12" [ngStyle]="{'display': zelf_site == 2 ? '' : 'none'}">  
                                Zelf afhalen in apotheek Van Causbroeck,  <br> Kerkstraat 88, 9200 Dendermonde  <br> (klik <a href="#"> hier </a> voor openingsuren)
                            </div>  

                            <div class="form-group col-lg-12" style="margin-top: -20px;" [ngStyle]="{'display': zelf_site != 0 ? '' : 'none'}">  
                                Beschikbaar vanaf: {{_order.deliverytime_txt}}
                            </div> 
        
                            <div class="form-group col-lg-12" style="margin-top: -20px;">     
                                    Opmerking: 
                                    <span [ngStyle]="{'display': _order.delivery.opm != '' ? '' : 'none'}">{{_order.delivery.opm}}</span>
                                    <span [ngStyle]="{'display': _order.delivery.opm == '' ? '' : 'none'}">-</span>
                            </div>         
                        </div>

                    </div>
                </div>
            </div> 

            <div class="form-group col-lg-9" style="margin-top: -20px;">

                <div class="row">
                    <div class="form-group col-lg-2">     
                        <span style="font-family: 'Montserrat'; color: rgba(81,189,77,255); font-weight: 800;">BESTELLING:</span>                      
                    </div> 
                    
                    <div class="form-group col-lg-10" style="font-size: 15px; font-family: 'Montserrat'; font-weight: 500; color: rgba(81,189,77,255)">   
                        
                        <div class="row">         
                            <div *ngFor="let cartitem of _order.cart; let i = index" [ngStyle]="{'margin-top': i == 0 ? '' : '-20px'}"
                             class="form-group col-lg-12">  
                               <div>{{cartitem.quantity}}x {{cartitem.product.name}}: {{(cartitem.product.price)*(cartitem.quantity) | number: '1.0-2'}}€</div>
                               <div [ngStyle]="{'display': cartitem.quantity_free != 0 ? '' : 'none'}">{{cartitem.quantity_free}}x {{cartitem.product.name}}: gratis</div>
                            </div>
                            <div class="form-group col-lg-12" [ngStyle]="{'display': aantal_producten == 0 ? '' : 'none'}" >
                                -
                            </div>
                            <div class="form-group col-lg-12" style="margin-top: -20px; font-style: italic;">
                                verzendkosten: {{cost_send}}€
                            </div>
                            <div class="form-group col-lg-12" style="margin-top: -20px;font-style: italic;">
                                Totaal te betalen: {{(_order.cost)+(cost_send) | number: '1.0-2'}}€
                            </div>
                        </div>

                    </div>
                </div>
            </div> 


            <div class="form-group col-lg-12" style="margin-top: -20px">
                <div class="row">
                    <div class="form-group col-lg-4"> 
                        <mat-checkbox style=" padding-right: 30px;" (change)="change_agree($event)" [ngStyle]="{'display': !agree ? '' : 'none'}" >
                            <span style="font-family: 'Montserrat'; font-weight: 500; color: red ">
                                 Ik ga akkoord met de verkoopsvoorwaarden
                            </span>
                        </mat-checkbox>
                        <mat-checkbox style=" padding-right: 30px;" [checked]="true" [disabled]="true" [ngStyle]="{'display': agree ? '' : 'none'}">
                            <span style="font-family: 'Montserrat'; font-weight: 500; color: rgba(81,189,77,255); ">
                                 Ik ga akkoord met de verkoopsvoorwaarden
                            </span>
                        </mat-checkbox>
                    </div>
                    <div class="form-group col-lg-4" style="margin-left: -15px;"> 
                        (klik <a href="javascript: void(0)" data-toggle="modal" data-target="#AVV" >hier</a> voor de verkoopsvoorwaarden)
                    </div>
                    <div class="form-group col-lg-4"> 
                    
                    </div>
                </div>
            </div>
        </div>
</div>

