import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { info, mailing } from 'src/app/model';

@Component({
  selector: 'app-blog-masonary',
  templateUrl: './blog-masonary.component.html',
  styleUrls: ['./blog-masonary.component.css']
})
export class BlogMasonaryComponent implements OnInit, OnDestroy {

  
  private destroy$: Subject<void> = new Subject<void>();
  list_info = [];
  _api = "";
  spinner_active = true;
  cat = "";

  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }


  constructor(public _aposervice: ApoService, public http: HttpClient) {

    this._aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => { 
      this._api = data;
    });

    this._aposervice.current_infocat.pipe(takeUntil(this.destroy$)).subscribe(cat => { 

      this.cat = cat;
      this.spinner_active = true;
      this.list_info = [];
      if (cat == ""){
        this.http.get<any>(this._api + "infos/15").pipe(takeUntil(this.destroy$)).subscribe(data => {
          for (let x of data){
            
            var products = [];
            for (let y of x[1]){
              for (let z of y){
                if (z.photo != null) { z.photo = "https://apoathomeapi.be/data/foto/" + z.photo;}
                else {z.photo = "https://apoathomeapi.be/data/no_image.jpg"}
                z.active = 1; 
                z.percentage = z.percentage;
                z.number_to_free = z.number_to_free;
                z = this._aposervice.calculate_discount(z); 
                products.push(z);
              }
            }       

            var _info: info = {
              title: x[0].title, text: x[0].text, products: x[0].products, id: x[0].id, category: x[0].category, photo: "https://apoathomeapi.be/data/info/" + x[0].photo,
              text_intro: x[0].text_intro, list_products: products}
            

            this.list_info.push(_info);
          }
          this.spinner_active = false;
        });
      }
      else {
        this.http.get<any>(this._api + "infos_cat/" + cat.replace(' ','_')).pipe(takeUntil(this.destroy$)).subscribe(data => {
          for (let x of data){
            
            var products = [];
            for (let y of x[1]){
              for (let z of y){
                if (z.photo != null) { z.photo = "https://apoathomeapi.be/data/foto/" + z.photo;}
                else {z.photo = "https://apoathomeapi.be/data/no_image.jpg"}
                z.active = 1; 
                z.percentage = z.percentage;
                z.number_to_free = z.number_to_free;
                z = this._aposervice.calculate_discount(z); 
                products.push(z);
              }
            }       

            var _info: info = {
              title: x[0].title, text: x[0].text, products: x[0].products, id: x[0].id, category: x[0].category, photo: "https://apoathomeapi.be/data/info/" + x[0].photo,
              text_intro: x[0].text_intro, list_products: products}
            

            this.list_info.push(_info);
          }
          this.spinner_active = false;
        });
      }
    });
   }

  ngOnInit(): void {
    this.to_top();    
  }

  select_info(id){
    this._aposervice.change_info(this.list_info[this.list_info.findIndex(x => x.id == id)]);
  }

  change_page(cat: string){
    this._aposervice.select_cat(cat);   
  }

  change_cat(){
    this._aposervice.change_infocat('');
  }
  

  scrolltotop(){
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView({behavior: "smooth"});
      top = null;
    }  
  }

  to_top(){
    setTimeout (() => {
      this.scrolltotop();
   }, 200);
  }

}
