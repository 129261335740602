<!-- Aside (Right Panel) -->
<aside class="andro_aside andro_aside-right">
    <div class="sidebar">
        <!-- Popular Categories Start -->
        <div class="sidebar-widget widget-categories-icons">
            <h5 class="widget-title">Popular Categories</h5>
            <div class="row">
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-diet"></i>
                            <h5>Food</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-groceries"></i>
                            <h5>Baskets</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-supplements"></i>
                            <h5>Supplements</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-cleaning-spray"></i>
                            <h5>Home Care</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-baby"></i>
                            <h5>Kids Care</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-olive-oil"></i>
                            <h5>Oils</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Popular Categories End -->
        <!-- Popular Tags Start -->
        <div class="sidebar-widget">
            <h5 class="widget-title">Popular Tags</h5>
            <div class="tagcloud">
                <a routerLink='/product-single'>Health</a>
                <a routerLink='/product-single'>Food</a>
                <a routerLink='/product-single'>Ingredients</a>
                <a routerLink='/product-single'>Organic</a>
                <a routerLink='/product-single'>Farms</a>
                <a routerLink='/product-single'>Green</a>
                <a routerLink='/product-single'>Fiber</a>
                <a routerLink='/product-single'>Supplements</a>
                <a routerLink='/product-single'>Gain</a>
                <a routerLink='/product-single'>Live Stock</a>
                <a routerLink='/product-single'>Harvest</a>
            </div>
        </div>
        <!-- Popular Tags End -->
    </div>
</aside>
<div class="andro_aside-overlay aside-trigger-right"></div>
<!-- Aside (Mobile Navigation) -->
<aside class="andro_aside andro_aside-left">
    <a class="navbar-brand" routerLink='/'> <img src="assets/img/logo.png" alt="logo"> </a>
    <ul>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Home Pages</a>
            <ul class="sub-menu">
                <li class="menu-item"> <a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v1</a> </li>
                <li class="menu-item"> <a routerLink='/home-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v2</a> </li>
                <li class="menu-item"> <a routerLink='/home-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v3</a> </li>
            </ul>
        </li>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Blog</a>
            <ul class="sub-menu">
                <li class="menu-item menu-item-has-children">
                    <a href="javascript:void(0)">Blog Archive</a>
                    <ul class="sub-menu">
                        <li class="menu-item"> <a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid View</a> </li>
                        <li class="menu-item"> <a routerLink='/blog-list' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">List View</a> </li>
                        <li class="menu-item"> <a routerLink='/blog-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Masonry View</a> </li>
                    </ul>
                </li>
                <li class="menu-item"> <a routerLink='/blog-single' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Single</a> </li>
            </ul>
        </li>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Pages</a>
            <ul class="sub-menu">
                <li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a> </li>
                <li class="menu-item"> <a routerLink='/login' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a> </li>
                <li class="menu-item"> <a routerLink='/register' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a> </li>
                <li class="menu-item"> <a routerLink='/checkout' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a> </li>
                <li class="menu-item"> <a routerLink='/cart' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a> </li>
                <li class="menu-item"> <a routerLink='/wishlist' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wish List</a> </li>
                <li class="menu-item"> <a routerLink='/legal' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Legal</a> </li>
            </ul>
        </li>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Shop Pages</a>
            <ul class="sub-menu">
                <li class="menu-item"> <a routerLink='/shop-v1' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v1 (Default) </a> </li>
                <li class="menu-item"> <a routerLink='/shop-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v2 (Full Width) </a> </li>
                <li class="menu-item"> <a routerLink='/shop-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v3 (No Sidebar) </a> </li>
                <li class="menu-item"> <a routerLink='/shop-v4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v4 (List view) </a> </li>
            </ul>
        </li>
        <li class="menu-item"> <a routerLink='/contact' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a> </li>
    </ul>
</aside>
<div class="andro_aside-overlay aside-trigger-left"></div>
<!-- Header Start -->
<header class="andro_header header-2 can-sticky">
    <!-- Topheader Start -->
    <div class="andro_header-top">
        <div class="container">
            <div class="andro_header-top-inner">
                <ul class="andro_header-top-sm andro_sm">
                    <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                    <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                    <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
                    <li> <a href="#"> <i class="fab fa-youtube"></i> </a> </li>
                </ul>
                <ul class="andro_header-top-links">
                    <li class="menu-item"><a routerLink='/login'> My Account </a></li>
                    <li class="menu-item menu-item-has-children">
                        <a href="#"> <span class="andro_current-currency-text">Currency</span> (USD) </a>
                        <ul class="sub-menu sub-menu-left">
                            <li> <a href="#">United States Dollar (USD)</a> </li>
                            <li> <a href="#">Kuwait Dinar (KWD)</a> </li>
                            <li> <a href="#">Pound Sterling (GBP)</a> </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- Topheader End -->
    <!-- Middle Header Start -->
    <div class="andro_header-middle">
        <div class="container">
            <nav class="navbar">
                <!-- Logo -->
                <a class="navbar-brand" routerLink='/'> <img src="assets/img/logo.png" alt="logo"> </a>
                <!-- Menu -->
                <ul class="navbar-nav">
                    <li class="menu-item menu-item-has-children">
                        <a href="javascript:void(0)">Home Pages</a>
                        <ul class="sub-menu">
                            <li class="menu-item"> <a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v1</a> </li>
                            <li class="menu-item"> <a routerLink='/home-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v2</a> </li>
                            <li class="menu-item"> <a routerLink='/home-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v3</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children">
                        <a href="javascript:void(0)">Blog</a>
                        <ul class="sub-menu">
                            <li class="menu-item menu-item-has-children">
                                <a href="javascript:void(0)">Blog Archive</a>
                                <ul class="sub-menu">
                                    <li class="menu-item"> <a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid View</a> </li>
                                    <li class="menu-item"> <a routerLink='/blog-list' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">List View</a> </li>
                                    <li class="menu-item"> <a routerLink='/blog-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Masonry View</a> </li>
                                </ul>
                            </li>
                            <li class="menu-item"> <a routerLink='/blog-single' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Single</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children">
                        <a href="javascript:void(0)">Pages</a>
                        <ul class="sub-menu">
                            <li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a> </li>
                            <li class="menu-item"> <a routerLink='/login' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a> </li>
                            <li class="menu-item"> <a routerLink='/register' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a> </li>
                            <li class="menu-item"> <a routerLink='/checkout' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a> </li>
                            <li class="menu-item"> <a routerLink='/cart' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a> </li>
                            <li class="menu-item"> <a routerLink='/wishlist' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wish List</a> </li>
                            <li class="menu-item"> <a routerLink='/legal' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Legal</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children mega-menu-wrapper">
                        <a href="javascript:void(0)">Shop</a>
                        <ul class="sub-menu">
                            <li>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="mega-menu-item">
                                                <h6>Membership Delivery</h6>
                                                <p>
                                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC,
                                                    making it over 2000 years old.
                                                </p>
                                                <a routerLink='/login' class="andro_btn-custom shadow-none btn-sm">Become a Member</a>
                                            </div>
                                        </div>
                                        <div class="offset-lg-1 col-lg-3">
                                            <div class="mega-menu-item">
                                                <h6>Shop Pages</h6>
                                                <a routerLink='/shop-v1' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v1 (Default)</a>
                                                <a routerLink='/shop-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v2 (Full Width)</a>
                                                <a routerLink='/shop-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v3 (No Sidebar)</a>
                                                <a routerLink='/shop-v4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v4 (List view)</a>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="mega-menu-item">
                                                <h6>Product Details Pages</h6>
                                                <a routerLink='/product-single' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product Details v1</a>
                                                <a routerLink='/product-single-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product Details v2</a>
                                                <a href="#" class="coming-soon">Product Details v3 <span>Coming Soon</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="menu-item"> <a routerLink='/contact' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a> </li>
                </ul>
                <div class="andro_header-controls">
                    <ul class="andro_header-controls-inner">
                        <li class="andro_header-favorites"> <a routerLink='/wishlist' title="Your Wishlist"> <i class="flaticon-like"></i> </a> </li>
                        <li class="andro_header-cart">
                            <a routerLink='/cart' title="Your Cart">
                                <i class="flaticon-shopping-basket"></i>
                                <div class="andro_header-cart-content">
                                    <span>9 Items</span>
                                    <span>249.99$</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- Toggler -->
                    <div class="aside-toggler aside-trigger-left">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <!-- Middle Header End -->
    <!-- Bottom Header Start -->
    <div class="andro_header-bottom">
        <div class="container">
            <div class="andro_header-bottom-inner">
                <!-- Search Form -->
                <div class="andro_search-adv">
                    <form method="post">
                        <div class="andro_search-adv-cats">
                            <span>All Categories</span>
                            <div class="sub-menu">
                                <div class="andro_dropdown-scroll">
                                    <label>
                                        <input type="checkbox" name="category1" value="food">
                                        Food
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category2" value="home-care">
                                        Home Care
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category3" value="keto">
                                        Keto
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category4" value="baskets">
                                        Baskets
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category5" value="supplements">
                                        Supplements
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category6" value="baby-kids">
                                        Baby & Kids care
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category7" value="serum">
                                        Serum
                                        <i class="fas fa-check"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="andro_search-adv-input">
                            <input type="text" class="form-control" placeholder="Look for Fruits, Vegetables" name="search" value="">
                            <button type="submit" name="button"><i class="fa fa-search"></i></button>
                        </div>
                    </form>
                </div>
                <!-- Side navigation toggle -->
                <div class="aside-toggler aside-trigger-right desktop-toggler">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
    <!-- Bottom Header End -->
</header>
<!-- Header End -->