<!-- Aside (Right Panel) -->
<aside class="andro_aside andro_aside-right">
    <div class="sidebar">
        <!-- Popular Categories Start -->
        <div class="sidebar-widget widget-categories-icons">
            <h5 class="widget-title">Popular Categories</h5>
            <div class="row">
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-diet"></i>
                            <h5>Food</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-groceries"></i>
                            <h5>Baskets</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-supplements"></i>
                            <h5>Supplements</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-cleaning-spray"></i>
                            <h5>Home Care</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-baby"></i>
                            <h5>Kids Care</h5>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="andro_icon-block text-center has-link">
                        <a routerLink='/product-single'>
                            <i class="flaticon-olive-oil"></i>
                            <h5>Oils</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Popular Categories End -->
        <!-- Popular Tags Start -->
        <div class="sidebar-widget">
            <h5 class="widget-title">Popular Tags</h5>
            <div class="tagcloud">
                <a routerLink='/product-single'>Health</a>
                <a routerLink='/product-single'>Food</a>
                <a routerLink='/product-single'>Ingredients</a>
                <a routerLink='/product-single'>Organic</a>
                <a routerLink='/product-single'>Farms</a>
                <a routerLink='/product-single'>Green</a>
                <a routerLink='/product-single'>Fiber</a>
                <a routerLink='/product-single'>Supplements</a>
                <a routerLink='/product-single'>Gain</a>
                <a routerLink='/product-single'>Live Stock</a>
                <a routerLink='/product-single'>Harvest</a>
            </div>
        </div>
        <!-- Popular Tags End -->
    </div>
</aside>
<div class="andro_aside-overlay aside-trigger-right"></div>
<!-- Aside (Mobile Navigation) -->
<aside class="andro_aside andro_aside-left">
    <a class="navbar-brand" routerLink='/'> <img src="assets/img/logo.png" alt="logo"> </a>
    <ul>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Home Pages</a>
            <ul class="sub-menu">
                <li class="menu-item"> <a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v1</a> </li>
                <li class="menu-item"> <a routerLink='/home-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v2</a> </li>
                <li class="menu-item"> <a routerLink='/home-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v3</a> </li>
            </ul>
        </li>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Blog</a>
            <ul class="sub-menu">
                <li class="menu-item menu-item-has-children">
                    <a href="javascript:void(0)">Blog Archive</a>
                    <ul class="sub-menu">
                        <li class="menu-item"> <a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid View</a> </li>
                        <li class="menu-item"> <a routerLink='/blog-list' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">List View</a> </li>
                        <li class="menu-item"> <a routerLink='/blog-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Masonry View</a> </li>
                    </ul>
                </li>
                <li class="menu-item"> <a routerLink='/blog-single' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Single</a> </li>
            </ul>
        </li>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Pages</a>
            <ul class="sub-menu">
                <li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a> </li>
                <li class="menu-item"> <a routerLink='/login' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a> </li>
                <li class="menu-item"> <a routerLink='/register' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a> </li>
                <li class="menu-item"> <a routerLink='/checkout' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a> </li>
                <li class="menu-item"> <a routerLink='/cart' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a> </li>
                <li class="menu-item"> <a routerLink='/wishlist' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wish List</a> </li>
                <li class="menu-item"> <a routerLink='/legal' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Legal</a> </li>
            </ul>
        </li>
        <li class="menu-item menu-item-has-children">
            <a href="javascript:void(0)">Shop Pages</a>
            <ul class="sub-menu">
                <li class="menu-item"> <a routerLink='/shop-v1' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v1 (Default) </a> </li>
                <li class="menu-item"> <a routerLink='/shop-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v2 (Full Width) </a> </li>
                <li class="menu-item"> <a routerLink='/shop-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v3 (No Sidebar) </a> </li>
                <li class="menu-item"> <a routerLink='/shop-v4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Shop v4 (List view) </a> </li>
            </ul>
        </li>
        <li class="menu-item"> <a routerLink='/contact' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a> </li>
    </ul>
</aside>
<div class="andro_aside-overlay aside-trigger-left"></div>
<!-- Header Start -->
<header class="andro_header header-1 can-sticky">
    <!-- Topheader Start -->
    <div  class="andro_header-top">
        <div class="container" >
            <div class="andro_header-top-inner" style="height: 50px;">
                <ul class="andro_header-top-sm andro_sm">
                    <li><a routerLink='/home-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"><img style="height: 130px; width: 130px; margin-top: 90px;" src="assets/img/logo3.png"></a></li>
                    <li style="color: white;"> <i class="fas fa-phone-square" style="padding-right: 7px;"></i> +32 52 21 18 00 </li>
                    <li style="color: white;"> <i class="fas fa-fax" style="padding-right: 7px;"></i> +32 52 21 31 24 </li>
                    <li style="color: white;"> <i class="fas fa-envelope-square" style="padding-right: 7px;"></i> info@apoathome.be </li>
                    <li style="color: white;"> <a href="#"><i class="fas fa-store" style="padding-right: 7px;"></i> verkooppunten </a> </li>
                </ul>
                <ul class="andro_header-top-links">
                    <li class="menu-item"><a routerLink='/login'> Mijn Account </a></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- Topheader End -->
    <!-- Middle Header Start -->
    <div class="andro_header-middle">
        <div class="container">
            <nav class="navbar">
                <!-- Logo -->
                <!-- <a class="navbar-brand" routerLink='/'> <img src="assets/img/logo.png" alt="logo"> </a> -->
                <!-- Search Form -->
                <div class="andro_search-adv" style="margin-left: 26%">
                    <form method="post">
                        <div class="andro_search-adv-cats" style="background-color: rgba(81,189,77,255);">
                            <span style="color: white; font-weight: normal">Alle Categorieën</span>
                            <div class="sub-menu">
                                <div class="andro_dropdown-scroll">
                                    <label>
                                        <input type="checkbox" name="category1" value="Afslanken">
                                        Afslanken
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category2" value="Baby">
                                        Baby & mama
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <!-- <label>
                                        <input type="checkbox" name="category3" value="Kinderen">
                                        Kinderen
                                        <i class="fas fa-check"></i>
                                    </label> -->
                                    <label>
                                        <input type="checkbox" name="category4" value="Wondzorg">
                                        Eerste hulp
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category5" value="Lichaam">
                                        Lichaam en Gelaat
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category6" value="Fytotherapie">
                                        Natuurgeneeskunde
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category7" value="Seksualiteit">
                                        Seksualiteit
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <!-- <label>
                                        <input type="checkbox" name="category8" value="Sport">
                                        Sport
                                        <i class="fas fa-check"></i>
                                    </label> -->
                                    <label>
                                        <input type="checkbox" name="category9" value="Vitamines">
                                        Vitamines en voedingssupplementen
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <!-- <label>
                                        <input type="checkbox" name="category10" value="Zon">
                                        Zon
                                        <i class="fas fa-check"></i>
                                    </label> -->
                                    <label>
                                        <input type="checkbox" name="category11" value="Thuiszorg">
                                        Thuiszorg
                                        <i class="fas fa-check"></i>
                                    </label>
                                    <label>
                                        <input type="checkbox" name="category12" value="Dieren">
                                        Dieren & Insecten
                                        <i class="fas fa-check"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="andro_search-adv-input">
                            <input style="border-color: rgba(81,189,77,255) ; border-width: 1px;" type="text" class="form-control" placeholder="Zoek in meer dan 50.000 producten" name="search" value="">
                            <button type="submit" name="button" style="background-color: transparent; border-width: 0px;"><i class="fa fa-search"></i></button>
                        </div>
                    </form>
                </div>
                <div class="andro_header-controls">
                    <ul class="andro_header-controls-inner">
                        <!-- <li class="andro_header-favorites"> <a routerLink='/wishlist' title="Your Wishlist"> <i class="flaticon-like"></i> </a> </li> -->
                        <li class="andro_header-cart">
                            <a routerLink='/cart' title="Your Cart">
                                <i class="flaticon-shopping-basket"></i>
                                <div class="andro_header-cart-content">
                                    <span>0 Items</span>
                                    <span>0€</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- Toggler -->
                    <div class="aside-toggler aside-trigger-left">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <!-- Middle Header End -->
    <!-- Bottom Header Start -->
    <div class="andro_header-bottom">
        <div class="container">
            <div class="andro_header-bottom-inner">
                
                <div class="andro_search-adv-cats" style="background-color: rgba(81,189,77,255); border-radius: 5px 5px 0px 0px; width: 265px; height: 65px;">
                    <h6 style="font-weight: normal; font-size: 14px; line-height: 1.7; color: white; margin-top: 15px; left: 5px;"> <i class="fas fa-th-large" style="padding-right: 5px"></i> Categorieën </h6>
                    <div class="sub-menu">
                        <div class="andro_dropdown-scroll">
                            <ul>
                                <li class="andro_category-mm-item hover"> <a href="#"> Afslanken </a> </li>
                                <li class="andro_category-mm-item"> <a href="#"> Baby & mama</a> </li>
                                <li class="andro_category-mm-item"> <a href="#"> Kinderen</a> </li>
                                <li class="andro_category-mm-item"> <a href="#"> Eerste hulp</a> </li>
                                <li class="andro_category-mm-item"> <a href="#"> Lichaam en gelaat</a> </li>
                                <li class="andro_category-mm-item"> <a href="#"> Vitamines/voedingssupplementen</a> </li>
                                <li class="andro_category-mm-item"> <a href="#"> Seksualiteit</a> </li>
                                
                                <!-- Level 1 -->
                                <!-- <li class="andro_category-mm-item andro_category-mm-item-has-children">
                                    <a href="#"> Food </a>
                                    <ul class="andro_category-mm-2-cols">
                                         Level 2 
                                        <li>
                                            <div class="andro_category-mm-banner">
                                                <img src="assets/img/megamenu/1.jpg" alt="megamenu img">
                                                <div class="andro_category-mm-banner-desc">
                                                    <h6>Food</h6>
                                                    <p>Experience organic food like never before</p>
                                                </div>
                                            </div>
                                            <ul>
                                                <li> <a href="#">Vegetables & Fruits</a> </li>
                                                <li> <a href="#">Dairy</a> </li>
                                                <li> <a href="#">Vegan Dairy</a> </li>
                                                <li> <a href="#">Meats & Fish</a> </li>
                                                <li> <a href="#">Breads & Cereal</a> </li>
                                                <li> <a href="#">Honey</a> </li>
                                                <li> <a href="#">Jam & Spreads</a> </li>
                                                <li> <a href="#">Legumes</a> </li>
                                                <li> <a href="#">Oils</a> </li>
                                            </ul>
                                            <ul>
                                                <li> <a href="#">Beans</a> </li>
                                                <li> <a href="#">Vegan Food</a> </li>
                                                <li> <a href="#">Distillates</a> </li>
                                                <li> <a href="#">Eggs</a> </li>
                                                <li> <a href="#">Snacks</a> </li>
                                                <li> <a href="#">Syrups</a> </li>
                                                <li> <a href="#">Pickles</a> </li>
                                                <li> <a href="#">Wines & Spirit</a> </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li> -->
                                
                                <li class="andro_category-mm-item andro_category-mm-item-has-children andro_category-mm-item-expand">
                                    <a href="javascript:void(0);"> <i class="fas fa-plus"></i> Meer categorieën</a>
                                    <ul class="andro_category-mm-2-cols">
                                        <!-- Level 2 -->
                                        <li>                                        
                                            <ul>                                           
                                                <li> <a href="#">Sport</a> </li>
                                                <li> <a href="#">Natuurgeneeskunde</a> </li>
                                                <li> <a href="#">Zon</a> </li>
                                                <li> <a href="#">Thuiszorg</a> </li>
                                                <li> <a href="#">Dieren & Insecten</a> </li>
                                            </ul>                                      
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Menu -->
                <ul class="navbar-nav">
                    <!-- <li class="menu-item menu-item-has-children">
                        <a href="javascript:void(0)">Home Pages</a>
                        <ul class="sub-menu">
                            <li class="menu-item"> <a routerLink='/' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v1</a> </li>
                            <li class="menu-item"> <a routerLink='/home-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v2</a> </li>
                            <li class="menu-item"> <a routerLink='/home-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home v3</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children">
                        <a href="javascript:void(0)">Blog</a>
                        <ul class="sub-menu">
                            <li class="menu-item menu-item-has-children">
                                <a href="javascript:void(0)">Blog Archive</a>
                                <ul class="sub-menu">
                                    <li class="menu-item"> <a routerLink='/blog-grid' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grid View</a> </li>
                                    <li class="menu-item"> <a routerLink='/blog-list' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">List View</a> </li>
                                    <li class="menu-item"> <a routerLink='/blog-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Masonry View</a> </li>
                                </ul>
                            </li>
                            <li class="menu-item"> <a routerLink='/blog-single'>Blog Single</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children">
                        <a href="javascript:void(0)">Pages</a>
                        <ul class="sub-menu">
                            <li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a> </li>
                            <li class="menu-item"> <a routerLink='/login' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a> </li>
                            <li class="menu-item"> <a routerLink='/register' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a> </li>
                            <li class="menu-item"> <a routerLink='/checkout' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a> </li>
                            <li class="menu-item"> <a routerLink='/cart' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a> </li>
                            <li class="menu-item"> <a routerLink='/wishlist' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wish List</a> </li>
                            <li class="menu-item"> <a routerLink='/legal' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Legal</a> </li>
                            
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children mega-menu-wrapper">
                        <a href="javascript:void(0)">Shop</a>
                        <ul class="sub-menu">
                            <li>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="mega-menu-item">
                                                <h6>Membership Delivery</h6>
                                                <p>
                                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC,
                                                    making it over 2000 years old.
                                                </p>
                                                <a routerLink='/login' class="andro_btn-custom shadow-none btn-sm">Become a Member</a>
                                            </div>
                                        </div>
                                        <div class="offset-lg-1 col-lg-3">
                                            <div class="mega-menu-item">
                                                <h6>Shop Pages</h6>
                                                <a routerLink='/shop-v1' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v1 (Default)</a>
                                                <a routerLink='/shop-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v2 (Full Width)</a>
                                                <a routerLink='/shop-v3' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v3 (No Sidebar)</a>
                                                <a routerLink='/shop-v4' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop v4 (List view)</a>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="mega-menu-item">
                                                <h6>Product Details Pages</h6>
                                                <a routerLink='/product-single' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product Details v1</a>
                                                <a routerLink='/product-single-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Product Details v2</a>
                                                <a href="#" class="coming-soon">Product Details v3 <span>Coming Soon</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li> -->
                    <li class="menu-item"> <a routerLink='/home-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a> </li>
                    <li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Over ons</a> </li>
                    <li class="menu-item"> <a routerLink='/blog-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nieuws en informatie</a> </li>
                    <li class="menu-item"> <a routerLink='/Contact' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hoe bestellen?</a> </li>
                </ul>
                <!-- Side navigation toggle -->
                <div class="aside-toggler aside-trigger-right desktop-toggler">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    </div>
    <!-- Bottom Header End -->
</header>
<!-- Header End -->

<!-- <li class="menu-item"> <a routerLink='/home-v2' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a> </li>
<li class="menu-item"> <a routerLink='/about' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Over APOATHOME</a> </li>
<li class="menu-item"> <a routerLink='/blog-masonary' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nieuws en informatie</a> </li>
<li class="menu-item"> <a routerLink='/contact' routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hoe bestellen?</a> </li> -->