import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { __values } from 'tslib';
import { account, cartitem, delivery_address, fav, filter, info, login_answer, Order, pricefork, Product } from './model';

@Injectable({
  providedIn: 'root'
})
export class ApoService {

  private destroy$: Subject<void> = new Subject<void>();
  _account_rr = "";
  account_curr: account = {lname: '', fname: '', mail: '', telefoon: '', RR: '', land: '', straat: '', nummer: '', bus: '', postcode: '', gemeente: '', 
  info: '', nieuw: true, logged_in: false, failed_login:0, uid: "0", bedrijfsnaam: '',vertegenwoordiger: '', mail_fac: '', land_fac: '',straat_fac: '', 
  nummer_fac: '', bus_fac: '', postcode_fac: '', gemeente_fac: '', id: 0 };  


  constructor(public http: HttpClient) {

    this.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {
        this._account_rr = data.RR;
        this.account_curr = data;
    })
   }

  // winkelmandje
  mandje : cartitem[] = [];
  private mandje_source = new BehaviorSubject<cartitem[]>(this.mandje);
  current_mandje = this.mandje_source.asObservable();
  
  // Products : Product[]= [];
  // private cartSubject = new BehaviorSubject<Product[]>(this.Products);
  // CartState = this.cartSubject.asObservable();
  
  addProduct(_cartitem:cartitem) {

    console.log(_cartitem);
    
    var free = 0;
    var korting = '';
    _cartitem.product.price_ori = _cartitem.product.price;

    //kortingscodes verwijderen bij toevoegen nieuw product (uitz als je code toevoegt)
    if (_cartitem.product.principle != 'code'){
      var index = this.mandje.findIndex(x => x.product.principle == 'code');
      if (index != -1) {this.mandje.splice(index, 1);}
      this.add_code("");
    }
    
    //kortingstekst berekenen
    if ((_cartitem.product.percentage != 0 && _cartitem.product.percentage != null)){
      korting = (_cartitem.product.price_ori - _cartitem.product.price).toFixed(2) + '€';
    }
    else if ((_cartitem.product.number_to_free != 0 && _cartitem.product.number_to_free != null)){
      korting = _cartitem.product.number_to_free.toString() + ' + 1 gratis';
    }
    _cartitem.product.discount_text = korting;

    // bereken gratis producten
    if (_cartitem.product.number_to_free != 0 && _cartitem.product.percentage == 0){
      _cartitem.quantity_free = Math.floor(_cartitem.quantity / _cartitem.product.number_to_free);
    }

    if(this.mandje.findIndex(x => x.product.cnk == _cartitem.product.cnk) != -1){
      this.mandje[this.mandje.findIndex(x => x.product.cnk == _cartitem.product.cnk)].quantity = _cartitem.quantity;
      this.mandje[this.mandje.findIndex(x => x.product.cnk == _cartitem.product.cnk)].quantity_free = _cartitem.quantity_free;      
    }
    else {
      this.mandje.push(_cartitem);
    }

    this.mandje_source.next(this.mandje);
    this.order_add_cartitems(this.mandje);
  }

  removeProduct(_cartitem:cartitem){
    if (_cartitem.promocode != ""){this.add_code("");}
    this.mandje.splice(this.mandje.findIndex(x=>x.product == _cartitem.product),1);
    this.mandje_source.next(this.mandje);
    this.order_add_cartitems(this.mandje);
  }

  // removeProduct(id:number) {
  //   this.Products = this.Products.filter((_item) =>  _item.id !== id )
  //   this.cartSubject.next(<CartState>{loaded: false , products:  this.Products});
  // }

  // getAllProducts() : Observable <any> {
  //   return this.httpclient.get(url).map((res : Response) => res.json())
  //     .catch((error : any) => Observable.throw('Server error'));
  // }

  //filter
  // filter : filter = { modus: "", categories: [], subcategories: [], attention: false, sale: false, no_presc: false, 
  //                     brands: [], price_min: 0, price_max: 1000, searchterm: "", sort: ""};
  // private filtersubject = new BehaviorSubject<filter>(this.filter);
  // current_filter = this.filtersubject.asObservable();
  
  // changefilter(_filter:filter) {    
  //   this.filtersubject.next(_filter);
  // }

  // clearfilter(_cat: any[], _modus: string){
  //   this.filter.categories = _cat;
  //   this.filter.subcategories = [];
  //   this.filter.attention = false;
  //   this.filter.sale = false;
  //   this.filter.no_presc = false;
  //   this.filter.brands = [];
  //   this.filter.price_min = 0;
  //   this.filter.price_max = 1000;
  //   this.filter.searchterm = "";
  //   this.filter.sort = "";   
  //   this.filter.modus = _modus;
  //   this.filtersubject.next(this.filter);
  // }

  // changecat(_cat:string, _modus: string){
  //   if (_cat == ""){
  //     this.filter.categories = [];
  //     this.filter.subcategories = [];
  //     this.filter.attention = false;
  //     this.filter.sale = false;
  //     this.filter.no_presc = false;
  //     this.filter.brands = [];
  //     this.filter.price_min = 0;
  //     this.filter.price_max = 1000;
  //     this.filter.searchterm = "";
  //     this.filter.sort = "";      
  //   }
  //   else {this.filter.categories.push(_cat);}
  //   this.filter.modus = _modus;
  //   this.filtersubject.next(this.filter);
  // }
  
  // //filteractief
  // filter_active : Boolean = false;
  // private filteractivesubject = new BehaviorSubject<Boolean>(this.filter_active);
  // current_filteractive = this.filteractivesubject.asObservable();
  
  // changefilteractive(_filteractive: Boolean) {    
  //   this.filteractivesubject.next(_filteractive);
  // }
  
  //favorieten
  list_favorites : string[] = [];
  private favsubject = new BehaviorSubject<string[]>(this.list_favorites);
  current_listfav = this.favsubject.asObservable();

  make_list_fav(list_id: string[]){
    this.list_favorites = [];
    this.list_favorites = list_id;
    this.favsubject.next(this.list_favorites);
  }
  
  addlist_fav(id: string, userid: number) {
    if (this.list_favorites.findIndex(x => x == id) == -1){
      this.list_favorites.push(id);
      //http request naar api om id toe te voegen
      let fav: fav = {
        user_id: userid,
        product_id: id
      };
      this.http.post<account>(this.api + "favorieten",fav).pipe(takeUntil(this.destroy$)).subscribe();      
    }
    this.favsubject.next(this.list_favorites);
  }

  removelist_fav(id: string, userid: number) {
    var index = this.list_favorites.findIndex(x => x == id);
    if ( index != -1){
      this.list_favorites.splice(index,1);
      //http request naar api om id te verwijderen
      let fav: fav = {
        user_id: userid,
        product_id: id
      };
      this.http.get<any>(this.api + "favoriet/" + userid + "/" + id).pipe(takeUntil(this.destroy$)).subscribe();
    }
    this.favsubject.next(this.list_favorites);
  }

  //noproduct
  noproduct: Boolean = false;
  private noproductsubject = new BehaviorSubject<Boolean>(this.noproduct);
  current_noproduct = this.noproductsubject.asObservable();

  change_noproduct(_noproduct: Boolean){
    this.noproductsubject.next(_noproduct);
  }

  //noproduct
  contact_page: Boolean = false;
  private contactsubject = new BehaviorSubject<Boolean>(this.contact_page);
  currentcontactpage = this.contactsubject.asObservable();

  change_contact_page(_contact_page: Boolean){
    this.contactsubject.next(_contact_page);
  }

  //info_cat
  infocat: string = "";
  private infocatsubject = new BehaviorSubject<string>(this.infocat);
  current_infocat = this.infocatsubject.asObservable();

  change_infocat(_infocat: string){
    this.infocatsubject.next(_infocat);
  }

  //mailgegevens blocken
  mail_blocked: Boolean = false;
  private mailblocksubject = new BehaviorSubject<Boolean>(this.mail_blocked);
  current_mailblock = this.mailblocksubject.asObservable();

  set_mailblock(_block: Boolean){
    this.mailblocksubject.next(_block);
  }

   //headercat doorgeven
   cat_selected: string = "";
   private catselect_subject = new BehaviorSubject<string>(this.cat_selected);
   current_catselected = this.catselect_subject.asObservable();
 
   select_cat(cat: string){
     this.catselect_subject.next(cat);     
   }

  //factuurgegevens volledig
  facvalid: Boolean = false;
  private facvalidsubject = new BehaviorSubject<Boolean>(this.facvalid);
  current_facvalid = this.facvalidsubject.asObservable();

  change_facvalid(_facvalid: Boolean){
    this.facvalidsubject.next(_facvalid);
  }  

  //promocode volledig
  promocode: string = "";
  public promocodesubject = new BehaviorSubject<string>(this.promocode);
  current_promocode = this.promocodesubject.asObservable();

  add_code(_code: string){
    this.promocodesubject.next(_code);
  }  

  //price_max
  pricemax = 0;
  private pricemaxsubject = new BehaviorSubject<number>(this.pricemax);
  current_pricemax = this.pricemaxsubject.asObservable();

  change_pricemax(_pricemax: number){
    this.pricemaxsubject.next(_pricemax);
  }

  //check products
  products_selected = [];
  private products_selected_source = new BehaviorSubject<any[]>(this.products_selected);
  current_products_selected = this.products_selected_source.asObservable();

  set_products_selected(products: Product[]){        
    this.products_selected_source.next(products);
  }

  remove_all_products_selected(){
    this.products_selected = [];
    this.products_selected_source.next(this.products_selected);
  }

  //formgegevens volledig
  formvalid: Boolean = false;
  private formvalidsubject = new BehaviorSubject<Boolean>(this.formvalid);
  current_formvalid = this.formvalidsubject.asObservable();

  change_formvalid(_formvalid: Boolean){
    this.formvalidsubject.next(_formvalid);
  }

  //bestelgegevens volledig
  Ordervalid: Boolean = false;
  private Ordervalidsubject = new BehaviorSubject<Boolean>(this.Ordervalid);
  current_Ordervalid = this.Ordervalidsubject.asObservable();

  change_ordervalid(_ordervalid: Boolean){
    this.Ordervalidsubject.next(_ordervalid);
  }

  //geselecteerd product
  product_selected: Product = {name: '', price: 0, price_disc: 0, info_short_product: '', photo: '', cnk: '', brand: '', percentage: 0, attention_product: 0, popular: 0, id: 0, 
  subcategory: '', documentation: '', prescription: '', principle: '', _use: '', composition: '', favoriet: false, active: 1, special: '', creationtime: new Date(), 
  price_ori:0, number_to_free: 0, discount_special: 0, btw: 6, discount_text: '', disc_start: new Date(), disc_stop: new Date(), att_start: new Date(), att_stop: new Date(),
  check: false, check_select: false, principle2: '', info: '', product_collection: ""};   
  private productselected_subject = new BehaviorSubject<Product>(this.product_selected);
  current_product_select = this.productselected_subject.asObservable();

  change_product_selected(_product: Product){
    this.productselected_subject.next(_product);
    console.log(_product); 
    
  }

  //geselecteerde account
  account: account = {lname: '', fname: '', mail: '', telefoon: '', RR: '', land: '', straat: '', nummer: '', bus: '', postcode: '', gemeente: '', info: '', 
  nieuw: true, logged_in: false, failed_login: 0, uid: "0", bedrijfsnaam: '',vertegenwoordiger: '', mail_fac: '', land_fac: '',straat_fac: '', 
  nummer_fac: '', bus_fac: '', postcode_fac: '', gemeente_fac: '', id: 0 };  
  private account_subject = new BehaviorSubject<account>(this.account);
  current_account = this.account_subject.asObservable();

  change_account(_account: account){
    // checken of account al bestaat op RR
    _account.nieuw = false;
    this.account = _account;
    this.account_subject.next(_account);
    this.order_add_account(_account);
    this.isfacvalid(_account);
    this.isformvalid(_account);    
  }

  change_account_veld(waarde: string, veld: string){
    this.logout(false);
    if (veld == 'lname') {this.account.lname = waarde;}
    if (veld == 'fname') {this.account.fname = waarde;}
    if (veld == 'mail') {this.account.mail = waarde;}
    if (veld == 'telefoon') {this.account.telefoon = waarde;}
    if (veld == 'RR') {this.account.RR = waarde;}
    if (veld == 'land') {this.account.land = waarde;}
    if (veld == 'straat') {this.account.straat = waarde;}
    if (veld == 'nummer') {this.account.nummer = waarde;}
    if (veld == 'bus') {this.account.bus = waarde;}
    if (veld == 'postcode') {this.account.postcode = waarde;}
    if (veld == 'gemeente') {this.account.gemeente = waarde;}
    if (veld == 'info') {this.account.info = waarde;}
    if (veld == 'bedrijfsnaam') {this.account.bedrijfsnaam = waarde;}
    if (veld == 'vertegenwoordiger') {this.account.vertegenwoordiger = waarde;}
    if (veld == 'mail_fac') {this.account.mail_fac = waarde;}
    if (veld == 'land_fac') {this.account.land_fac = waarde;}
    if (veld == 'straat_fac') {this.account.straat_fac = waarde;}
    if (veld == 'nummer_fac') {this.account.nummer_fac = waarde;}
    if (veld == 'bus_fac') {this.account.bus_fac = waarde;}
    if (veld == 'postcode_fac') {this.account.postcode_fac = waarde;}
    if (veld == 'gemeente_fac') {this.account.gemeente_fac = waarde;}
    if (veld == 'logout') {this.account.logged_in = false;}

    this.account_subject.next(this.account);
    this.order_add_account(this.account);
    this.isformvalid(this.account);
    this.isfacvalid(this.account);
  }

  // //account_temp
  // account1: account = {lname: '', fname: '', mail: '', telefoon: '', RR: '', land: '', straat: '', nummer: '', bus: '', postcode: '', gemeente: '', info: '', 
  // nieuw: true, logged_in: false, failed_login:0, uid: "0", bedrijfsnaam: '',vertegenwoordiger: '', mail_fac: '', land_fac: '',straat_fac: '', 
  // nummer_fac: '', bus_fac: '', postcode_fac: '', gemeente_fac: '' };  
  // private accounttemp_subject = new BehaviorSubject<account>(this.account1);
  // current_account_temp = this.accounttemp_subject.asObservable();

  // change_accounttemp(_account: account){

  //   // checken of account al bestaat op RR
  //   this.accounttemp_subject.next(_account);

  // }

  //inloggen
  login_auth(_uid, _mail, _lname){  
    this.http.get<account>(this.api + "users/" + _uid + "/" + _mail + "/" + _lname).pipe(takeUntil(this.destroy$)).subscribe(data => {   
      var _account = {  lname: data[0][0].lname, fname: data[0][0].fname, mail: data[0][0].mail, telefoon: data[0][0].telefoon, RR: data[0][0].RR, land: data[0][0].land, 
                        straat: data[0][0].straat,nummer: data[0][0].nummer, bus: data[0][0].bus, postcode: data[0][0].postcode, gemeente: data[0][0].gemeente, info: data[0][0].info, 
                        nieuw: data[0][0].nieuw, failed_login: data[0][0].failed_login, logged_in: true, uid: data[0][0].uid, bedrijfsnaam: data[0][0].bedrijfsnaam,
                        vertegenwoordiger: data[0][0].vertegenwoordiger, mail_fac: data[0][0].mail_fac, land_fac: data[0][0].land_fac,straat_fac: data[0][0].straat_fac, 
                        nummer_fac: data[0][0].nummer_fac, bus_fac: data[0][0].bus_fac, postcode_fac: data[0][0].postcode_fac, gemeente_fac: data[0][0].gemeente_fac, id: data[0][0].id };
      console.log(data);
      this.account = _account;
      this.account_subject.next(_account);
      this.order_add_account(_account);
      this.isformvalid(_account);
      this.isfacvalid(_account);
      this.set_mailblock(true);
    });   
  } 
  
  create_account(_account: account){
    this.http.post<account>(this.api + "users",_account).pipe(takeUntil(this.destroy$)).subscribe(data => console.log(data));
    this.set_mailblock(true);
  }
    
  //modus_account
  _modus: string = '';  
  private modusaccount_subject = new BehaviorSubject<string>(this._modus);
  current_modusaccount = this.modusaccount_subject.asObservable();

  change_modusaccount(_modusnew: string){
    this.modusaccount_subject.next(_modusnew);
  }
    
  //akkoord met de voorwaarden
  _agree: boolean = false;  
  private agree_subject = new BehaviorSubject<boolean>(this._agree);
  current_agree = this.agree_subject.asObservable();

  change_agree(_agree: boolean){
    this.agree_subject.next(_agree);
  }

  //geselecteerde info
  _info: info = {list_products: [], products: '', title: '', photo: '', text: '', id: 0, category: '', text_intro: ''};  
  private info_subject = new BehaviorSubject<info>(this._info);
  current_info = this.info_subject.asObservable();

  change_info(_info: info){
      this.info_subject.next(_info); 
  }

  

  calculate_discount(x: Product){
    x.special = '';
   
    if (new Date(x.disc_start) <= new Date() && new Date() <= new Date(x.disc_stop)){
      //x.price_ori = x.price;
      if (x.percentage != null && x.percentage != 0) { 
        x.price_ori = x.price;
        x.price = +(x.price*(100-x.percentage)/100).toFixed(2);
        x.special = 'Promo: ' + x.percentage + '% korting';
      } 
      else if (x.number_to_free != null && x.number_to_free != 0 ) {
        x.special = 'Promo: ' + x.number_to_free + ' + 1 gratis';
      }
      else if (x.discount_special != null && x.discount_special != 0) {
        x.special = 'Promoverpakking';
      }
    }
    
    return x;
  }
    
  //bewaren verzendkosten
  _cost_send: number = 0;  
  private cost_send_subject = new BehaviorSubject<number>(this._cost_send);
  current_cost_send = this.cost_send_subject.asObservable();

  change_costsend(_cost: number){
    this.cost_send_subject.next(_cost);
  }
  
  //uitloggen
  _logout: boolean = false;  
  private logout_subject = new BehaviorSubject<boolean>(this._logout);
  current_logout = this.logout_subject.asObservable();

  logout(_boolean: boolean){
    this.logout_subject.next(_boolean);
  }
  
  //URL api
  //api: string = "http://127.0.0.1:8000/api/";
  api: string = "https://apoathomeapi.be/api/public/api/";
  private api_subject = new BehaviorSubject<string>(this.api);
  current_api = this.api_subject.asObservable();    

  isformvalid(form_account: account){
    this.formvalidsubject.next(false);
    if (!form_account.logged_in){
      if (form_account.telefoon != "" && form_account.postcode != "" && form_account.fname != "" && form_account.lname != "" && 
      form_account.mail != "" && form_account.straat != "" && form_account.nummer != "" && form_account.gemeente != "") 
        {
          this.http.get<string>(this.api + "users/exist/" + form_account.mail).pipe(takeUntil(this.destroy$)).subscribe(x => {  
              if (  x == "no" && form_account.fname != '' && form_account.lname != '' && this.validateEmail(form_account.mail) &&
              (form_account.telefoon != '' && form_account.telefoon.length > 8 && /^\d+$/.test(form_account.telefoon)) &&
              // (form_account.RR != '' && form_account.RR.length == 11 && /^\d+$/.test(form_account.RR)) &&
              form_account.straat != '' && form_account.nummer != '' && 
              (form_account.postcode != '' && form_account.postcode.length == 4 && /^\d+$/.test(form_account.postcode)) && form_account.gemeente != '') 
            {this.formvalidsubject.next(true);} else {this.formvalidsubject.next(false);}     
          });
        } 
    }  
    else if (form_account.fname != '' && form_account.lname != '' && this.validateEmail(form_account.mail) &&
    (form_account.telefoon != '' && form_account.telefoon.length > 8 && /^\d+$/.test(form_account.telefoon)) &&
    // (form_account.RR != '' && form_account.RR.length == 11 && /^\d+$/.test(form_account.RR)) &&
    form_account.straat != '' && form_account.nummer != '' && 
    (form_account.postcode != '' && form_account.postcode.length == 4 && /^\d+$/.test(form_account.postcode)) && form_account.gemeente != ''){
      this.formvalidsubject.next(true);    
    } 
    else {this.formvalidsubject.next(false);} 
  }  

  isfacvalid(form_account: account){

    var valid = false;
    if ((form_account.bedrijfsnaam != '' && form_account.bedrijfsnaam != null) &&
    (form_account.straat_fac != '' && form_account.straat_fac != null) && 
    (form_account.nummer_fac != '' && form_account.nummer_fac != null) &&
    (form_account.postcode_fac != '' && form_account.postcode_fac.length == 4 && /^\d+$/.test(form_account.postcode_fac)) &&
    (form_account.gemeente_fac != '' && form_account.gemeente_fac != null)) {
      valid = true;
    }
    this.facvalidsubject.next(valid);
    console.log('facvalid:' + valid);
    
  }  

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

   //order
  _order: Order = {
    _account: {lname: '', fname: '', mail: '', telefoon: '', RR: '', land: '', straat: '', nummer: '', bus: '', postcode: '', gemeente: '', info: '', 
    nieuw: true, logged_in: false, failed_login: 0, uid: "0", bedrijfsnaam: '',vertegenwoordiger: '', mail_fac: '', land_fac: '',straat_fac: '', 
    nummer_fac: '', bus_fac: '', postcode_fac: '', gemeente_fac: '', id: 0 },
    creationtime: new Date(), 
    cart: [],
    delivery: {lname: '', fname: '', straat: '', nummer: '', bus: '', postcode: 0, gemeente: '', land: 'België', opm: '', zelf_site: 0, id: 0}, 
    deliverytime: new Date(),
    min_deliverytime: new Date(),
    deliverytime_txt: "",
    availabletime_txt: "",
    cart_available: true,
    cost: 0,
    cost_btw: 0,
    cost_total:0
    };  

  private order_subject = new BehaviorSubject<Order>(this._order);
  Current_order = this.order_subject.asObservable();

 order_add_account(_account: account){
   this._order._account = _account;
   this.order_subject.next(this._order);
 }

 order_add_cartitems(_cartitems: cartitem[]){
  var totaal = 0;
  var totaal_btw = 0;

  for (let x of _cartitems){
    
    if (this._order.cart_available){
      if (x.product.active == 0) {this._order.cart_available = false;}
    }    
    totaal = totaal + x.product.price*x.quantity;
    totaal_btw = totaal_btw + (x.quantity*x.product.price*x.product.btw/100);
  }  

  this._order.cost = totaal;
  
  this._order.cost_btw = totaal_btw;
  this._order.cart = _cartitems;
  this.order_subject.next(this._order);
  this.calc_deliverytime(this._order);
}

order_add_delivery(_delivery: delivery_address){
  this._order.delivery = _delivery;
  this.order_subject.next(this._order);
  this.calc_deliverytime(this._order);
}

order_add_costtotal(_cost: number){
  this._order.cost_total = _cost;
  this.order_subject.next(this._order);
}

order_add_deliverytime(time: Date){
  this._order.deliverytime = time;
  this._order.deliverytime_txt = this.convert_day(time.getDay()) + " " + time.getDate() + " " + this.convert_month(time.getMonth()) + " " + time.getFullYear();
  this.order_subject.next(this._order);
}

calc_deliverytime(_order2: Order){

  const creationtime = _order2.creationtime.getHours() * 60 + _order2.creationtime.getMinutes();
  var result = new Date();
  var result_av = new Date();

  // order bevat alleen beschikbare (in apotheek of via febelco) producten
  if (_order2.cart_available){
    // order moet geleverd worden op postcodes 9255, 9280 of 9200
    if (_order2.delivery.postcode == 9255 || _order2.delivery.postcode == 9280 || _order2.delivery.postcode == 9200){
      // order is doorgestuurd tussen 00:00 en 11:30
      if (creationtime <=  11 * 60 + 30){ result = _order2.creationtime; result_av = _order2.creationtime;}
      //order is doorgestuurd tussen 11:31 en 00:00
      else {result = this.addDays(_order2.creationtime,1); result_av = this.addDays(_order2.creationtime,1)}
    }
    else if (creationtime <= 16 * 60){ result = this.addDays(_order2.creationtime,2); result_av = this.addDays(_order2.creationtime,1)}
    else { result = this.addDays(_order2.creationtime,3); result_av = this.addDays(_order2.creationtime,2)}
  }
  else {
     // order moet geleverd worden op postcodes 9255, 9280 of 9200
     if (_order2.delivery.postcode == 9255 || _order2.delivery.postcode == 9280 || _order2.delivery.postcode == 9200){
      // order is doorgestuurd tussen 00:00 en 11:30
      if (creationtime <=  11 * 60 + 30){ result = this.addDays(_order2.creationtime,1); result_av = this.addDays(_order2.creationtime,1)}
      //order is doorgestuurd tussen 11:31 en 00:00
      else {result = this.addDays(_order2.creationtime,2); result_av = this.addDays(_order2.creationtime,2)}
    }
    else if (creationtime <= 16 * 60){ result = this.addDays(_order2.creationtime,3); result_av = this.addDays(_order2.creationtime,2)}
    else { result = this.addDays(_order2.creationtime,4); result_av = this.addDays(_order2.creationtime,3)}
  }

  // checken of result in het weekend ligt, dan verleggen datum naar maandag
  if (result.getDay() == 0){ result = this.addDays(result,1);}
  else if (result.getDay() == 6){ result = this.addDays(result,2);}

  // result doorsturen naar order
  this._order.deliverytime = result;
  this._order.min_deliverytime = result;
  this._order.deliverytime_txt = this.convert_day(result.getDay()) + " " + result.getDate() + " " + this.convert_month(result.getMonth()) + " " + result.getFullYear();
  this._order.availabletime_txt = this.convert_day(result_av.getDay()) + " " + result_av.getDate() + " " + this.convert_month(result_av.getMonth()) + " " + result_av.getFullYear();
  
  this.order_subject.next(this._order);
}

addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

convert_day(day){
  if (day == 1){return "maandag"}
  else if (day == 2){return "dinsdag"}
  else if (day == 3){return "woensdag"}
  else if (day == 4){return "donderdag"}
  else if (day == 5){return "vrijdag"}  
  return "";
}

convert_month(month){
  if (month == 0) {return "januari"}
  else if (month == 1) {return "februari"}
  else if (month == 2) {return "maart"}
  else if (month == 3) {return "april"}
  else if (month == 4) {return "mei"}
  else if (month == 5) {return "juni"}
  else if (month == 6) {return "juli"}
  else if (month == 7) {return "augustus"}
  else if (month == 8) {return "september"}
  else if (month == 9) {return "oktober"}
  else if (month == 10) {return "november"}
  else if (month == 11) {return "december"}
}

}
