import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { cartitem, kortingscode, Order, Product, vestiging } from 'src/app/model';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent implements OnInit, OnDestroy {

  constructor(public formBuilder: FormBuilder, public _aposervice: ApoService, private calendar: NgbCalendar, public http: HttpClient, private toastr: ToastrService) { 

     //to disable specific date and specific weekdays
     this.isDisabled = ( date: NgbDateStruct) => { return (this.json.disable.includes(calendar.getWeekday(new NgbDate(date.year,date.month,date.day))) 
      ) ? true : false;
    };

    this.form_delivery.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {      
      this.check_ordervalid();
      _aposervice.order_add_delivery(val);
      if (val.postcode == 0 || val.postcode.length != 4 || !/^\d+$/.test(val.postcode)) {this.postnl = "geen";}
      else if (val.postcode == 9280 || val.postcode == 9255 || val.postcode == 9200){ this.postnl = "neen";} 
      else {this.postnl = "ja";}     
      this.calc_verzendkost();
    })

    this.form_delivery.get("factuurgegevens").valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val) {
        this.form_delivery.controls['accountgegevens'].patchValue(false);
        this.form_delivery.controls['andere'].patchValue(false); 
        
        this.form_delivery.controls['lname'].patchValue(this.bedrijfsnaam);
        this.form_delivery.controls['fname'].patchValue(this.vertegenwoordiger);
        this.form_delivery.controls['straat'].patchValue(this.straat_fac);
        this.form_delivery.controls['nummer'].patchValue(this.nummer_fac);
        this.form_delivery.controls['bus'].patchValue(this.bus_fac);
        this.form_delivery.controls['postcode'].patchValue(this.postcode_fac);
        this.form_delivery.controls['gemeente'].patchValue(this.gemeente_fac);  
        this.eerste_veld = "Bedrijfsnaam";
        this.tweede_veld = "Vertegenwoordiger";
        this.modus_selected = "fac";
        this.zelf = false;
        this.accountvalid = true;
        this.factvalid = false;
        this.andervalid = true;
        this.zelfvalid = true;
        this.calc_verzendkost();
      }      
    });

    this.form_delivery.get("accountgegevens").valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val) {
        this.form_delivery.controls['factuurgegevens'].patchValue(false);
        this.form_delivery.controls['andere'].patchValue(false);  
        
        this.form_delivery.controls['lname'].patchValue(this.lname);
        this.form_delivery.controls['fname'].patchValue(this.fname);
        this.form_delivery.controls['straat'].patchValue(this.straat);
        this.form_delivery.controls['nummer'].patchValue(this.nummer);
        this.form_delivery.controls['bus'].patchValue(this.bus);
        this.form_delivery.controls['postcode'].patchValue(this.postcode);
        this.form_delivery.controls['gemeente'].patchValue(this.gemeente);  
        this.eerste_veld = "Naam";
        this.tweede_veld = "Voornaam";
        this.modus_selected = "acc";
        this.zelf = false;
        this.accountvalid = false;
        this.factvalid = true;
        this.andervalid = true;
        this.zelfvalid = true;
        this.calc_verzendkost();
      }      
    });

    this.form_delivery.get("zelf_afhalen").valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val) {
        this.zelf = true;
        this.accountvalid = true;
        this.factvalid = true;
        this.andervalid = true;
        this.zelfvalid = false;
        this.form_delivery.controls['lname'].patchValue('');
        this.form_delivery.controls['fname'].patchValue('');
        this.form_delivery.controls['straat'].patchValue('');
        this.form_delivery.controls['nummer'].patchValue('');
        this.form_delivery.controls['bus'].patchValue('');
        this.form_delivery.controls['postcode'].patchValue('');
        this.form_delivery.controls['gemeente'].patchValue(''); 
        this.calc_verzendkost();
      }});

    this.form_delivery.get("andere").valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val) {
        this.form_delivery.controls['accountgegevens'].patchValue(false);
        this.form_delivery.controls['factuurgegevens'].patchValue(false); 
        this.items_focussed = [];
        
        this.form_delivery.controls['lname'].patchValue('');
        this.form_delivery.controls['fname'].patchValue('');
        this.form_delivery.controls['straat'].patchValue('');
        this.form_delivery.controls['nummer'].patchValue('');
        this.form_delivery.controls['bus'].patchValue('');
        this.form_delivery.controls['postcode'].patchValue('');
        this.form_delivery.controls['gemeente'].patchValue('');        
        this.eerste_veld = "Naam";
        this.tweede_veld = "Voornaam";
        this.modus_selected = "neo";
        this.zelf = false;
        this.accountvalid = true;
        this.factvalid = true;
        this.andervalid = false;
        this.zelfvalid = true;
        this.calc_verzendkost();
      }      
    });

    _aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.current_mailadres = data.mail;
      if (data.lname != 'null'){ this.lname = data.lname;}
      if (data.fname != 'null'){ this.fname = data.fname;}
      if (data.straat != 'null'){this.straat = data.straat;}
      if (data.gemeente != 'null'){this.gemeente = data.gemeente;}
      if (data.nummer != 'null'){ this.nummer = data.nummer;}
      if (data.bus != 'null'){ this.bus = data.bus;}
      if (data.postcode != 'null'){ this.postcode = data.postcode;}
      if (data.vertegenwoordiger != 'null'){ this.vertegenwoordiger = data.vertegenwoordiger;}
      if (data.bedrijfsnaam != 'null'){ this.bedrijfsnaam = data.bedrijfsnaam;}
      if (data.straat_fac != 'null'){this.straat_fac = data.straat_fac;}
      if (data.gemeente_fac != 'null'){this.gemeente_fac = data.gemeente_fac;}
      if (data.nummer_fac != 'null'){ this.nummer_fac = data.nummer_fac;}
      if (data.bus_fac != 'null'){ this.bus_fac = data.bus_fac;}
      if (data.postcode_fac != 'null'){ this.postcode_fac = data.postcode_fac;}

      if (this.modus_selected == "acc"){
        this.form_delivery.controls['lname'].patchValue(this.lname);
        this.form_delivery.controls['fname'].patchValue(this.fname);
        this.form_delivery.controls['straat'].patchValue(this.straat);
        this.form_delivery.controls['nummer'].patchValue(this.nummer);
        this.form_delivery.controls['bus'].patchValue(this.bus);
        this.form_delivery.controls['postcode'].patchValue(this.postcode);
        this.form_delivery.controls['gemeente'].patchValue(this.gemeente);   
      }
      else if (this.modus_selected == "fac"){
        this.form_delivery.controls['lname'].patchValue(this.bedrijfsnaam);
        this.form_delivery.controls['fname'].patchValue(this.vertegenwoordiger);
        this.form_delivery.controls['straat'].patchValue(this.straat_fac);
        this.form_delivery.controls['nummer'].patchValue(this.nummer_fac);
        this.form_delivery.controls['bus'].patchValue(this.bus_fac);
        this.form_delivery.controls['postcode'].patchValue(this.postcode_fac);
        this.form_delivery.controls['gemeente'].patchValue(this.gemeente_fac); 
      }
 
    });

    _aposervice.current_facvalid.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.facvalid = data;
    });

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this._api = data;
    });

    _aposervice.current_promocode.pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.form_delivery.controls['promo'].patchValue(data);
          if (data != ""){
            this.promo_ok = 'true';
          }
          else {this.promo_ok = '';}
    });

    _aposervice.Current_order.pipe(takeUntil(this.destroy$)).subscribe(data => {   
      this.cost = data.cost;
      this.calc_verzendkost();        
      this._order = data;
      this.minjaar = data.min_deliverytime.getFullYear();
      this.minmaand = data.min_deliverytime.getMonth() + 1;
      this.mindag = data.min_deliverytime.getDate();      
      for (let x of data.cart){
        if (x.product.name == "Beginnerskorting" || x.product.name == "Korting mailing nieuwsbrief"){
          this.code_used = true;
        }
        else {
          this.code_used = false;
        }     
       }     
    });
   
  }

  calc_verzendkost(){
      
    if (this.postnl == 'ja'){
      if (this.cost < 27){
        this.kost_verzend = "4,75€ (gratis bij aankoop van minimum 27€)";
        this._aposervice.change_costsend(4.75);
      }
      else {
        this.kost_verzend = "Gratis verzending";
        this._aposervice.change_costsend(0);
      }
    }
    else if (this.postnl == 'neen') 
    { this.kost_verzend = "Gratis (levering aan huis is gratis in de postcodes 9280, 9255 en 9200)"; 
    this._aposervice.change_costsend(0);
  }
    else {
      this.kost_verzend = "";
      this._aposervice.change_costsend(0);
    }
  }

  open_datepicker(){
    this.show_datepicker = !this.show_datepicker;
    this.btn1.nativeElement.focus();
  }

  date_select(date: NgbDate){   
    var deltime = new Date(date.year.toString() + "-" + date.month.toString() + "-" + date.day.toString());
    this._aposervice.order_add_deliverytime(deltime);
    this.show_datepicker = false;
  }

  convert_month(maand){
    if (maand > 1) {return maand - 1;}
    else return 12;
  }

  convert_year(jaar){
    if (jaar > 2021) {return jaar - 1;}
    else return jaar;
  }

  ngOnInit(): void {
    this.vestigingen.push({naam: "Apotheek Ceulemans", adres: "Kerkstraat 97, 9255 Buggenhout"})
    this.vestigingen.push({naam: "Apotheek Van Causbroeck", adres: "Kerkstraat 88, 9200 Dendermonde"})
  }

  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }

  @ViewChild('btn1') btn1: ElementRef;
  lname = '';
  fname = '';
  straat = '';
  nummer = '';
  bus = '';
  postcode = '';
  gemeente = ''; 
  bedrijfsnaam = '';
  vertegenwoordiger = '';
  straat_fac = '';
  nummer_fac = '';
  bus_fac = '';
  postcode_fac = '';
  gemeente_fac = ''; 
  opm = '';
  facvalid: Boolean = false;
  eerste_veld = "Naam";
  tweede_veld = "Voornaam";
  modus_selected = "acc";
  cart_available = true;
  _order: Order = new Order();
  accountvalid = false;
  factvalid = true;
  andervalid = true;
  zelfvalid = true;
  vestigingen: vestiging[] = [];
  show_datepicker = false;
  minjaar = 0;
  minmaand = 0;
  mindag = 0;
  isDisabled;
  json = {disable: [6, 7]};
  postnl = "geen";
  cost = 0;
  kost_verzend = "";

  form_delivery = this.formBuilder.group({
    lname: '',
    fname: '',
    land: 'België',
    straat: '',
    nummer: '',
    bus: '',
    postcode: '',
    gemeente: '',
    vertegenwoordiger: '',
    bedrijfsnaam: '',
    accountgegevens: true,
    factuurgegevens: false,
    andere: false,
    opm: '',
    zelf_afhalen: false,
    zelf_site: 0,
    promo: ''
  });

  focus_item = '';
  items_focussed = [];  
  account_selected = true;
  factuur_selected = true;
  andere_selected = false;
  selected = 0;
  apo_selected = 3;
  private destroy$: Subject<void> = new Subject<void>();
  zelf = false;
  zelf_locatie = "geen";
  _api = "";  
  code_txt = '';
  code_used = false;
  current_mailadres = "";
  promo_ok = '';

  change_apo(event, index){
    if (event.checked){
      this.apo_selected = index;
      if (index == 0){this.zelf_locatie = "apo_ceulemans"; this.form_delivery.controls["zelf_site"].patchValue(1);}
      else if (index == 1){this.zelf_locatie = "apo_vancausbroeck"; this.form_delivery.controls["zelf_site"].patchValue(2);}
      else {this.zelf_locatie = "geen"; this.form_delivery.controls["zelf_site"].patchValue(0);}
    }
    else {
      this.zelf_locatie = "geen"; this.form_delivery.controls["zelf_site"].patchValue(0);
    }   
    this.check_ordervalid();
    this._aposervice.order_add_delivery({
      lname: this.form_delivery.controls["lname"].value,
      fname: this.form_delivery.controls["fname"].value,
      straat: this.form_delivery.controls["straat"].value,
      nummer: this.form_delivery.controls["nummer"].value,
      bus: this.form_delivery.controls["bus"].value,
      postcode: this.form_delivery.controls["postcode"].value,
      gemeente: this.form_delivery.controls["gemeente"].value,
      land: this.form_delivery.controls["land"].value,
      opm: this.form_delivery.controls["opm"].value,
      zelf_site: this.form_delivery.controls["zelf_site"].value,
      id: 0
    });
  }

  setMyStyles_account(account_item){
    let styles = {
      'border-color': this.form_delivery.controls[account_item].value == null ? '' : this.focus_item == account_item ? '' : this.form_delivery.controls[account_item].value != ''  ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_account5(account_item){
    let styles = {
      'border-color': this.focus_item == account_item ? '' : (this.form_delivery.controls[account_item].value != '' && this.form_delivery.controls[account_item].value != null) ? 'rgba(81,189,77,255)' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_account6(account_item){
    let styles = {
      'border-color': (this.focus_item == account_item || this.form_delivery.controls['promo'].value == '') ? '' : this.promo_ok == 'true' ? 'rgba(81,189,77,255)' : 'red',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_account4(account_item){
    let styles = {
      'border-color': this.form_delivery.controls[account_item].value == null ? 'red' : this.focus_item == account_item ? '' : (this.form_delivery.controls[account_item].value != ''  && this.form_delivery.controls[account_item].value.length== 4 && /^\d+$/.test(this.form_delivery.controls[account_item].value)) ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_check(account_item){
    let styles = {
      'display': this.form_delivery.controls[account_item].value == null ? 'none' : this.focus_item == account_item ? 'none' : this.form_delivery.controls[account_item].value != ''  ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_check4(account_item){
    let styles = {
      'display': this.form_delivery.controls[account_item].value == null ? 'none' : this.focus_item == account_item ? 'none' : (this.form_delivery.controls[account_item].value != '' && this.form_delivery.controls[account_item].value.length == 4 && /^\d+$/.test(this.form_delivery.controls[account_item].value)) ? '' : 'none',
    };
    return styles;
  }  

  set_blur(item){
    if (this.focus_item == item){
      this.focus_item = '';
    }
  }

  set_focus(item){
    this.focus_item = item;
    if (this.items_focussed.findIndex(x => x == item) == -1){this.items_focussed.push(item);}
  }

  set_delivery_address(check: string){
    console.log(check);
    // adres invoeren in velden
  }
/* 
  check1(event){    
    this.account_selected = event.checked;
    if (event.checked) {
      this.factuur_selected = true;
      this.andere_selected = false;   
      document.getElementById('2').setAttribute('checked','false')   
      document.getElementById('3').setAttribute('checked','false')   
    }
    
  } */

  check_ordervalid(){
    if (( this.form_delivery.controls["lname"].value != '' &&
          this.form_delivery.controls["fname"].value != '' &&
          this.form_delivery.controls["straat"].value != '' &&
          this.form_delivery.controls["nummer"].value != '' &&
          this.form_delivery.controls["postcode"].value != '' &&
          this.form_delivery.controls["gemeente"].value != '' &&
          this.form_delivery.controls["postcode"].value.length == 4 && 
          /^\d+$/.test(this.form_delivery.controls["postcode"].value)) || 
          (this.zelf_locatie != "geen" && this.zelf)){ this._aposervice.change_ordervalid(true);}
    else {this._aposervice.change_ordervalid(false);}
  }

  

  apply_code(){
    console.log(this.code_used);
    
    var code = this.form_delivery.controls['promo'].value;
    var _code : kortingscode = {
      code : code,
      valid : true,
      quantity_min : 50,
      used: false,
      name: 'Beginnerskorting',
      discount: 5
    }

    if (code != "NEO22" && code != "neo22"){
      _code.name = "Korting mailing nieuwsbrief";
      this.http.get<string>(this._api + "mailing/" + code).pipe(takeUntil(this.destroy$)).subscribe(x => { 
        
        if (x != "yippie"){
          _code.valid = false;
        }

        // checken of code valide is en wat het minimum aantal is   
        if (!_code.valid){ 
          this.promo_ok = 'false';
          this.code_txt = 'kortingscode \'' + _code.code + '\' niet (meer) geldig';
          this.toastr.error(this.code_txt, 'FOUT', {timeOut: 3000, positionClass: 'toast-bottom-center'})} 
        else if (this.code_used) {
          this.promo_ok = 'false';
          this.code_txt = 'Er is reeds een kortingscode gebruikt; codes zijn niet cumuleerbaar'; 
          this.toastr.error(this.code_txt, 'FOUT', {timeOut: 3000, positionClass: 'toast-bottom-center'})}
        else if (this.cost < _code.quantity_min) {
          this.promo_ok = 'false';
          this.code_txt = 'kortingscode \'' + _code.code + '\' geldig vanaf een aankoopbedrag van ' + _code.quantity_min + '€';
          this.toastr.error(this.code_txt, 'FOUT', {timeOut: 3000, positionClass: 'toast-bottom-center'}) }
        else {
          this.promo_ok = 'true';
          var code_product: Product = {name: _code.name, price: -_code.discount, price_disc: -_code.discount, info_short_product: '', photo: '', cnk: '', brand: '', percentage: 0, attention_product: 0, popular: 0, id: 0, 
          subcategory: '', documentation: '', prescription: '', principle: 'code', _use: '', composition: '', favoriet: false, active: 1, special: '', creationtime: new Date(), 
          price_ori:-_code.discount, number_to_free: 0, discount_special: 0, btw: 0, discount_text: '', disc_start: new Date(), disc_stop: new Date(), att_start: new Date(), att_stop: new Date(),
          check: false, check_select: false, principle2: '', info: '', product_collection: ''};
          var code_cartitem: cartitem = {product: code_product, quantity: 1, quantity_free: 0, promocode: code}
          this._aposervice.add_code(this.form_delivery.controls['promo'].value);
          this._aposervice.addProduct(code_cartitem);
        }
      })  
    }

    else {      

          this.http.get<string>(this._api + "mailing/" + this.current_mailadres + "/NEO22").pipe(takeUntil(this.destroy$)).subscribe(x => { 

            if (x != "yippie"){
                  _code.valid = false;
            }

            // checken of code valide is en wat het minimum aantal is   
            if (!_code.valid){ 
              this.promo_ok = 'false';
              this.code_txt = 'kortingscode \'NEO22\' is reeds gebruikt op dit account';
              this.toastr.error(this.code_txt, 'FOUT', {timeOut: 3000, positionClass: 'toast-bottom-center'})} 
            else if (this.code_used) {
              this.promo_ok = 'false';
              this.code_txt = 'Er is reeds een kortingscode gebruikt; codes zijn niet cumuleerbaar'; 
              this.toastr.error(this.code_txt, 'FOUT', {timeOut: 3000, positionClass: 'toast-bottom-center'})}
            else if (this.cost < 50) {
              this.promo_ok = 'false';
              this.code_txt = 'kortingscode \'' + _code.code + '\' geldig vanaf een aankoopbedrag van 50€';
              this.toastr.error(this.code_txt, 'FOUT', {timeOut: 3000, positionClass: 'toast-bottom-center'}) }
            else {
              this.promo_ok = 'true';
              var code_product: Product = {name: _code.name, price: -_code.discount, price_disc: -_code.discount, info_short_product: '', photo: '', cnk: '', brand: '', percentage: 0, attention_product: 0, popular: 0, id: 0, 
              subcategory: '', documentation: '', prescription: '', principle: 'code', _use: '', composition: '', favoriet: false, active: 1, special: '', creationtime: new Date(), 
              price_ori:-_code.discount, number_to_free: 0, discount_special: 0, btw: 0, discount_text: '', disc_start: new Date(), disc_stop: new Date(), att_start: new Date(), att_stop: new Date(),
              check: false, check_select: false, principle2: '', info: '', product_collection: ''};
              var code_cartitem: cartitem = {product: code_product, quantity: 1, quantity_free: 0, promocode: 'NEO22'}
              this._aposervice.addProduct(code_cartitem);
              this._aposervice.add_code(this.form_delivery.controls['promo'].value);
            }
          })  
    }
   
  }
}
