import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { info } from 'src/app/model';

@Component({
  selector: 'app-blog-single',
  templateUrl: './blog-single.component.html',
  styleUrls: ['./blog-single.component.css']
})
export class BlogSingleComponent implements OnInit, OnDestroy {

  _info: info = {list_products: [], products: '', title: '', photo: '', text: '', id: 0, category: '', text_intro: ''};
  private destroy$: Subject<void> = new Subject<void>();
  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;
  _api = "";
  list_info = [];
  cat = "";
  
  slideConfig1 = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "arrows": false,
    "dots": false,
    "autoplay": true,
    "responsive": [
     {
       breakpoint: 1024,
       settings: {
         slidesToShow: 2,
         slidesToScroll: 1,
         arrows: false,
         dots: false,
         autoplay: true
       }
     },
     {
       breakpoint: 800,
       settings: {
         slidesToShow: 1,
         slidesToScroll: 1,
         arrows: false,
         dots: false,
         autoplay: true
       }
     }
   ]
   };

  constructor(public _aposervice: ApoService, public http: HttpClient, public router: Router) {

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this._api = data;      
    })

    _aposervice.current_info.pipe(takeUntil(this.destroy$)).subscribe(data => {

      if (data.id == 0){
        //terug naar home
        this.router.navigate(['']);

      }

      this._info = data; 
      this.list_info = [];
      
      this.http.get<any>(this._api + "infos/15").pipe(takeUntil(this.destroy$)).subscribe(data => {
          for (let x of data){
            
            var products = [];
            for (let y of x[1]){
              for (let z of y){
                if (z.photo != null) { z.photo = "https://apoathomeapi.be/data/foto/" + z.photo;}
                else {z.photo = "https://apoathomeapi.be/data/no_image.jpg"}
                z.active = 1; 
                z.percentage = z.percentage;
                z.number_to_free = z.number_to_free;
                z = this._aposervice.calculate_discount(z); 
                products.push(z);
              }
            }       
  
            var __info: info = {
              title: x[0].title, text: x[0].text, products: x[0].products, id: x[0].id, category: x[0].category, photo: "https://apoathomeapi.be/data/info/" + x[0].photo,
              text_intro: x[0].text_intro, list_products: products}              

            if (__info.id != this._info.id){this.list_info.push(__info);}
            
          }
        });     
    })
  }

  ngOnInit(): void {
    this.to_top();    
  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();    
  }  
      
  next() {
    this.slickModal.slickNext();
  }
  
  prev() {
    this.slickModal.slickPrev();
  }

  select_info(id){
    this._aposervice.change_info(this.list_info[this.list_info.findIndex(x => x.id == id)]);
    this.to_top();
  }

  scrolltotop(){
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView({behavior: "smooth"});
      top = null;
    }  
  }

  to_top(){
    setTimeout (() => {
      this.scrolltotop();
   }, 200);
  }
  
  change_page(cat: string){
    this._aposervice.select_cat(cat);
  }

}
