<ngb-accordion [closeOthers]="true" activeIds="static-1" #acc="ngbAccordion">
    <ngb-panel id="static-1" title="Accountgegevens" style="background-color: red !important" >
        <ng-template ngbPanelContent >
<div>                
    <form method="post" [formGroup]="form_account">
        <div class="row"> 
            <div class="form-group col-lg-12" [ngStyle]="{'display': this.modusaccount == 'afrekenen'? '' : 'none'}" >
               <span style="font-family: 'Montserrat'; color: blue; font-weight: 500;">Vul je gegevens in .. sla ze op of ga verder als gast. Heb je al een account, log dan aub in.</span> 
            </div>
            <div class="form-group col-lg-12" [ngStyle]="{'display': this.modusaccount == 'neo'? '' : 'none'}" >
                <span style="font-family: 'Montserrat'; color: blue; font-weight: 500;">Vul je gegevens in en kies hierna een paswoord</span> 
             </div>
             <div class="form-group col-lg-6" [ngStyle]="{'display': this.modusaccount == 'data'? '' : 'none'}" >
                <span style="font-family: 'Montserrat'; color: blue; font-weight: 500;">Uw gegevens</span> 
             </div>
             <div class="form-group col-lg-6" [ngStyle]="{'display': this.modusaccount == 'data'? '' : 'none'}" >
                <a style="font-family: 'Montserrat'; color: red; font-weight: 500; float: right;" data-dismiss="modal">Sluiten</a> 
             </div>

            <div class="form-group col-lg-7">
                <input (input)="change_txt($event.target.value, 'lname')" type="text" placeholder="Naam" (blur)="set_blur('lname')" (focus)="set_focus('lname')" [ngStyle]="setMyStyles_account('lname')" formControlName="lname" class="form-control account">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('lname')"></i></font>
            </div>
            <div class="form-group col-lg-5">
                <input (input)="change_txt($event.target.value, 'fname')" type="text" placeholder="Voornaam" (blur)="set_blur('fname')" (focus)="set_focus('fname')" [ngStyle]="setMyStyles_account('fname')" formControlName="fname" class="form-control account" name="fname" value="">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('fname')"></i></font>
            </div>                        
            <div class="form-group col-lg-4">
                <input type="email" (input)="change_txt($event.target.value, 'mail')" placeholder="Email" placement="top" ngbTooltip="Dit mailadres is al gekoppeld aan een account. Gelieve een nieuwe te kiezen of in te loggen" [disableTooltip]="!this.account_db" (blur)="set_blur('mail')" (focus)="set_focus('mail')" [ngStyle]="setMyStyles_account2('mail')" [attr.disabled]="mailblock?'':null" formControlName="mail" class="form-control account" name="mail" value="">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check2('mail')"></i></font>
            </div>
            <div class="form-group col-lg-2" placement="top" ngbTooltip="telefoonnummer zonder / of . enkel cijfers toegelaten" [disableTooltip]="this.modusaccount == 'afrekenen'">
                <input (input)="change_txt($event.target.value, 'telefoon')" type="text" (blur)="set_blur('telefoon')" (focus)="set_focus('telefoon')" [ngStyle]="setMyStyles_account3('telefoon')" placeholder="Telefoon/GSM" formControlName="telefoon" class="form-control account" name="telefoon" value="">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check3('telefoon')"></i></font>
            </div>
            <div class="form-group col-lg-3" placement="top" ngbTooltip="Rijksregisternr zonder - of . bestaat uit 11 cijfers" [disableTooltip]="this.modusaccount == 'afrekenen'">
                <input (input)="change_txt($event.target.value, 'RR')" type="text" placeholder="Rijksregisternummer" maxlength="11" (blur)="set_blur('RR')" (focus)="set_focus('RR')" [ngStyle]="setMyStyles_account6('RR')" formControlName="RR" class="form-control account" name="RR" value="">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check6('RR')"></i></font>
                <a href="javascript:void(0)"  data-toggle="modal" data-target="#RRmodal" ><font size="1"><i  matTooltip="klik voor meer info" class="fas fa-question-circle fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check7('RR')"></i></font></a>
            </div>
            <div class="form-group col-lg-3">
                <input (input)="change_txt($event.target.value, 'land')" type="text" placeholder="Land" formControlName="land" class="form-control account" name="land" value="" disabled placement="top" ngbTooltip="Voorlopig leveren we enkel in België">
            </div>
            <div class="form-group col-lg-5">
                <input (input)="change_txt($event.target.value, 'straat')" type="text" placeholder="Straat" (blur)="set_blur('straat')" (focus)="set_focus('straat')" [ngStyle]="setMyStyles_account('straat')" formControlName="straat" class="form-control account" name="straat" value="">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('straat')"></i></font>
            </div>
            <div class="form-group col-lg-1">
                <input (input)="change_txt($event.target.value, 'nummer')" type="text" placeholder="Nr." (blur)="set_blur('nummer')" (focus)="set_focus('nummer')" [ngStyle]="setMyStyles_account('nummer')" formControlName="nummer" class="form-control account" name="nummer" value="">
            </div>
            <div class="form-group col-lg-1">
                <input (input)="change_txt($event.target.value, 'bus')" type="text" placeholder="Bus" (blur)="set_blur('bus')" (focus)="set_focus('bus')" [ngStyle]="setMyStyles_account5('bus')" formControlName="bus" class="form-control account" name="bus" value="">
            </div>
            <div class="form-group col-lg-2" placement="top" ngbTooltip="Enkel cijfers toegelaten bestaat uit 4 cijfers" [disableTooltip]="this.modusaccount == 'afrekenen'">
                <input (input)="change_txt($event.target.value, 'postcode')" type="text" (blur)="set_blur('postcode')" (focus)="set_focus('postcode')" maxlength="4" [ngStyle]="setMyStyles_account4('postcode')"  placeholder="Postcode" formControlName="postcode" class="form-control account" name="postcode" value="">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check4('postcode')"></i></font>
            </div>
            <div class="form-group col-lg-3">
                <input (input)="change_txt($event.target.value, 'gemeente')" type="text" placeholder="Gemeente" (blur)="set_blur('gemeente')" (focus)="set_focus('gemeente')" [ngStyle]="setMyStyles_account('gemeente')" formControlName="gemeente" class="form-control account" name="gemeente" value="">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('gemeente')"></i></font>
            </div>
            <div class="form-group col-lg-12">
                <input (input)="change_txt($event.target.value, 'info')" type="text" formControlName="info" (blur)="set_blur('info')" (focus)="set_focus('info')" [ngStyle]="setMyStyles_account5('info')" placeholder="Is er nog relevante informatie omtrent uw gezondheid dat we moeten weten?" class="form-control account" name="opm" value="">
            </div>
            <div class="form-group col-lg-2" [ngStyle]="setMyStyles_layout1()" >
            </div>
            <div class="form-group col-lg-3" [ngStyle]="setMyStyles_layout1()">
                <button *ngIf="confirm_txt =='ingelogd' || confirm_txt =='gegevens aangepast' || confirm_txt =='gegevens opgeslagen'" class="andro_btn-custom primary btn-block account1" (click)="confirm_account()" data-toggle="modal" data-target="#choose_pwd" data-backdrop="static" data-keyboard="false" [disabled] = "true" >
                    {{confirm_txt}}</button>
                <button *ngIf="confirm_txt =='gegevens opslaan?'" class="andro_btn-custom primary btn-block account1" (click)="confirm_account()" data-toggle="modal" data-target="#choose_pwd" data-backdrop="static" data-keyboard="false">{{confirm_txt}}</button>
                <button *ngIf="confirm_txt =='gegevens aanpassen?'" class="andro_btn-custom primary btn-block account1" (click)="confirm_account()">{{confirm_txt}} 
                    <mat-spinner [diameter]="20" style="position: absolute; margin-left: 65px; margin-top: -23px" [ngStyle]="{'display': spinner_active ? '' : 'none'}"></mat-spinner></button>
                <button *ngIf="confirm_txt =='inloggen'" class="andro_btn-custom primary btn-block account1" data-toggle="modal" data-target="#login_fb" data-backdrop="static" data-keyboard="false">
                    INLOGGEN
                </button>
            </div>
            <div class="form-group col-lg-2" [ngStyle]="setMyStyles_layout1()" >
            </div>
            <div class="form-group col-lg-3">
                <button [ngClass]="facvalid ? 'account2' : 'account1'" class="andro_btn-custom primary btn-block" (click)="acc.toggle('static-2')">factuurgegevens
                    <font size="1"><i [ngStyle]="{'display': this.facvalid ? '' : 'none'}" class="fas fa-check fa-2x" style="color: white; position: absolute; right: 20px; top: 13px;"></i></font>
                </button>               
            </div>
            <div class="form-group col-lg-2">
            </div>
        </div>
    </form>
</div>
</ng-template>
</ngb-panel>
<ngb-panel id="static-2" title="FACTUURGEGEVENS">
    <ng-template ngbPanelContent >

        <form method="post" [formGroup]="form_account">
            <div class="row" style="min-height: 395px;"> 
                <div class="form-group col-lg-11">
                   <span style="font-family: 'Montserrat'; color: blue; font-weight: 500;">De aankoopfactuur wordt standaard opgemaakt met de accountgegevens. Indien dit niet ok is, voer hier de juiste gegevens in ...  </span> 
                </div>                
                <div class="form-group col-lg-1" [ngStyle]="{'display': this.modusaccount == 'data'? '' : 'none'}" >
                    <a style="font-family: 'Montserrat'; color: red; font-weight: 500; float: right;" data-dismiss="modal" (click)="back_to_account()">Sluiten</a> 
                </div>    
                <div class="form-group col-lg-12">
                    <input (input)="change_txt($event.target.value, 'bedrijfsnaam')" type="text" placeholder="Bedrijfsnaam" (blur)="set_blur('bedrijfsnaam')" (focus)="set_focus('bedrijfsnaam')" [ngStyle]="setMyStyles_account5('bedrijfsnaam')" formControlName="bedrijfsnaam" class="form-control account">
                    <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('bedrijfsnaam')"></i></font>
                </div>  
                   
                <div class="form-group col-lg-5">
                    <input (input)="change_txt($event.target.value, 'vertegenwoordiger')" type="text" placeholder="vertegenwoordiger" (blur)="set_blur('vertegenwoordiger')" (focus)="set_focus('vertegenwoordiger')" [ngStyle]="setMyStyles_account5('vertegenwoordiger')" formControlName="vertegenwoordiger" class="form-control account">
                    <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('vertegenwoordiger')"></i></font>
                </div>                   
                <div class="form-group col-lg-4">
                    <input (input)="change_txt($event.target.value, 'mail_fac')" type="Mail" placeholder="Email" (blur)="set_blur('mail_fac')" (focus)="set_focus('mail_fac')" [ngStyle]="setMyStyles_account7('mail_fac')" formControlName="mail_fac" class="form-control account" name="mail_fac" value="">
                    <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check2('mail_fac')"></i></font>
                </div>
                <div class="form-group col-lg-3">
                    <input (input)="change_txt($event.target.value, 'land')" type="text" placeholder="Land" formControlName="land" class="form-control account" name="land" value="" disabled placement="top" ngbTooltip="Voorlopig leveren we enkel in België">
                </div>
                <div class="form-group col-lg-5">
                    <input (input)="change_txt($event.target.value, 'straat_fac')" type="text" placeholder="Straat" (blur)="set_blur('straat_fac')" (focus)="set_focus('straat_fac')" [ngStyle]="setMyStyles_account5('straat_fac')" formControlName="straat_fac" class="form-control account" name="straat" value="">
                    <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('straat_fac')"></i></font>
                </div>
                <div class="form-group col-lg-1">
                    <input (input)="change_txt($event.target.value, 'nummer_fac')" type="text" placeholder="Nr." (blur)="set_blur('nummer_fac')" (focus)="set_focus('nummer_fac')" [ngStyle]="setMyStyles_account5('nummer_fac')" formControlName="nummer_fac" class="form-control account" name="nummer" value="">
                </div>
                <div class="form-group col-lg-1">
                    <input (input)="change_txt($event.target.value, 'bus_fac')" type="text" placeholder="Bus" (blur)="set_blur('bus_fac')" (focus)="set_focus('bus_fac')" [ngStyle]="setMyStyles_account5('bus_fac')" formControlName="bus_fac" class="form-control account" name="bus" value="">
                </div>
                <div class="form-group col-lg-2" placement="top" ngbTooltip="Enkel cijfers toegelaten bestaat uit 4 cijfers" [disableTooltip]="this.modusaccount == 'afrekenen'">
                    <input (input)="change_txt($event.target.value, 'postcode_fac')" type="text" (blur)="set_blur('postcode_fac')" (focus)="set_focus('postcode_fac')" maxlength="4" [ngStyle]="setMyStyles_account8('postcode_fac')"  placeholder="Postcode" formControlName="postcode_fac" class="form-control account" name="postcode" value="">
                    <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check4('postcode_fac')"></i></font>
                </div>
                <div class="form-group col-lg-3">
                    <input (input)="change_txt($event.target.value, 'gemeente_fac')" type="text" placeholder="Gemeente" (blur)="set_blur('gemeente_fac')" (focus)="set_focus('gemeente_fac')" [ngStyle]="setMyStyles_account5('gemeente_fac')" formControlName="gemeente_fac" class="form-control account" name="gemeente" value="">
                    <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('gemeente_fac')"></i></font>
                </div>
                
            <div class="form-group col-lg-2" [ngStyle]="setMyStyles_layout1()" >
            </div>
            <div class="form-group col-lg-3" [ngStyle]="setMyStyles_layout1()">
                <button *ngIf="confirm_txt =='ingelogd' || confirm_txt =='gegevens aangepast' || confirm_txt =='gegevens opgeslagen'" class="andro_btn-custom primary btn-block account1" (click)="confirm_account()" data-toggle="modal" data-target="#choose_pwd" data-backdrop="static" data-keyboard="false" [disabled] = "true" >
                    {{confirm_txt}}</button>
                <button *ngIf="confirm_txt =='gegevens opslaan?'" class="andro_btn-custom primary btn-block account1" (click)="confirm_account()" data-toggle="modal" data-target="#choose_pwd" data-backdrop="static" data-keyboard="false">{{confirm_txt}}</button>
                <button *ngIf="confirm_txt =='gegevens aanpassen?'" class="andro_btn-custom primary btn-block account1" (click)="confirm_account()">{{confirm_txt}} 
                    <mat-spinner [diameter]="20" style="position: absolute; margin-left: 65px; margin-top: -23px" [ngStyle]="{'display': spinner_active ? '' : 'none'}"></mat-spinner></button>
                <button *ngIf="confirm_txt =='inloggen'" class="andro_btn-custom primary btn-block account1" data-toggle="modal" data-target="#login_fb" data-backdrop="static" data-keyboard="false">
                    INLOGGEN
                </button>
            </div>
            <div class="form-group col-lg-2" [ngStyle]="setMyStyles_layout1()" >
            </div>
            <div class="form-group col-lg-3">
                <button class="andro_btn-custom primary btn-block account1" (click)="acc.toggle('static-1')">Accountgegevens</button>
            </div>
            <div class="form-group col-lg-2">
            </div>
            </div>
        </form>
        </ng-template>
        </ngb-panel>
</ngb-accordion>
