import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './organista/pages/home/home.component';
import { HomeTwoComponent } from './organista/pages/home-two/home-two.component';
import { HomeThreeComponent } from './organista/pages/home-three/home-three.component';
import { AboutComponent } from './organista/pages/about/about.component';
import { BlogGridComponent } from './organista/pages/blog-grid/blog-grid.component';
import { BlogListComponent } from './organista/pages/blog-list/blog-list.component';
import { BlogMasonaryComponent } from './organista/pages/blog-masonary/blog-masonary.component';
import { BlogSingleComponent } from './organista/pages/blog-single/blog-single.component';
import { ShopOneComponent } from './organista/pages/shop-one/shop-one.component';
import { ShopTwoComponent } from './organista/pages/shop-two/shop-two.component';
import { ShopThreeComponent } from './organista/pages/shop-three/shop-three.component';
import { ShopFourComponent } from './organista/pages/shop-four/shop-four.component';
import { ProductSingleComponent } from './organista/pages/product-single/product-single.component';
import { ProductSingleTwoComponent } from './organista/pages/product-single-two/product-single-two.component';
import { LoginComponent } from './organista/pages/login/login.component';
import { RegisterComponent } from './organista/pages/register/register.component';
import { CartComponent } from './organista/pages/cart/cart.component';
import { CheckoutComponent } from './organista/pages/checkout/checkout.component';
import { WishlistComponent } from './organista/pages/wishlist/wishlist.component';
import { LegalComponent } from './organista/pages/legal/legal.component';
import { ContactComponent } from './organista/pages/contact/contact.component';
import { ErrorComponent } from './organista/pages/error/error.component';
import { LastOrdersComponent } from './organista/pages/last-orders/last-orders.component';
import { BetalingOrderComponent } from './organista/pages/betaling-order/betaling-order.component';
import { SalePointsComponent } from './organista/pages/sale-points/sale-points.component';


const routes: Routes = [
  {
    path:'',component:HomeTwoComponent
  },
  // {
  //   path:'home-v1',component:HomeComponent
  // },
  {
    path:'order/:id',component:BetalingOrderComponent
  },
  {
    path:'sale_point', component: SalePointsComponent
  },
  // {
  //   path:'home-v3',component:HomeThreeComponent
  // },
  {
    path:'about',component:AboutComponent
  },
  // {
  //   path:'blog-grid',component:BlogGridComponent
  // },
  // {
  //   path:'blog-list',component:BlogListComponent
  // },
  {
    path:'blog-masonary',component:BlogMasonaryComponent
  },
  {
    path:'blog-single',component:BlogSingleComponent
  },
  {
    path:'shop-v1',component:ShopOneComponent
  },
  // {
  //   path:'shop-v2',component:ShopTwoComponent
  // },
  // {
  //   path:'shop-v3',component:ShopThreeComponent
  // },
  // {
  //   path:'shop-v4',component:ShopFourComponent
  // },
  // {
  //   path:'product-single',component:ProductSingleComponent
  // },
  // {
  //   path:'product-single-v2',component:ProductSingleTwoComponent
  // },
  // {
  //   path:'login',component:LoginComponent
  // },
  // {
  //   path:'register',component:RegisterComponent
  // },
  {
    path:'cart',component:CartComponent
  },
  // {
  //   path:'checkout',component:CheckoutComponent
  // },
  // {
  //   path:'wishlist',component:WishlistComponent
  // },
  // {
  //   path:'legal',component:LegalComponent
  // },
  {
    path:'contact',component:ContactComponent
  },
  {
    path:'last_orders',component:LastOrdersComponent
  },
  // {
  //   path:'**',component:ErrorComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
