<div style="margin-top: 30px;"> 
<form method="post" [formGroup]="form_delivery">
    <div class="row"> 
        <div class="form-group col-lg-12">     
            <span style="font-family: 'Montserrat'; color: blue; font-weight: 500;">Specifieer hoe je de producten graag had geleverd / afgehaald.  </span>                      
        </div> 
        <div class="row"> 
            <div class="form-group col-lg-2">             
                <span style="margin-left: 14px;font-family: 'Montserrat'; color: rgba(81,189,77,255); font-weight: 800;">ADRES</span>                      
            </div>
            <div class="form-group col-lg-10">     
                <div class="row">
                    <div class="form-group col-lg-12">
                        <mat-checkbox *ngFor="let item of ['accountgegevens','factuurgegevens','andere', 'zelf_afhalen'];  let i = index"
                        style=" padding-right: 30px;" [disabled]="(!facvalid && i === 1) || (i === 0 && !accountvalid) 
                         || (i === 1 && !factvalid) || (i === 2 && !andervalid) || (i === 3 && !zelfvalid)  ? 'true': 'false'" [checked]="selected === i" (change)="selected = i" formControlName="{{item}}">
                           <span style="font-family: 'Montserrat'; font-weight: 500;" [ngStyle]="{'color': (!facvalid && i === 1) ? 'lightgray': 'rgba(81,189,77,255)'}">{{item}}</span>
                       </mat-checkbox>
                    </div>
                    <div class="form-group col-lg-12" [ngStyle]="{'display': !zelf ? 'none': ''}">
                        <mat-checkbox *ngFor="let item of vestigingen;  let i = index"
                        style=" padding-right: 30px;" [checked]="apo_selected === i" (change)="change_apo($event, i)">
                           <span placement="top" ngbTooltip="{{item.adres}}"  style="font-family: 'Montserrat'; font-weight: 500;" [ngStyle]="{'color': (zelf_locatie == 'geen') ? 'red': 'rgba(81,189,77,255)'}">
                                {{item.naam}}
                           </span>
                       </mat-checkbox>
                       
                    </div>
                    <div class="form-group col-lg-7" [ngStyle]="{'display': zelf ? 'none': ''}">
                        <input type="text" placeholder="{{eerste_veld}}" (blur)="set_blur('lname')" (focus)="set_focus('lname')" [ngStyle]="setMyStyles_account('lname')" formControlName="lname" class="form-control account">
                        <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('lname')"></i></font>
                    </div>
                    <div class="form-group col-lg-5" [ngStyle]="{'display': zelf ? 'none': ''}">
                        <input type="text" placeholder="{{tweede_veld}}" (blur)="set_blur('fname')" (focus)="set_focus('fname')" [ngStyle]="setMyStyles_account('fname')" formControlName="fname" class="form-control account" name="fname" value="">
                        <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('fname')"></i></font>
                    </div>  
                    <div class="form-group col-lg-5" [ngStyle]="{'display': zelf ? 'none': ''}">
                        <input type="text" placeholder="Straat" (blur)="set_blur('straat')" (focus)="set_focus('straat')" [ngStyle]="setMyStyles_account('straat')" formControlName="straat" class="form-control account" name="straat" value="">
                        <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('straat')"></i></font>
                    </div>
                    <div class="form-group col-lg-1" [ngStyle]="{'display': zelf ? 'none': ''}">
                        <input type="text" placeholder="Nr." (blur)="set_blur('nummer')" (focus)="set_focus('nummer')" [ngStyle]="setMyStyles_account('nummer')" formControlName="nummer" class="form-control account" name="nummer" value="">
                    </div>
                    <div class="form-group col-lg-1" [ngStyle]="{'display': zelf ? 'none': ''}">
                        <input type="text" placeholder="Bus" (blur)="set_blur('bus')" (focus)="set_focus('bus')" [ngStyle]="setMyStyles_account5('bus')" formControlName="bus" class="form-control account" name="bus" value="">
                    </div>
                    <div class="form-group col-lg-2" [ngStyle]="{'display': zelf ? 'none': ''}">
                        <input type="text" (blur)="set_blur('postcode')" (focus)="set_focus('postcode')" maxlength="4" [ngStyle]="setMyStyles_account4('postcode')"  placeholder="Postcode" formControlName="postcode" class="form-control account" name="postcode" value="">
                        <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check4('postcode')"></i></font>
                    </div>
                    <div class="form-group col-lg-3" [ngStyle]="{'display': zelf ? 'none': ''}">
                        <input type="text" placeholder="Gemeente" (blur)="set_blur('gemeente')" (focus)="set_focus('gemeente')" [ngStyle]="setMyStyles_account('gemeente')" formControlName="gemeente" class="form-control account" name="gemeente" value="">
                        <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('gemeente')"></i></font>
                    </div>
                </div>
            </div>  
            <div class="form-group col-lg-2" style="padding-top: 15px;">     
                <span style="margin-left: 14px;font-family: 'Montserrat'; color: rgba(81,189,77,255); font-weight: 800;">TIJDSTIP</span>                      
            </div>
            <div class="form-group col-lg-10"  [ngStyle]="{'display': (zelf || postnl == 'geen') ? 'none': ''}"> 
                <div class="row">
                    <div class="form-group col-lg-4 " style="padding-top: 15px; font-family: 'Montserrat'; font-weight: 500; color: rgba(81,189,77,255)">
                        {{_order.deliverytime_txt}}
                        <span [ngStyle]="{'display': postnl == 'ja' ? 'none': ''}" placement="top" ngbTooltip="We communiceren je het exacte tijdstip telefonisch 30min op voorhand" style="padding-left: 5px;">(17u-19u)</span>
                        <span [ngStyle]="{'display': postnl == 'neen' ? 'none': ''}" placement="top" ngbTooltip="Indicatief tijdstip; we houden je via mail op de hoogte van het juiste tijstip" style="padding-left: 5px;">(POSTNL)</span>
                    </div>
                    <div class="form-group col-lg-8" [ngStyle]="{'display': postnl == 'ja' ? 'none': ''}"> 
                        <button style="background: transparent; border: none !important; font-size: 0; outline: none;" #btn1></button>
                        <button class="andro_btn-custom primary btn-block account1" style="width: 200px;" (click)="open_datepicker()">Datum aanpassen</button>
                        <ngb-datepicker [minDate]="{year: minjaar, month: minmaand, day: mindag}" [markDisabled]="isDisabled" (dateSelect)="date_select($event)"
                            [ngStyle]="{'display': show_datepicker ? '': 'none'}"  style="position: absolute; z-index: 99999 !important; background-color: white; ">
                        </ngb-datepicker>
                    </div> 
                </div>               
            </div>
            <div class="form-group col-lg-10" [ngStyle]="{'display': (zelf || postnl != 'geen') ? 'none': ''}"> 
            </div>
            <div class="form-group col-lg-10" [ngStyle]="{'display': !zelf ? 'none': ''}"  style="padding-top: 15px; font-family: 'Montserrat'; font-weight: 500; color: rgba(81,189,77,255)"> 
                De producten zijn beschikbaar in het afhaalpunt vanaf {{_order.availabletime_txt}}              
            </div>             
            <div class="form-group col-lg-2" style="padding-top: 5px;" [ngStyle]="{'display': (zelf || postnl == 'geen') ? 'none': ''}">     
                <span style="margin-left: 14px;font-family: 'Montserrat'; color: rgba(81,189,77,255); font-weight: 800;">VERZENDKOSTEN</span>                      
            </div>
            <div class="form-group col-lg-10" [ngStyle]="{'display': zelf ? 'none': ''}" style="padding-top: 5px; font-family: 'Montserrat'; font-weight: 500; color: rgba(81,189,77,255)"> 
                {{kost_verzend}}              
            </div>
            <div class="form-group col-lg-2" style="padding-top: 15px;">     
                <span style="margin-left: 14px;font-family: 'Montserrat'; color: rgba(81,189,77,255); font-weight: 800;">PROMOCODE</span>                      
            </div>  
            <div class="form-group col-lg-5">
                <input type="text" [attr.disabled]="promo_ok == 'true' ? '' : null" (keyup.enter)="apply_code()" formControlName="promo" (blur)="set_blur('promo')" [ngStyle]="setMyStyles_account6('promo')" (focus)="set_focus('promo')" placeholder="Voer hier je promocode in" class="form-control account" name="opm" value="">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="{'display': promo_ok == 'true' ? '' : 'none'}"></i></font>
                <font size="1"><i class="fas fa-exclamation fa-2x" style="color: red; position: absolute; right: 30px; top: 15px;" [ngStyle]="{'display': promo_ok == 'false' ? '' : 'none'}"></i></font>
            </div>
            <div class="form-group col-lg-5"></div>
            <div class="form-group col-lg-2" style="padding-top: 15px;">     
                <span style="margin-left: 14px;font-family: 'Montserrat'; color: rgba(81,189,77,255); font-weight: 800;">OPMERKINGEN?</span>                      
            </div>  
            <div class="form-group col-lg-10">
                <input type="text" formControlName="opm" (blur)="set_blur('opm')" (focus)="set_focus('opm')" [ngStyle]="setMyStyles_account5('opm')" placeholder="Heb je nog iets te melden?" class="form-control account" name="opm" value="">
                <font size="1"><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" [ngStyle]="setMyStyles_check('opm')"></i></font>
            </div>
        </div>     
        
        
        
    </div>
</form>
</div>