import { NumberLiteralType } from "typescript";
import { product } from "./organista/pages/variable";

export class Product {
    name: string;
    price: number;
    price_disc: number;
    info_short_product: string;
    photo: string;
    cnk: string;
    brand: string;    
    attention_product: number;
    popular: number;
    id: number;
    subcategory: string;
    documentation: string;
    prescription: string;
    principle: string; 
    _use: string; 
    composition: string;
    favoriet: Boolean;
    special: string;
    creationtime: Date;
    percentage: number;
    number_to_free: number;
    discount_special: number;
    price_ori: number;
    btw: number;
    discount_text: string;
    active: number;
    disc_start: Date;
    disc_stop: Date;
    att_start: Date;
    att_stop: Date;
    check: Boolean;
    check_select: Boolean;
    principle2: string;
    info: string;
    product_collection: string;
}

export class stat_cat {
    cat: string;
    aantal: number;
}

export class send_check {
    cnk: string; 
    cat: string;
    subcat: string;
    info: string;
}

export class product_select {
    number: number;
    categorie_select: string;
    subcategorie_select: string;
    cnk: string;
    check: Boolean;
}

export class cat_subcat {
    cat: string;
    subcat_name: string;
    subcat_nr: number;
    check: Boolean;
}

export class mailmessage {
    firstname: string; 
    lastname: string; 
    subject: string; 
    mail: string; 
    message: string;
}

export class info{
    title: string; 
    text: string;
    products: string;
    id: number;
    category: string;
    photo: string;
    text_intro: string;
    list_products: Product[];
}

export class mailing {
    mail: string;
    discount: string;
}

export class Discount {
    title_short: string;
    info_long: string;
    info_short: string; 
    number_to_free: number;
    percentage: number;
    _product: Product;  
    name: string;  
}

export class Fresh {
    title_short: string;
    info_short: string; 
    _product: Product;  
    name: string;  
    disc_start: Date;
    disc_stop: Date;
    percentage: number;
    number_to_free: number;
}

export class Attention {
    title_short: string;
    info_short: string; 
    _product: Product;  
    name: string;    
    disc_start: Date;
    disc_stop: Date;
    percentage: number;
    number_to_free: number;
}

export class paginationlink {
    url: string;
    label: string;
    active: boolean;
}

export class vestiging {
    naam: string;
    adres: string;
}

export class Order {
    _account: account;
    creationtime: Date; 
    cart: cartitem[];
    delivery: delivery_address; 
    deliverytime: Date;
    deliverytime_txt: string;
    availabletime_txt: string;
    cart_available: Boolean;
    min_deliverytime: Date; 
    cost: number;
    cost_btw: number;
    cost_total: number;
}

export class Order_api {
    account_id: number;
    deliver_id: number; 
    deliverytime: string;
    cart_available: number;
    cost: number;
    cost_btw: number;
    cost_total: string;
    send: string;
    payed: number;
    delivered: string;
    id: number;
    user_id: number;
    cost_send: number;
}

export class pag_request {
    current_page: number;
    data: Product[];
    first_page_url: string; 
    from: number; 
    last_page: number; 
    last_page_url: string;
    links: paginationlink[];
    next_page_url: string;
    path: string;
    per_page: number;
    to: number;
    total: number;
}

export class subcategory {
    category: string;
    list_subcat: subcategory_item[];
}

export class category {
    name: string;
    checked: boolean;
    code: string;
}

export class subcategory_item {
    subcat: string; 
    value: number[];
    checked: boolean;
}

export class brand {
    category: string;
    list_brands: category[];
}

export class searchitem {
    category: string;
    list_search: string[];
}

export class pricefork {
    min_price: number;
    max_price: number;
}

export class filter {
    categories: any[];
    subcategories: any[];
    attention: Boolean;
    sale: Boolean;
    no_presc: Boolean;
    brands: any[];
    price_min: number;
    price_max: number;
    searchterm: string;
    sort: string;
    modus: string;
    gen_search: string;
}

export class cartitem {
    product: Product;
    quantity: number;
    quantity_free: number;
    promocode: string;
}

export class cartitem_api {
    order_id: number;
    product_id: number;
    discount_id: number;
    price: number;
    quantity: number;
    quantity_free: number;
}

export class kortingscode {
    code: string;
    valid: Boolean;
    quantity_min: number;
    used: Boolean;
    name: string;
    discount: number;
}

export class account {
    lname: string;
    fname: string; 
    mail: string; 
    telefoon: string; 
    RR: string; 
    land: string;
    straat: string;
    nummer: string; 
    bus: string;
    postcode: string;
    gemeente: string;
    info: string;
    nieuw: Boolean;
    logged_in: Boolean;
    failed_login: number;
    uid: string;
    bedrijfsnaam: string;
    vertegenwoordiger: string;
    mail_fac: string;
    land_fac: string;
    straat_fac: string;
    nummer_fac: string;
    bus_fac: string;
    postcode_fac: string;
    gemeente_fac: string;
    id: number;
}

export class delivery_address{
    lname: string;
    fname: string;
    straat: string;
    nummer: string;
    bus: string;
    postcode: number;
    gemeente: string;
    land: string;
    opm: string;
    zelf_site: number;
    id: number;
}

export class login_answer {
    account: account;
    dummy: string;
    dummy2: string;
}

export class last_order {
    bestelnummer: string; 
    datum: string; 
    product: Product;
    hoeveelheid: number;
    afgeleverd: string;
}

export class fav {
    user_id: number;
    product_id: string;
}