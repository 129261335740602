import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insta-one',
  templateUrl: './insta-one.component.html',
  styleUrls: ['./insta-one.component.css']
})
export class InstaOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
