import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { account, cartitem, cartitem_api, delivery_address, kortingscode, Order, Order_api, Product } from 'src/app/model';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';
import { moveSyntheticComments } from 'typescript';
import * as moment from 'moment';
import { Url } from 'url';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  totaal = 0;
  totaal_btw = 0;
  korting = false;
  kortingscodes = [];
  code_txt = '';
  levering_disabled: Boolean = true;
  order_disabled: Boolean = true;
  cart_account_txt = 'Laten we kennis maken ...'; 
  cart_aantal = false;
  agree = false;
  _Order: Order = new Order();
  api = "";
  _cost_send = 0;
  spinner_active = false;

  @ViewChild('cdkStepper') private myStepper: CdkStepper;

  constructor(public _aposervice: ApoService, public http: HttpClient, public router: Router, private toastr: ToastrService, public formBuilder: FormBuilder) { 

    this.router.events.subscribe((event:Event) => {
      if(event instanceof NavigationEnd ){
        if (event.url == '/cart') {
          this.scrolltotop();
        }        
      }
    });

    this._aposervice.current_formvalid.pipe(takeUntil(this.destroy$)).subscribe(data => {      
      this.levering_disabled = !data;
    });  

    this._aposervice.current_agree.pipe(takeUntil(this.destroy$)).subscribe(data => {      
      this.agree = data;
    });  

    this._aposervice.current_Ordervalid.pipe(takeUntil(this.destroy$)).subscribe(data => {      
      this.order_disabled = !data;
    });   

    this._aposervice.current_mandje.pipe(takeUntil(this.destroy$)).subscribe(data => {      
        if (data.length > 0){this.cart_aantal = true;}
        else {this.cart_aantal = false;}
    });  


    this._aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {      
      if (data.logged_in){
        this.cart_account_txt = 'Accountgegevens controleren';
      }
      else {
        this.cart_account_txt = 'Laten we kennis maken ...'; 
      }
    }); 

    this._aposervice.current_logout.pipe(takeUntil(this.destroy$)).subscribe(data => { 
      if (data){
        this.myStepper.selectedIndex = 1;
      }
     });

     this._aposervice.Current_order.pipe(takeUntil(this.destroy$)).subscribe(data => { 
      this._Order = data;
    });

    this._aposervice.current_cost_send.pipe(takeUntil(this.destroy$)).subscribe(data => { 
     this._cost_send = data;
   });

    this._aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => { 
       this.api = data;
     });
  }

  scrolltotop(){
    setTimeout(() => {
      let top = document.getElementById('top');
      top.scrollIntoView({behavior: "smooth"});
    }, 100);
  }

  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  ngOnInit(): void {};

  selectionChange(event: StepperSelectionEvent) {
    let stepLabel = event.selectedIndex;   
    this.scrolltotop();
    this._aposervice.change_modusaccount('afrekenen');
  }

  booltonumber(_bool: Boolean){
    if (_bool){return 1;}
    else {return 0;}
  }

  place_order(){

    this.spinner_active = true;
    let deliver = this.http.post<delivery_address>(this.api + "delivers",this._Order.delivery);
    let account = this.http.post<account>(this.api + "accounts",this._Order._account);

    forkJoin([deliver, account]).pipe(takeUntil(this.destroy$)).subscribe(results => {

      var _order_api: Order_api = {
        account_id: results[1].id,
        deliver_id: results[0].id,
        deliverytime: moment(this._Order.deliverytime).format('YYYY-MM-DD HH:mm:ss'),
        cart_available: this.booltonumber(this._Order.cart_available),
        cost: this._Order.cost,
        cost_btw: this._Order.cost_btw,
        cost_total: (this._Order.cost + this._cost_send).toFixed(2),
        send: null, 
        payed: 0,
        delivered: null,
        id: 0,
        user_id: this._Order._account.id,
        cost_send: this._cost_send
      }

      this.http.post<Order_api>(this.api + "orders",_order_api).pipe(takeUntil(this.destroy$)).subscribe(data => {

        var _cartitem_api = [];
        for (let x of this._Order.cart){
          let productid = 1;
          if (x.promocode == ""){productid = x.product.id;}
          else if (x.promocode == "NEO22"){productid = 0;}
          _cartitem_api.push({
            order_id: data.id, 
            product_id: productid,
            discount_id: null,
            price: x.product.price,
            quantity: x.quantity,
            quantity_free: x.quantity_free,
            promocode: x.promocode
          });

        this._aposervice.addlist_fav(x.product.cnk,this._Order._account.id);
        }

        this.http.post<cartitem_api[]>(this.api + "orderitems",_cartitem_api).pipe(takeUntil(this.destroy$)).subscribe(
          data =>{}          
        );

        this.http.get<any>(this.api + "mollie/" + data.id + "/" + _order_api.cost_total).pipe(takeUntil(this.destroy$)).subscribe(info => {
         
            this.http.get<any>(this.api + "orders/" + data.id + "/" + info.id + "/0").pipe(takeUntil(this.destroy$)).subscribe(data => {
              this.spinner_active = false;
              window.location.href = info._links.checkout.href;
            });    
        });
        
      });
    });   
  }



}
