import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { Order } from 'src/app/model';

@Component({
  selector: 'app-orderconfirmation',
  templateUrl: './orderconfirmation.component.html',
  styleUrls: ['./orderconfirmation.component.css']
})
export class OrderconfirmationComponent implements OnInit, OnDestroy {

  agree = false;
  private destroy$: Subject<void> = new Subject<void>();
  _order: Order = new Order();
  cost_send = 0;
  aantal_producten = 0;
  zelf_site = 0;
  facvalid: Boolean = false;

  constructor(public _aposervice: ApoService) {

    this._aposervice.current_cost_send.pipe(takeUntil(this.destroy$)).subscribe(data => {      
      this.cost_send = data;
    }); 
    
    this._aposervice.current_agree.pipe(takeUntil(this.destroy$)).subscribe(data => {      
      this.agree = data;
    }); 

    this._aposervice.current_facvalid.pipe(takeUntil(this.destroy$)).subscribe(data => {      
      this.facvalid = data;
    });  

    this._aposervice.Current_order.pipe(takeUntil(this.destroy$)).subscribe(data => {      
      this._order = data;
      this.aantal_producten = data.cart.length;  
      this.zelf_site = data.delivery.zelf_site;          
    }); 
   }

  ngOnInit(): void {
  }

  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }

  change_agree(event){
      this._aposervice.change_agree(event.checked); 
    }
  



}
