<div id="top"></div>
<app-header></app-header>
<!-- Subheader Start -->
<!-- <div class="andro_subheader pattern-bg primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1>APO@HOME</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">APO@HOME</li>
                </ol>
            </nav>
        </div>
    </div>
</div> -->
<!-- Subheader End -->
<!-- Img Start -->
<div class="section" style="text-align: justify;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">
                    <h2 class="title"><span class="custom-primary">APO@HOME</span> parafarmacie</h2>
                    <p class="subtitle">
                        Apo@home ontstond uit de samenwerking van een aantal apotheken uit de <span class="custom-primary">Denderstreek</span>. Hier was men reeds gewend om 
                        uitgebreid farmaceutisch advies mee te geven aan de klanten, met een aantal duidelijke voordelen tot gevolg; zo waren de klanten 
                        tevreden over de verstrekte service, was er een beter gebruik van de gekochte middelen, was er meer therapietrouw en 
                        begrip waarom een bepaald middel moet genomen worden. Vanuit de filosofie dat ook bij de aankoop van producten op websites, 
                        het nodige advies tot bij de klant thuis in de woonkamer moet kunnen komen, werd Apo@home opgericht.                     
                    </p>
                </div>
            </div>
        </div>
    </div>
<!-- Img End -->

<!-- Img Start -->
    <div class="container">
        <div class="row align-items-center">            
            <div class="col-lg-6">
                <div class="section-title-wrap mr-lg-30">                   
                    <p class="subtitle">
                        Apo@home staat voor advies op maat door <span class="custom-primary">professionele</span> zorgverstrekkers tot bij u thuis
                        gebracht voor elke aankoop van voedingssupplementen, medische hulpmiddelen of geneesmiddelen.
                        Zulke werkwijze verzekert u ook van de juiste aankoop van de producten en bespaart u de
                        rompslomp van een verkeerd aangekocht product te moeten terugsturen.
                    </p>
                    <p>
                        Wij vinden het belangrijk om aan huis te kunnen leveren indien de klant niet in
                        staat is om tot de apotheek te komen en toch nog altijd het juiste advies te verkrijgen. Daarom
                        trekken onze professionele zorgverstrekkers er vaak op uit om middelen aan huis te leveren. Deze
                        strekking trekken we verder in apo@home. In de regio Lebbeke, Buggenhout en Dendermonde kunnen 
                        we <span class="custom-primary">gratis levering</span> aan huis verzekeren.
                    </p>
                  
                </div>
            </div>
            <div class="col-lg-6 mb-lg-20 andro_single-img-wrapper" style="padding-bottom: 20px;">
                <img src="assets/foto/about1.jpg" alt="img">
            </div>
        </div>
    </div>
<!-- Img End -->
 
<!-- Img Start -->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="section-title-wrap mr-lg-30">
                    <p class="subtitle">
                        Apo@home komt tot in je zitkamer en wil je de best mogelijke service bieden, ook op vlak van
                        nieuwigheden en promoties. Doordat we elke dag in de zorg betrokken zijn, zijn we op de hoogte van
                        de nieuwste producten die op de markt komen. Al deze nieuwigheden worden ook meegedeeld op
                        de website, zodat u ook onmiddellijk op de hoogte bent van de allernieuwste trends binnen de
                        voedingssupplementen en medische hulpmiddelen. Hou dus zeker onze pagina met nieuwigheden in
                        het oog! Apo@home snuistert elke dag de markt af naar voordeelverpakkingen van producten of
                        eventuele <span class="custom-primary">kortingen</span> die kunnen aangeboden worden. Hou dus zeker ook onze promopagina in het
                        oog.
                    </p>                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Img End -->
<!-- Img Start -->
<div class="section pt-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 mb-lg-20 andro_single-img-wrapper">
                <img src="assets/foto/about2.jpg" alt="img">
            </div>
            <div class="col-lg-6">
                <div class="section-title-wrap mr-lg-30">
                    <p class="subtitle">
                        Samengevat, Apo@home staat voor
                    </p>
                    <ul class="andro_list">
                        <li>farmaceutisch advies door professionele zorgverstrekkers</li>
                        <li>snelle levering aan huis</li>
                        <li>uitgebreid gamma aan scherpe prijzen    </li>
                    </ul>
                    <p class="subtitle">
                        Dan rest er ons nog enkel om jullie veel plezier te wensen met het opzoeken op onze website. Denk
                        eraan, de zorgverstrekkers in de apotheek zijn er om jullie te helpen indien nodig. En dan zien we
                        elkaar wel binnenkort @ home!
                    </p>
                    <a routerLink='/shop-v1' (click)="change_page('')" class="andro_btn-custom">Ondek onze producten</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Img End -->
<!-- Testimonials Start -->
<!-- <div class="section section-padding pt-0">
    <div class="container">
        <div class="section-title">
            <h4 class="title">What Are People Saying</h4>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="andro_testimonial">
                    <div class="andro_testimonial-body">
                        <h5>Perfect service</h5>
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    </div>
                    <div class="andro_testimonial-author">
                        <img src="assets/img/people/1.jpg" alt="testimonial">
                        <div class="andro_testimonial-author-inner">
                            <h6>John Mishlen</h6>
                            <span>Executive CEO at company</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="andro_testimonial">
                    <div class="andro_testimonial-body">
                        <h5>Competitive prices</h5>
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    </div>
                    <div class="andro_testimonial-author">
                        <img src="assets/img/people/2.jpg" alt="testimonial">
                        <div class="andro_testimonial-author-inner">
                            <h6>Moe Sondi</h6>
                            <span>Executive CEO at company</span>
                        </div>
                    </div>
                </div>
            </div>           
            <div class="col-lg-4 col-md-12">
                <div class="andro_testimonial">
                    <div class="andro_testimonial-body">
                        <h5>Great agents</h5>
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    </div>
                    <div class="andro_testimonial-author">
                        <img src="assets/img/people/3.jpg" alt="testimonial">
                        <div class="andro_testimonial-author-inner">
                            <h6>Mandy Floss</h6>
                            <span>Executive CEO at company</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <app-insta-two></app-insta-two> -->
<app-footer (to_top)="to_top()"></app-footer>
