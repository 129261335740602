<app-header-two></app-header-two>
<div class="section">
    <div class="imgs-wrapper">
        <img src="assets/img/products/1.png" alt="veg" class="d-none d-lg-block">
        <img src="assets/img/products/14.png" alt="veg" class="d-none d-lg-block">
    </div>
    <div class="container">
        <div class="andro_auth-wrapper">
            <div class="andro_auth-description bg-cover bg-center dark-overlay dark-overlay-2" style="background-image: url('assets/img/auth.jpg')">
                <div class="andro_auth-description-inner">
                    <i class="flaticon-diet"></i>
                    <h2>Welcome Back!</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </div>
            </div>
            <div class="andro_auth-form">
                <h2>Log in</h2>
                <form method="post">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Username" name="username" value="">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Password" name="password" value="">
                    </div>
                    <a href="#">Forgot Password?</a>
                    <button type="submit" class="andro_btn-custom primary">Login</button>
                    <div class="andro_auth-seperator">
                        <span>OR</span>
                    </div>
                    <div class="andro_social-login">
                        <button type="button" class="andro_social-login-btn facebook"><i class="fab fa-facebook-f"></i> Continue with Facebook </button>
                        <button type="button" class="andro_social-login-btn google"><i class="fab fa-google"></i> Continue with Google</button>
                    </div>
                    <p>Don't have an account? <a routerLink='/register'>Create One</a> </p>
                </form>
            </div>
        </div>
    </div>
</div>
<app-insta-two></app-insta-two>
<app-footer></app-footer>