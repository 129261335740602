import { Component, OnInit } from '@angular/core';
import { ApoService } from 'src/app/apo.service';

@Component({
  selector: 'app-shop-two',
  templateUrl: './shop-two.component.html',
  styleUrls: ['./shop-two.component.css']
})
export class ShopTwoComponent implements OnInit {

  constructor(public _aposervice: ApoService) { }

  ngOnInit(): void {
  }

}
