<app-header-two></app-header-two>
<!-- Subheader Start -->
<div class="andro_subheader pattern-bg primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1>Shop v4 (List view)</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Shop</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Shop v4 (List view)</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Products Start -->
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <!-- Product Count & Orderby Start -->
                <div class="andro_shop-global">
                    <p>Showing <b>20</b> of <b>320</b> products </p>
                    <form method="post">
                        <select class="form-control" name="orderby">
                            <option value="default">Default sorting</option>
                            <option value="latest">Latest release</option>
                            <option value="price-down">Price: High - Low</option>
                            <option value="price-up">Price: Low - High</option>
                            <option value="popularity">Popularity Sorting</option>
                        </select>
                    </form>
                </div>
                <!-- Product Count & Orderby End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/2.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>4 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Kiwi &amp; Strawberry </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>19$</span>
                                <span>29$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>3 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Cucumbers </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                            </div>
                            <span>5 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Watermelons </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>8$</span>
                                <span>15$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>4 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>19$</span>
                                <span>29$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/7.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>4 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Ginger </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>19$</span>
                                <span>29$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/7.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>4 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Ginger </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>19$</span>
                                <span>29$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/10.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>4 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Corn </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>19$</span>
                                <span>29$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Load More Start -->
                <a href="#" class="load-more"> Load More </a>
                <!-- Load More End -->
            </div>
            <!-- Sidebar Start -->
            <div class="col-lg-4">
                <app-shop-sidebar></app-shop-sidebar>
            </div>
            <!-- Sidebar End -->
        </div>
    </div>
</div>
<!-- Products End -->
<app-insta-two></app-insta-two>
<app-footer></app-footer>