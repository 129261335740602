<app-header-three></app-header-three>
<!-- Banner Start -->
<div class="andro_banner banner-2">
    <div class="andro_banner-slider">
        <div class="andro_banner-slider-inner" style="background-image: url('assets/img/banner/1.jpg');">
            <div class="container">
                <div class="andro_banner-slider-text">
                    <img src="assets/img/products/8.png" class="img-1" alt="product">
                    <p>Use code <strong class="custom-primary">ORGANIC991</strong> during checkout</p>
                    <h1 style="background-image: url('assets/img/cta/3.jpg') "> Organic Vegetables </h1>
                    <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Sed porttitor lectus nibh. Vestibulum ac diam sit amet quam vehicula.</p>
                    <a routerLink='/shop-v1' class="andro_btn-custom">Shop Now</a>
                </div>
            </div>
        </div>
        <div class="andro_banner-slider-inner" style="background-image: url('assets/img/banner/2.jpg');">
            <div class="container">
                <div class="andro_banner-slider-text">
                    <img src="assets/img/products/14.png" class="img-1" alt="product">
                    <p>Use code <strong class="custom-primary">ORGANIC991</strong> during checkout</p>
                    <h1 style="background-image: url('assets/img/cta/2.jpg') "> Organic Exotic Fruits </h1>
                    <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Sed porttitor lectus nibh. Vestibulum ac diam sit amet quam vehicula.</p>
                    <a routerLink='/shop-v1' class="andro_btn-custom">Shop Now</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Banner End -->
<!-- Categories Start -->
<div class="section section-padding category_section">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-diet"></i>
                        <h5>Food</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-supplements"></i>
                        <h5>Supplements</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-groceries"></i>
                        <h5>Baskets</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-cleaning-spray"></i>
                        <h5>Home Care</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-baby"></i>
                        <h5>Kids Care</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-olive-oil"></i>
                        <h5>Oils</h5>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Categories End -->
<!-- CTA Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="andro_cta">
                    <img src="assets/img/cta/1.jpg" alt="cta">
                    <div class="andro_cta-content">
                        <h4 class="text-white">Freshly Picked <span class="fw-400">Vegetables</span> </h4>
                        <p class="text-white">Cras ultricies ligula sed magna dictum porta. Proin eget tortor risus.</p>
                        <a routerLink='/shop-v1' class="andro_btn-custom btn-sm light">Shop Now</a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="andro_cta">
                    <img src="assets/img/cta/2.jpg" alt="cta">
                    <div class="andro_cta-content">
                        <h4 class="text-white">Freshly Picked <span class="fw-400">Fruits</span> </h4>
                        <p class="text-white">Cras ultricies ligula sed magna dictum porta. Proin eget tortor risus.</p>
                        <a routerLink='/shop-v1' class="andro_btn-custom btn-sm light">Shop Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CTA End -->
<!-- Icons Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="andro_icon-block icon-block-2">
                    <i class="flaticon-diet"></i>
                    <h5>Guaranteed Organic</h5>
                    <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="andro_icon-block icon-block-2">
                    <i class="flaticon-harvest"></i>
                    <h5>Daily Harvesting</h5>
                    <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="andro_icon-block icon-block-2">
                    <i class="flaticon-tag"></i>
                    <h5>Cheap & Healthy</h5>
                    <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Icons End -->
<!-- Products Start -->
<div class="section pt-0 andro_fresh-arrivals">
    <div class="container">
        <div class="section-title flex-title">
            <h4 class="title">Fresh Arrivals</h4>
            <div class="andro_arrows">
                <i class="fa fa-arrow-left slick-arrow slider-prev"></i>
                <i class="fa fa-arrow-right slick-arrow slider-next"></i>
            </div>
        </div>
        <div class="andro_fresh-arrivals-slider">
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls">
                <div class="andro_product-badge andro_badge-featured">
                    <i class="fa fa-star"></i>
                    <span>Featured</span>
                </div>
                <div class="andro_product-thumb thumb-sm">
                    <a routerLink='/product-single'><img src="assets/img/products/2.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Kiwi & Strawberry </a> </h5>
                    <div class="andro_product-price">
                        <span>19$</span>
                        <span>29$</span>
                    </div>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-controls">
                        <a href="#"> <i data-toggle="tooltip" title="Add To Cart" data-placement="top" class="flaticon-shopping-basket"></i></a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal"> <i data-toggle="tooltip" title="Quick View" data-placement="top" class="flaticon-view"></i></a>
                        <a href="#"> <i data-toggle="tooltip" title="Compare" data-placement="top" class="flaticon-compare"></i></a>
                        <a href="#" class="favorite"> <i data-toggle="tooltip" title="Add To Wishlist" data-placement="top" class="flaticon-like"></i></a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls">
                <div class="andro_product-badge andro_badge-featured">
                    <i class="fa fa-star"></i>
                    <span>Featured</span>
                </div>
                <div class="andro_product-thumb thumb-sm">
                    <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Fresh Oranges </a> </h5>
                    <div class="andro_product-price">
                        <span>19$</span>
                        <span>29$</span>
                    </div>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                        </div>
                        <span>5 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-controls">
                        <a href="#"> <i data-toggle="tooltip" title="Add To Cart" data-placement="top" class="flaticon-shopping-basket"></i></a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal"> <i data-toggle="tooltip" title="Quick View" data-placement="top" class="flaticon-view"></i></a>
                        <a href="#"> <i data-toggle="tooltip" title="Compare" data-placement="top" class="flaticon-compare"></i></a>
                        <a href="#" class="favorite"> <i data-toggle="tooltip" title="Add To Wishlist" data-placement="top" class="flaticon-like"></i></a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls">
                <div class="andro_product-badge andro_badge-sale">
                    20% Off
                </div>
                <div class="andro_product-thumb thumb-sm">
                    <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Cucumbers </a> </h5>
                    <div class="andro_product-price">
                        <span>8$</span>
                        <span>14$</span>
                    </div>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-controls">
                        <a href="#"> <i data-toggle="tooltip" title="Add To Cart" data-placement="top" class="flaticon-shopping-basket"></i></a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal"> <i data-toggle="tooltip" title="Quick View" data-placement="top" class="flaticon-view"></i></a>
                        <a href="#"> <i data-toggle="tooltip" title="Compare" data-placement="top" class="flaticon-compare"></i></a>
                        <a href="#" class="favorite"> <i data-toggle="tooltip" title="Add To Wishlist" data-placement="top" class="flaticon-like"></i></a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls">
                <div class="andro_product-thumb thumb-sm">
                    <a routerLink='/product-single'><img src="assets/img/products/8.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Lettuce </a> </h5>
                    <div class="andro_product-price">
                        <span>19$</span>
                        <span>29$</span>
                    </div>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-controls">
                        <a href="#"> <i data-toggle="tooltip" title="Add To Cart" data-placement="top" class="flaticon-shopping-basket"></i></a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal"> <i data-toggle="tooltip" title="Quick View" data-placement="top" class="flaticon-view"></i></a>
                        <a href="#"> <i data-toggle="tooltip" title="Compare" data-placement="top" class="flaticon-compare"></i></a>
                        <a href="#" class="favorite"> <i data-toggle="tooltip" title="Add To Wishlist" data-placement="top" class="flaticon-like"></i></a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-has-controls">
                <div class="andro_product-badge andro_badge-featured">
                    <i class="fa fa-star"></i>
                    <span>Featured</span>
                </div>
                <div class="andro_product-thumb thumb-sm">
                    <a routerLink='/product-single'><img src="assets/img/products/9.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Tomatoes </a> </h5>
                    <div class="andro_product-price">
                        <span>11$</span>
                    </div>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                        </div>
                        <span>5 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-controls">
                        <a href="#"> <i data-toggle="tooltip" title="Add To Cart" data-placement="top" class="flaticon-shopping-basket"></i></a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal"> <i data-toggle="tooltip" title="Quick View" data-placement="top" class="flaticon-view"></i></a>
                        <a href="#"> <i data-toggle="tooltip" title="Compare" data-placement="top" class="flaticon-compare"></i></a>
                        <a href="#" class="favorite"> <i data-toggle="tooltip" title="Add To Wishlist" data-placement="top" class="flaticon-like"></i></a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
        </div>
    </div>
</div>
<!-- Products End -->
<!-- Featured Products Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="section-title">
                    <h4 class="title">Featured Products</h4>
                </div>
                <div class="row">
                    <!-- Product Start -->
                    <div class="col-sm-6">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/7.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Ginger </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>19$</span>
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-sm-6">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/8.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Lettuce </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>8$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-sm-6">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/9.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Tomatoes </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>6$</span>
                                    <span></span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-sm-6">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/10.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Corn </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>19$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-sm-6">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/11.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Onions </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>13$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="col-sm-6">
                        <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/12.png" alt="product"></a>
                            </div>
                            <div class="andro_product-body">
                                <h6 class="andro_product-title"> <a routerLink='/product-single'> Brocolis </a> </h6>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>19$</span>
                                    <span>29$</span>
                                </div>
                                <div class="andro_product-buttons">
                                    <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                    <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                </div>
            </div>
            <!-- Sidebar Start -->
            <div class="col-lg-4">
                <div class="sidebar">
                    <!-- Product Categories Start -->
                    <div class="sidebar-widget">
                        <div class="section-title">
                            <h4 class="title">Popular Categories</h4>
                        </div>
                        <ul class="sidebar-widget-list">
                            <li>
                                <a href="#"> Food <span>(32)</span> </a>
                                <ul>
                                    <li> <a href="#">Vegetables & Fruits <span>(14)</span> </a> </li>
                                    <li> <a href="#">Dairy <span>(39)</span></a> </li>
                                    <li> <a href="#">Vegan <span>(54)</span> </a> </li>
                                </ul>
                            </li>
                            <li> <a href="#"> Keto <span>(24)</span> </a> </li>
                            <li> <a href="#"> Baskets <span>(44)</span> </a> </li>
                            <li> <a href="#"> Baby & Kids Care <span>(15)</span> </a> </li>
                            <li> <a href="#"> Home Care <span>(43)</span> </a> </li>
                            <li> <a href="#"> Supplements <span>(75)</span> </a> </li>
                        </ul>
                    </div>
                    <!-- Product Categories End -->
                    <!-- Daily Deals Start -->
                    <div class="sidebar-widget">
                        <div class="section-title">
                            <h4 class="title">Daily Deals</h4>
                        </div>
                        <div class="deals-slider">
                            <!-- Deal Start -->
                            <div class="andro_product">
                                <div class="andro_product-badge andro_badge-sale">
                                    20% Off
                                </div>
                                <div class="andro_product-thumb">
                                    <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                                    <div class="andro_countdown-timer" data-countdown="2021/01/01"></div>
                                </div>
                                <div class="andro_product-body">
                                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Watermelon </a> </h5>
                                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                    <div class="andro_rating-wrapper">
                                        <div class="andro_rating">
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                        <span>4 Stars</span>
                                    </div>
                                </div>
                                <div class="andro_product-footer">
                                    <div class="andro_product-price">
                                        <span>8$</span>
                                        <span>14$</span>
                                    </div>
                                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                </div>
                            </div>
                            <!-- Deal End -->
                            <!-- Deal Start -->
                            <div class="andro_product">
                                <div class="andro_product-badge andro_badge-sale">
                                    10% Off
                                </div>
                                <div class="andro_product-thumb">
                                    <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                                    <div class="andro_countdown-timer" data-countdown="2021/05/06"></div>
                                </div>
                                <div class="andro_product-body">
                                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Cucumbers </a> </h5>
                                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                    <div class="andro_rating-wrapper">
                                        <div class="andro_rating">
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                        <span>4 Stars</span>
                                    </div>
                                </div>
                                <div class="andro_product-footer">
                                    <div class="andro_product-price">
                                        <span>8$</span>
                                        <span>14$</span>
                                    </div>
                                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                </div>
                            </div>
                            <!-- Deal End -->
                            <!-- Deal Start -->
                            <div class="andro_product">
                                <div class="andro_product-badge andro_badge-sale">
                                    20% Off
                                </div>
                                <div class="andro_product-thumb">
                                    <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                                    <div class="andro_countdown-timer" data-countdown="2021/09/09"></div>
                                </div>
                                <div class="andro_product-body">
                                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h5>
                                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                    <div class="andro_rating-wrapper">
                                        <div class="andro_rating">
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star active"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                        <span>4 Stars</span>
                                    </div>
                                </div>
                                <div class="andro_product-footer">
                                    <div class="andro_product-price">
                                        <span>8$</span>
                                        <span>14$</span>
                                    </div>
                                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                </div>
                            </div>
                            <!-- Deal End -->
                        </div>
                    </div>
                    <!-- Daily Deals End -->
                    <!-- Newsletter Start -->
                    <div class="sidebar-widget">
                        <div class="andro_newsletter-form">
                            <h5>Join our newsletter</h5>
                            <p>Get exclusive weekly deals with our newsletter subscription</p>
                            <form method="post">
                                <div class="form-group">
                                    <input type="email" class="form-control" name="newsletter_email" placeholder="Email Address" value="">
                                </div>
                                <button type="submit" class="andro_btn-custom secondary btn-block shadow-none" name="button">Join Newsletter</button>
                            </form>
                        </div>
                    </div>
                    <!-- Newsletter End -->
                </div>
            </div>
            <!-- Sidebar End -->
        </div>
    </div>
</div>
<!-- Featured Products End -->
<!-- Call to action Start -->
<div class="section pt-0">
    <div class="container">
        <div class="andro_cta-notice secondary-bg pattern-bg">
            <div class="andro_cta-notice-inner">
                <h3 class="text-white">Buy Today and Get 20% Off</h3>
                <p class="text-white">Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
                <a href="#" class="andro_btn-custom light">Shop Now</a>
            </div>
        </div>
    </div>
</div>
<!-- Call to action End -->
<!-- Top Picks Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="section-title">
            <h4 class="title">Top Picks</h4>
        </div>
        <div class="row">
            <!-- Product Start -->
            <div class="col-lg-6">
                <div class="andro_product andro_product-list andro_product-has-controls andro_product-has-buttons">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/2.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>4 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Kiwi & Strawberry </a> </h5>
                        <div class="andro_product-price">
                            <span>19$</span>
                            <span>29$</span>
                        </div>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    </div>
                    <div class="andro_product-footer">
                        <div class="andro_product-buttons">
                            <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                            <a href="#" class="andro_btn-custom light">Quick View</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="col-lg-6">
                <div class="andro_product andro_product-list andro_product-has-controls andro_product-has-buttons">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                            </div>
                            <span>5 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Watermelons </a> </h5>
                        <div class="andro_product-price">
                            <span>11$</span>
                        </div>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    </div>
                    <div class="andro_product-footer">
                        <div class="andro_product-buttons">
                            <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                            <a href="#" class="andro_btn-custom light">Quick View</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="col-lg-6">
                <div class="andro_product andro_product-list andro_product-has-controls andro_product-has-buttons">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/3.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                            </div>
                            <span>5 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Kiwi </a> </h5>
                        <div class="andro_product-price">
                            <span>17$</span>
                        </div>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    </div>
                    <div class="andro_product-footer">
                        <div class="andro_product-buttons">
                            <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                            <a href="#" class="andro_btn-custom light">Quick View</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="col-lg-6">
                <div class="andro_product andro_product-list andro_product-has-controls andro_product-has-buttons">
                    <div class="andro_product-badge andro_badge-featured">
                        <i class="fa fa-star"></i>
                        <span>Featured</span>
                    </div>
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                            </div>
                            <span>5 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h5>
                        <div class="andro_product-price">
                            <span>23$</span>
                        </div>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    </div>
                    <div class="andro_product-footer">
                        <div class="andro_product-buttons">
                            <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                            <a href="#" class="andro_btn-custom light">Quick View</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Product End -->
        </div>
    </div>
</div>
<!-- Top Picks End -->
<!-- CTA Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <div class="andro_cta">
                    <img src="assets/img/cta/3.jpg" alt="cta">
                    <div class="andro_cta-content">
                        <h4 class="text-white">Get Your <span class="fw-400">Lemons</span> </h4>
                        <p class="text-white">Cras ultricies ligula sed magna dictum porta. Proin eget tortor risus.</p>
                        <a routerLink='/shop-v1' class="andro_btn-custom btn-sm light">Shop Now</a>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="andro_cta">
                    <img src="assets/img/cta/4.jpg" alt="cta">
                    <div class="andro_cta-content">
                        <h4 class="text-white">Freshly Picked <span class="fw-400">Vegetables</span> </h4>
                        <a routerLink='/shop-v1' class="andro_btn-custom btn-sm light">Shop Now</a>
                    </div>
                </div>
                <div class="andro_cta">
                    <img src="assets/img/cta/5.jpg" alt="cta">
                    <div class="andro_cta-content">
                        <h4 class="text-white">Freshly Picked <span class="fw-400">Fruits</span> </h4>
                        <a routerLink='/shop-v1' class="andro_btn-custom btn-sm light">Shop Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CTA End -->
<!-- Blog Posts Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="section-title">
            <h4 class="title">From Our Blog</h4>
        </div>
        <!-- Article Start -->
        <article class="andro_post andro_post-list">
            <div class="andro_post-thumb">
                <a routerLink='/blog-single'>
                    <img src="assets/img/blog/1.jpg" alt="post">
                </a>
            </div>
            <div class="andro_post-body">
                <div class="andro_post-categories">
                    <a href="#">Food</a>
                    <a href="#">Veg</a>
                </div>
                <div class="andro_post-desc">
                    <h5> <a routerLink='/blog-single'>Difference Is In the Soil</a> </h5>
                    <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                </div>
                <a class="andro_btn-custom" routerLink='/blog-single'> Read More </a>
            </div>
        </article>
        <!-- Article End -->
        <!-- Article Start -->
        <article class="andro_post andro_post-list">
            <div class="andro_post-thumb">
                <a routerLink='/blog-single'>
                    <img src="assets/img/blog/2.jpg" alt="post">
                </a>
            </div>
            <div class="andro_post-body">
                <div class="andro_post-categories">
                    <a href="#">Health</a>
                </div>
                <div class="andro_post-desc">
                    <h5> <a routerLink='/blog-single'>Planting Season Begins</a> </h5>
                    <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                </div>
                <a class="andro_btn-custom" routerLink='/blog-single'> Read More </a>
            </div>
        </article>
        <!-- Article End -->
        <!-- Article Start -->
        <article class="andro_post andro_post-list">
            <div class="andro_post-thumb">
                <a routerLink='/blog-single'>
                    <img src="assets/img/blog/3.jpg" alt="post">
                </a>
            </div>
            <div class="andro_post-body">
                <div class="andro_post-categories">
                    <a href="#">Food</a>
                    <a href="#">Dishes</a>
                </div>
                <div class="andro_post-desc">
                    <h5> <a routerLink='/blog-single'>Freshly Picked Organics</a> </h5>
                    <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                </div>
                <a class="andro_btn-custom" routerLink='/blog-single'> Read More </a>
            </div>
        </article>
        <!-- Article End -->
    </div>
</div>
<!-- Blog Posts End -->
<!-- Testimonials & Clients start -->
<div class="section pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mb-lg-30">
                <div class="andro_testimonials">
                    <div class="andro_testimonial-item">
                        <div class="andro_testimonials-inner">
                            <img src="assets/img/people/4.jpg" alt="author">
                            <h5>Jina Flock</h5>
                            <span>Farmer</span>
                            <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec sollicitudin molestie malesuada. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor
                                at sem.</p>
                        </div>
                        <ul class="andro_sm">
                            <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-youtube"></i> </a> </li>
                        </ul>
                    </div>
                    <div class="andro_testimonial-item">
                        <div class="andro_testimonials-inner">
                            <img src="assets/img/people/4.jpg" alt="author">
                            <h5>Jina Flock</h5>
                            <span>Chemist</span>
                            <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec sollicitudin molestie malesuada. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor
                                at sem.</p>
                        </div>
                        <ul class="andro_sm">
                            <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-youtube"></i> </a> </li>
                        </ul>
                    </div>
                    <div class="andro_testimonial-item">
                        <div class="andro_testimonials-inner">
                            <img src="assets/img/people/4.jpg" alt="author">
                            <h5>Jina Flock</h5>
                            <span>Farmer</span>
                            <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec sollicitudin molestie malesuada. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et, porttitor
                                at sem.</p>
                        </div>
                        <ul class="andro_sm">
                            <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-youtube"></i> </a> </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="andro_companies">
                    <div class="andro_companies-item">
                        <img src="assets/img/clients/1.png" alt="client">
                        <div class="andro_companies-item-name">
                            <span>Green Co.</span>
                        </div>
                    </div>
                    <div class="andro_companies-item">
                        <img src="assets/img/clients/2.png" alt="client">
                        <div class="andro_companies-item-name">
                            <span>Green Co.</span>
                        </div>
                    </div>
                    <div class="andro_companies-item">
                        <img src="assets/img/clients/3.png" alt="client">
                        <div class="andro_companies-item-name">
                            <span>Green Co.</span>
                        </div>
                    </div>
                    <div class="andro_companies-item">
                        <img src="assets/img/clients/4.png" alt="client">
                        <div class="andro_companies-item-name">
                            <span>Green Co.</span>
                        </div>
                    </div>
                    <div class="andro_companies-item">
                        <img src="assets/img/clients/5.png" alt="client">
                        <div class="andro_companies-item-name">
                            <span>Green Co.</span>
                        </div>
                    </div>
                    <div class="andro_companies-item">
                        <img src="assets/img/clients/6.png" alt="client">
                        <div class="andro_companies-item-name">
                            <span>Green Co.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Testimonials & Clients end -->
<!-- FAQ & Contact Form Start -->
<div class="section pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 mb-lg-30">
                <div class="section-title">
                    <h4 class="title">FAQ</h4>
                </div>
                <div class="accordion with-gap" id="generalFAQExample">
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalOne" aria-expanded="true" aria-controls="generalOne">
                            What is Organista?
                        </div>
                        <div id="generalOne" class="collapse show" data-parent="#generalFAQExample">
                            <div class="card-body">
                                Nulla porttitor accumsan tincidunt. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Proin eget tortor risus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalTwo" aria-expanded="false" aria-controls="generalTwo">
                            Getting Started with Organista
                        </div>
                        <div id="generalTwo" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body">
                                Nulla porttitor accumsan tincidunt. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Proin eget tortor risus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalThree" aria-expanded="false" aria-controls="generalThree">
                            Do i have the latest version?
                        </div>
                        <div id="generalThree" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body">
                                Nulla porttitor accumsan tincidunt. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Proin eget tortor risus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalFour" aria-expanded="false" aria-controls="generalFour">
                            How many times can I use Organista?
                        </div>
                        <div id="generalFour" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body">
                                Nulla porttitor accumsan tincidunt. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Proin eget tortor risus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" data-toggle="collapse" role="button" data-target="#generalFive" aria-expanded="false" aria-controls="generalFive">
                            How to migrate my website?
                        </div>
                        <div id="generalFive" class="collapse" data-parent="#generalFAQExample">
                            <div class="card-body">
                                Nulla porttitor accumsan tincidunt. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Proin eget tortor risus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="section-title">
                    <h4 class="title">Contact Us</h4>
                </div>
                <form method="post">
                    <div class="row">
                        <div class="form-group col-lg-6">
                            <input type="text" placeholder="First Name" class="form-control" name="fname" value="">
                        </div>
                        <div class="form-group col-lg-6">
                            <input type="text" placeholder="Last Name" class="form-control" name="lname" value="">
                        </div>
                        <div class="form-group col-lg-12">
                            <input type="email" placeholder="Email Address" class="form-control" name="email" value="">
                        </div>
                        <div class="form-group col-lg-12">
                            <input type="text" placeholder="Subject" class="form-control" name="subject" value="">
                        </div>
                        <div class="form-group col-lg-12">
                            <textarea name="message" class="form-control" placeholder="Type your message" rows="8"></textarea>
                        </div>
                    </div>
                    <button type="submit" class="andro_btn-custom primary" name="button">Send Message</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- FAQ & Contact Form End -->
<app-insta-two></app-insta-two>
<app-footer-two></app-footer-two>