import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sale-points',
  templateUrl: './sale-points.component.html',
  styleUrls: ['./sale-points.component.css']
})
export class SalePointsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.to_top();
  }

  

  to_top(){
    setTimeout (() => {
      this.scrolltotop();
   }, 200);
  }

  scrolltotop(){
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView({behavior: "smooth"});
      top = null;
    }  
  }

}
