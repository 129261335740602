<app-header></app-header>
<!-- Banner Start -->
<div class="andro_banner banner-3 bg-contain bg-norepeat">
    <div class="andro_banner-slider">
        <!-- Banner Item Start -->
        <div class="andro_banner-slider-inner">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 d-none d-lg-block">
                        <div class="andro_product">
                            <div class="andro_product-badge andro_badge-sale">
                                10% Off
                            </div>
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                                <div class="andro_countdown-timer" data-countdown="2021/05/06">378 days 13:13:07</div>
                            </div>
                            <div class="andro_product-body">
                                <h5 class="andro_product-title"> <a routerLink='/product-single'> Cucumbers </a> </h5>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                    <span>4 Stars</span>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>8$</span>
                                    <span>14$</span>
                                </div>
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 offset-lg-1">
                        <p>Use code <strong class="custom-primary">ORGANIC991</strong> during checkout</p>
                        <h1> Freshly Picked Cucumbers <span class="fw-400">Can Now Be Purchased</span> </h1>
                        <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Sed porttitor lectus nibh. Vestibulum ac diam sit amet quam vehicula.</p>
                        <a routerLink='/shop-v1' class="andro_btn-custom">Shop Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Banner Item End -->
        <!-- Banner Item Start -->
        <div class="andro_banner-slider-inner">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 d-none d-lg-block">
                        <div class="andro_product">
                            <div class="andro_product-badge andro_badge-featured">
                                <i class="fa fa-star"></i>
                                <span>Featured</span>
                            </div>
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/14.png" alt="product"></a>
                                <div class="andro_countdown-timer" data-countdown="2021/05/06">378 days 13:13:07</div>
                            </div>
                            <div class="andro_product-body">
                                <h5 class="andro_product-title"> <a routerLink='/product-single'> Grapes </a> </h5>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                    </div>
                                    <span>4 Stars</span>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>8$</span>
                                    <span>14$</span>
                                </div>
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 offset-lg-1">
                        <p>Use code <strong class="custom-primary">ORGANIC991</strong> during checkout</p>
                        <h1> Grapes Season is Here <span class="fw-400">Get It Today For 50% Off</span> </h1>
                        <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Sed porttitor lectus nibh. Vestibulum ac diam sit amet quam vehicula.</p>
                        <a routerLink='/shop-v1' class="andro_btn-custom">Shop Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Banner Item End -->
        <!-- Banner Item Start -->
        <div class="andro_banner-slider-inner">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 d-none d-lg-block">
                        <div class="andro_product">
                            <div class="andro_product-badge andro_badge-featured">
                                <i class="fa fa-star"></i>
                                <span>Featured</span>
                            </div>
                            <div class="andro_product-thumb">
                                <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                                <div class="andro_countdown-timer" data-countdown="2021/05/06">378 days 13:13:07</div>
                            </div>
                            <div class="andro_product-body">
                                <h5 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h5>
                                <div class="andro_rating-wrapper">
                                    <div class="andro_rating">
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star active"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                    <span>4 Stars</span>
                                </div>
                            </div>
                            <div class="andro_product-footer">
                                <div class="andro_product-price">
                                    <span>8$</span>
                                    <span>14$</span>
                                </div>
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 offset-lg-1">
                        <p>Use code <strong class="custom-primary">ORGANIC991</strong> during checkout</p>
                        <h1> Juice It While It's Fresh <span class="fw-400">Get Oranges From Our Shop</span> </h1>
                        <p>Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Sed porttitor lectus nibh. Vestibulum ac diam sit amet quam vehicula.</p>
                        <a routerLink='/shop-v1' class="andro_btn-custom">Shop Now</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Banner Item End -->
    </div>
    <div class="container">
        <div class="andro_arrows justify-content-center">
            <i class="fa fa-arrow-left slick-arrow slider-prev"></i>
            <i class="fa fa-arrow-right slick-arrow slider-next"></i>
        </div>
    </div>
</div>
<!-- Banner End -->
<!-- Categories Start -->
<div class="section section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-diet"></i>
                        <h5>Food</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-supplements"></i>
                        <h5>Supplements</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-groceries"></i>
                        <h5>Baskets</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-cleaning-spray"></i>
                        <h5>Home Care</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-baby"></i>
                        <h5>Kids Care</h5>
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-4">
                <div class="andro_icon-block text-center has-link">
                    <a href="#">
                        <i class="flaticon-olive-oil"></i>
                        <h5>Oils</h5>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Categories End -->
<!-- Featured Products Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-badge andro_badge-featured">
                        <i class="fa fa-star"></i>
                        <span>Featured</span>
                    </div>
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/2.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>4 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Kiwi & Strawberry </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>19$</span>
                                <span>29$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>3 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Cucumbers </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-badge andro_badge-featured">
                        <i class="fa fa-star"></i>
                        <span>Featured</span>
                    </div>
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                            </div>
                            <span>5 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Watermelons </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>8$</span>
                                <span>15$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
            </div>
            <div class="col-lg-6">
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/14.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                            </div>
                            <span>3 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Grapes </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>12$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/8.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                            </div>
                            <span>5 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Lettuce </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
                <!-- Product Start -->
                <div class="andro_product andro_product-list">
                    <div class="andro_product-thumb">
                        <a routerLink='/product-single'><img src="assets/img/products/7.png" alt="product"></a>
                    </div>
                    <div class="andro_product-body">
                        <div class="andro_rating-wrapper">
                            <div class="andro_rating">
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                                <i class="fa fa-star active"></i>
                            </div>
                            <span>5 Stars</span>
                        </div>
                        <h5 class="andro_product-title"> <a routerLink='/product-single'> Ginger </a> </h5>
                        <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        <div class="andro_product-footer">
                            <div class="andro_product-price">
                                <span>8$</span>
                                <span>15$</span>
                            </div>
                            <div class="btn-group">
                                <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                                <button type="button" class="andro_btn-custom dropdown-toggle dropdown-toggle-split btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#quickViewModal"> <i class="fa fa-eye"></i> Quick View</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-sync"></i> Compare</a>
                                    <a class="dropdown-item" href="#"> <i class="fa fa-heart"></i> Add To Wishlist</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Product End -->
            </div>
        </div>
    </div>
</div>
<!-- Featured Products End -->
<!-- Call To Action Start -->
<div class="section section-padding pt-0">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="andro_cta">
                    <img src="assets/img/cta/1.jpg" alt="cta">
                    <div class="andro_cta-content">
                        <h4 class="text-white">Freshly Picked <span class="fw-400">Vegetables</span> </h4>
                        <p class="text-white">Cras ultricies ligula sed magna dictum porta. Proin eget tortor risus.</p>
                        <a routerLink='/shop-v1' class="andro_btn-custom btn-sm light">Shop Now</a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="andro_cta">
                    <img src="assets/img/cta/2.jpg" alt="cta">
                    <div class="andro_cta-content">
                        <h4 class="text-white">Freshly Picked <span class="fw-400">Fruits</span> </h4>
                        <p class="text-white">Cras ultricies ligula sed magna dictum porta. Proin eget tortor risus.</p>
                        <a routerLink='/shop-v1' class="andro_btn-custom btn-sm light">Shop Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Call To Action End -->
<!-- Top Category picks start -->
<div class="section pt-0">
    <div class="container">
        <div class="section-title flex-title">
            <h4 class="title">Top Picks</h4>
            <ul class="nav" id="productsTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="foodCategory-tab" data-toggle="tab" href="#foodCategory" role="tab" aria-controls="foodCategory" aria-selected="true">Food</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="ketaCategory-tab" data-toggle="tab" href="#ketaCategory" role="tab" aria-controls="ketaCategory" aria-selected="false">Keta</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="oilsCategory-tab" data-toggle="tab" href="#oilsCategory" role="tab" aria-controls="oilsCategory" aria-selected="false">Oils</a>
                </li>
            </ul>
        </div>
        <!-- Tabs Start -->
        <div class="tab-content" id="productsTabContent">
            <div class="tab-pane fade show active" id="foodCategory" role="tabpanel" aria-labelledby="foodCategory-tab">
                <div class="andro_grid-slider">
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-badge andro_badge-featured">
                            <i class="fa fa-star"></i>
                            <span>Featured</span>
                        </div>
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Cucumber </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-badge andro_badge-sale">
                            20% Off
                        </div>
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Watermelon </a> </h6>
                            <div class="andro_product-price">
                                <span>22$</span>
                                <span>29$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/7.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Ginger </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/8.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Lettuce </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                </div>
            </div>
            <div class="tab-pane fade" id="ketaCategory" role="tabpanel" aria-labelledby="ketaCategory-tab">
                <div class="andro_grid-slider">
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-badge andro_badge-featured">
                            <i class="fa fa-star"></i>
                            <span>Featured</span>
                        </div>
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Cucumber </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/8.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Lettuce </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/7.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Ginger </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-badge andro_badge-sale">
                            20% Off
                        </div>
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Watermelon </a> </h6>
                            <div class="andro_product-price">
                                <span>22$</span>
                                <span>29$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                </div>
            </div>
            <div class="tab-pane fade" id="oilsCategory" role="tabpanel" aria-labelledby="oilsCategory-tab">
                <div class="andro_grid-slider">
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-badge andro_badge-featured">
                            <i class="fa fa-star"></i>
                            <span>Featured</span>
                        </div>
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Cucumber </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/7.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Ginger </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-badge andro_badge-sale">
                            20% Off
                        </div>
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Watermelon </a> </h6>
                            <div class="andro_product-price">
                                <span>22$</span>
                                <span>29$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                    <!-- Product Start -->
                    <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                        <div class="andro_product-thumb">
                            <a routerLink='/product-single'><img src="assets/img/products/8.png" alt="product"></a>
                        </div>
                        <div class="andro_product-body">
                            <h6 class="andro_product-title"> <a routerLink='/product-single'> Lettuce </a> </h6>
                            <div class="andro_product-price">
                                <span>19$</span>
                            </div>
                            <div class="andro_rating-wrapper">
                                <div class="andro_rating">
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                    <i class="fa fa-star active"></i>
                                </div>
                            </div>
                        </div>
                        <div class="andro_product-footer">
                            <div class="andro_product-buttons">
                                <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                                <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                            </div>
                        </div>
                    </div>
                    <!-- Product End -->
                </div>
            </div>
        </div>
        <!-- Tabs End -->
    </div>
</div>
<!-- Top Category picks end -->
<!-- Call to action Start -->
<div class="section pt-0">
    <div class="andro_cta-notice secondary-bg pattern-bg rounded-0">
        <div class="container">
            <div class="andro_cta-notice-inner">
                <h3 class="text-white">Buy Today and Get 20% Off</h3>
                <p class="text-white">Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Cras ultricies ligula sed magna dictum porta. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.</p>
                <a href="#" class="andro_btn-custom light">Shop Now</a>
            </div>
        </div>
    </div>
</div>
<!-- Call to action End -->
<!-- Daily Deals Start -->
<div class="section pt-0 andro_fresh-arrivals">
    <div class="container">
        <div class="section-title flex-title">
            <h4 class="title">Daily Deals</h4>
            <div class="andro_arrows">
                <i class="fa fa-arrow-left slick-arrow slider-prev"></i>
                <i class="fa fa-arrow-right slick-arrow slider-next"></i>
            </div>
        </div>
        <div class="andro_fresh-arrivals-slider">
            <!-- Deal Start -->
            <div class="andro_product">
                <div class="andro_product-badge andro_badge-featured">
                    <i class="fa fa-star"></i>
                    <span>Featured</span>
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                    <div class="andro_countdown-timer" data-countdown="2021/01/01"></div>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Watermelon </a> </h5>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>8$</span>
                        <span>14$</span>
                    </div>
                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                </div>
            </div>
            <!-- Deal End -->
            <!-- Deal Start -->
            <div class="andro_product">
                <div class="andro_product-badge andro_badge-sale">
                    10% Off
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/4.png" alt="product"></a>
                    <div class="andro_countdown-timer" data-countdown="2021/05/06"></div>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Cucumbers </a> </h5>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>8$</span>
                        <span>14$</span>
                    </div>
                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                </div>
            </div>
            <!-- Deal End -->
            <!-- Deal Start -->
            <div class="andro_product">
                <div class="andro_product-badge andro_badge-sale">
                    20% Off
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                    <div class="andro_countdown-timer" data-countdown="2021/09/09"></div>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h5>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>8$</span>
                        <span>14$</span>
                    </div>
                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                </div>
            </div>
            <!-- Deal End -->
            <!-- Deal Start -->
            <div class="andro_product">
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/14.png" alt="product"></a>
                    <div class="andro_countdown-timer" data-countdown="2021/01/01"></div>
                </div>
                <div class="andro_product-body">
                    <h5 class="andro_product-title"> <a routerLink='/product-single'> Grapes </a> </h5>
                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <span>4 Stars</span>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>8$</span>
                        <span>14$</span>
                    </div>
                    <a routerLink='/product-single' class="andro_btn-custom btn-sm">Buy Now</a>
                </div>
            </div>
            <!-- Deal End -->
        </div>
    </div>
</div>
<!-- Daily Deals End -->
<!-- Blog posts start -->
<div class="section pt-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="section-title">
                    <h4 class="title">Latest News</h4>
                </div>
                <div class="row masonry">
                    <!-- Article Start -->
                    <div class="col-lg-6 col-md-6 masonry-item">
                        <article class="andro_post">
                            <div class="andro_post-thumb">
                                <a routerLink='/blog-single'>
                                    <img src="assets/img/blog/11.jpg" alt="post">
                                </a>
                            </div>
                            <div class="andro_post-body">
                                <div class="andro_post-categories">
                                    <a href="#">Food</a>
                                    <a href="#">Veg</a>
                                </div>
                                <div class="andro_post-desc">
                                    <h5> <a routerLink='/blog-single'>Difference Is In the Soil</a> </h5>
                                    <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                </div>
                                <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                            </div>
                        </article>
                    </div>
                    <!-- Article End -->
                    <!-- Article Start -->
                    <div class="col-lg-6 col-md-6 masonry-item">
                        <article class="andro_post">
                            <div class="andro_post-thumb">
                                <a routerLink='/blog-single'>
                                    <img src="assets/img/blog/2.jpg" alt="post">
                                </a>
                            </div>
                            <div class="andro_post-body">
                                <div class="andro_post-categories">
                                    <a href="#">Health</a>
                                </div>
                                <div class="andro_post-desc">
                                    <h5> <a routerLink='/blog-single'>Planting Season Begins</a> </h5>
                                    <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                </div>
                                <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                            </div>
                        </article>
                    </div>
                    <!-- Article End -->
                    <!-- Article Start -->
                    <div class="col-lg-6 col-md-6 masonry-item">
                        <article class="andro_post">
                            <div class="andro_post-thumb">
                                <a routerLink='/blog-single'>
                                    <img src="assets/img/blog/3.jpg" alt="post">
                                </a>
                            </div>
                            <div class="andro_post-body">
                                <div class="andro_post-categories">
                                    <a href="#">Food</a>
                                    <a href="#">Dishes</a>
                                </div>
                                <div class="andro_post-desc">
                                    <h5> <a routerLink='/blog-single'>Freshly Picked Organics</a> </h5>
                                    <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                </div>
                                <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                            </div>
                        </article>
                    </div>
                    <!-- Article End -->
                    <!-- Article Start -->
                    <div class="col-lg-6 col-md-6 masonry-item">
                        <article class="andro_post">
                            <div class="andro_post-thumb">
                                <a routerLink='/blog-single'>
                                    <img src="assets/img/blog/4.jpg" alt="post">
                                </a>
                            </div>
                            <div class="andro_post-body">
                                <div class="andro_post-categories">
                                    <a href="#">Food</a>
                                </div>
                                <div class="andro_post-desc">
                                    <h5> <a routerLink='/blog-single'>Make The Perfect Basket</a> </h5>
                                    <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                </div>
                                <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                            </div>
                        </article>
                    </div>
                    <!-- Article End -->
                    <!-- Article Start -->
                    <div class="col-lg-6 col-md-6 masonry-item">
                        <article class="andro_post">
                            <div class="andro_post-thumb">
                                <a routerLink='/blog-single'>
                                    <img src="assets/img/blog/10.jpg" alt="post">
                                </a>
                            </div>
                            <div class="andro_post-body">
                                <div class="andro_post-categories">
                                    <a href="#">Food</a>
                                    <a href="#">Tips</a>
                                </div>
                                <div class="andro_post-desc">
                                    <h5> <a routerLink='/blog-single'>Avocado Tips & Tricks</a> </h5>
                                    <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                </div>
                                <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                            </div>
                        </article>
                    </div>
                    <!-- Article End -->
                    <!-- Article Start -->
                    <div class="col-lg-6 col-md-6 masonry-item">
                        <article class="andro_post">
                            <div class="andro_post-thumb">
                                <a routerLink='/blog-single'>
                                    <img src="assets/img/blog/5.jpg" alt="post">
                                </a>
                            </div>
                            <div class="andro_post-body">
                                <div class="andro_post-categories">
                                    <a href="#">Seasons</a>
                                </div>
                                <div class="andro_post-desc">
                                    <h5> <a routerLink='/blog-single'>Into the Blueberry Season</a> </h5>
                                    <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                                    <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                                </div>
                                <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                            </div>
                        </article>
                    </div>
                    <!-- Article End -->
                </div>
            </div>
            <div class="col-lg-4">
                <div class="sidebar">
                    <!-- About Author Start -->
                    <div class="sidebar-widget widget-about-author">
                        <div class="widget-about-author-inner">
                            <img src="assets/img/people/4.jpg" alt="author">
                            <h5>Jina Flock</h5>
                            <p class="mb-0">Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Donec sollicitudin molestie malesuada. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur arcu erat, accumsan id imperdiet et,
                                porttitor at
                                sem.</p>
                        </div>
                        <ul class="andro_sm">
                            <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
                            <li> <a href="#"> <i class="fab fa-youtube"></i> </a> </li>
                        </ul>
                    </div>
                    <!-- About Author End -->
                    <!-- Categories Start -->
                    <div class="sidebar-widget">
                        <h5 class="widget-title"> Post Categories </h5>
                        <ul class="sidebar-widget-list">
                            <li>
                                <a href="#"> Food <span>(32)</span> </a>
                                <ul>
                                    <li> <a href="#">Vegetables & Fruits <span>(14)</span> </a> </li>
                                    <li> <a href="#">Dairy <span>(39)</span></a> </li>
                                    <li> <a href="#">Vegan <span>(54)</span> </a> </li>
                                </ul>
                            </li>
                            <li> <a href="#"> Keto <span>(24)</span> </a> </li>
                            <li> <a href="#"> Baskets <span>(44)</span> </a> </li>
                            <li> <a href="#"> Baby & Kids Care <span>(15)</span> </a> </li>
                        </ul>
                    </div>
                    <!-- Categories End -->
                    <!-- Fresh Products Start -->
                    <div class="sidebar-widget widget-recent-products">
                        <h5 class="widget-title">Fresh Products</h5>
                        <div class="andro_product-recent">
                            <a class="andro_product-recent-thumb" routerLink='/blog-single'>
                                <img src="assets/img/products/5.png" alt="product">
                                <div class="andro_product-recent-content">
                                    <h6> Watermelon</h6>
                                    <div class="andro_product-price">
                                        <span>12$</span>
                                    </div>
                                </div>
                            </a>
                            <button type="button" data-toggle="modal" data-target="#quickViewModal"> <i data-toggle="tooltip" title="Quick View" data-placement="top" class="flaticon-view"></i></button>
                        </div>
                        <div class="andro_product-recent">
                            <a class="andro_product-recent-thumb" routerLink='/blog-single'>
                                <img src="assets/img/products/6.png" alt="product">
                                <div class="andro_product-recent-content">
                                    <h6>Oranges</h6>
                                    <div class="andro_product-price">
                                        <span>8$</span>
                                        <span>14$</span>
                                    </div>
                                </div>
                            </a>
                            <button type="button" data-toggle="modal" data-target="#quickViewModal"> <i data-toggle="tooltip" title="Quick View" data-placement="top" class="flaticon-view"></i></button>
                        </div>
                        <div class="andro_product-recent">
                            <a class="andro_product-recent-thumb" routerLink='/blog-single'>
                                <img src="assets/img/products/10.png" alt="product">
                                <div class="andro_product-recent-content">
                                    <h6>Corn</h6>
                                    <div class="andro_product-price">
                                        <span>8$</span>
                                    </div>
                                </div>
                            </a>
                            <button type="button" data-toggle="modal" data-target="#quickViewModal"> <i data-toggle="tooltip" title="Quick View" data-placement="top" class="flaticon-view"></i></button>
                        </div>
                        <div class="andro_product-recent">
                            <a class="andro_product-recent-thumb" routerLink='/blog-single'>
                                <img src="assets/img/products/14.png" alt="product">
                                <div class="andro_product-recent-content">
                                    <h6>Grapes</h6>
                                    <div class="andro_product-price">
                                        <span>8$</span>
                                        <span>14$</span>
                                    </div>
                                </div>
                            </a>
                            <button type="button" data-toggle="modal" data-target="#quickViewModal"> <i data-toggle="tooltip" title="Quick View" data-placement="top" class="flaticon-view"></i></button>
                        </div>
                    </div>
                    <!-- Fresh Products End -->
                    <!-- CTA Start -->
                    <div class="sidebar-widget">
                        <div class="andro_cta">
                            <img src="assets/img/cta/3.jpg" alt="cta">
                            <div class="andro_cta-content">
                                <h4 class="text-white">Grab Your <span class="fw-400">Lemons</span> </h4>
                                <a routerLink='/shop-v1' class="andro_btn-custom btn-sm light">Shop Now</a>
                            </div>
                        </div>
                    </div>
                    <!-- CTA End -->
                    <!-- Newsletter Start -->
                    <div class="sidebar-widget">
                        <div class="andro_newsletter-form secondary-bg pattern-bg">
                            <h5>Join our newsletter</h5>
                            <p>Get exclusive weekly deals with our newsletter subscription</p>
                            <form method="post">
                                <div class="form-group">
                                    <input type="email" class="form-control" name="newsletter_email" placeholder="Email Address" value="">
                                </div>
                                <button type="submit" class="andro_btn-custom btn-block" name="button">Join Newsletter</button>
                            </form>
                        </div>
                    </div>
                    <!-- Newsletter End -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Blog posts End -->
<!-- Other Products Start -->
<div class="section pt-0 andro_other-mentions">
    <div class="container">
        <div class="section-title flex-title">
            <h4 class="title">Other Mentions</h4>
            <div class="andro_arrows">
                <i class="fa fa-arrow-left slick-arrow slider-prev"></i>
                <i class="fa fa-arrow-right slick-arrow slider-next"></i>
            </div>
        </div>
        <div class="andro_other-mentions-slider">
            <!-- Product Start -->
            <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/10.png" alt="product"></a>
                </div>
                <div class="andro_product-badge andro_badge-sale">
                    20% Off
                </div>
                <div class="andro_product-body">
                    <h6 class="andro_product-title"> <a routerLink='/product-single'> Corn </a> </h6>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                        </div>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>19$</span>
                        <span>26$</span>
                    </div>
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/14.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h6 class="andro_product-title"> <a routerLink='/product-single'> Grapes </a> </h6>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                        </div>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>19$</span>
                    </div>
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/12.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h6 class="andro_product-title"> <a routerLink='/product-single'> Brocoli </a> </h6>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                        </div>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>19$</span>
                    </div>
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-badge andro_badge-featured">
                    <i class="fa fa-star"></i>
                    <span>Featured</span>
                </div>
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/6.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h6 class="andro_product-title"> <a routerLink='/product-single'> Oranges </a> </h6>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                        </div>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>19$</span>
                    </div>
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/5.png" alt="product"></a>
                </div>
                <div class="andro_product-badge andro_badge-sale">
                    15% Off
                </div>
                <div class="andro_product-body">
                    <h6 class="andro_product-title"> <a routerLink='/product-single'> Watermelon </a> </h6>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                        </div>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>19$</span>
                        <span>22$</span>
                    </div>
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
            <!-- Product Start -->
            <div class="andro_product andro_product-minimal andro_product-has-controls andro_product-has-buttons">
                <div class="andro_product-thumb">
                    <a routerLink='/product-single'><img src="assets/img/products/7.png" alt="product"></a>
                </div>
                <div class="andro_product-body">
                    <h6 class="andro_product-title"> <a routerLink='/product-single'> Ginger </a> </h6>
                    <div class="andro_rating-wrapper">
                        <div class="andro_rating">
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                            <i class="fa fa-star active"></i>
                        </div>
                    </div>
                </div>
                <div class="andro_product-footer">
                    <div class="andro_product-price">
                        <span>19$</span>
                    </div>
                    <div class="andro_product-buttons">
                        <a href="#" class="andro_btn-custom primary">Add To Cart</a>
                        <a href="#" data-toggle="modal" data-target="#quickViewModal" class="andro_btn-custom light">Quick View</a>
                    </div>
                </div>
            </div>
            <!-- Product End -->
        </div>
    </div>
</div>
<!-- Other Products End -->
<app-insta-one></app-insta-one>
<app-footer></app-footer>