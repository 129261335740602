import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { last, min, takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { filter, paginationlink, pag_request, Product } from 'src/app/model';
import { ShopSidebarComponent } from '../../layouts/shop-sidebar/shop-sidebar.component';
declare let $: any;

@Component({
  selector: 'app-shop-one',
  templateUrl: './shop-one.component.html',
  styleUrls: ['./shop-one.component.css']
})
export class ShopOneComponent implements OnInit, OnDestroy {

  categorie = [];
  categorie_txt = "Alle categorieën";
  private destroy$: Subject<void> = new Subject<void>();
  list_page: paginationlink[] = [];
  list_products: Product[] = [];
  current_page = 0;
  prev_active = false;
  nxt_active = false;
  last_page = 0;
  sort_active = "";
  @ViewChild('btn') btn: ElementRef;
  min_price_last = 0;
  max_price_last = 100;
  spinner_active = true;
  filter_active : Boolean = false;
  filter_init : filter = {modus: "", categories: [], subcategories: [], attention: false, sale: false, 
  no_presc: false, brands: [], price_min: 0, price_max: 1000, searchterm: '', sort: '', gen_search: ''};
  noproduct: Boolean = false;
  _api = "";
  categorie_single = "";
  @ViewChild(ShopSidebarComponent) sidebar;
  next_page_url = "";
  list_page_def = [];
  list_page_down = [];
  list_page_ori = [];
  current_cat = [];
  current_subcat = [];
  checked = false;
  to_checked = false;
  _filter_lastused: filter = this.filter_init;
  check_visible = false;
  current_index = 0;

  
  constructor(public _aposervice: ApoService, public http: HttpClient) { 

      this._aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => { 
        this._api = data;
      })

      this._aposervice.current_catselected.pipe(takeUntil(this.destroy$)).subscribe(data => {       
        if (data == ""){
          this.load_products(this.filter_init);
        }
      })

      _aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {     
        
        console.log(data.uid);
 
        if (data.uid == "YIzrj1r6g6NyBIMmfMHHEJ4HgcG2" || data.uid == "HKAVXBnPAFX9jsFgB14CPNUyEzF3" || data.uid == "ogh1LYBksHUGmQxORNFwjvNiT012" || data.uid == "uaJvFJe8CaUpAWesIVfKGJ0FqlL2"){this.check_visible = true;}
        else {this.check_visible = false;}

        this.load_products(this._filter_lastused);

      });
    
    }

  ngOnInit(): void { 
    setTimeout (() => {
      this.scrolltotop();
   }, 500); 
  }  

  ngOnDestroy(){  
    this.load_products(this.filter_init);
    this.destroy$.next();
    this.destroy$.complete();    
  }

  clear_search(){
    this.load_products(this.filter_init);
    this._aposervice.select_cat(" ");
  }

  check_products(product:Product, event){

    for (let x of this.list_products){
      if (x.cnk == product.cnk){x.check_select = event.checked;}
    }

    this._aposervice.set_products_selected(
      this.list_products.filter(x => x.check_select == true)
    ) 
  }

  check_all(event){
    for (let x of this.list_products){
      x.check_select = event.checked;
      this._aposervice.set_products_selected(
        this.list_products.filter(x => x.check_select == true)
      )
    }   
    this.checked = event.checked;   
  }

  get_to_check(event){
    this.to_checked = event.checked;
    this.load_products(this._filter_lastused);
  }

  clear_selection(event){
    this._aposervice.remove_all_products_selected();
    for (let x of this.list_products){
      x.check_select = false;
    }
    this.checked = false;    
  }

  reload_products(){    
    if (this.current_index == 0){
      console.log("2x");
      
      this.load_products(this._filter_lastused);
    }
    else {
      this.nxt_page(this.current_index, this.list_page_down);
    }
  }  

  load_products(_filter: filter){   

    this.current_index = 0;
    this._filter_lastused = _filter;
    
      if (_filter.gen_search != ""){
        this.categorie_txt = "Zoekterm: '" + _filter.gen_search + "'";
      }
      else if (_filter.modus == "f"){
        this.categorie_txt = "Favorieten";
      }
      else {        
        this.categorie_txt = "Alle categorieën";
        if (_filter.categories.length > 0){
          this.categorie_txt = "";
          for (let x of _filter.categories){
            this.categorie_txt = this.categorie_txt + ", " + x.name;
          }
          if (this.categorie_txt.length > 40){this.categorie_txt = this.categorie_txt.substring(0,40) + "..."}
          this.categorie_txt = this.categorie_txt.substring(2);
        }
      }

      let searchterm = "_"; if (_filter.searchterm != "") {searchterm = _filter.searchterm.replace('/','__').replace('%','_x_');}
            
      let cat = "_";
      if (_filter.categories.length > 0){
        cat = "";
        for (let x of _filter.categories){
          var y = x.code.split(' ').join('');
          cat = cat + "_" + y;
        }
        cat = cat.substring(1);
      } 
      
      let subcat = "_";
      if (_filter.subcategories.length != 0){        
        subcat = "";
        for (let x of _filter.subcategories){
          for (let y of x.value){
            subcat = subcat + "_" + y;
          }
        }
        subcat = subcat.substring(1);
      } 

      let brand = "_";
      if (_filter.brands.length != 0){
        brand = "";
      for (let x of _filter.brands) {brand = brand + "_" + x.name;}
      brand = brand.substring(1);}

      let _sort = "_"; if (_filter.sort != "") {_sort = _filter.sort;}
      let attent = 0; if (_filter.attention == true) {attent = 1;}
      let sale = 0; if (_filter.sale == true) {sale = 1;}
      let nopresc = 0; if (_filter.no_presc == true) {nopresc = 1;}
      let _modus = "x"; if (_filter.modus != ""){_modus = _filter.modus;}
      let _admin = "0"; if (this.check_visible) {_admin = "1";}
  
      this.list_products = [];
      this.list_page = [];
      this.list_page_down = [];
      this.spinner_active = true;
      this.sort_active = "";
      this.checked = false;
      this.clear_selection(true);


      this.http.get<pag_request>(this._api + "producten/" + _modus + "/" + cat + "/" + searchterm + "/" + subcat + "/" +
        attent + "/" + sale + "/" + brand + "/" + _filter.price_min + "_" + _filter.price_max + "/" + nopresc + "/" + _sort + "/" + this.to_checked + "/" + _admin)
        .pipe(takeUntil(this.destroy$)).subscribe(data => {   

          console.log(data);
                     
          this.next_page_url = data.next_page_url;          
          this.list_page = data.links;
          this.list_products = data.data;
          this.current_page = data.current_page;
          this.last_page = data.last_page;  
          for (let x of this.list_products){
            if (x.photo != null) { x.photo = "https://apoathomeapi.be/data/foto/" + x.photo;}
            else {x.photo = "https://apoathomeapi.be/data/no_image.jpg"}            
            x = this._aposervice.calculate_discount(x); 
          }
          
          if (this.list_products.length == 0){this._aposervice.change_noproduct(true); this.noproduct = true;} 
          else {this._aposervice.change_noproduct(false); this.noproduct = false;}
          this.spinner_active = false;
          this.morf_listpage();  
          
       });           

       if (_filter.price_min == this.min_price_last && _filter.price_max == this.max_price_last){
          this.http.get<number>(  this._api + "prijs/" + _modus + "/" + cat + "/" + searchterm + "/" + subcat + "/" +
          attent + "/" + sale + "/" + brand + "/" + _filter.price_min + "_" + _filter.price_max + "/" + nopresc + "/" + _sort + "/" + this.to_checked + "/" + _admin)
          .pipe(takeUntil(this.destroy$)).subscribe(data => {             
            this._aposervice.change_pricemax(Math.ceil(data / 10) * 10);
        });
       }
       else { this.max_price_last = _filter.price_max;
              this.min_price_last = _filter.price_min;
       }
    }    
  

  change_filteractive($event){
    this.filter_active = $event;    
  }

  nxt_page(index: number, _array: paginationlink[]){    
    this.scrolltotop();   

    if (_array[index].url != null){
      this.list_page_down = [];
      this.spinner_active = true;
      this.list_products = [];
      this.checked = false;
      this.clear_selection(true);
  
      this.http.get<pag_request>(_array[index].url).pipe(takeUntil(this.destroy$)).subscribe(data => {            
        this.list_page = data.links;        
        this.list_products = data.data;
        this.current_page = data.current_page;
        this.last_page = data.last_page;        
  
        for (let x of this.list_products){
          if (x.photo != null) { x.photo = "https://apoathomeapi.be/data/foto/" + x.photo;}
          else {x.photo = "https://apoathomeapi.be/data/no_image.jpg"}            
          x = this._aposervice.calculate_discount(x); 
        }
  
        this.morf_listpage();   

        for (let x of this.list_page_down){
          if (x.url == _array[index].url){
            this.current_index = this.list_page_down.indexOf(x);
          }
        }
        this.spinner_active = false;

      });
    }  
  }

  setMyStyles_prev(){
    let styles = {
      'visibility': !this.prev_active ? 'hidden' : ''
    };
    return styles;
  } 

  setMyStyles_prev2(){
    let styles = {
      'padding-left': !this.prev_active ? '0px' : '0px'
    };
    return styles;
  } 

  scrolltotop(){
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView({behavior: "smooth"});
      top = null;
    }  
  }

  to_top(){
    setTimeout (() => {
      this.scrolltotop();
   }, 200);
  }

  setMyStyles_noproduct(){
    let styles = {
      'display': this.noproduct && !this.spinner_active ? '' : 'none',
      'font-size': '18px',
      'margin-left': '80px',
      'color': 'red'
    };
    return styles;
  }   

  setMyStyles_nopagination(){
    let styles = {
      'display': this.noproduct && !this.spinner_active ? 'none' : '',
    };
    return styles;
  }   

  setMyStyles_nopagination2(){
    let styles = {
      'display': this.list_products.length == 0 && !this.spinner_active ? 'none' : '',
    };
    return styles;
  }  

  setMyStyles_special(product){
    let styles = {
      'display': product.special == '' ? 'none' : '',
    };
    return styles;
  }

  setMyStyles_discount(product){
    let styles = {
      'display': (this.last_word(product.special) == "korting") ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_nxt(){
    let styles = {
      'display': !this.nxt_active ? 'none' : ''
    };
    return styles;
  } 

  setMyStyles_filter(){
    let styles = {
      'display': this.filter_active ? '' : 'none'
    };
    return styles;
  } 

  filter_verwijderen() {
    this.sort_active = "";
    this.sidebar.construct_filter();
    this.sidebar.is_filter_empty("");
    this.filter_active = false;
  }

  setMyStyles_spinner(){
    let styles = {
      'display': this.spinner_active ? '' : 'none',
      
    };
    return styles;
  }  

  morf_listpage(){   
    this.list_page_def = [];
    this.list_page_down = [];
    for (let y of this.list_page){
      if (this.list_page.indexOf(y) != 0 && this.list_page.indexOf(y) != this.list_page.length - 1){
        this.list_page_down.push(y);
      }
    }

    var _index_current = this.list_page.findIndex(x => x.label === this.current_page.toString());

    if (this.last_page < 4){
      this.list_page.splice(this.list_page.length - 1);
      this.list_page.splice(0,1);
      this.list_page_def = this.list_page;
    }
    else if (this.current_page < 3){
      var volgende = this.list_page[4];
      this.list_page.splice(4);
      this.list_page.splice(0,1);
      this.list_page_def =  this.list_page_def.concat(this.list_page);
      this.list_page_def.push({label: "...",url: volgende.url, active: false})
    }
    else if (!this.even(this.current_page)){
      if (this.list_page.length > _index_current + 4){
        var vorige = this.list_page[_index_current - 1];
        volgende = this.list_page[_index_current + 4];
        this.list_page.splice(_index_current + 3);
        this.list_page.splice(0,_index_current);
  
        this.list_page_def.push({label: "...",url: vorige.url, active: false})
        this.list_page_def =  this.list_page_def.concat(this.list_page)
        this.list_page_def.push({label: "...",url: volgende.url, active: false})
      }
      else {        
        vorige = this.list_page[_index_current - 1];
        this.list_page.splice(this.list_page.length - 1);
        this.list_page.splice(0,_index_current);
        this.list_page_def.push({label: "...",url: vorige.url, active: false})
        this.list_page_def =  this.list_page_def.concat(this.list_page)
      }
    }
    else { 
      if (this.list_page.length > _index_current + 3){
      vorige = this.list_page[_index_current - 2];
      volgende = this.list_page[_index_current + 2];   
      this.list_page.splice(_index_current + 2);
      this.list_page.splice(0,_index_current - 1);

      this.list_page_def.push({label: "...",url: vorige.url, active: false})
      this.list_page_def =  this.list_page_def.concat(this.list_page)
      this.list_page_def.push({label: "...",url: volgende.url, active: false})
      }
      else {
        vorige = this.list_page[_index_current - 2];
        this.list_page.splice(this.list_page.length - 1);
        this.list_page.splice(0,_index_current - 1);
        this.list_page_def.push({label: "...",url: vorige.url, active: false})
        this.list_page_def =  this.list_page_def.concat(this.list_page)
      }
    } 
  }

  even (_number: number){
    if (_number % 2 === 0) {
      return true;
    } else {
     return false;
    }
  }

  sort(sort: string){  
      this.sidebar.is_filter_empty(sort);
      this.sort_active = sort;
      this.btn.nativeElement.focus();
  } 

  select_product(_product){    
    this._aposervice.change_product_selected(_product);
  }
  
  nieuw_product(_product: Product){     
    if (_product.creationtime != undefined){
      var oriDate = new Date(3501, 7, 13, 0, 0, 0);
      var oriDays = 3000000.000000;
      var diff = Math.abs(oriDate.getTime() - new Date().getTime());
      var vandaag_dec = oriDays - (Math.ceil(diff / (1000 * 3600 * 24)))
      console.log(((_product.creationtime).getTime()) > (vandaag_dec - 20));
      if (((_product.creationtime).getTime()) > (vandaag_dec - 20)){ return true; } 
    }
    return false;
  }

  
  last_word(_string: String) {
    var n = _string.split(" ");
    return n[n.length - 1];
  }
}
