import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insta-two',
  templateUrl: './insta-two.component.html',
  styleUrls: ['./insta-two.component.css']
})
export class InstaTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
