import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { account, cartitem, last_order, Order, Product } from 'src/app/model';
import * as moment from 'moment';
import { product } from '../variable';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-last-orders',
  templateUrl: './last-orders.component.html',
  styleUrls: ['./last-orders.component.css']
})
export class LastOrdersComponent implements OnInit, OnDestroy {

  list_lastorders: last_order[] = [];
  private destroy$: Subject<void> = new Subject<void>();
  api = "";
  spinner_active = false;
  geen_items = false;

  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }

  constructor(public http: HttpClient, public _aposervice: ApoService,  private toastr: ToastrService) {
    
    this._aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => { 
      this.api = data;
    });

    this._aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(_account => { 
      this.spinner_active = true;

      if (_account.id != 0){
        this.http.get<any>(this.api + "orderitems/" + _account.id).pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.spinner_active = false;  
            if (data.length == 0){this.geen_items = true;}
            else {
              this.geen_items = false;
              for (let x of data){
                let _product : Product = x[2];
                _product.photo = "https://apoathomeapi.be/data/foto/" + x[2].photo;
                _product = this._aposervice.calculate_discount(_product); 
    
                let _afgeleverd = "";
                if (x[4] == null){_afgeleverd = "neen";} else {_afgeleverd = "ja";}
    
                this.list_lastorders.push(
                  {
                      bestelnummer: x[0],
                      datum:moment(x[1]).format('DD/MM/YYYY'),
                      product: _product,
                      hoeveelheid: x[3],
                      afgeleverd: _afgeleverd
                  }
                )
              }
            }
         


          
        });
      }
      else {
        this.list_lastorders = [];
      }
      
      
    });




  }

  ngOnInit(): void {}
  
  select_product(_product){
    this._aposervice.change_product_selected(_product);
  }

  order_again(last_order:last_order){
    var cartitem: cartitem = {product: last_order.product, quantity: last_order.hoeveelheid, quantity_free: 0, promocode: ""};
    this._aposervice.addProduct(cartitem);
    this.toastr.success('item(s) toegevoegd aan mandje', 'Gelukt', {timeOut: 3000, positionClass: 'toast-bottom-center'})
  }

  

}
