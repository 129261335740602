import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';

@Component({
  selector: 'app-betaling-order',
  templateUrl: './betaling-order.component.html',
  styleUrls: ['./betaling-order.component.css']
})
export class BetalingOrderComponent implements OnInit, OnDestroy {

  constructor(public _Activatedroute:ActivatedRoute, public http: HttpClient, public _aposervice: ApoService) {  
    this._aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => { 
      this.api = data;
    });

    this.id = this._Activatedroute.snapshot.paramMap.get("id");  
    
    this.http.get<any>(this.api + "mollies/" + this.id).pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data == "betaald" || data == "al getoond"){
        this.betaald = data;
        this.som = "";
      }
      else {
        this.betaald = "niet betaald";
        this.som = data.value;
      }      
   })

   }

  id = "";
  betaald = "";
  som = "";
  private destroy$: Subject<void> = new Subject<void>();
  api = "";

  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }



  ngOnInit(): void {

   

  }

}
