import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbAccordion, NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { count, takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { account } from 'src/app/model';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  providers: [NgbAccordionConfig]   
})
export class AccountComponent implements OnInit, OnDestroy {

  // @Output() formvalid = new EventEmitter<boolean>();

  counter = 0;
  facvalid: Boolean = false;
  mailblock: Boolean = true;

  constructor(public formBuilder: FormBuilder, public _aposervice: ApoService, public http: HttpClient) {    

    _aposervice.current_modusaccount.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.modusaccount = data;
    });

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this._api = data;
    })

    _aposervice.current_mailblock.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.mailblock = data;      
    })

    _aposervice.current_facvalid.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.facvalid = data;      
    })

    _aposervice.current_formvalid.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.form_valid = data;      
    })

    _aposervice.current_logout.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data){
        this.items_focussed = [];
      };      
    })

    this.form_account.valueChanges.subscribe((val: account) => {

      this.counter = this.counter + 1;    
            
      if (this.counter != 11 && this.counter != 22){
        if (this.form_valid){
          
            if (this.logged_in) {this.confirm_txt = 'gegevens aanpassen?';}
            else {this.confirm_txt = 'gegevens opslaan?'}
                  
        }
        else if (this.logged_in){
          this.confirm_txt = 'ingelogd';
        }
        else {this.confirm_txt = 'inloggen'}          
      }

      else  {
        if (this.logged_in){
          this.confirm_txt = 'ingelogd';
        }
        else if (this.form_valid) {
          this.confirm_txt = "gegevens opslaan?"
        }
      }   

    });
    
    _aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {
              
        // this.items_focussed = [];
      
        // if (data.lname != '' && data.lname != 'null') {       
          if (data.logged_in) {       
          this.confirm_txt = 'ingelogd';
          this.logged_in = true;
        }
        else {
          this.form_account.controls['lname'].patchValue('');
          this.confirm_txt = 'inloggen';
          this.logged_in = false;
        }

        if (data.lname != 'null'){ this.form_account.controls['lname'].patchValue(data.lname);}
          else {this.form_account.controls['lname'].patchValue('');}
        if (data.fname != 'null'){ this.form_account.controls['fname'].patchValue(data.fname);}
          else {this.form_account.controls['fname'].patchValue('');}
        if (data.mail != 'null'){ this.form_account.controls['mail'].patchValue(data.mail);}
          else {this.form_account.controls['mail'].patchValue('');}
        if (data.telefoon != 'null'){ this.form_account.controls['telefoon'].patchValue(data.telefoon);}
          else {this.form_account.controls['telefoon'].patchValue('');}
        if (data.RR != 'null'){ this.form_account.controls['RR'].patchValue(data.RR);}
          else {this.form_account.controls['RR'].patchValue('');}
        if (data.straat != 'null'){ this.form_account.controls['straat'].patchValue(data.straat);}
          else {this.form_account.controls['straat'].patchValue('');}
        if (data.gemeente != 'null'){ this.form_account.controls['gemeente'].patchValue(data.gemeente);}
          else {this.form_account.controls['gemeente'].patchValue('');}
        if (data.nummer != 'null'){ this.form_account.controls['nummer'].patchValue(data.nummer);}
          else {this.form_account.controls['nummer'].patchValue('');}
        if (data.bus != 'null'){ this.form_account.controls['bus'].patchValue(data.bus);}
          else {this.form_account.controls['bus'].patchValue('');}
        if (data.postcode != 'null'){ this.form_account.controls['postcode'].patchValue(data.postcode);}
          else {this.form_account.controls['postcode'].patchValue('');}
        if (data.info != 'null'){ this.form_account.controls['info'].patchValue(data.info);}
          else {this.form_account.controls['info'].patchValue('');}  
        if (data.bedrijfsnaam != 'null'){ this.form_account.controls['bedrijfsnaam'].patchValue(data.bedrijfsnaam);}
          else {this.form_account.controls['bedrijfsnaam'].patchValue('');}     
        if (data.vertegenwoordiger != 'null'){ this.form_account.controls['vertegenwoordiger'].patchValue(data.vertegenwoordiger);}
          else {this.form_account.controls['vertegenwoordiger'].patchValue('');}     
        if (data.mail_fac != 'null'){ this.form_account.controls['mail_fac'].patchValue(data.mail_fac);}
          else {this.form_account.controls['mail_fac'].patchValue('');}       
        if (data.straat_fac != 'null'){ this.form_account.controls['straat_fac'].patchValue(data.straat_fac);}
          else {this.form_account.controls['straat_fac'].patchValue('');}     
        if (data.nummer_fac != 'null'){ this.form_account.controls['nummer_fac'].patchValue(data.nummer_fac);}
          else {this.form_account.controls['nummer_fac'].patchValue('');}     
          if (data.bus_fac != 'null'){ this.form_account.controls['bus_fac'].patchValue(data.bus_fac);}
            else {this.form_account.controls['bus_fac'].patchValue('');}        
        if (data.postcode_fac != 'null'){ this.form_account.controls['postcode_fac'].patchValue(data.postcode_fac);}
          else {this.form_account.controls['postcode_fac'].patchValue('');}        
          if (data.gemeente_fac != 'null'){ this.form_account.controls['gemeente_fac'].patchValue(data.gemeente_fac);}
            else {this.form_account.controls['gemeente_fac'].patchValue('');}       
        if (data.land_fac != 'null'){ this.form_account.controls['land_fac'].patchValue(data.land_fac);}
          else {this.form_account.controls['land_fac'].patchValue('');}             
        
          this._uid = data.uid;
      
    })
    
   }

  private destroy$: Subject<void> = new Subject<void>();
  focus_item = '';
  items_focussed = [];
  form_valid: Boolean = false;
  confirm_txt = 'inloggen';
  logged_in: Boolean = false;
  modusaccount = '';
  _uid = "";
  _api = "";
  spinner_active = false;
  account_db: boolean = false;

  form_account = this.formBuilder.group({
    lname: '',
    fname: '',
    mail: '',
    telefoon: '',
    RR: '',
    land: 'België',
    straat: '',
    nummer: '',
    bus: '',
    postcode: '',
    gemeente: '',
    info: '',
    vertegenwoordiger: '',
    mail_fac: '',
    bedrijfsnaam: '',
    straat_fac: '',
    nummer_fac: '',
    bus_fac: '',
    postcode_fac: '',
    gemeente_fac: '',
    land_fac:'België'
  });

  change_txt(waarde: string, veld: string){
    this._aposervice.change_account_veld(waarde,veld);    
  }

  @ViewChild('acc') acc: NgbAccordion;
  
  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }

  back_to_account(){
    this.acc.toggle('static-1');
  }

  ngOnInit(): void { }

  set_focus(item){
    this.focus_item = item;
    if (this.items_focussed.findIndex(x => x == item) == -1){this.items_focussed.push(item);}
  }

  set_blur(item){
    if (this.focus_item == item){
      this.focus_item = '';
    }
    if (item == "mail" && this.form_account.controls['mail'].value != ""){
      this.http.get<string>(this._api + "users/exist/" + this.form_account.controls['mail'].value).pipe(takeUntil(this.destroy$)).subscribe(x => {   
        if (x == "yes"){this.account_db = true;} else {this.account_db = false;}        
      });
      
    }
  }

  setMyStyles_account(account_item){
    let styles = {
      'border-color': this.form_account.controls[account_item].value == null ? 'red' : this.focus_item == account_item ? '' : this.form_account.controls[account_item].value != ''  ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_account2(account_item){
    let styles = {
      'border-color': this.form_account.controls[account_item].value == null || this.account_db ? 'red' : this.focus_item == account_item ? '' : this.validateEmail(this.form_account.controls[account_item].value) ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_account7(account_item){
    let styles = {
      'border-color': this.form_account.controls[account_item].value == null ? '' : this.focus_item == account_item ? '' : this.validateEmail(this.form_account.controls[account_item].value) ? 'rgba(81,189,77,255)' : this.form_account.controls[account_item].value != '' ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_account3(account_item){
    let styles = {
      'border-color': this.form_account.controls[account_item].value == null ? 'red' : this.focus_item == account_item ? '' : (this.form_account.controls[account_item].value != ''  && this.form_account.controls[account_item].value.length > 8 && /^\d+$/.test(this.form_account.controls[account_item].value)) ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_layout(){
    let styles = {
      'margin-top': this.modusaccount == 'afrekenen'? '50px' : '',
    };
    return styles;
  } 

  setMyStyles_layout1(){
    let styles = {
      'display': this.modusaccount == 'neo'? 'none' : '',
    };
    return styles;
  } 

  setMyStyles_account4(account_item){
    let styles = {
      'border-color': this.form_account.controls[account_item].value == null ? 'red' : this.focus_item == account_item ? '' : (this.form_account.controls[account_item].value != ''  && this.form_account.controls[account_item].value.length== 4 && /^\d+$/.test(this.form_account.controls[account_item].value)) ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_account8(account_item){
    let styles = {
      'border-color': this.form_account.controls[account_item].value == null ? '' : this.focus_item == account_item ? '' : (this.form_account.controls[account_item].value != ''  && this.form_account.controls[account_item].value.length== 4 && /^\d+$/.test(this.form_account.controls[account_item].value)) ? 'rgba(81,189,77,255)' : this.form_account.controls[account_item].value != '' ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_account6(account_item){
    let styles = {
      'border-color': this.form_account.controls[account_item].value == null ? 'red' : this.focus_item == account_item ? '' : (this.form_account.controls[account_item].value != ''  && this.form_account.controls[account_item].value.length == 11 && /^\d+$/.test(this.form_account.controls[account_item].value)) ? 'rgba(81,189,77,255)' : this.items_focussed.findIndex(x => x == account_item) != -1 ? 'red' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_account5(account_item){
    let styles = {
      'border-color': this.focus_item == account_item ? '' : (this.form_account.controls[account_item].value != '' && this.form_account.controls[account_item].value != null) ? 'rgba(81,189,77,255)' : '',
      'border-width': '2px'
    };
    return styles;
  } 

  setMyStyles_check(account_item){
    let styles = {
      'display': this.form_account.controls[account_item].value == null ? 'none' : this.focus_item == account_item ? 'none' : this.form_account.controls[account_item].value != ''  ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_check2(account_item){
    let styles = {
      'display': this.form_account.controls[account_item].value == null || this.account_db ? 'none' : this.focus_item == account_item ? 'none' : this.validateEmail(this.form_account.controls[account_item].value) ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_check3(account_item){
    let styles = {
      'display': this.form_account.controls[account_item].value == null ? 'none' : this.focus_item == account_item ? 'none' : (this.form_account.controls[account_item].value != '' && this.form_account.controls[account_item].value.length > 8 && /^\d+$/.test(this.form_account.controls[account_item].value)) ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_check4(account_item){
    let styles = {
      'display': this.form_account.controls[account_item].value == null ? 'none' : this.focus_item == account_item ? 'none' : (this.form_account.controls[account_item].value != '' && this.form_account.controls[account_item].value.length == 4 && /^\d+$/.test(this.form_account.controls[account_item].value)) ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_check6(account_item){
    let styles = {
      'display': this.form_account.controls[account_item].value == null ? 'none' : this.focus_item == account_item ? 'none' : (this.form_account.controls[account_item].value != '' && this.form_account.controls[account_item].value.length == 11 && /^\d+$/.test(this.form_account.controls[account_item].value)) ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_check7(account_item){
    let styles = {
      'display': this.form_account.controls[account_item].value == null ? 'none' : this.focus_item == account_item ? '' : (this.form_account.controls[account_item].value != '' && this.form_account.controls[account_item].value.length == 11 && /^\d+$/.test(this.form_account.controls[account_item].value)) ? 'none' : '',
    };
    return styles;
  }

  update_account(_account: account){   
    this.spinner_active = true;
    this.http.put<account>( this._api + "users/" + _account.uid,_account).pipe(takeUntil(this.destroy$)).subscribe(data => 
      {        
        if (data[0] == 'ok'){
        this.confirm_txt = "gegevens aangepast";
        }
        this.spinner_active = false;
      })
  }  

  confirm_account(){
    if (this.confirm_txt == 'gegevens opslaan?'){
        this._aposervice.change_account({
          fname: this.form_account.controls['fname'].value, lname: this.form_account.controls['lname'].value, mail: this.form_account.controls['mail'].value,
          telefoon: this.form_account.controls['telefoon'].value, RR: this.form_account.controls['RR'].value, land: this.form_account.controls['land'].value,
          straat: this.form_account.controls['straat'].value, nummer: this.form_account.controls['nummer'].value, bus: this.form_account.controls['bus'].value,
          postcode: this.form_account.controls['postcode'].value, gemeente: this.form_account.controls['gemeente'].value, info: this.form_account.controls['info'].value,
          nieuw: true, logged_in: false, failed_login: 0, uid: this._uid, bedrijfsnaam: this.form_account.controls['bedrijfsnaam'].value,vertegenwoordiger: this.form_account.controls['vertegenwoordiger'].value, 
          mail_fac: this.form_account.controls['mail_fac'].value, land_fac: this.form_account.controls['land_fac'].value,straat_fac: this.form_account.controls['straat_fac'].value, 
          nummer_fac: this.form_account.controls['nummer_fac'].value, bus_fac: this.form_account.controls['bus_fac'].value, postcode_fac: this.form_account.controls['postcode_fac'].value, 
          gemeente_fac: this.form_account.controls['gemeente_fac'].value, id: 0 })
      }
      else if (this.confirm_txt == 'gegevens aanpassen?'){
        var _account: account = {
          fname: this.form_account.controls['fname'].value, lname: this.form_account.controls['lname'].value, mail: this.form_account.controls['mail'].value,
          telefoon: this.form_account.controls['telefoon'].value, RR: this.form_account.controls['RR'].value, land: this.form_account.controls['land'].value,
          straat: this.form_account.controls['straat'].value, nummer: this.form_account.controls['nummer'].value, bus: this.form_account.controls['bus'].value,
          postcode: this.form_account.controls['postcode'].value, gemeente: this.form_account.controls['gemeente'].value, info: this.form_account.controls['info'].value,
          nieuw: true, logged_in: true, failed_login: 0, uid: this._uid, bedrijfsnaam: this.form_account.controls['bedrijfsnaam'].value,
          vertegenwoordiger: this.form_account.controls['vertegenwoordiger'].value, mail_fac: this.form_account.controls['mail_fac'].value, 
          land_fac: this.form_account.controls['land_fac'].value,straat_fac: this.form_account.controls['straat_fac'].value, 
          nummer_fac: this.form_account.controls['nummer_fac'].value, bus_fac: this.form_account.controls['bus_fac'].value, 
          postcode_fac: this.form_account.controls['postcode_fac'].value, 
          gemeente_fac: this.form_account.controls['gemeente_fac'].value, id: 0
          };
        this._aposervice.change_account(_account);
        this._aposervice.set_mailblock(true);
        this.update_account(_account);
        this.counter = 0;
      }
  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

}
