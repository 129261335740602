<app-header-two></app-header-two>
<!-- Subheader Start -->
<div class="andro_subheader pattern-bg primary-bg">
    <div class="container">
        <div class="andro_subheader-inner">
            <h1>Blog Grid</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Blog</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Blog Grid</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Blog Posts Start -->
<div class="section">
    <div class="container">
        <div class="row">
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6">
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single'>
                            <img src="assets/img/blog/1.jpg" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a href="#">Food</a>
                            <a href="#">Veg</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single'>Difference Is In the Soil</a> </h5>
                            <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                            <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6">
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single'>
                            <img src="assets/img/blog/2.jpg" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a href="#">Health</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single'>Planting Season Begins</a> </h5>
                            <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                            <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6">
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single'>
                            <img src="assets/img/blog/3.jpg" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a href="#">Food</a>
                            <a href="#">Dishes</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single'>Freshly Picked Organics</a> </h5>
                            <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                            <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6">
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single'>
                            <img src="assets/img/blog/4.jpg" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a href="#">Food</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single'>Make The Perfect Basket</a> </h5>
                            <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                            <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6">
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single'>
                            <img src="assets/img/blog/5.jpg" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a href="#">Food</a>
                            <a href="#">Tips</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single'>Avocado Tips & Tricks</a> </h5>
                            <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                            <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6">
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single'>
                            <img src="assets/img/blog/6.jpg" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a href="#">Seasons</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single'>Into the Blueberry Season</a> </h5>
                            <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                            <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6">
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single'>
                            <img src="assets/img/blog/7.jpg" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a href="#">Food</a>
                            <a href="#">Tips</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single'>Picking Grapes Tips</a> </h5>
                            <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                            <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Curabitur aliquet quam id dui posuere blandit.</p>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6">
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single'>
                            <img src="assets/img/blog/8.jpg" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a href="#">Food</a>
                            <a href="#">Breeding</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single'>Mixed Lemon Breeds</a> </h5>
                            <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                            <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6">
                <article class="andro_post">
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single'>
                            <img src="assets/img/blog/9.jpg" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">
                        <div class="andro_post-categories">
                            <a href="#">Food</a>
                            <a href="#">Dishes</a>
                        </div>
                        <div class="andro_post-desc">
                            <h5> <a routerLink='/blog-single'>Freshly Picked Organics</a> </h5>
                            <span> <span class="fw-600">Posted On</span> <a routerLink='/blog-single' class="andro_post-date">May 20, 2021</a> </span>
                            <p>Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single'> Read More </a>
                    </div>
                </article>
            </div>
            <!-- Article End -->
        </div>
        <!-- Pagination Start -->
        <ul class="pagination mb-0">
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item active">
                <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
            </li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
        </ul>
        <!-- Pagination End -->
    </div>
</div>
<!-- Blog Posts End -->
<app-insta-two></app-insta-two>
<app-footer></app-footer>