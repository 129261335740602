<div class="sidebar">
    <!-- Recent Posts Start -->
    <div class="sidebar-widget widget-recent-posts">
        <h5 class="widget-title">Terzake</h5>
        <perfect-scrollbar style="height: 850px;">
            <article class="post" *ngFor = "let product of _info.list_products" style="padding: 10px;">
                <a href="javascript:void(0)" (click)="select_product(product)" data-toggle="modal" data-target="#quickViewModal" data-keyboard="true"><img src="{{product.photo}}" alt="post"></a>
                <h6> <a routerLink='/blog-single'>{{product.name}}</a> </h6>
            </article>
        </perfect-scrollbar>
    </div> 
    <!-- Recent Posts End -->
    <!-- Categories Start -->
    <div class="sidebar-widget">
        <h5 class="widget-title"> Categorieën </h5>
        <ul class="sidebar-widget-list" *ngFor="let item of list_cat">
            <li> <a routerLink='/blog-masonary' (click)="change_cat(item.cat)"> {{item.cat}} <span>{{item.aantal}}</span> </a> </li> 
        </ul>
    </div>
    <!-- Categories End -->
    <div class="andro_newsletter-form" [ngStyle]="{'border': mailing_ok == 'yippie' ? '3px solid rgba(81,189,77,255)' : (mailing_ok == 'noway' || mailing_ok == 'novalid') ? '3px solid red' : '3px solid transparent'} "  style="height: 200px; margin-top: 30px; position: relative;">
        <h5>Nieuwsbrief</h5>
        <span [ngStyle]="{'display': (mailing_ok != 'yippie' && mailing_ok != 'noway' && mailing_ok != 'novalid') ? '' : 'none'} "><p> Snel op de hoogte van koopjes, nieuws, etc..<br>Schrijf je in en ontvang 5€ korting</p></span>
        <span [ngStyle]="{'display': mailing_ok == 'yippie' ? '' : 'none'} "><p> Bedankt voor de registratie<br>Check je mail voor jouw persoonlijk couponcode</p></span>
        <span [ngStyle]="{'display': mailing_ok == 'noway' ? '' : 'none'} "><p> Er is iets misgegaan<br>Dit e-mailadres is reeds geregistreerd</p></span>
        <span [ngStyle]="{'display': mailing_ok == 'novalid' ? '' : 'none'} "><p> Er is iets misgegaan<br>Dit is geen geldig e-mailadres</p></span>
        <mat-spinner style="position: absolute; top: 50%; left: 50%; color: green" [ngStyle]="{'display': spinner_active? '' : 'none'}" [diameter]="30"></mat-spinner>
        <form method="post">
            <div class="form-group">
                <input type="email" [(ngModel)]="_mailing" class="form-control" name="newsletter_email" placeholder="e-mailadres" value="" (keyup)="check_empty($event)">
            </div>
            <!-- <button type="submit" class="andro_btn-custom secondary btn-block shadow-none" name="button">Join Newsletter</button> -->
        </form>
        <font size="1" [ngStyle]="{'display': mailing_ok == 'yippie' ? '' : 'none'} "><i class="fas fa-check fa-2x" style="color: rgba(81,189,77,255); position: absolute; right: 30px; top: 15px;" ></i></font>
        <font size="1" [ngStyle]="{'display': (mailing_ok == 'noway' || mailing_ok == 'novalid') ? '' : 'none'} "><i class="fas fa-times fa-2x" style="color: red; position: absolute; right: 30px; top: 15px;" ></i></font>
    </div>
</div>