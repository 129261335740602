import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ApoService } from 'src/app/apo.service';
import { AuthService } from 'src/app/auth.service';
import { account, filter, Product } from 'src/app/model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  aantal_mandje = 0;
  kostprijs_mandje = 0;
  @Input() page = "";
  hover = false;
  hover1 = false;
  aantal_favorieten = 0;
  favdisabled = true; 
  cartdisabled = false;
  favvisible: Boolean = false;
  @ViewChild('btn') btn: ElementRef;
  @ViewChild('btn1') btn1: ElementRef;
  api = "";
  account_id = 0;
  searchitem = "";
  list_products = [];
  myControl = new FormControl();
  options: String[] = [];
  filteredOptions: Observable<String[]>;

  private destroy$: Subject<void> = new Subject<void>();
  ngOnDestroy(){     
    this.destroy$.next();
    this.destroy$.complete();
  }

  search_products(){
    this.http.get<any>(this.api + "producten/" + this.searchitem).pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.list_products = data;      
    });
  }

  change_page(cat: string){    
    this._aposervice.select_cat(cat);
    (document.elementFromPoint(10, 5) as HTMLElement).click();
    if (cat.substring(0,1) == "_"){
      this._router.navigate(['/shop-v1']); 
      setTimeout(() => {
        this.myControl.patchValue("");
      }, 1000);
      }
  }

  constructor(public _aposervice: ApoService, public _authservice: AuthService, public _router: Router, public http: HttpClient) {

    _aposervice.current_mandje.pipe(takeUntil(this.destroy$)).subscribe(data => {
     
      this.kostprijs_mandje = 0;
      this.aantal_mandje = 0;

      for (let x of data){
        this.aantal_mandje = this.aantal_mandje + x.quantity + x.quantity_free;
        this.kostprijs_mandje = this.kostprijs_mandje + x.product.price * x.quantity;
      }
      this.kostprijs_mandje = +this.kostprijs_mandje.toFixed(2);

      if (this.aantal_mandje == 0){this.cartdisabled = true;}
      else {this.cartdisabled = false;}
    })

    _aposervice.current_listfav.pipe(takeUntil(this.destroy$)).subscribe(data => { 
      this.aantal_favorieten = data.length;
      if (this.aantal_favorieten == 0){this.favdisabled = true;}
      else {this.favdisabled = false;}
    });

    

    _aposervice.current_api.pipe(takeUntil(this.destroy$)).subscribe(data => { 
      this.api = data;
    });

    _aposervice.current_account.pipe(takeUntil(this.destroy$)).subscribe(data => {
      
        if (data.logged_in){
          this.http.get<any>(this.api + "favorieten/" + data.id).pipe(takeUntil(this.destroy$)).subscribe(fav => {            
            let list_fav = [];  
            for (let x of fav) {
              list_fav.push(x.product_id);
            }
            _aposervice.make_list_fav(list_fav);
           });

          this.favvisible = true;
          this.account_id = data.id;
        }
        else {
          this.favvisible = false;
          this.account_id = 0;
        }

      });

     _authservice.current_error.pipe(takeUntil(this.destroy$)).subscribe(data =>{
       if (data != ''){
        this.btn1.nativeElement.click();  
       }       
     });

     this.myControl.valueChanges.subscribe((val) => {       
       this.searchitem = val;
       if (val.length > 2){
        this.http.get<any>(this.api + "producten/" + val).pipe(takeUntil(this.destroy$)).subscribe(data => {
          this.filteredOptions = data;        
          });
       }
       else {this.filteredOptions = undefined}

     })

   }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.btn.nativeElement.click();  
    // }, 500);        
  } 

  change_cat(_cat){
    this._aposervice.change_infocat(_cat);
  }
  
  logout(){
    var account1: account = {lname: '', fname: '', mail: '', telefoon: '', RR: '', land: '', straat: '', nummer: '', bus: '', postcode: '', gemeente: '', info: '', 
    nieuw: true, logged_in: false, failed_login:0, uid: "0", bedrijfsnaam: '',vertegenwoordiger: '', mail_fac: '', land_fac: '',straat_fac: '', 
    nummer_fac: '', bus_fac: '', postcode_fac: '', gemeente_fac: '', id: 0 };  
    this._authservice.SignOut();
    this._aposervice.change_account(account1);
    this._aposervice.logout(true);
    this._aposervice.set_mailblock(false);
    this._router.navigate(['']);    
  }

  change_modus(){
    this._aposervice.change_modusaccount('data');
  }

  setMyStyles_account(){
    let styles = {
      'display': this.favvisible ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_login(){
    let styles = {
      'display': !this.favvisible ? '' : 'none',
    };
    return styles;
  }

  setMyStyles_favoriet_color(){
    let styles = {
      'background-color': this.hover1 ? '' : this.aantal_favorieten != 0 ? 'red': '',
    };
    return styles;
  }

  setMyStyles_favoriet_color1(){
    let styles = {
      'color': this.hover1 ? '' : this.aantal_favorieten != 0 ? 'white' : ''
    };
    return styles;
  }

  setMyStyles_categorie(){
    let styles = {
      'visibility': (this.page == "home") ? 'hidden' : 'normal',
    };
    return styles;
  }

  setMyStyles_cart(){
    let styles = {
      'background-color': this.aantal_mandje != 0 ? 'rgba(81,189,77,255)' : '',
      'border-radius': this.aantal_mandje != 0 ? '10px' : '',
    };
    return styles;
  }

  setMyStyles_user(){
    let styles = {
      'background-color': this.favvisible ? 'rgba(81,189,77,255)' : '',
      'border-radius': '10px'      
    };
    return styles;
  }

  setMyStyles_cart_color(){
    let styles = {
      'color': this.hover ? '' : this.aantal_mandje != 0 ? 'white' : '',
    };
    return styles;
  }

}
