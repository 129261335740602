<div id="top"></div>
<app-header></app-header>                  

<!-- Blog Posts Start -->
<div class="section" style="min-height: 500px">
    <div class="container">
        <img [ngStyle]="{'display': spinner_active ? '' : 'none'}" style="left: 40%;z-index:10; position: absolute; opacity: 0.5; height: 450px;" src="assets/img/logo.gif">
        <div class="row masonry">
            <div style="font-size: 15px; color: #5d5d5d; font-style: italic; padding-bottom: 15px; padding-left: 15px;" [ngStyle]="{'display': cat == '' ? 'none' : ''}">
                Categorie {{cat}} <a style="padding-left: 10px;" href="javascript: void(0)" (click)="change_cat()">(verwijderen)</a></div>
            <!-- Article Start -->
            <div class="col-lg-4 col-md-6 masonry-item" *ngFor="let item of list_info" >
                <article class="andro_post" >
                    <div class="andro_post-thumb">
                        <a routerLink='/blog-single' (click)="select_info(item.id)">
                            <img src="{{item.photo}}" alt="post">
                        </a>
                    </div>
                    <div class="andro_post-body">   
                        <div class="andro_post-categories">
                            <a routerLink='/shop-v1' (click)="change_page(item.category)">{{item.category}}</a>
                        </div>
                        <div class="andro_post-desc" style="height: 225px; text-align: justify;">
                            <h5> <a routerLink='/blog-single' (click)="select_info(item.id)">{{item.title}}</a> </h5>
                            <div [innerHTML]="item.text_intro"></div>
                        </div>
                        <a class="andro_btn-custom btn-block" routerLink='/blog-single' (click)="select_info(item.id)"> Lees meer </a>
                    </div>
                </article>                        
            </div>
        </div>        
    </div>
</div>
<!-- Blog Posts End -->
<app-insta-two></app-insta-two>
<app-footer (to_top)="to_top()"></app-footer>
